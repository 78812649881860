import { NON_DIGIT_REGEX } from '@gf/cross-platform-lib/constants';

export const formatString = function (fmt: string, ...args: any[]): string {
  return fmt.replace(/{(\d+)}/g, function (match, number) {
    return typeof args[number] != 'undefined' ? `${args[number]}` : match;
  });
};

export const formatUSPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  if (phoneNumber.length < 4) return phoneNumber;
  if (phoneNumber.length < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const formatNumber = (value: string, isZipCode?: boolean) => {
  const number = isZipCode ? value.replace(/[^0-9]/g, '') : value.replace(/[^0-9,.]/g, '');
  return isZipCode ? number.slice(0, ZIP_CODE_LENGTH) : number;
};

export const formatPhoneNumber = (value: string) => {
  const cleaned = value.replace(NON_DIGIT_REGEX, '');
  const formattedNo = formatUSPhoneNumber(cleaned);
  const isValid = cleaned.length === 10 || cleaned.length === 0 ? true : false;
  return { value: formattedNo, isValid };
};

const ZIP_CODE_LENGTH = 5;
