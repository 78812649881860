import { css } from 'styled-components';
import { baseStyles } from './RHFInput.base.styles';

export const styles = {
  ...baseStyles,
  input: css<{ disabled?: boolean }>`
    ${baseStyles.input};
    ${({ disabled }) => disabled && 'cursor: not-allowed'}
  `
};
