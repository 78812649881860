import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { Carbonicons, IconName, StyledText } from '@gf/cross-platform-lib/components';
import { styles } from './ShareEventModal.styles';

const SelectionWrapper = styled.TouchableOpacity`
  ${styles.selectionWrapper}
`;
const Selection = styled.View`
  ${styles.selection}
`;

export interface IOption {
  title?: string;
  name: string;
  icon: IconName | React.ReactNode;
  action?: (param: any) => void;
}

export interface SharingOptionProps {
  option: IOption;
  isActive: boolean;
  centered?: boolean;
  onSelectOption: ({ name, action }: IOption) => void;
}

export const SharingOption = ({ option, isActive, centered, onSelectOption }: SharingOptionProps) => {
  const { icon, name, title } = option;
  return (
    <SelectionWrapper
      onPress={() => {
        onSelectOption(option);
      }}
      isActive={isActive}
      key={name}
    >
      <Selection centered={centered}>
        {title && (
          <StyledText typeToken='bodyCompact02' color='textPrimary'>
            {title}
          </StyledText>
        )}
        {typeof icon === 'string' ? <Carbonicons name={icon as IconName} size={16} color='#323232' /> : icon}
      </Selection>
    </SelectionWrapper>
  );
};
