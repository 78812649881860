import 'setimmediate';
import 'raf/polyfill'; // resolve requestKeyframe issue for Moti
import React from 'react';

import '../styles/globalStyles.css';
import { GoFanApp, HeadSEO, IMyAppProps } from '@gf/cross-platform-lib';
import { Hydrate as ReactQueryHydrate } from '@tanstack/react-query';
import { COOKIE_ACKNOWLEDGED_KEY } from '@gf/cross-platform-lib/constants';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import { CookieBannerProvider, CookieBannerContainer } from '@gf/cross-platform-lib/modules/web/CookieBanner';

import get from 'lodash/get';
import { isHideCookieBanner } from '@gf/cross-platform-lib/utils';
import 'raf/polyfill';

if (!global.setImmediate) {
  // @ts-ignore
  global.setImmediate = setTimeout;
}

// FIXME remove this once when gets released, this is reanimated fix
// fix issue going when using Moti
// https://github.com/software-mansion/react-native-reanimated/issues/3355
if (typeof window !== 'undefined') {
  // @ts-ignore
  window._frameTimestamp = null;
}

function MyApp({ Component, pageProps, hideCookieBanner }: IMyAppProps & AppProps) {
  return (
    <>
      <HeadSEO seoData={pageProps?.seo} />
      <GoFanApp>
        <ReactQueryHydrate state={pageProps.reactQueryDehydratedState}>
          <Component {...pageProps} />
        </ReactQueryHydrate>
      </GoFanApp>
      {!hideCookieBanner && (
        <CookieBannerProvider>
          <CookieBannerContainer />
        </CookieBannerProvider>
      )}
    </>
  );
}

MyApp.getInitialProps = async (context: AppContext): Promise<IMyAppProps & AppInitialProps> => {
  const ctx = await App.getInitialProps(context);
  try {
    const hideCookieBanner = isHideCookieBanner(
      get(context, 'ctx.pathname'),
      get(context, `ctx.req.cookies.${COOKIE_ACKNOWLEDGED_KEY}`) || '',
      get(context, 'ctx.req.headers.user-agent') || ''
    );
    return { ...ctx, hideCookieBanner };
  } catch (error) {
    console.error('Error in _app.tsx getInitialProps', error);
    return { hideCookieBanner: false, ...ctx };
  }
};

export default MyApp;
