import React from 'react';
import { SegmentProvider } from './SegmentProvider';

interface TrackingProviderProps {
  children: React.ReactNode;
}

const TrackingProvider = ({ children }: TrackingProviderProps) => {
  return <SegmentProvider>{children}</SegmentProvider>;
};

export { TrackingProvider };
