import React, { useEffect, useState } from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { FieldErrorsImpl, useForm } from 'react-hook-form';

import { Tab } from '../Tab';
import { styles } from './ApplyToAllTab.styles';
import { TicketsAdditionalForm } from '../TicketsAdditionalForm';

import { AdditionalFormFields } from '@gf/cross-platform-lib/interfaces';

const Content = styled.View`
  ${styles.content}
`;

export interface ApplyToAllTabProps {
  fields: AdditionalFormFields;
  onCancel: () => void;
  onConfirm: (data: any) => void;
  isActive: boolean;
}

export const ApplyToAllTab = ({ fields, onConfirm, onCancel, isActive }: ApplyToAllTabProps) => {
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true
  });
  const [ignoreTabSelection, setIgnoreTabSelection] = useState<boolean>(true);
  useEffect(() => {
    const fieldsData = {};
    Object.keys(fields).forEach(key => {
      Object.assign(fieldsData, {
        [key]: fields[key].value
      });
    });
    setTimeout(() => {
      reset(fieldsData);
    }, 0);
  }, [fields, reset, setFocus]);
  useEffect(() => {
    if (isActive) setIgnoreTabSelection(false);
    else setIgnoreTabSelection(true);
  }, [isActive]);
  return (
    <Tab
      title='Apply to all?'
      subTitle='Are you sure you want to apply this information to all tickets? Information previously entered for individual tickets will be removed.'
      confirmLabel='Apply all'
      onCancel={onCancel}
      onConfirm={handleSubmit(onConfirm)}
      disableConfirm={!isValid}
      ignoreTabSelection={ignoreTabSelection}
    >
      <Content>
        <TicketsAdditionalForm
          fields={fields}
          setValue={setValue}
          control={control}
          errors={
            errors as FieldErrorsImpl<{
              [x: string]: string;
            }>
          }
          onSubmit={handleSubmit(onConfirm)}
          disabled
        />
      </Content>
    </Tab>
  );
};
