import { useQuery } from '@tanstack/react-query';
import { fetchValidTlds } from '../apis/validationApis';
import { buildQueryKey } from '../utils';

export const useFetchValidTldsQuery = () => {
  return useQuery({
    queryKey: buildQueryKey(['validation-valid-tlds']),
    queryFn: () => fetchValidTlds(),
    staleTime: 1000 * 60 * 60 * 24 * 30 // 30 days (TLD list will rarely change)
  });
};
