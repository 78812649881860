import React, { useEffect, useState } from 'react';
import { GetCurrentApplicationConfiguration } from '../../utils';
import {
  FirebaseContext,
  FirebaseProviderProps,
  FirebaseRef,
  getAppAuthInitValues,
  getG4InitValues,
  IFirebaseContextValue,
  IGA4
} from './FirebaseContext';

const appConfig = GetCurrentApplicationConfiguration();

export const FirebaseProvider = ({ children }: FirebaseProviderProps) => {
  const [contextValue, setContextValue] = useState<IFirebaseContextValue>({
    appAuth: getAppAuthInitValues(),
    ga4: getG4InitValues(),
    isReady: false,
    getAuthToken(): Promise<unknown> {
      return Promise.resolve(undefined);
    }
  });

  const initFirebase = async () => {
    const { initializeApp } = await import('firebase/app');
    const {
      getAuth,
      createUserWithEmailAndPassword,
      sendPasswordResetEmail,
      signOut,
      fetchSignInMethodsForEmail,
      signInWithEmailAndPassword,
      updatePassword,
      reauthenticateWithCredential,
      EmailAuthProvider
    } = await import('firebase/auth');

    const app = initializeApp({
      ...appConfig.settings.firebase,
      authDomain: undefined,
      appId: appConfig.settings.firebase.webAppId
    });
    const appAuth = getAuth(app);
    const logEventAsync = async (eventName: string, payload?: any) => {
      setTimeout(async () => {
        const { getAnalytics, logEvent } = await import('firebase/analytics');
        const analytics = getAnalytics(app);
        await logEvent(analytics, eventName, payload);
      }, 0);
    };
    const updatedAppAuth = {
      ...appAuth,
      createUserWithEmailAndPassword: (email: string, password: string) => {
        return createUserWithEmailAndPassword(appAuth, email, password);
      },
      fetchSignInMethodsForEmail: (email: string) => {
        return fetchSignInMethodsForEmail(appAuth, email);
      },
      sendPasswordResetEmail: (email: string) => {
        return sendPasswordResetEmail(appAuth, email);
      },
      signInWithEmailAndPassword: (email: string, password: string) => {
        return signInWithEmailAndPassword(appAuth, email, password);
      },
      signOut: () => {
        return signOut(appAuth);
      },
      currentUser: () => {
        return appAuth.currentUser;
      },
      updatePassword: (newPassword: string) => {
        return updatePassword(appAuth.currentUser!, newPassword);
      },
      reauthenticateWithCredential: (oldPassword: string) => {
        if (appAuth.currentUser && appAuth.currentUser.email !== null) {
          return reauthenticateWithCredential(
            appAuth.currentUser!,
            EmailAuthProvider.credential(appAuth.currentUser.email, oldPassword)
          );
        }
        return Promise.reject('Missing currentUser');
      }
    };
    const getG4Helpers: () => IGA4 = () => ({
      track: async (eventName: string, payload: any) => {
        await logEventAsync(eventName, payload);
      },
      pageView: async (name: string, _payload?: any) => {
        await logEventAsync('screen_view' as any, {
          firebase_screen: name,
          firebase_screen_class: name,
          page_title: name
        });
      }
    });

    appAuth?.onAuthStateChanged(_user => {
      const updatedContextValue = {
        ...contextValue,
        ga4: getG4Helpers(),
        isReady: true,
        appAuth: updatedAppAuth as any,
        getAuthToken: () => {
          if (appAuth?.currentUser) {
            return appAuth?.currentUser.getIdToken();
          }
          return new Promise(resolve =>
            appAuth.onAuthStateChanged((user: any) => {
              return !user ? resolve(null) : user.getIdToken().then((token: string) => resolve(token));
            })
          );
        }
      };
      setContextValue(updatedContextValue);
      FirebaseRef.current = updatedContextValue;
    });
  };

  useEffect(() => {
    try {
      initFirebase();
    } catch (e) {
      console.warn('INIT FIREBASE ERROR', e);
    }
  }, []);

  return <FirebaseContext.Provider value={contextValue}>{contextValue?.isReady && children}</FirebaseContext.Provider>;
};
