import dayjs from 'dayjs';
import { ProductSeating } from '@gf/cross-platform-lib/interfaces';
import { doesExist, formatString, useCurrencyFormatter } from '@gf/cross-platform-lib/utils';
import { getDateRange } from './useDate';
import pluralize from 'pluralize';

export enum Reason {
  justFine = 0,
  soldOut,
  tooEarly,
  tooLate,
  notEnough,
  lowInventory
}

const Messages: string[] = [];
Messages[Reason.justFine] = '';
Messages[Reason.soldOut] = "We're sorry, this ticket for the event is sold out.";
Messages[Reason.tooEarly] = 'This ticket is not yet available for purchase';
Messages[Reason.tooLate] = 'This ticket is no longer available for purchase.';
Messages[Reason.notEnough] = 'There are not enough of this ticket.';
Messages[Reason.lowInventory] = '{0} {1} left';

export type UseTicketTypeResult = {
  name: string;
  canSell: boolean; // whether we can sell this ticket type
  reason: Reason;
  message?: string; // message explaining why we can't sell this ticket type
  formattedPrice: string;
};

export const useTicketType = (ticketType: ProductSeating, desiredQuantity?: number): UseTicketTypeResult => {
  let result: UseTicketTypeResult = {
    name: '',
    canSell: true,
    message: undefined,
    reason: Reason.justFine,
    formattedPrice: ''
  };

  result.name = ticketType.name;

  const { format: formatCurrency } = useCurrencyFormatter();
  result.formattedPrice = formatCurrency(ticketType.price);

  if (ticketType.salesStartDateTime && ticketType.salesEndDateTime) {
    const now = dayjs();
    const salesStartDateTime = dayjs(ticketType.salesStartDateTime);
    const salesEndDateTime = dayjs(ticketType.salesEndDateTime);

    const { currentlyWithinRange } = getDateRange(
      ticketType.salesStartDateTime,
      ticketType.salesEndDateTime,
      ticketType.timezone
    );

    console.assert(
      salesStartDateTime.isBefore(salesEndDateTime),
      `ticket type sales start ${salesStartDateTime} should be before sales end ${salesEndDateTime}`
    );

    result.canSell = currentlyWithinRange();
    if (!result.canSell) {
      if (now.isBefore(salesStartDateTime)) result.message = Messages[Reason.tooEarly];
      else if (now.isAfter(salesEndDateTime)) result.message = Messages[Reason.tooLate];
      else result.message = 'It is simultaneously the start and the end of the sales window';
    }
  }

  const productSales = ticketType.productSales;
  if (productSales) {
    const remainingQuantity = productSales?.remainingQuantity;

    if (
      result.canSell &&
      ((doesExist(remainingQuantity) && remainingQuantity! === 0) || (productSales.soldOut ?? false))
    ) {
      result.canSell = false;
      result.reason = Reason.soldOut;
      result.message = Messages[Reason.soldOut];
    }

    if (
      result.canSell &&
      doesExist(remainingQuantity) &&
      doesExist(desiredQuantity) &&
      remainingQuantity! < desiredQuantity!
    ) {
      result.canSell = false;
      result.reason = Reason.notEnough;
      result.message = Messages[Reason.notEnough];
    }

    if (result.canSell && doesExist(remainingQuantity) && remainingQuantity! <= 10) {
      result.reason = Reason.lowInventory;
      result.message = formatString(
        Messages[Reason.lowInventory],
        remainingQuantity,
        pluralize('ticket', remainingQuantity)
      );
    }
  }

  return result;
};

export { useTicketType as useProductSeating };
