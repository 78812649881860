import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { useCartState, useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { useModal } from '@gf/cross-platform-lib/providers';
import { styles } from './PaymentValidtionModal.styles';
import { Carbonicons, StyledButton } from '@gf/cross-platform-lib/components';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const Container = styled.View`
  ${styles.container};
`;

const Title = styled.Text`
  ${styles.title};
`;

const DescriptionTitle = styled.Text`
  ${styles.descriptionTitle};
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

const BtnContainer = styled.View<{ isCenter: boolean }>`
  ${styles.container};
  justify-content: ${({ isCenter = false }) => (isCenter ? 'center' : 'flex-end')};
`;

const ContentWrapper = styled.View`
  ${styles.contentWrapper}
`;

const ContentContainer = styled.View<{ paddingRight: number }>`
  ${styles.contentContainer}
`;

const CloseBtnContainer = styled.TouchableOpacity`
  ${styles.closeBtnContainer}
`;

export interface PaymentValidationModalProps {
  title: string;
  description: string;
}

export const PaymentValidationModal = ({ title, description }: PaymentValidationModalProps) => {
  const { isMobile } = useMediaQuery();
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const { onConfirm = () => {}, subDescriptionTitle, largeContent = false } = modalProps || {};

  const modalHeight = isMobile ? 212 : 190;
  const modalWidth = isMobile ? 320 : 640;

  const cartState = useCartState();
  const { cartSchools } = cartState;
  const multipleEventsInCart = cartSchools.map(school => school.events).flat()?.length > 1;

  return (
    <ModalWrapper isMobile={isMobile} height={modalHeight} width={isMobile ? 320 : modalWidth}>
      <CloseBtnContainer
        onPress={() => {
          onConfirm();
          hideModal();
        }}
      >
        <Carbonicons name='close' size={22} color='#161616' />
      </CloseBtnContainer>
      <ContentWrapper>
        <ContentContainer paddingRight={isMobile ? 40 : 84}>
          <Container>
            <Title>{title}</Title>
          </Container>
          <SubTitle
            maxWidth={isMobile ? 250 : 580}
            numberOfLines={multipleEventsInCart ? 3 : 4}
            isMobile={isMobile}
            marginBottom={isMobile || multipleEventsInCart ? (largeContent ? 0 : 24) : 48}
          >
            {description}
          </SubTitle>
          <DescriptionTitle
            isMobile={isMobile}
            marginBottom={isMobile || multipleEventsInCart ? (largeContent ? 0 : 24) : 48}
          >
            {subDescriptionTitle}
          </DescriptionTitle>
        </ContentContainer>
      </ContentWrapper>
      <BtnContainer isCenter>
        <StyledButton
          buttonType='primary'
          title='OK'
          accessibilityLabel='OK'
          onPress={async () => {
            await onConfirm();
            hideModal();
          }}
          width='128px'
        />
      </BtnContainer>
    </ModalWrapper>
  );
};
