import React, { useState, useEffect } from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { FieldErrorsImpl, FieldValues, useForm } from 'react-hook-form';
import pluralize from 'pluralize';

import { StyledText } from '@gf/cross-platform-lib/components/StyledText';
import { Checkbox } from '@gf/cross-platform-lib/components/Checkbox';
import { Tab } from '../Tab';
import { styles } from './AddInformationTab.styles';
import { TicketsAdditionalForm } from '../TicketsAdditionalForm';
import { AdditionalFormFields } from '@gf/cross-platform-lib/interfaces';

import { TicketsAdditionalInfo } from '@gf/cross-platform-lib/models';

const Content = styled.View`
  ${styles.content}
`;

const Info = styled(StyledText)`
  ${styles.info}
`;

const ApplyAllCheckbox = styled.View`
  ${styles.applyAllCheckbox}
`;

const Label = styled(StyledText)`
  ${styles.label}
`;

type FormState = 'initial' | 'submitting' | 'submitted';
type Mode = 'Edit' | 'Add';

export interface AddInformationTabProps {
  fields: AdditionalFormFields;
  mode: Mode;
  hasApplyAllCheckbox?: boolean;
  totalForm: number;
  perTicket?: boolean;
  formIndex: number;
  appliedToAll?: boolean;
  onCancel: () => void;
  onConfirm: (data: FieldValues, isAppliedToAll: boolean) => void;
}

export const AddInformationTab = ({
  fields,
  mode,
  hasApplyAllCheckbox,
  totalForm,
  perTicket,
  formIndex,
  appliedToAll,
  onConfirm,
  onCancel
}: AddInformationTabProps) => {
  const {
    control,
    setValue,
    handleSubmit,
    reset,
    setFocus,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
    shouldFocusError: true
  });

  const [isAppliedToAll, setIsAppliedToAll] = useState<boolean>(appliedToAll || false);
  const [formState, setFomrState] = useState<FormState>('initial');

  const title = mode == 'Add' ? 'Add information' : 'Edit information';
  const additionalInfo = (function () {
    if (!perTicket) {
      return `Additional information`;
    }
    if (isAppliedToAll) {
      return `Additional information (${totalForm} ${pluralize('ticket', totalForm)})`;
    }
    return totalForm > 1 ? `Additional information (${formIndex + 1} of ${totalForm})` : `Additional information`;
  })();

  const onSubmit = (data: FieldValues) => {
    onConfirm(data, isAppliedToAll);
    setFomrState('submitting');
  };

  useEffect(() => {
    const fieldsData = {};
    Object.keys(fields).forEach(key => {
      Object.assign(fieldsData, {
        [key]: fields[key].value
      });
    });
    setTimeout(() => {
      reset(fieldsData);
      setFomrState('submitted');
    }, 0);
  }, [fields, setFocus, reset]);

  useEffect(() => {
    if (formState === 'submitted') {
      const firstField = TicketsAdditionalInfo.getOrderedFields(fields)[0];
      if (firstField) {
        setFocus(`${firstField.id}`);
      }
    }
  }, [formState, fields]);
  return (
    <Tab
      title={title}
      subTitle='Please complete the information below. All fields are required unless otherwise noted.'
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit)}
      disableConfirm={!isValid}
    >
      <Content>
        <Info typeToken='heading01' color='text-primary'>
          {additionalInfo}
        </Info>
        <TicketsAdditionalForm
          fields={fields}
          control={control}
          setValue={setValue}
          errors={
            errors as FieldErrorsImpl<{
              [x: string]: string;
            }>
          }
          onSubmit={handleSubmit(onSubmit)}
        />
        {hasApplyAllCheckbox && (
          <ApplyAllCheckbox>
            <Checkbox
              testId={isAppliedToAll ? 'apply-all-checked' : 'apply-all-un-check'}
              disabled={false}
              value={isAppliedToAll}
              color='#323232'
              onValueChange={() => setIsAppliedToAll(!isAppliedToAll)}
              label={
                <Label htmlFor='apply-all' typeToken='bodyCompact01' color='textPrimary'>
                  Apply information to all tickets
                </Label>
              }
            />
          </ApplyAllCheckbox>
        )}
      </Content>
    </Tab>
  );
};
