import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalWrapper: css`
    border-radius: 4px;
    margin: auto;
    padding: 16px;
    width: 320px;
    background: #f4f4f4;
    overflow-y: auto;
    max-height: 60%;
  `,
  title: css`
    width: 288px;
    color: rgb(50, 50, 50);
    font-size: 20px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
    margin-bottom: 4px;
  `,
  description: css`
    width: 288px;
    color: rgb(22, 22, 22);
    font-size: 14px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
  `,
  closedIconWrapper: css`
    margin-left: 269px;
    margin-bottom: 24px;
  `,
  closeButtonWrapper: css`
    margin-top: 32px;
    height: 44px;
    border-top-width: 0.5px;
    border-top-color: rgba(60, 60, 67, 0.36);
    justify-content: center;
  `
};
