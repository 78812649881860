import { baseStyles } from './RedemptionModal.base.styles';
import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  ...baseStyles,
  buttonWrapper: css<{ isMobile: boolean }>`
    margin-top: 16px;
    position: ${({ isMobile }) => (isMobile ? 'absolute' : 'unset')};
    bottom: 10%;
  `
};
