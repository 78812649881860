import { Platform } from 'react-native';
import { GOFAN_APP_PAGES } from '../constants';

export const bottomNavigator = {
  home: 'home-menu',
  tickets: 'tickets-menu',
  schools: 'schools-menu',
  favoriteSchool: 'favorite-school-view',
  more: 'more-menu'
};

export const leftBarMenu = {
  signIn: 'sign-in',
  signUp: 'sign-up',
  signOut: 'sign-out',
  changePassword: 'change-password'
};

export const homePage = {
  searchHeader: 'search-header',
  searchComponent: {
    searchBoxWrapper: 'search-box-wrapper',
    wrapper: 'search-wrapper',
    searchInput: 'search-input',
    clearInput: 'clear-search-btn',
    searchComponent: 'search-component'
  },
  searchResults: {
    schoolName: 'search-school-name',
    schoolItem: 'search-school-item',
    schoolDistance: 'search-school-distance',
    schoolVenue: 'search-school-venue',
    favIcon: 'favorite-icon',
    title: (name: string) => name.toLowerCase().split(' ').join('-')
  },
  searchList: {
    recentlyView: 'search-recently-view-section',
    myFavorite: 'search-my-favorite-section',
    result: 'search-results-section',
    noResult: 'search-no-result'
  }
};

export const mySchoolsPage = {
  schoolItemWrapper: 'school-item-wrapper',
  schoolWrapper: 'school-wrapper',
  schoolName: (name: string) => ({
    name
  }),
  favorSchoolBtn: 'favor-school-btn'
};

export const schoolPage = {
  eventCard: 'event-card',
  fundraiserCard: 'fundraiser-card',
  filterCategory: 'filter-by-category',
  filterHomeAway: 'filter-by-home-away',
  filterSchool: 'filter-by-school',
  filterItem: 'filter-item',
  reloadEvent: 'reload-btn',
  checkForNewEvent: 'check-for-new-event',
  visitSupport: 'visit-our-support-page',
  aboutSection: 'event-list-about-section',
  eventList: 'event-list-section-full',
  eventListToday: 'event-list-section-today',
  eventListUpcoming: 'event-list-section-upcoming',
  eventTag: 'event-tag',
  schoolInfoSection: 'school-info-section',
  schoolName: 'school-name'
};

export const eventCardComponent = {
  name: 'event-name',
  days: 'day-of-week',
  monthDay: 'month-day-of-year',
  year: 'year',
  time: 'time',
  startDate: 'event-start',
  endDate: 'event-end',
  sport: 'sport',
  activityLevels: 'activity-levels',
  venue: 'venue',
  buyProduct: 'buy-tickets-btn',
  expandButton: 'expand-button',
  secondarySection: 'event-list-more-section',
  moreInfo: 'more-info'
};

export const seasonCardComponent = {
  sport: 'sport',
  level: 'level',
  date: 'season-date',
  eventSchedule: 'event-schedule'
};

export const passCardComponent = {
  sport: 'sport',
  date: 'pass-date'
};

export const mobilePassComponent = {
  wrapper: 'pass-wrapper',
  body: 'pass-body',
  name: 'pass-name',
  sport: 'sport',
  ownerName: 'owner-name',
  status: 'pass-status',
  transferStatus: 'pass-transfer-status',
  transferredTime: 'transferred-time',
  receivedEmail: 'received-email',
  sentToEmail: 'sent-to-email'
};

export const formFields = {
  email: 'email-input',
  password: 'password-input',
  confirmPassword: 'confirm-password-input',
  oldPassword: 'old-password',
  newPassword: 'new-password',
  confirmNewPasswordInput: 'confirm-new-password',
  changePasswordBtn: 'change-password-btn',
  signUpBtn: 'sign-up-btn',
  signInBtn: 'sign-in-btn',
  validateMsg: 'validate-msg'
};

export const transferPassDialog = {
  name: 'transfer-pass-dialog'
};

export const transferTicketDialog = {
  name: 'transfer-ticket-dialog',
  copyLink: 'copy-transfer-link',
  email: 'email-transferring',
  openInBrowser: 'open-in-browser',
  message: 'message-transferring',
  moreOptions: 'more-options'
};

export const declinePassDialog = {
  name: 'decline-pass-dialog',
  productName: 'product-name',
  declineBtn: 'decline-pass-btn',
  cancelBtn: 'cancel-btn'
};

export const eventDetailsPage = {
  schoolName: 'event-school',
  eventName: 'event-name',
  sport: 'sport',
  eventTime: 'event-time',
  product: {
    name: 'product-name',
    price: 'product-price',
    fee: 'product-fee',
    qty: 'product-quantity'
  },
  addToCart: 'add-to-cart-btn',
  accessCode: 'access-code-input',
  goToTop: 'go-to-top-btn'
};

export const seasonDetailsPage = {
  time: 'season-time',
  name: (name?: string) => (name ? name : 'season-name'),
  goToTop: 'go-to-top-btn'
};

export const reviewAndBuyPage = {
  schoolEventSection: 'school-event-detail-section',
  schoolDetailSection: 'school-detail-section',
  checkoutSection: {
    guestCheckout: 'guest-check-out-section',
    applePayCheckout: 'apple-pay-section',
    googlePayCheckout: 'google-pay-section'
  },
  paymentSection: 'payment-section',
  cartSummary: 'cart-summary-text',
  expandCollapse: 'expand-collapse-btn',
  deleteTicket: 'delete-ticket-icon',
  email: 'email',
  payWithCard: 'pay-credit-card-btn',
  eventInfo: {
    section: 'event-details-section',
    event: {
      name: 'event-name',
      time: 'event-datetime',
      sport: 'sport'
    }
  },
  product: {
    section: 'event-product-section',
    name: 'product-name',
    price: 'product-price',
    qty: 'product-quantity',
    promoCode: 'product-promo-code'
  },
  subTotal: 'subtotal',
  serviceFees: 'service-fees',
  tax: 'tax',
  totalSection: 'total-section',
  total: 'total',
  promoCodes: 'promo-codes-section',
  promoCode: {
    section: 'add-promo-code-section',
    addPromoCodeLabel: 'add-promo-code-label',
    addPromoCodeInput: 'add-promo-code-input',
    addPromoCodeBtn: 'add-promo-code-btn'
  },
  addFormInfo: 'add-info-btn',
  addInfoDialog: {
    section: 'info-dialog',
    save: 'info-dialog-save-btn',
    cancel: 'info-dialog-cancel-btn',
    field: (name: string) => name
  },
  button: {
    add: 'add',
    subtract: 'subtract'
  }
};

export const orderDetailsPage = {
  accessItNowSection: 'access-it-now-section',
  orderSection: 'order-section',
  yourOrderSection: 'your-order-header',
  alert: 'order-alert-section',
  notification: 'order-notification-section',
  event: {
    alert: 'event-alert-section',
    name: 'event-name',
    time: 'event-time',
    sport: 'sport-name',
    venue: 'venue',
    cancelledTag: 'event-cancelled-tag',
    viewTicketBtn: 'view-ticket-btn',
    transferTicketBtn: 'transfer-ticket-btn',
    calender: 'add-calendar-btn',
    share: 'share-btn',
    details: 'event-details-btn',
    print: 'print-btn'
  },
  receipt: {
    section: 'order-receipt-section',
    orderDetails: 'order-details-section',
    orderSummary: 'order-summary-section'
  }
};

export const ticketDetailsPage = {
  useSelectActions: 'use-select-section',
  useTransferBtn: 'use-transfer-btn',
  useAllBtn: 'use-all-btn',
  selectAllBtn: 'select-all-btn',
  ticketSection: 'ticket-section',
  showInfoBtn: 'show-info-btn',
  formField: 'form-field'
};

export const productTicket = {
  selectedTicket: 'selected-ticket',
  ticketCategory: 'ticket-category',
  ticketName: 'ticket-name',
  eventActivity: 'event-activity',
  eventTime: 'event-time',
  eventName: 'event-name',
  statusTime: 'status-time',
  fullName: 'full-name'
};

export const loadingIndicator = 'loading-icon';
export const favoriteIndicator = 'favorite-icon';

export const headerTestId = {
  home: 'search-view',
  reviewAndBuy: 'review-and-buy',
  school: 'school-details',
  mySchools: 'my-schools',
  smsTerms: 'sms-terms',
  signIn: 'sign-in',
  signUp: 'sign-up',
  changePassword: 'change-password',
  createPassword: 'create-password',
  createPasswordSuccess: 'create-password-success',
  myTickets: 'my-tickets',
  event: 'event-details',
  orders: 'orders',
  orderDetails: 'order-details',
  maintenance: 'maintenance',
  downloadTickets: 'download-tickets',
  updateApp: 'update-app'
};

type TestProps =
  | string
  | {
      id?: string;
      name?: string;
    };

export function testProperties(props: TestProps) {
  const IS_ANDROID = Platform.OS === 'android';
  const normProps = typeof props === 'string' ? { id: props } : props;
  const { id, ...rest } = normProps || {};
  /* TODO: Just return testID for QA & Staging ENV */
  return IS_ANDROID
    ? {
        accessibilityLabel: id,
        ...rest
      }
    : {
        testID: id,
        ...rest
      };
}

export const getHeaderTestId = (currentRoute: string) => {
  let testId = '';
  switch (currentRoute) {
    case GOFAN_APP_PAGES.home.path:
      testId = headerTestId.home;
      break;

    case GOFAN_APP_PAGES.reviewAndBuy.path:
      testId = headerTestId.reviewAndBuy;
      break;

    case GOFAN_APP_PAGES.school.path:
    case GOFAN_APP_PAGES.shortName.path:
      testId = headerTestId.school;
      break;

    case GOFAN_APP_PAGES.reviewAndBuy.path:
      testId = headerTestId.reviewAndBuy;
      break;

    case GOFAN_APP_PAGES['terms-of-use'].path:
      testId = 'terms-of-use';
      break;

    case GOFAN_APP_PAGES['privacy-policy'].path:
      testId = 'privacy-policy';
      break;

    case GOFAN_APP_PAGES['forgot-password'].path:
      testId = 'forgot-password';
      break;

    case GOFAN_APP_PAGES['seat-selection'].path:
    case GOFAN_APP_PAGES['season-seat-selection'].path:
    case GOFAN_APP_PAGES['hidden-event'].path:
    case GOFAN_APP_PAGES.event.path:
    case GOFAN_APP_PAGES.season.path:
      testId = headerTestId.event;
      break;

    case GOFAN_APP_PAGES['my-schools'].path:
      testId = headerTestId.mySchools;
      break;

    case GOFAN_APP_PAGES['event-sad-paths'].path:
      testId = 'event-sad-paths';
      break;

    case GOFAN_APP_PAGES['sign-up-success'].path:
      testId = 'sign-up-success';
      break;

    case GOFAN_APP_PAGES['check-email'].path:
      testId = 'check-email';
      break;

    case GOFAN_APP_PAGES.orders.path:
      testId = headerTestId.orders;
      break;

    case GOFAN_APP_PAGES.orderDetails.path:
      testId = headerTestId.orderDetails;
      break;

    case GOFAN_APP_PAGES.maintenance.path:
      testId = headerTestId.maintenance;
      break;

    case GOFAN_APP_PAGES.error.path:
      testId = 'error';
      break;

    case GOFAN_APP_PAGES.info.path:
      testId = 'info';
      break;

    case GOFAN_APP_PAGES.donations.path:
      testId = 'donations';
      break;

    case GOFAN_APP_PAGES.myTickets.path:
      testId = 'my-tickets';
      break;

    case GOFAN_APP_PAGES.transferTicket.path:
    case GOFAN_APP_PAGES.transferDonation.path:
    case GOFAN_APP_PAGES.transferMobilePass.path:
    case GOFAN_APP_PAGES.browserTransferTicket.path:
      testId = 'transfer-page';
      break;

    case GOFAN_APP_PAGES.redemption.path:
      testId = 'redemption';
      break;

    case GOFAN_APP_PAGES.passUnavailable.path:
      testId = 'passUnavailable';
      break;

    case GOFAN_APP_PAGES['season-renewal'].path:
    case GOFAN_APP_PAGES['legacy-season-renewal-decline'].path:
      testId = 'season-renewal';
      break;

    case GOFAN_APP_PAGES.downloadTickets.path:
      testId = headerTestId.downloadTickets;
      break;

    case GOFAN_APP_PAGES.profile.path:
    case GOFAN_APP_PAGES.profileNotifications.path:
    case GOFAN_APP_PAGES.profileMyInfo.path:
      testId = 'profile';
      break;

    case GOFAN_APP_PAGES.updateApp.path:
      testId = headerTestId.updateApp;
      break;

    case GOFAN_APP_PAGES.signIn.path:
      testId = headerTestId.signIn;
      break;

    case GOFAN_APP_PAGES.signUp.path:
      testId = headerTestId.signUp;
      break;

    case GOFAN_APP_PAGES.changePassword.path:
      testId = headerTestId.changePassword;
      break;

    case GOFAN_APP_PAGES.mobilePasses.path:
      testId = 'passes';
      break;

    case GOFAN_APP_PAGES.tickets.path:
      testId = 'tickets';
      break;
  }
  return testId ? `header-${testId}` : 'header';
};
