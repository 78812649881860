import { useCustomTracking } from './useCustomTracking';
import { useIdentifyTracking } from './useIdentifyTracking';
import { usePageViewTracking } from './usePageViewTracking';
import { useSegmentTracking } from '../../providers/Tracking';

export const useTracking = () => {
  const { reset: resetTracking } = useSegmentTracking();
  const customTrackings = useCustomTracking();
  const pageViewTrackings = usePageViewTracking();
  const identityTrackings = useIdentifyTracking();
  return {
    ...customTrackings,
    ...identityTrackings,
    ...pageViewTrackings,
    resetTracking
  };
};
