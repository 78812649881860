import React, { lazy, createContext, useContext, useState, Suspense } from 'react';
import { Platform } from 'react-native';
import { Modal } from './Modal';

let AccessCodeModal;
let ConfirmationModal;
let ErrorTransferModal;
let EventInfoDescriptionModal;
let EventToolTipModal;
let InvalidMessageModal;
let FundraiserUpsellModal;
let LimitedTicketModal;
let MobilePassDeclineModal;
let PassInvalidModal;
let PassTransferModal;
let PaymentValidationModal;
let PromotionLimitModal;
let RedeemOnDesktopModal;
let RedemptionModal;
let RedemptionTooEarlyModal;
let RemoveTicketModal;
let TicketsAdditionalModal;
let TicketTransferModal;
let RenewalDeclineModal;
let ShareEventModal;
let CalendarAdditionModal;
let CartExpiredModal;
let TicketPrintLimitModal;
let ErrorTaxModal;

// There is a bug in the version of Metro shipped with Expo SDK 49 that causes the app to crash when using
// lazy loading on native instead of just ignoring it. This will be fixed when the next version of Expo is
// released (it is already fixed in React Native 0.73, which that release will include). At that time, this conditional logic can be removed.
if (Platform.OS === 'web') {
  AccessCodeModal = lazy(() =>
    import('./Modals/AccessCodeModal').then(module => ({ default: module.AccessCodeModal }))
  );
  ConfirmationModal = lazy(() =>
    import('./Modals/ConfirmationModal').then(module => ({ default: module.ConfirmationModal }))
  );
  ErrorTransferModal = lazy(() =>
    import('./Modals/ErrorTransferModal').then(module => ({ default: module.ErrorTransferModal }))
  );
  EventInfoDescriptionModal = lazy(() =>
    import('./Modals/EventInfoDescriptionModal').then(module => ({ default: module.EventInfoDescriptionModal }))
  );
  EventToolTipModal = lazy(() =>
    import('./Modals/EventToolTipModal').then(module => ({ default: module.EventToolTipModal }))
  );
  InvalidMessageModal = lazy(() =>
    import('./Modals/InvalidMessageModal').then(module => ({ default: module.InvalidMessageModal }))
  );
  FundraiserUpsellModal = lazy(() =>
    import('./Modals/FundraiserUpsellModal').then(module => ({ default: module.FundraiserUpsellModal }))
  );
  LimitedTicketModal = lazy(() =>
    import('./Modals/LimitedTicketModal').then(module => ({ default: module.LimitedTicketModal }))
  );
  MobilePassDeclineModal = lazy(() =>
    import('./Modals/MobilePassDeclineModal').then(module => ({ default: module.MobilePassDeclineModal }))
  );
  PassInvalidModal = lazy(() =>
    import('./Modals/PassInvalidModal').then(module => ({ default: module.PassInvalidModal }))
  );
  PassTransferModal = lazy(() =>
    import('./Modals/PassTransferModal').then(module => ({ default: module.PassTransferModal }))
  );
  PaymentValidationModal = lazy(() =>
    import('./Modals/PaymentValidationModal').then(module => ({ default: module.PaymentValidationModal }))
  );
  PromotionLimitModal = lazy(() =>
    import('./Modals/PromotionLimitModal').then(module => ({ default: module.PromotionLimitModal }))
  );
  RedeemOnDesktopModal = lazy(() =>
    import('./Modals/RedeemOnDesktopModal').then(module => ({ default: module.RedeemOnDesktopModal }))
  );
  RedemptionModal = lazy(() =>
    import('./Modals/RedemptionModal').then(module => ({ default: module.RedemptionModal }))
  );
  RedemptionTooEarlyModal = lazy(() =>
    import('./Modals/RedemptionTooEarlyModal').then(module => ({ default: module.RedemptionTooEarlyModal }))
  );
  RemoveTicketModal = lazy(() =>
    import('./Modals/RemoveTicketModal').then(module => ({ default: module.RemoveTicketModal }))
  );
  TicketsAdditionalModal = lazy(() =>
    import('./Modals/TicketsAdditionalModal').then(module => ({ default: module.TicketsAdditionalModal }))
  );
  TicketTransferModal = lazy(() =>
    import('./Modals/TicketTransferModal').then(module => ({ default: module.TicketTransferModal }))
  );
  RenewalDeclineModal = lazy(() =>
    import('./Modals/RenewalDeclineModal').then(module => ({ default: module.RenewalDeclineModal }))
  );
  ShareEventModal = lazy(() =>
    import('./Modals/ShareEventModal').then(module => ({ default: module.ShareEventModal }))
  );
  CalendarAdditionModal = lazy(() =>
    import('./Modals/CalendarAdditionModal').then(module => ({ default: module.CalendarAdditionModal }))
  );
  CartExpiredModal = lazy(() =>
    import('./Modals/CartExpiredModal').then(module => ({ default: module.CartExpiredModal }))
  );
  TicketPrintLimitModal = lazy(() =>
    import('./Modals/TicketPrintLimitModal').then(module => ({ default: module.TicketPrintLimitModal }))
  );
  ErrorTaxModal = lazy(() => import('./Modals/ErrorTaxModal').then(module => ({ default: module.ErrorTaxModal })));
} else {
  AccessCodeModal = require('./Modals/AccessCodeModal').AccessCodeModal;
  ConfirmationModal = require('./Modals/ConfirmationModal').ConfirmationModal;
  ErrorTransferModal = require('./Modals/ErrorTransferModal').ErrorTransferModal;
  EventInfoDescriptionModal = require('./Modals/EventInfoDescriptionModal').EventInfoDescriptionModal;
  EventToolTipModal = require('./Modals/EventToolTipModal').EventToolTipModal;
  InvalidMessageModal = require('./Modals/InvalidMessageModal').InvalidMessageModal;
  FundraiserUpsellModal = require('./Modals/FundraiserUpsellModal').FundraiserUpsellModal;
  LimitedTicketModal = require('./Modals/LimitedTicketModal').LimitedTicketModal;
  MobilePassDeclineModal = require('./Modals/MobilePassDeclineModal').MobilePassDeclineModal;
  PassInvalidModal = require('./Modals/PassInvalidModal').PassInvalidModal;
  PassTransferModal = require('./Modals/PassTransferModal').PassTransferModal;
  PaymentValidationModal = require('./Modals/PaymentValidationModal').PaymentValidationModal;
  PromotionLimitModal = require('./Modals/PromotionLimitModal').PromotionLimitModal;
  RedeemOnDesktopModal = require('./Modals/RedeemOnDesktopModal').RedeemOnDesktopModal;
  RedemptionModal = require('./Modals/RedemptionModal').RedemptionModal;
  RedemptionTooEarlyModal = require('./Modals/RedemptionTooEarlyModal').RedemptionTooEarlyModal;
  RemoveTicketModal = require('./Modals/RemoveTicketModal').RemoveTicketModal;
  TicketsAdditionalModal = require('./Modals/TicketsAdditionalModal').TicketsAdditionalModal;
  TicketTransferModal = require('./Modals/TicketTransferModal').TicketTransferModal;
  RenewalDeclineModal = require('./Modals/RenewalDeclineModal').RenewalDeclineModal;
  ShareEventModal = require('./Modals/ShareEventModal').ShareEventModal;
  CalendarAdditionModal = require('./Modals/CalendarAdditionModal').CalendarAdditionModal;
  CartExpiredModal = require('./Modals/CartExpiredModal').CartExpiredModal;
  TicketPrintLimitModal = require('./Modals/TicketPrintLimitModal').TicketPrintLimitModal;
  ErrorTaxModal = require('./Modals/ErrorTaxModal').ErrorTaxModal;
}

import styled from '@gf/cross-platform-lib/styled-components';
import { useCloseModalOnRouteChange } from './useCloseModalOnRouteChange';

const Overlay = styled.TouchableOpacity`
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

export const MODAL_TYPES = {
  TICKET_LIMITS_MODAL: 'TICKETS_LIMITS_MODAL',
  REMOVE_ADDITIONAL_FORMS_MODAL: 'REMOVE_ADDITIONAL_FORMS_MODAL',
  CONFIRMATION_MODAL: 'CONFIRMATION_MODAL',
  TICKETS_ADDITIONAL_FORM_MODAL: 'TICKETS_ADDITIONAL_FORM_MODAL',
  EVENT_TOOL_TIP_MODAL: 'EVENT_TOOL_TIP_MODAL',
  TICKET_TRANSFER_MODAL: 'TICKET_TRANSFER_MODAL',
  PASS_TRANSFER_MODAL: 'PASS_TRANSFER_MODAL',
  INVALID_MESSAGE_MODAL: 'INVALID_MESSAGE_MODAL',
  FUNDRAISER_UPSELL_MODAL: 'FUNDRAISER_UPSELL_MODAL',
  EVENT_INFO_DESCRIPTION_MODAL: 'EVENT_INFO_DESCRIPTION_MODAL',
  MOBILE_PASS_DECLINE_MODAL: 'MOBILE_PASS_DECLINE_MODAL',
  PAYMENT_VALIDATION_MODAL: 'PAYMENT_VALIDATION_MODAL',
  REDEEM_ON_DESKTOP_MODAL: 'REDEEM_ON_DESKTOP_MODAL',
  REDEMPTION_TOO_EARLY_MODAL: 'REDEMPTION_TOO_EARLY_MODAL',
  PASS_INVALID_MODAL: 'PASS_INVALID_MODAL',
  REDEMPTION_MODAL: 'REDEMPTION_MODAL',
  ERROR_TRANSFER_MODAL: 'ERROR_TRANSFER_MODAL',
  ACCESS_CODE_MODAL: 'ACCESS_CODE_MODAL',
  PROMOTION_LIMIT_MODAL: 'PROMOTION_LIMIT_MODAL',
  RENEWAL_DECLINE_MODAL: 'RENEWAL_DECLINE_MODAL',
  SHARE_EVENT_MODAL: 'SHARE_EVENT_MODAL',
  CALENDAR_ADDITION_MODAL: 'CALENDAR_ADDITION_MODAL',
  CART_EXPIRED_MODAL: 'CART_EXPIRED_MODAL',
  TICKET_PRINT_LIMIT_MODAL: 'TICKET_PRINT_LIMIT_MODAL',
  ERROR_TAX_MODAL: 'ERROR_TAX_MODAL'
};

const MODAL_COMPONENTS: any = {
  [MODAL_TYPES.TICKET_LIMITS_MODAL]: LimitedTicketModal,
  [MODAL_TYPES.REMOVE_ADDITIONAL_FORMS_MODAL]: RemoveTicketModal,
  [MODAL_TYPES.CONFIRMATION_MODAL]: ConfirmationModal,
  [MODAL_TYPES.TICKETS_ADDITIONAL_FORM_MODAL]: TicketsAdditionalModal,
  [MODAL_TYPES.EVENT_TOOL_TIP_MODAL]: EventToolTipModal,
  [MODAL_TYPES.TICKET_TRANSFER_MODAL]: TicketTransferModal,
  [MODAL_TYPES.PASS_TRANSFER_MODAL]: PassTransferModal,
  [MODAL_TYPES.INVALID_MESSAGE_MODAL]: InvalidMessageModal,
  [MODAL_TYPES.FUNDRAISER_UPSELL_MODAL]: FundraiserUpsellModal,
  [MODAL_TYPES.EVENT_INFO_DESCRIPTION_MODAL]: EventInfoDescriptionModal,
  [MODAL_TYPES.MOBILE_PASS_DECLINE_MODAL]: MobilePassDeclineModal,
  [MODAL_TYPES.PAYMENT_VALIDATION_MODAL]: PaymentValidationModal,
  [MODAL_TYPES.REDEEM_ON_DESKTOP_MODAL]: RedeemOnDesktopModal,
  [MODAL_TYPES.REDEMPTION_TOO_EARLY_MODAL]: RedemptionTooEarlyModal,
  [MODAL_TYPES.PASS_INVALID_MODAL]: PassInvalidModal,
  [MODAL_TYPES.REDEMPTION_MODAL]: RedemptionModal,
  [MODAL_TYPES.ERROR_TRANSFER_MODAL]: ErrorTransferModal,
  [MODAL_TYPES.ACCESS_CODE_MODAL]: AccessCodeModal,
  [MODAL_TYPES.PROMOTION_LIMIT_MODAL]: PromotionLimitModal,
  [MODAL_TYPES.RENEWAL_DECLINE_MODAL]: RenewalDeclineModal,
  [MODAL_TYPES.SHARE_EVENT_MODAL]: ShareEventModal,
  [MODAL_TYPES.CALENDAR_ADDITION_MODAL]: CalendarAdditionModal,
  [MODAL_TYPES.CART_EXPIRED_MODAL]: CartExpiredModal,
  [MODAL_TYPES.TICKET_PRINT_LIMIT_MODAL]: TicketPrintLimitModal,
  [MODAL_TYPES.ERROR_TAX_MODAL]: ErrorTaxModal
};

interface ModalContextType {
  hideModal: () => void;
  inProgress: boolean;
  setInprogress: (inprogress: boolean) => void;
  showModal: (modalType: string, modalProps?: any) => void;
  modalState: {
    modalType: string;
    modalProps: any;
  };
}

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalContext = createContext<ModalContextType | undefined>(undefined);

const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modalState, setState] = useState<any>({});
  const [inProgress, setInprogress] = useState<boolean>(false);
  //usage showModal(MODAL_TYPES.TICKET_LIMITS_MODAL, { title: 'ABCXYZ' })
  const showModal = (modalType: string, modalProps: any = {}) => {
    setState({
      ...modalState,
      modalType,
      modalProps
    });
  };

  const hideModal = () => {
    setState({
      ...modalState,
      modalType: null,
      modalProps: {}
    });
  };

  const modalComponents = () => {
    const { modalType, modalProps } = modalState;
    const { hideModalWhenClickOutSite = true, onHideModal = () => {} } = modalProps || {};
    const ModalComponent = MODAL_COMPONENTS[modalType];
    if (!modalType || !ModalComponent) {
      return null;
    }

    const onModalOverlayPressed = () => {
      if (!hideModalWhenClickOutSite) return;
      if (typeof onHideModal === 'function') {
        onHideModal();
      }
      hideModal();
    };

    return (
      <Modal transparent visible>
        <Overlay activeOpacity={1} onPress={onModalOverlayPressed} />
        <ModalComponent id={modalType} {...modalProps} />
      </Modal>
    );
  };

  const ModalContextValue = { modalState, showModal, hideModal, inProgress, setInprogress };

  useCloseModalOnRouteChange(hideModal);

  return (
    <ModalContext.Provider value={ModalContextValue}>
      {children}
      <Suspense>{modalComponents()}</Suspense>
    </ModalContext.Provider>
  );
};

const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModalProvider is not setup');
  }
  return context;
};

export { useModal, ModalProvider };
