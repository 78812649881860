import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css``,
  schoolName: css`
    font-size: 14px;
    font-weight: 400;
    color: #565658;
    margin-bottom: 4px;
  `,
  title: css`
    font-family: Rubik-SemiBold;
    font-size: 20px;
    font-weight: 600;
    line-height: 28;
  `,
  descriptionContainer: css``
};
