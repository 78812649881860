import React from 'react';
import { useModal } from '../../ModalProvider';
import { RedeemOnDesktopComponent } from '@gf/cross-platform-lib/components';

export const RedeemOnDesktopModal = () => {
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const {
    onContinue = () => {
      hideModal();
    },
    onClosed = () => {
      hideModal();
    }
  } = modalProps || {};

  return <RedeemOnDesktopComponent onClosed={onClosed} onContinue={onContinue} />;
};
