import { createContext, useContext } from 'react';

interface StripeNativeContextType {
  resetPaymentSheetCustomer: () => void;
}

export const AppStripeNativeContext = createContext<StripeNativeContextType>({
  resetPaymentSheetCustomer: () => {}
});

export const useAppStripeNativeContext = () => {
  return useContext(AppStripeNativeContext);
};
