// @ts-nocheck
import * as React from 'react';
import { SvgExtProps } from '../types';
import Svg, { Path } from 'react-native-svg';

export const SendAltFilled = (props: SvgExtProps) => (
  <Svg
    id='gf_icon_send_alt_filled'
    aria-label='Send Alt icon'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    {...props}
  >
    <Path d='M27.71 4.29a1 1 0 00-1.05-.23l-22 8a1 1 0 000 1.87l8.59 3.43L19.59 11 21 12.41l-6.37 6.37 3.44 8.59A1 1 0 0019 28a1 1 0 00.92-.66l8-22a1 1 0 00-.21-1.05z' />
    <Path
      id='SendAltFilled_Transparent_Rectangle_'
      data-name='&lt;Transparent Rectangle&gt;'
      d='M0 0H32V32H0z'
      fill='none'
    />
  </Svg>
);
