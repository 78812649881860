// @ts-nocheck
import * as React from 'react';
import { SvgExtProps } from '../types';
import Svg, { Path } from 'react-native-svg';

export const NewTab = (props: SvgExtProps) => (
  <Svg
    id='gf_icon_new_tab'
    aria-label='New tab icon'
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    {...props}
  >
    <Path d='M26 26H6V6h10V4H6a2.002 2.002 0 00-2 2v20a2.002 2.002 0 002 2h20a2.002 2.002 0 002-2V16h-2z' />
    <Path d='M26 26H6V6h10V4H6a2.002 2.002 0 00-2 2v20a2.002 2.002 0 002 2h20a2.002 2.002 0 002-2V16h-2z' />
    <Path d='M26 6L26 2 24 2 24 6 20 6 20 8 24 8 24 12 26 12 26 8 30 8 30 6 26 6z' />
    <Path id='NewTab_Transparent_Rectangle_' data-name='&lt;Transparent Rectangle&gt;' d='M0 0H32V32H0z' fill='none' />
  </Svg>
);
