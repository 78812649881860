import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalWrapper: css<{ height: number; width: number }>`
    background-color: #f4f4f4;
    border-radius: 4px;
    height: ${({ height }) => `${height}px`};
    justify-content: center;
    margin: auto;
    padding: 16px;
    width: ${({ width }) => `${width}px`};
    z-index: 3;
  `,
  container: css`
    display: flex;
    flex-direction: row;
  `,
  title: css`
    color: rgb(50, 50, 50);
    font-family: Rubik-Regular;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
  `,
  subTitle: css<{ isMobile: boolean }>`
    margin-top: ${({ isMobile }) => (isMobile ? '4px' : '14px')};
    margin-bottom: ${({ isMobile }) => (isMobile ? '24px' : '48px')}
    color: rgb(22, 22, 22);
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
  `,
  closeBtnContainer: css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
    z-index: 5;
  `,
  contentWrapper: css`
    display: flex;
  `,
  contentContainer: css`
    padding-right: 40px;
  `
};
