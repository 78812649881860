import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { useModal } from '@gf/cross-platform-lib/providers';
import { styles } from './AccessCodeModal.styles';
import { Carbonicons, StyledButton } from '@gf/cross-platform-lib/components';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const Container = styled.View`
  ${styles.container};
`;

const Title = styled.Text`
  ${styles.title};
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

const BtnContainer = styled.View<{ isCenter: boolean }>`
  ${styles.container};
  justify-content: ${({ isCenter = false }) => (isCenter ? 'center' : 'flex-end')};
`;

const ContentWrapper = styled.View`
  ${styles.contentWrapper}
`;

const ContentContainer = styled.View<{ paddingRight: number }>`
  ${styles.contentContainer}
`;

const CloseBtnContainer = styled.TouchableOpacity`
  ${styles.closeBtnContainer}
`;

export interface AccessCodeModalModalProps {
  title: string;
  description: string;
  remove: Function;
}

export const AccessCodeModal = ({ title, description, remove }: AccessCodeModalModalProps) => {
  const { isMobile } = useMediaQuery();
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const {
    onConfirm = async () => {
      await remove();
      hideModal();
    }
  } = modalProps || {};

  const modalHeight = isMobile ? 212 : 190;
  const modalWidth = isMobile ? 320 : 640;

  return (
    <ModalWrapper isMobile={isMobile} height={modalHeight} width={isMobile ? 320 : modalWidth}>
      <CloseBtnContainer onPress={onConfirm}>
        <Carbonicons name='close' size={22} color='#161616' />
      </CloseBtnContainer>
      <ContentWrapper>
        <ContentContainer paddingRight={20}>
          <Container>
            <Title>{title}</Title>
          </Container>
          <SubTitle numberOfLines={4} isMobile={isMobile} marginBottom={12}>
            {description}
          </SubTitle>
        </ContentContainer>
      </ContentWrapper>
      <BtnContainer isCenter>
        <StyledButton buttonType='primary' title='OK' onPress={onConfirm} width='128px' accessibilityLabel='OK' />
      </BtnContainer>
    </ModalWrapper>
  );
};
