import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalWrapper: css`
    border-radius: 4px;
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 14px;
    padding-top: 14px;
    width: 320px;
    background: rgb(244, 244, 244);
    overflow-y: auto;
    max-height: 55%;
  `,
  title: css`
    width: 288px;
    color: rgb(50, 50, 50);
    font-size: 20px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
    margin-bottom: 4px;
  `,
  description: css`
    width: 288px;
    color: rgb(22, 22, 22);
    font-size: 14px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
  `,
  iconWrapper: css`
    margin-left: 269px;
    margin-bottom: 29px;
  `,
  buttonWrapper: css`
    margin-top: 32px;
  `
};
