import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './base.styles';
import React from 'react';
import { View } from 'react-native';
import { Ticket, WarningAltFilled } from '../Icons/Carbonicons/collections';
import { StyledText } from '../StyledText';
import { SeasonRenewalStatus } from '@gf/cross-platform-lib/interfaces';

const DateWrapper = styled(View)`
  ${styles.dateWrapper}
`;
const SubIconContainer = styled(View)`
  ${styles.subIconContainer}
`;
const WarningWrapper = styled(View)`
  ${styles.warningWrapper}
`;
const TextWrapper = styled(View)`
  ${styles.textWrapper}
`;

interface RenewalAcceptedProps {
  date?: string;
  showText?: boolean;
  renewalStatus?: SeasonRenewalStatus;
}

export const RenewalExpired = ({ date, showText, renewalStatus }: RenewalAcceptedProps) => (
  <>
    <SubIconContainer>
      <Ticket height={96} width={104} fill='#323232' />
      <WarningWrapper>
        <WarningAltFilled
          height={40}
          width={39}
          color={'rgb(241, 194, 27)'}
          exclamationColor={'rgb(50, 50, 50)'}
          shouldHaveBorder={'false'}
        />
      </WarningWrapper>
    </SubIconContainer>
    {showText ? (
      <>
        <TextWrapper>
          <StyledText textAlign='center' typeToken='heading03'>
            {renewalStatus === SeasonRenewalStatus.EXPIRED
              ? 'The offer has expired and is no longer available to renew.'
              : 'This offer is no longer available.'}
          </StyledText>
        </TextWrapper>
        {date && renewalStatus === SeasonRenewalStatus.EXPIRED ? (
          <DateWrapper renewed={false}>
            <StyledText typeToken='label01' color='rgb(162, 25, 31)'>
              Expired {date}
            </StyledText>
          </DateWrapper>
        ) : null}
      </>
    ) : null}
  </>
);

export default RenewalExpired;
