import React from 'react';
import { styles } from './FundraiserDetail.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { FundraiserProgressBar } from '../../../../../../event/components/FundraiserProgressBar/FundraiserProgressBar';
import { GFImage } from '@gf/cross-platform-lib/components/Image/Image';
import { activityIcons } from '@gf/cross-platform-lib/constants/activity-icons';
import { useMediaQuery } from '../../../../../../..';

const Container = styled.View`
  ${styles.container}
`;
const Column = styled.View`
  ${styles.column}
`;
const IconContainer = styled.View`
  ${styles.iconContainer}
`;
const FundraiserDate = styled.Text`
  ${styles.fundraiserDate}
`;
const FundraiserTitle = styled.Text`
  ${styles.fundraiserTitle}
`;
export type FundraiserDetailProps = {
  eventSchedule: string;
  eventTitle?: string;
  eventGoal: number;
  eventRaised: number;
  barColor: string;
};

export const FundraiserDetail = ({
  eventSchedule,
  eventTitle,
  eventGoal,
  eventRaised,
  barColor
}: FundraiserDetailProps) => {
  const { isNativeApp } = useMediaQuery();
  return (
    <Container>
      <IconContainer>
        <GFImage alt='Fundraiser Icon' name={activityIcons.fundraiser} width={30} height={30} tintColor={'#565658'} />
      </IconContainer>
      <Column>
        <FundraiserDate>{eventSchedule}</FundraiserDate>
        <FundraiserTitle numberOfLines={1} isNativeApp={isNativeApp}>
          {eventTitle}
        </FundraiserTitle>
        <FundraiserProgressBar
          donationGoal={eventGoal}
          donationsRaised={eventRaised}
          barColor={barColor}
          isMobile={true}
          isEventDetails={false}
          disableGradient={true}
        />
      </Column>
    </Container>
  );
};
