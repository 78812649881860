// @ts-nocheck
import * as React from 'react';
import { SvgExtProps } from '../types';
import Svg, { Path } from 'react-native-svg';

export const SendAlt = (props: SvgExtProps) => (
  <Svg
    id='gf_icon_send_alt'
    aria-label='Send Alt icon'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    {...props}
  >
    <Path d='M27.71 4.29a1 1 0 00-1.05-.23l-22 8a1 1 0 000 1.87l9.6 3.84 3.84 9.6a1 1 0 00.9.63 1 1 0 00.92-.66l8-22a1 1 0 00-.21-1.05zM19 24.2l-2.79-7L21 12.41 19.59 11l-4.83 4.83L7.8 13l17.53-6.33z' />
    <Path id='SendAlt_Transparent_Rectangle_' data-name='&lt;Transparent Rectangle&gt;' d='M0 0H32V32H0z' fill='none' />
  </Svg>
);
