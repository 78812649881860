import { Fan } from '@gf/cross-platform-lib/models';
import { GetCurrentApplicationConfiguration } from '@gf/cross-platform-lib/utils';

declare global {
  interface Window {
    YellowMessengerPlugin: any;
    ymConfig: any;
  }
}

export const useChatBot = () => {
  const openChatBot = () => {
    const config = GetCurrentApplicationConfiguration();

    window.ymConfig = {
      hideChatButton: true,
      bot: config.yellowai.chatbotId,
      host: config.yellowai.host,
      payload: {
        username: Fan.getInstance().email || ''
      }
    };

    const loadScript = () => {
      if (window.YellowMessengerPlugin) {
        return;
      }

      const script = window.document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://cdn.yellowmessenger.com/plugin/widget-v2/latest/dist/main.min.js';
      window.document.body.appendChild(script);
    };

    loadScript();

    let yellowMessengerPlugin = window.YellowMessengerPlugin;
    let attempt = 0;
    const theInterval = setInterval(function () {
      yellowMessengerPlugin = !!window.YellowMessengerPlugin;
      window.YellowMessengerPlugin && window.YellowMessengerPlugin.openBot();
      if (yellowMessengerPlugin || attempt >= 10) {
        clearInterval(theInterval);
      }
      attempt += 1;
    }, 1000);
  };

  const closeChatBot = () => {
    window.YellowMessengerPlugin && window.YellowMessengerPlugin.closeBot();
  };

  const toggleChatBot = () => {
    window.YellowMessengerPlugin && window.YellowMessengerPlugin.toggleChat();
  };

  const setChatPayload = (payload: any) => {
    window.YellowMessengerPlugin && window.YellowMessengerPlugin.init({ payload });
  };

  return { openChatBot, closeChatBot, toggleChatBot, setChatPayload };
};
