import { fontFamilies, fontWeights } from './fonts';

export type LabelTokenType = 'label01' | 'label02' | 'label01SemiBold' | 'label03';
export type HelperTextTokenType = 'helperText01' | 'helperText02' | 'helperText01SemiBold';
export type LegalTokenType = 'legal01' | 'legal02';
export type BodyCompactTokenType = 'bodyCompact01' | 'bodyCompact02' | 'bodyCompact01SemiBold';
export type BodyTokenType = 'body01' | 'body02' | 'body01SemiBold';
export type HeadingCompactTokenType = 'headingCompact01' | 'headingCompact02' | 'headingCompact03';
export type HeadingTokenType =
  | 'heading01'
  | 'heading02'
  | 'heading03'
  | 'heading03SemiBold'
  | 'heading04'
  | 'heading04Regular'
  | 'heading05'
  | 'heading06'
  | 'heading07'
  | 'heading05Light';
export type FluidHeadingTokenType = 'fluidHeading03' | 'fluidHeading04' | 'fluidHeading05' | 'fluidHeading06';
export type FluidParagraphTokenType = 'fluidParagraph01';
export type FluidQuotationTokenType = 'fluidQuotation01' | 'fluidQuotation02';
export type FluidDisplayTokenType = 'fluidDisplay01' | 'fluidDisplay02' | 'fluidDisplay03' | 'fluidDisplay04';

export type TextTokenType =
  | LabelTokenType
  | HelperTextTokenType
  | LegalTokenType
  | BodyCompactTokenType
  | BodyTokenType
  | HeadingCompactTokenType
  | HeadingTokenType
  | FluidHeadingTokenType
  | FluidParagraphTokenType
  | FluidQuotationTokenType
  | FluidDisplayTokenType;

export type FontStyleByToken = {
  fontFamily: string;
  fontSize: string;
  lineHeight: string;
  fontWeight: string;
  letterSpacing: string;
};

export const label01: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0.32px'
};

export const label01SemiBold: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0.32px'
};

export const label02: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0.16px'
};

export const label03: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: fontWeights.light,
  letterSpacing: '0.16px'
};

export const helperText01: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0.32px'
};

export const helperText02: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0.16px'
};

export const helperText01SemiBold: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0.32px'
};

export const legal01: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '12px',
  lineHeight: '16px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0.32px'
};

export const legal02: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0.16px'
};

export const bodyCompact01: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0.16px'
};

export const bodyCompact02: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0px'
};

export const bodyCompact01SemiBold: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: fontWeights.semibold,
  letterSpacing: '0.16px'
};

export const body01: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0.16px'
};

export const body02: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0px'
};

export const body01SemiBold: FontStyleByToken = {
  ...body01,
  fontFamily: fontFamilies.RubikSemiBold
};

export const headingCompact01: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: fontWeights.semibold,
  letterSpacing: '0.16px'
};

export const headingCompact02: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '16px',
  lineHeight: '22px',
  fontWeight: fontWeights.semibold,
  letterSpacing: '0px'
};

export const headingCompact03: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '18px',
  lineHeight: '22px',
  fontWeight: fontWeights.semibold,
  letterSpacing: '0px'
};

export const heading01: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '14px',
  lineHeight: '20px',
  fontWeight: fontWeights.semibold,
  letterSpacing: '0.16px'
};

export const heading02: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: fontWeights.semibold,
  letterSpacing: '0px'
};

export const heading03: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0px'
};

export const heading03SemiBold: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: fontWeights.semibold,
  letterSpacing: '0px'
};

export const heading04: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '28px',
  lineHeight: '36px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0px'
};

export const heading04Regular: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '28px',
  lineHeight: '36px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0px'
};

export const heading05: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '32px',
  lineHeight: '40px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0px'
};

export const heading05Light: FontStyleByToken = {
  fontFamily: fontFamilies.RubikLight,
  fontSize: '32px',
  lineHeight: '40px',
  fontWeight: fontWeights.light,
  letterSpacing: '0px'
};

export const heading06: FontStyleByToken = {
  fontFamily: fontFamilies.RubikLight,
  fontSize: '42px',
  lineHeight: '50px',
  fontWeight: fontWeights.light,
  letterSpacing: '0px'
};

export const heading07: FontStyleByToken = {
  fontFamily: fontFamilies.RubikLight,
  fontSize: '54px',
  lineHeight: '64px',
  fontWeight: fontWeights.light,
  letterSpacing: '0px'
};

export const fluidHeading03: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0px'
};

export const fluidHeading04: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '28px',
  lineHeight: '36px',
  fontWeight: fontWeights.semibold,
  letterSpacing: '0px'
};

export const fluidHeading05: FontStyleByToken = {
  fontFamily: fontFamilies.RubikLight,
  fontSize: '42px',
  lineHeight: '50px',
  fontWeight: fontWeights.light,
  letterSpacing: '0px'
};

export const fluidHeading06: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '36px',
  lineHeight: '44px',
  fontWeight: fontWeights.semibold,
  letterSpacing: '0px'
};

export const fluidParagraph01: FontStyleByToken = {
  fontFamily: fontFamilies.RubikLight,
  fontSize: '28px',
  lineHeight: '36px',
  fontWeight: fontWeights.light,
  letterSpacing: '0px'
};

export const fluidQuotation01: FontStyleByToken = {
  fontFamily: fontFamilies.RubikRegular,
  fontSize: '24px',
  lineHeight: '30px',
  fontWeight: fontWeights.regular,
  letterSpacing: '0px'
};

export const fluidQuotation02: FontStyleByToken = {
  fontFamily: fontFamilies.RubikLight,
  fontSize: '42px',
  lineHeight: '50px',
  fontWeight: fontWeights.light,
  letterSpacing: '0px'
};

export const fluidDisplay01: FontStyleByToken = {
  fontFamily: fontFamilies.RubikLight,
  fontSize: '54px',
  lineHeight: '64px',
  fontWeight: fontWeights.light,
  letterSpacing: '0px'
};

export const fluidDisplay02: FontStyleByToken = {
  fontFamily: fontFamilies.RubikSemiBold,
  fontSize: '54px',
  lineHeight: '64px',
  fontWeight: fontWeights.semibold,
  letterSpacing: '0px'
};

export const fluidDisplay03: FontStyleByToken = {
  fontFamily: fontFamilies.RubikLight,
  fontSize: '92px',
  lineHeight: '102px',
  fontWeight: fontWeights.light,
  letterSpacing: '-0.64px'
};

export const tokens: { [key: string]: FontStyleByToken } = {
  label01,
  label02,
  label03,
  label01SemiBold,
  helperText01,
  helperText02,
  helperText01SemiBold,
  body01,
  body02,
  body01SemiBold,
  bodyCompact01,
  bodyCompact02,
  bodyCompact01SemiBold,
  headingCompact01,
  headingCompact02,
  headingCompact03,
  heading01,
  heading02,
  heading03,
  heading03SemiBold,
  heading04,
  heading04Regular,
  heading05,
  heading06,
  heading07,
  heading05Light,
  fluidHeading03,
  fluidHeading04,
  fluidHeading05,
  fluidHeading06,
  fluidParagraph01,
  fluidQuotation01,
  fluidQuotation02,
  fluidDisplay01,
  fluidDisplay02,
  fluidDisplay03
};

/**
 * Get definition of font style based on given token
 * @param { token } token - token name
 * @returns { string }
 */
export function textStyle(token?: TextTokenType) {
  if (!token) return null;
  if (!tokens[token]) {
    throw new Error(
      `Unable to find font token: \`${token}\`. Expected one of: ` + `[${Object.keys(tokens).join(', ')}]`
    );
  }
  return tokens[token];
}
