import { Platform } from 'react-native';

export const isSafari = () => {
  try {
    if (Platform.OS !== 'web') {
      return false;
    }
    let isSafari = false;
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') != -1) {
      if (ua.indexOf('chrome') <= -1) {
        isSafari = true;
      }
    }
    return isSafari;
  } catch (error) {
    return false;
  }
};
