import React, { useState, useRef, useEffect, useCallback } from 'react';
import { isEqual } from 'lodash';
import {
  LaunchDarklyContext,
  LaunchDarklyProviderProps,
  ILaunchDarklyContextValue,
  defaultLaunchDarklyContextValue,
  ContextKind,
  MultiContext,
  AnyContext
} from './LaunchDarklyContext';
import { initialize } from 'launchdarkly-js-client-sdk';
import { GetCurrentApplicationConfiguration } from '@gf/cross-platform-lib/utils/config';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { LaunchDarklyContextAttributes } from '../..';

const appConfig = GetCurrentApplicationConfiguration();
const { clientId } = appConfig.launchdarkly;

export const LaunchDarklyProvider = ({ children }: LaunchDarklyProviderProps) => {
  const [contextValue, setContextValue] = useState<ILaunchDarklyContextValue>({ ...defaultLaunchDarklyContextValue });
  const contextRef = useRef<ILaunchDarklyContextValue>(contextValue);

  useEffect(() => {
    contextRef.current = contextValue;
  }, [contextValue]);

  const updateLaunchDarklyContext = useCallback(
    async (kind: ContextKind, key: string, attributes: LaunchDarklyContextAttributes = { anonymous: false }) => {
      const currentContext = contextRef.current;
      if (!currentContext.client) {
        recordError('LaunchDarkly client not initialized', {
          customMessage: 'LaunchDarkly client not initialized on web.',
          originatingFunction: 'LaunchDarklyProvider-updateLaunchDarklyContext',
          data: { kind, key, attributes, currentContext }
        });
        return;
      }

      let multiContext: MultiContext =
        currentContext.ldContext.kind === 'multi'
          ? { ...(currentContext.ldContext as MultiContext) }
          : ({ kind: 'multi' } as MultiContext);

      const newContext: AnyContext = { kind, key, ...attributes } as AnyContext;

      if (!isEqual(multiContext[kind], newContext)) {
        multiContext = { ...multiContext, [kind]: newContext };

        await currentContext.client.identify(multiContext);

        contextRef.current = { ...currentContext, ldContext: multiContext };
        setContextValue(contextRef.current);
      }
    },
    []
  );

  contextRef.current = contextValue;

  const initLaunchDarkly = async () => {
    const client = initialize(clientId, contextValue.ldContext, {
      privateAttributes: ['fanEmail', 'name', 'fanFirstName', 'fanLastName', 'paymentEmail']
    });
    const updatedContextValue = {
      ...contextValue,
      isEnabled: true,
      client,
      isReady: true
    };
    client.on('ready', () => {
      for (const key in defaultLaunchDarklyContextValue.features) {
        const updatedVariation = client.variation(key, updatedContextValue.features[key].variation);
        updatedContextValue.features[key].variation = updatedVariation;
        if (contextValue.listeners[key]) {
          return;
        }
        const listener = async (variation: typeof updatedVariation) => {
          const newContext = Object.assign({}, contextRef.current);
          newContext.features[key].variation = variation;
          setContextValue(newContext);
        };
        client.on(`change:${key}`, listener);
      }
      setContextValue(updatedContextValue);
    });
  };

  const closeLaunchDarkly = async () => {
    if (contextValue.client) {
      await contextValue.client.close();
    }
  };

  useEffect(() => {
    if (!contextValue.client) {
      initLaunchDarkly();
    }
    return () => {
      closeLaunchDarkly();
    };
  }, [contextValue.client]);

  return (
    <LaunchDarklyContext.Provider value={{ ...contextValue, updateLaunchDarklyContext }}>
      {children}
    </LaunchDarklyContext.Provider>
  );
};
