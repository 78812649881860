import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './RenewalDeclineModal.styles';
import { useModal } from '../../ModalProvider';
import { CloseIcon, StyledButton, StyledText } from '@gf/cross-platform-lib/components';
import { TouchableOpacity, View } from 'react-native';
import RenewalExpired from '@gf/cross-platform-lib/components/RenewalStatusTicket/RenewalExpired';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;
const IconWrapper = styled.View`
  ${styles.iconWrapper};
`;
const IconButton = styled(TouchableOpacity)`
  ${styles.iconButtom};
`;
const MaxWidthSmallPadding = styled(View)`
  ${styles.maxWidthSmallPadding}
`;
const CenteredLargePadding = styled(View)`
  ${styles.centeredLargePadding}
`;

export interface RenewalDeclineModalProps {}

export const RenewalDeclineModal = ({}: RenewalDeclineModalProps) => {
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const { declineRenewal } = modalProps || {};

  return (
    <ModalWrapper>
      <IconWrapper>
        <IconButton
          onPress={() => {
            hideModal();
          }}
        >
          <CloseIcon height={16} width={16} />
        </IconButton>
      </IconWrapper>
      <RenewalExpired showText={false} />
      <MaxWidthSmallPadding>
        <StyledText textAlign='center' typeToken='heading03SemiBold'>
          Decline tickets renewal?
        </StyledText>
      </MaxWidthSmallPadding>
      <CenteredLargePadding>
        <StyledText typeToken='label02'>Once declined your seats will be released</StyledText>
        <StyledText typeToken='bodyCompact01SemiBold'>This cannot be undone</StyledText>
      </CenteredLargePadding>
      <MaxWidthSmallPadding>
        <StyledButton
          buttonType='danger'
          title='Decline and release my seats'
          accessibilityLabel='Decline and release my seats'
          width='100%'
          onPress={() => {
            declineRenewal();
            hideModal();
          }}
        />
      </MaxWidthSmallPadding>
      <StyledButton
        buttonType='ghost--border'
        title='Cancel'
        accessibilityLabel='Cancel'
        width='100%'
        onPress={() => {
          hideModal();
        }}
      />
    </ModalWrapper>
  );
};
