// @ts-nocheck
import * as React from 'react';
import { SvgExtProps } from '../types';
import Svg, { Path } from 'react-native-svg';

export const Send = (props: SvgExtProps) => (
  <Svg id='gf_icon_send' aria-label='Send icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <Path d='M27.45 15.11l-22-11a1 1 0 00-1.08.12 1 1 0 00-.33 1L7 16 4 26.74A1 1 0 005 28a1 1 0 00.45-.11l22-11a1 1 0 000-1.78zm-20.9 10L8.76 17H18v-2H8.76L6.55 6.89 24.76 16z' />
    <Path id='Send_Transparent_Rectangle_' data-name='&lt;Transparent Rectangle&gt;' d='M0 0H32V32H0z' fill='none' />
  </Svg>
);
