import React from 'react';
import { AppProvider } from '@gf/cross-platform-lib/providers';
import { RootLayout } from './Layout';
// @ts-ignore
import styled, { css } from 'styled-components';
interface GoFanAppProps {
  children?: React.ReactNode;
}

export const styles = {
  fixedContainer: css`
    display: flex;
    height: 100%;
    position: fixed;
    width: 100%;
    overflow: auto;
  `
};

const FixedContainer = styled.div`
  ${styles.fixedContainer}
`;

export const GoFanApp = ({ children }: GoFanAppProps) => {
  return (
    <AppProvider>
      <FixedContainer id='root-container'>
        <RootLayout>{children}</RootLayout>
      </FixedContainer>
    </AppProvider>
  );
};
