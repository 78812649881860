import { QueryParams } from '../interfaces';
//TODO: https://huddleinc.atlassian.net/browse/FAN-6856
export const parseQueryParamsToString = (queryParams?: QueryParams): string => {
  if (!queryParams) {
    return '';
  }

  const keys = Object.keys(queryParams);

  if (!keys.length) {
    return '';
  }

  return (
    '?' + keys.map((key: string) => encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])).join('&')
  );
};
