// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const Twitter = (props: SvgProps) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 1000 1000'
    enableBackground='new 0 0 1000 1000'
    xmlSpace='preserve'
    {...props}
  >
    <G>
      <Path d='M936.1,63.9C900.2,28,856.9,10,806.3,10H193.8c-50.6,0-93.9,18-129.8,53.9C28,99.8,10,143.1,10,193.7v612.5c0,50.6,18,93.9,53.9,129.8C99.9,972,143.1,990,193.8,990h612.5c50.6,0,93.9-18,129.8-53.9C972,900.1,990,856.9,990,806.3V193.7C990,143.1,972,99.8,936.1,63.9z M759.7,387.1c0.4,3.8,0.6,9.6,0.6,17.2c0,35.7-5.2,71.6-15.6,107.5c-10.4,35.9-26.4,70.4-47.8,103.4c-21.5,33-47.1,62.1-76.9,87.4c-29.8,25.3-65.5,45.5-107.2,60.6c-41.7,15.1-86.3,22.7-134,22.7c-74,0-142.5-20-205.4-60c10.2,1.3,20.8,1.9,31.9,1.9c61.7,0,117.2-19.1,166.5-57.4c-28.9-0.4-54.8-9.3-77.5-26.8c-22.8-17.4-38.6-39.6-47.5-66.4c11.1,1.7,19.4,2.5,24.9,2.5c9.4,0,20.2-1.7,32.5-5.1c-31-6-57.2-21.4-78.5-46.3c-21.3-24.9-31.9-53.3-31.9-85.2v-1.3c22.5,10.6,43.8,16.2,63.8,16.6c-38.7-26-58.1-63.2-58.1-111.7c0-23.8,6.2-46.4,18.5-67.6c33.6,41.3,74.4,74.2,122.5,98.9c48.1,24.7,99.5,38.5,154.4,41.5c-2.1-8.1-3.2-18.3-3.2-30.6c0-37,13.1-68.6,39.2-94.7c26.2-26.2,57.7-39.2,94.8-39.2c39.1,0,71.7,14,97.6,42.1c29.4-5.5,57.9-16.4,85.5-32.5c-10.6,32.7-30.4,57.6-59.3,74.6c27.6-3.8,53.4-11.1,77.2-21.7C808.8,344.7,786.5,367.9,759.7,387.1z' />
    </G>
  </Svg>
);
