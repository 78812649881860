import { useRouter as useNextRouter } from 'next/router';
import { UseRouter } from './types';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import get from 'lodash/get';
import { ParsedUrlQueryInput } from 'querystring';
import { parseQueryParamsToStringV2 } from '@gf/cross-platform-lib/utils';
import { QueryParams } from '@gf/cross-platform-lib/interfaces/QueryParams';

export const useRouter: UseRouter = () => {
  const router = useNextRouter();
  // Make nextjs route paramater format consistent with react navigation
  const currentRoute = get(router, 'pathname', '').replaceAll('[', ':').replaceAll(']', '');

  return {
    canGoBack: () => router.pathname !== GOFAN_APP_PAGES.home.path && window.history.length > 1,
    goBack: () => {
      router.back();
    },
    replace: path => {
      router.replace(path);
    },
    replaceParams: (params: QueryParams) => {
      router.replace(currentRoute + parseQueryParamsToStringV2(params));
    },
    currentRoute,
    updateCurrentUrl: (pathname: string, query?: ParsedUrlQueryInput) => {
      router.push(
        {
          pathname,
          ...(query ? { query } : {})
        },
        undefined,
        { shallow: true }
      );
    }
  };
};
