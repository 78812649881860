// @ts-nocheck
import * as React from 'react';
import { SvgExtProps } from '../types';
import Svg, { Path } from 'react-native-svg';

export const SendFilled = (props: SvgExtProps) => (
  <Svg
    id='gf_icon_send_filled'
    aria-label='Send icon'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    {...props}
  >
    <Path d='M27.45 15.11l-22-11a1 1 0 00-1.08.12 1 1 0 00-.33 1L6.69 15H18v2H6.69L4 26.74A1 1 0 005 28a1 1 0 00.45-.11l22-11a1 1 0 000-1.78z' />
    <Path
      id='SendFilled_Transparent_Rectangle_'
      data-name='&lt;Transparent Rectangle&gt;'
      d='M0 0H32V32H0z'
      fill='none'
    />
  </Svg>
);
