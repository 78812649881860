import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalWrapper: css<{ isMobile: boolean; height: number; width: number }>`
    background-color: #f4f4f4;
    border-radius: 4px;
    height: ${({ height }) => `${height}px`};
    justify-content: center;
    margin: auto;
    padding: 16px;
    width: ${({ width }) => `${width}px`};
    z-index: 3;
  `,
  container: css`
    display: flex;
    flex-direction: row;
  `,
  button: css<{ bgColor: string; removeBorder?: boolean; color: string; width: number }>`
    border-radius: 4px;
    border: 1px solid #323232;
    border-width: ${({ removeBorder }) => (removeBorder ? 0 : '1px')};
    height: 48px;
    width: ${({ width }) => `${width}px`};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ bgColor }) => bgColor};
    color: ${({ color }) => color};
    cursor: pointer;
  `,
  title: css`
    color: rgb(50, 50, 50);
    font-family: Rubik-Regular;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
  `,
  subTitle: css<{ isMobile: boolean; marginBottom: number }>`
    margin-top: ${({ isMobile }) => (isMobile ? '4px' : '14px')};
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};
    color: rgb(22, 22, 22);
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
  `,
  closeButton: css`
    height: 10px;
    width: 10px;
    cursor: pointer;
  `,
  closeBtnContainer: css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
    z-index: 5;
  `,
  contentWrapper: css`
    display: flex;
  `,
  contentContainer: css<{ paddingRight: number }>`
    padding-right: ${({ paddingRight = 0 }) => `${paddingRight}px`};
  `
};
