export const IMAGES_FOLDER = 'assets/images';

export function getImageSrc(src?: string, name?: string) {
  if (name) return { uri: `/${IMAGES_FOLDER}/${name}` };
  if (typeof src === 'string') {
    if (src?.includes('http') || src?.includes('https') || src?.includes('data:image')) return { uri: src };
    return { uri: `/${IMAGES_FOLDER}/${src}` };
  }
  return { uri: src || '' };
}
