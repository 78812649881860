// @ts-nocheck
import React from 'react';
import Svg, { Defs, Path, Mask, Use } from 'react-native-svg';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks/useMediaQuery';
import { SvgExtProps } from '../../Icons/Carbonicons/types';

export const SpinnerIcon = (props: SvgExtProps) => {
  const { isNativeApp } = useMediaQuery();
  return (
    <Svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      width={20}
      height={20}
      viewBox='0 0 20 20'
      {...props}
    >
      <Defs>
        <Path d='M13.524 23.524c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10z' id='a' />
        <Mask
          id='b'
          maskContentUnits='userSpaceOnUse'
          maskUnits='objectBoundingBox'
          fill='#fff'
          width={isNativeApp ? 24 : 20}
          height={isNativeApp ? 24 : 20}
          x='0'
          y='0'
        >
          <Use xlinkHref='#a' />
        </Mask>
      </Defs>
      <Use
        mask='url(#b)'
        transform='rotate(-28 4.695 18.83)'
        xlinkHref='#a'
        stroke={props.color || '#FFF'}
        strokeWidth={8}
        fill='none'
        fillRule='evenodd'
        strokeDasharray='5,16'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Svg>
  );
};
