import { css } from 'styled-components';

export const styles = {
  info: css`
    margin-top: 16px;
    margin-bottom: 8px;
  `,
  content: css`
    padding: 0 16px;
  `,
  applyAllCheckbox: css`
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
  `,
  label: css`
    align-items: center;
    margin-left: 8px;
  `
};
