import { EventCart, Fan, SeasonCart, TicketCart } from '@gf/cross-platform-lib/models';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import { DATE_FORMAT, titleCase } from '@gf/cross-platform-lib/utils';
import {
  INDUSTRIAL_CODES,
  PAYMENT_METHOD_LABEL,
  PAYMENT_TYPE,
  TicketState,
  PAYMENT_METHOD
} from '@gf/cross-platform-lib/constants';
import { Platform } from 'react-native';
import { IIdentifyPayload } from '@gf/cross-platform-lib/providers';
import { TheStore } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import {
  EventDetailsPageViewProps,
  EventListPageViewFilterProps,
  EventListPageViewProps,
  MyTicketsPageViewProps,
  RedemptionPageViewProps,
  TrackEventTicketsPage
} from './usePageViewTracking';
import {
  Event,
  PrintTicketTrackingProps,
  PurchasedTicket,
  UseTicketTrackingProps
} from '@gf/cross-platform-lib/interfaces';
import { FilterProps, FilterType } from './useCustomTracking';
import { FetchAllTicketsReturnType } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { Level } from '@gf/cross-platform-lib/interfaces/Level';

export type UseTicketTrackingType = Omit<UseTicketTrackingProps, 'tickets'> & { tickets: PurchasedTicket[] };

const CACHE_TRACKING_KEY_PREFIX = 'gf_tk';

const getTrackingCacheKey = (email: string, fieldName: string) => {
  return `${CACHE_TRACKING_KEY_PREFIX}_${email}_${fieldName}`;
};

export const setSignUpSourceCache = async (email: string, signup_source: string) => {
  return TheStore.setItem(getTrackingCacheKey(email, 'signup_source'), signup_source || '');
};

export const getSignUpSourceCache = async (email: string) => {
  return TheStore.getItem(getTrackingCacheKey(email, 'signup_source'));
};

export const setFirstPurchaseCache = async (email: string, first_purchase: string | null) => {
  if (!(await getFirstPurchaseCache(email))) {
    return TheStore.setItem(getTrackingCacheKey(email, 'first_purchase'), first_purchase || '');
  }
};

export const getFirstPurchaseCache = async (email: string) => {
  return TheStore.getItem(getTrackingCacheKey(email, 'first_purchase'));
};

export const getSignUpSource = (isMobile: boolean) => {
  if (Platform.OS === 'ios') {
    return 'ios';
  }
  if (Platform.OS === 'android') {
    return 'android';
  }
  return isMobile ? 'web mobile' : 'web desktop';
};

export const getDefaultIdentifyPayload = async (fan: Fan) => {
  const createdAt = fan?.firebaseUser?.createdAt
    ? parseInt(fan?.firebaseUser?.createdAt)
    : fan?.firebaseUser?.metadata?.creationTime
    ? new Date(fan?.firebaseUser?.metadata?.creationTime)
    : undefined;
  const created_at = createdAt ? dayjs(createdAt).format(DATE_FORMAT) : undefined;
  const phone_number = fan?.phoneNumber || fan?.firebaseUser?.metadata?.phone_number || null;
  return {
    email: fan.email || undefined,
    created_at: created_at,
    user_type: 'registered user',
    phone_number: phone_number || undefined,
    favorite_schools: fan.favorSchoolIds || undefined,
    signup_source: (await getSignUpSourceCache(fan.email)) || null
  } as IIdentifyPayload;
};

export const getFinancialOrgType = (industryCode: string) => {
  if (!industryCode) {
    return '';
  }
  if (industryCode === INDUSTRIAL_CODES.SCHOOL_BOARD) {
    return 'District';
  } else if (industryCode === INDUSTRIAL_CODES.STATE_ASSOCIATION) {
    return 'State Association';
  } else if (Object.values(INDUSTRIAL_CODES).includes(industryCode)) {
    return 'School';
  }
  return 'Other';
};

export const getEventListPageViewProps = (params: Readonly<object | undefined>): EventListPageViewProps => {
  let homeOrg = '';
  const filterProps = [] as EventListPageViewFilterProps[];
  if (params) {
    Object.entries(params).map(entry => {
      if (entry[0] === 'id') {
        homeOrg = entry[1];
      } else if (entry[0] !== 'redirectFromSchoolDistrict') {
        const filterProp = {} as EventListPageViewFilterProps;
        filterProp.name = entry[0];
        filterProp.value = entry[1];
        filterProps.push(filterProp);
      }
    });
  }
  const obj: EventListPageViewProps = {
    home_org: homeOrg,
    filters: filterProps
  };
  return obj;
};

export const getEventDetailsPageViewProps = (
  eventID: string,
  activity: string,
  isSeason: boolean,
  financialSchoolID: string,
  homeOrg: string
): EventDetailsPageViewProps => {
  const obj: EventDetailsPageViewProps = {
    package: isSeason ? 'Season' : 'Event',
    home_org: homeOrg,
    financial_org: financialSchoolID,
    activity: activity,
    eventID: eventID
  };
  return obj;
};

export const getTrackViewTicketData = ({
  event,
  tickets,
  selectionTickets,
  currentTab,
  orderId,
  boxOffice,
  isTransferred
}: {
  event: Event;
  tickets: { title: string; quantity: number; id: TicketState }[];
  selectionTickets: PurchasedTicket[];
  currentTab: string;
  orderId?: string;
  boxOffice: boolean;
  isTransferred?: boolean;
}): TrackEventTicketsPage => ({
  activity: event.activity?.name || '',
  eventID: event.isSeason ? undefined : event.id,
  homeOrg: event.schoolHuddleId || '',
  numTickets: tickets.reduce((acc, cur) => acc + cur.quantity, 0),
  numUnusedTickets: tickets.filter(({ id }) => id !== TicketState.USED).reduce((acc, cur) => acc + cur.quantity, 0),
  numUsedTickets: tickets.filter(({ id }) => id === TicketState.USED).reduce((acc, cur) => acc + cur.quantity, 0),
  orderID: orderId ? orderId : undefined,
  seasonID: event.isSeason ? event.seasonId || '' : undefined,
  tab: currentTab,
  ticketID: selectionTickets.map(ticket => ticket.id),
  user: boxOffice ? 'Box Office' : isTransferred ? 'Transfer recipient' : orderId ? 'Owner - order' : 'Owner'
});

export const setSegmentRecentProps = (recentEvents: Array<EventCart | SeasonCart>, paymentType = '', email = '') => {
  return TheStore.setItem(getTrackingCacheKey(email, 'segment_recent_props'), { recentEvents, paymentType } || {});
};

export const getSegmentRecentProps = (
  email = ''
): Promise<{ recentEvents: Array<EventCart | SeasonCart>; paymentType: string }> => {
  return TheStore.getItem(getTrackingCacheKey(email, 'segment_recent_props'));
};

export const emptySegmentStorageProps = (email = '') => {
  return TheStore.setItem(getTrackingCacheKey(email, 'segment_recent_props'), {});
};

export const getMyTicketsPageViewProps = (tickets: FetchAllTicketsReturnType): MyTicketsPageViewProps => {
  let numEvents = 0;
  const eventId: string[] = [];
  Object.entries(tickets).map(entry => {
    entry[1].forEach((ticket: PurchasedTicket) => {
      if (!eventId.includes(ticket.eventId)) {
        eventId.push(ticket.eventId);
        numEvents += 1;
      }
    });
  });

  const obj: MyTicketsPageViewProps = {
    numEvents: numEvents
  };
  return obj;
};
export const getRedemptionPageViewProps = (
  numTickets: number,
  eventID: string,
  activity: string,
  homeOrg: string,
  boxOffice: boolean,
  isTransferred: boolean
): RedemptionPageViewProps => {
  const obj: RedemptionPageViewProps = {
    numTickets: numTickets,
    eventID: eventID,
    activity: activity,
    homeOrg: homeOrg,
    user: boxOffice ? 'Box Office' : isTransferred ? 'Transfer recipient' : 'Owner'
  };
  return obj;
};

export const getProductRemovedInCart = ({
  ticketId,
  ticketInCart,
  event,
  removeAll,
  renewal
}: {
  ticketId: string;
  ticketInCart: TicketCart;
  event: EventCart | SeasonCart | undefined;
  removeAll?: boolean;
  renewal?: boolean;
}) => {
  if (event === undefined) {
    return undefined;
  }
  const foundTicket = event.ticketTypes.filter(tk => tk.id === ticketId)[0];
  const mappedEventInCart = (event: EventCart | SeasonCart) => {
    return {
      activity: event.activity,
      financialSchoolID: event.financialSchoolID,
      financialSchoolIndustryCode: event.financialSchoolIndustryCode,
      financialSchoolName: event.financialSchoolName,
      financialSchoolState: event.financialSchoolState,
      financialSchoolType: event.financialSchoolType,
      schoolHuddleId: ticketInCart.schoolHuddleId
    };
  };
  return {
    renewal: renewal || false,
    uniqueUrl: '',
    ticket: foundTicket,
    event: mappedEventInCart(event),
    quantity: 0,
    index: event?.ticketTypes.findIndex(tk => tk.id === ticketId) || 0,
    removeAll: removeAll || false,
    inputMethod: 'Click',
    coupon: ticketInCart?.promotion || '',
    inCart: true
  };
};

export const getFilterProps = (params: Readonly<object | undefined>): FilterProps => {
  const filters = [] as FilterType[];
  if (params) {
    Object.entries(params).map(entry => {
      if (entry[0] !== 'id' && entry[0] !== 'redirectFromSchoolDistrict') {
        const isFieldSchool = entry[0] === 'school';
        const filterProp = {} as FilterType;
        filterProp.name = isFieldSchool ? 'schoolId' : entry[0];
        filterProp.value = entry[1];
        filters.push(filterProp);
      }
    });
  }
  const obj: FilterProps = {
    filters: filters
  };
  return obj;
};

export const getUseTicketTrackingProps = ({
  tickets,
  event,
  boxOffice,
  isTransferred,
  orderId
}: UseTicketTrackingType) => {
  return {
    activity: event.activity?.name || '',
    ticket_id: tickets.map(ticket => ticket.id),
    product_id: tickets[0].productId,
    event_id: event?.id ? event.id.toString() : '',
    event_id_formatted: event?.id ? `e_${event.id.toString()}` : '',
    order_id: orderId,
    gender: getGender(event.levels) || '',
    quantity: tickets.length,
    name: tickets[0].name,
    user: boxOffice ? 'Box Office' : isTransferred ? 'Transfer recipient' : 'Owner'
  };
};

export const getPrintTicketTrackingProps = ({ tickets, event, page }: PrintTicketTrackingProps) => {
  return {
    activity: event.activity?.name || '',
    ticket_id: tickets.map(ticket => ticket.id),
    product_id: tickets[0].productId,
    event_id: event?.id || '',
    quantity: tickets.length,
    page
  };
};

export const getGender = (eventLevels: Level[] | undefined) => {
  if (eventLevels && eventLevels.length) {
    const genders: string[] = [];
    eventLevels.forEach(eventLevel => {
      if (eventLevel && eventLevel.genders && eventLevel.genders.length) {
        eventLevel.genders.forEach(eventLevelGender => genders.push(eventLevelGender));
      }
    });
    if (!genders.length) {
      return 'Coed';
    }
    const gendersUnique = new Set(genders);
    return Array.from(gendersUnique).join(' & ');
  }
  return 'Coed';
};

export const getPaymentTypeTracking = (paymentType: string) => {
  const newPaymentType = paymentType.toUpperCase();
  if (isEmpty(newPaymentType)) {
    return PAYMENT_METHOD_LABEL.FREE;
  }
  if (newPaymentType === PAYMENT_TYPE.STRIPE || newPaymentType === PAYMENT_TYPE.GUEST_CHECKOUT) {
    return PAYMENT_METHOD_LABEL.STRIPE;
  }
  if (newPaymentType === PAYMENT_TYPE.APPLE_PAY) {
    return PAYMENT_METHOD_LABEL.APPLE_PAY;
  }
  if (newPaymentType === PAYMENT_TYPE.GOOGLE_PAY) {
    return PAYMENT_METHOD_LABEL.GOOGLE_PAY;
  }
  if (newPaymentType === PAYMENT_TYPE.LINK) {
    return PAYMENT_METHOD_LABEL.LINK;
  }
  if (newPaymentType === PAYMENT_TYPE.CASH_APP) {
    return PAYMENT_METHOD_LABEL.CASH_APP;
  }
  if (newPaymentType === PAYMENT_TYPE.PAYPAL) {
    return PAYMENT_METHOD_LABEL.PAYPAL;
  }
  if (newPaymentType === PAYMENT_TYPE.ALI_PAY) {
    return PAYMENT_METHOD_LABEL.ALI_PAY;
  }
  if (newPaymentType === PAYMENT_TYPE.PAYMENT_SHEET) {
    return PAYMENT_TYPE.PAYMENT_SHEET;
  }
  return titleCase(newPaymentType.replaceAll('_', ' '));
};

export const getOrderPaymentType = (paymentType: string, chargePaymentMethod: string) => {
  let orderPaymentType = PAYMENT_TYPE.STRIPE;
  if (paymentType) {
    switch (paymentType) {
      case PAYMENT_METHOD.CASH_APP:
        orderPaymentType = PAYMENT_TYPE.CASH_APP;
        break;
      case PAYMENT_METHOD.PAYPAL:
        orderPaymentType = PAYMENT_TYPE.PAYPAL;
        break;
      default:
        orderPaymentType = paymentType;
        break;
    }
  } else if (chargePaymentMethod) {
    const chargePaymentMethodFormatted = chargePaymentMethod?.split(' ')[0].toUpperCase();
    switch (chargePaymentMethodFormatted) {
      case PAYMENT_METHOD.APPLE_PAY:
        orderPaymentType = PAYMENT_TYPE.APPLE_PAY;
        break;
      case PAYMENT_METHOD.GOOGLE_PAY:
        orderPaymentType = PAYMENT_TYPE.GOOGLE_PAY;
        break;
      case PAYMENT_METHOD.LINK:
        orderPaymentType = PAYMENT_TYPE.LINK;
        break;
      case PAYMENT_METHOD.CASH_APP:
        orderPaymentType = PAYMENT_TYPE.CASH_APP;
        break;
      default:
        orderPaymentType = chargePaymentMethod;
        break;
    }
  }
  return getPaymentTypeTracking(orderPaymentType);
};
