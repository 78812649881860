import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css`
    background-color: #f6f6fa;
    justify-self: center;
    align-self: center;
    position: relative;
    width: 608px;
    border-radius: 4px;
    margin: auto;
  `,
  modalBody: css`
    padding: 32px;
    background-color: #f6f6fa;
  `
};
