import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const Launch = (props: SvgProps) => {
  return (
    <Svg id='gf_icon_launch' aria-label='Launch icon' width={32} height={32} viewBox='0 0 32 32' {...props}>
      <Path d='M26 28H6a2.003 2.003 0 01-2-2V6a2.003 2.003 0 012-2h10v2H6v20h20V16h2v10a2.003 2.003 0 01-2 2z' />
      <Path d='M20 2L20 4 26.586 4 18 12.586 19.414 14 28 5.414 28 12 30 12 30 2 20 2z' />
      <Path
        id='Launch_Transparent_Rectangle_'
        data-name='&lt;Transparent Rectangle&gt;'
        d='M0 0H32V32H0z'
        fill='none'
      />
    </Svg>
  );
};
