import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import uniq from 'lodash/uniq';
import { Event, InformationFormField } from '@gf/cross-platform-lib/interfaces';
import { TicketTypeSelection } from '../Cart';
import { IOrderTicketForm } from '../Order';
import {
  InformationForm,
  InformationForms,
  TicketsForms,
  AdditionalForms,
  AdditionalFormField,
  AdditionalFormFields,
  AdditionalForm,
  ProductType,
  EventSeason,
  getProductTypeMeta
} from '@gf/cross-platform-lib/interfaces';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { getEncryptedStrings, TheStore } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { isEncryptedField } from '@gf/cross-platform-lib/utils';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

export interface TicketsFormsAggregation {
  totalAdditionalForm: number;
  totalUncompletedAdditionalForm: number;
  totalCompletedAdditionalForm: number;
  uncompletedAdditionalFormIds: string[];
}

export type SelectedProductList = { [key: string]: TicketTypeSelection };

export enum TicketLevelType {
  TICKET_FORM = 'TicketForm',
  EVENT_FORM = 'EventForm',
  SEASON_FORM = 'SeasonForm'
}

export class TicketsAdditionalInfo {
  private forms: InformationForms;
  public ticketsForms: TicketsForms;
  private static instance: TicketsAdditionalInfo;
  static getInstance(): TicketsAdditionalInfo {
    if (!TicketsAdditionalInfo.instance) {
      TicketsAdditionalInfo.instance = new TicketsAdditionalInfo();
    }
    return TicketsAdditionalInfo.instance;
  }

  private constructor() {
    this.forms = {} as InformationForms;
    this.ticketsForms = {} as TicketsForms;
  }

  async init() {
    this.forms = (await this.getLocalStorageForms()) as InformationForms;
    this.ticketsForms = (await this.getLocalStorageTicketsForms()) as TicketsForms;
  }

  async setForms(forms: InformationForms, saving = true) {
    this.forms = assign({}, forms);
    saving && (await this.saveForms());
  }

  async setTicketsForms(ticketsForms: TicketsForms, saving = true) {
    this.ticketsForms = assign({}, ticketsForms);
    saving && (await this.saveTicketsForms());
  }

  async clearTicketsForms(saving = true) {
    this.ticketsForms = {};
    saving && (await this.saveTicketsForms());
  }

  /**
   * Get form fields data based on forms
   */
  get fields() {
    const formFields = {};
    Object.values(this.forms).forEach(form => {
      Object.values(form.formFields).forEach(field => {
        Object.assign(formFields, {
          [field.id]: {
            ...field
          }
        });
      });
    });
    return formFields;
  }

  /**
   * Get all tickets's additional forms
   */
  get ticketsAdditionalForms() {
    return this.ticketsForms;
  }

  /**
   * Get tickets's forms info by ticketId
   */
  getTicketsFormsById(ticketId: string) {
    if (!isEmpty(this.ticketsForms)) {
      return this.ticketsForms[ticketId];
    }
    return null;
  }

  aggregateTicketsFormStatus(ticketId: string): TicketsFormsAggregation {
    const template: TicketsFormsAggregation = {
      totalAdditionalForm: 0,
      totalUncompletedAdditionalForm: 0,
      totalCompletedAdditionalForm: 0,
      uncompletedAdditionalFormIds: []
    };
    if (this.ticketsForms && this.ticketsForms[ticketId]) {
      const { additionalForms } = this.ticketsForms[ticketId];
      const additionalFormKeys = Object.keys(additionalForms);

      const totalAdditionalForm = additionalFormKeys.length;
      const uncompletedAdditionalFormIds = additionalFormKeys.filter(
        key => !TicketsAdditionalInfo.isFilledForm(additionalForms[key])
      );
      const totalUncompletedAdditionalForm = uncompletedAdditionalFormIds.length;
      assign(template, {
        totalAdditionalForm,
        totalUncompletedAdditionalForm,
        totalCompletedAdditionalForm: totalAdditionalForm - totalUncompletedAdditionalForm,
        uncompletedAdditionalFormIds
      });
    }
    return template;
  }
  /**
   * Get all tickets's forms fields by ticketId
   */
  getTicketsFormsFields(ticketId: string) {
    if (!isEmpty(this.ticketsForms)) {
      return assign({}, this.ticketsForms[ticketId]?.fields);
    }
    return {};
  }
  /**
   * Get all tickets's additional forms fields by ticketId
   */
  getTicketsAdditionalFields(ticketId: string, additionalFormId: string) {
    if (!isEmpty(this.ticketsForms)) {
      return assign({}, this.ticketsForms[ticketId]?.additionalForms[additionalFormId]?.fields);
    }
    return {};
  }
  /**
   * Get specific additional form's fields data as objact
   */
  getAdditionalFormFields(formId: string): AdditionalFormFields {
    const formFields: AdditionalFormFields = {};
    if (!isEmpty(this.forms)) {
      const form = this.forms[formId];
      if (form) {
        Object.values(form.formFields).forEach(field => {
          Object.assign(formFields, {
            [field.id]: {
              ...field
            }
          });
        });
        return formFields;
      } else return {};
    } else return {};
  }

  /**
   * add new form into forms
   * @param form
   */
  async addForm(form?: InformationForm) {
    if (form) {
      if (!isEmpty(this.forms)) {
        this.setForms({
          ...this.forms,
          [form.id]: {
            ...form
          }
        });
      } else {
        await this.setForms({
          [form.id]: {
            ...form
          }
        });
      }
    }
  }

  /**
   * add new ticket form into ticketsForms
   * @param ticketId
   * @param formId
   * @param perTicket
   */
  async addNewTicketsForm(
    ticketId: string,
    formId: string,
    perTicket: boolean,
    level: 'SeasonForm' | 'EventForm' | 'TicketForm',
    productType: ProductType
  ) {
    const fields: AdditionalFormFields = {};
    if (this.forms && this.forms[formId]) {
      const formFields = this.forms[formId].formFields;
      formFields.forEach(field => {
        if (field.enabled) {
          assign(fields, {
            [field.id]: {
              ...field,
              error: '',
              value: '',
              level
            }
          });
        }
      });
    }

    const ticketForm = {
      [ticketId]: {
        perTicket,
        additionalForms: {},
        fields,
        forms: {
          [formId]: {
            id: formId,
            type: level
          }
        },
        appliedToAll: false,
        productType
      }
    };

    if (!isEmpty(this.ticketsForms)) {
      const currentTicket = this.ticketsForms[ticketId];
      if (currentTicket) {
        await this.setTicketsForms(
          assign({}, this.ticketsForms, {
            [ticketId]: {
              ...currentTicket,
              perTicket,
              fields: {
                ...currentTicket.fields,
                ...fields
              },
              forms: {
                ...currentTicket.forms,
                [formId]: {
                  id: formId,
                  type: level
                }
              }
            }
          })
        );
      } else {
        await this.setTicketsForms(assign({}, this.ticketsForms, ticketForm));
      }
    } else {
      await this.setTicketsForms(ticketForm);
    }
  }

  /**
   * add forms from event or ticket type into forms
   * @param event
   */
  async addEventsForm(event: Event | EventSeason, isSeason?: boolean) {
    const { form: eventForm, ticketTypes = [] } = event;
    await this.addForm(eventForm);
    ticketTypes.forEach(ticket => {
      this.addForm(ticket.form);
    });
    // identify ticket forms
    if (eventForm) {
      if (eventForm.perTicket === true) {
        for (const ticket of ticketTypes) {
          await this.addNewTicketsForm(
            ticket.id,
            eventForm.id,
            true,
            isSeason ? TicketLevelType.SEASON_FORM : TicketLevelType.EVENT_FORM,
            ticket.productType
          );
          const { form: ticketForm } = ticket;
          if (ticketForm) {
            await this.addNewTicketsForm(
              ticket.id,
              ticketForm.id,
              true,
              TicketLevelType.TICKET_FORM,
              ticket.productType
            );
          }
        }
      } else {
        for (const ticket of ticketTypes) {
          const { form: ticketForm } = ticket;
          if (ticketForm) {
            if (ticketForm.perTicket == true) {
              await this.addNewTicketsForm(
                ticket.id,
                eventForm.id,
                true,
                isSeason ? TicketLevelType.SEASON_FORM : TicketLevelType.EVENT_FORM,
                ticket.productType
              );
              await this.addNewTicketsForm(
                ticket.id,
                ticketForm.id,
                true,
                TicketLevelType.TICKET_FORM,
                ticket.productType
              );
            } else {
              await this.addNewTicketsForm(
                ticket.id,
                eventForm.id,
                false,
                isSeason ? TicketLevelType.SEASON_FORM : TicketLevelType.EVENT_FORM,
                ticket.productType
              );
              await this.addNewTicketsForm(
                ticket.id,
                ticketForm.id,
                false,
                TicketLevelType.TICKET_FORM,
                ticket.productType
              );
            }
          } else {
            await this.addNewTicketsForm(
              ticket.id,
              eventForm.id,
              false,
              isSeason ? TicketLevelType.SEASON_FORM : TicketLevelType.EVENT_FORM,
              ticket.productType
            );
          }
        }
      }
    } else {
      for (const ticket of ticketTypes) {
        const { form: ticketForm } = ticket;
        if (ticketForm) {
          if (ticketForm.perTicket == true) {
            await this.addNewTicketsForm(
              ticket.id,
              ticketForm.id,
              true,
              isSeason ? TicketLevelType.SEASON_FORM : TicketLevelType.EVENT_FORM,
              ticket.productType
            );
          } else {
            await this.addNewTicketsForm(
              ticket.id,
              ticketForm.id,
              false,
              isSeason ? TicketLevelType.SEASON_FORM : TicketLevelType.EVENT_FORM,
              ticket.productType
            );
          }
        }
      }
    }
  }

  /**
   * remove form from forms
   * @param id
   */
  removeForm(id: string) {
    if (this.forms && this.forms[id]) {
      delete this.forms[id];
    }
  }

  /**
   * remove all additional forms for ticket of ticketsForms
   * @param ticketId
   * @returns
   */
  removeAllAdditionalForms = async (ticketId: string | number) => {
    if (
      (typeof ticketId === 'string' || typeof ticketId === 'number') &&
      !isEmpty(this.ticketsForms) &&
      !isEmpty(this.ticketsForms[ticketId])
    ) {
      const {
        [ticketId]: {},
        ...remain
      } = this.ticketsForms;
      await this.setTicketsForms(remain);
    }
  };

  /**
   * add ticket's additional form into ticketsForms
   * base on ticketId & formId
   * @param ticketId
   * @param formId
   * @param fields
   * @param quantity
   */
  async addTicketsAdditionalForm(ticketId: string, quantity = 1) {
    if (!isEmpty(this.ticketsForms)) {
      const currentTicket = this.ticketsForms[ticketId];
      if (currentTicket) {
        const fields = assign({}, currentTicket.fields);
        const additionalForms: AdditionalForms = {};

        for (let counter = 0; counter < quantity; counter++) {
          const id = `${ticketId}-${Date.now()}-${uniqueId()}`;
          Object.assign(additionalForms, {
            [id]: {
              id,
              fields
            }
          });
        }
        // ticket already had form(s)
        await this.setTicketsForms({
          ...this.ticketsForms,
          [ticketId]: {
            ...currentTicket,
            additionalForms: {
              ...currentTicket.additionalForms,
              ...additionalForms
            }
          }
        });
      }
    }
  }

  /**
   * Removed ticket's additonal forms
   * @param ticketId
   * @param additionalformIds
   * @returns deletedFormIds
   */
  async removeTicketAdditionalForms(ticketId: string, additionalformIds: string[]) {
    if (!isEmpty(this.ticketsForms)) {
      const ticketForms = this.ticketsForms[ticketId];
      if (ticketForms) {
        const additionalForms = { ...ticketForms.additionalForms };
        const deletedFormIds: string[] = [];
        additionalformIds.forEach(id => {
          if (additionalForms[id]) {
            delete additionalForms[id];
            deletedFormIds.push(id);
          }
        });
        if (deletedFormIds.length > 0) {
          await this.setTicketsForms({
            ...this.ticketsForms,
            [ticketId]: {
              ...ticketForms,
              additionalForms
            }
          });
        }
        return deletedFormIds;
      }
    }
    return [];
  }

  /**
   * add aditonal forms for ticket of ticketsForms
   * @param tickets
   * @returns
   */
  async addAdditionalFormsFromTickets(tickets: TicketTypeSelection[]) {
    if (isEmpty(tickets)) return;
    tickets.forEach(async ticket => {
      const ticketId = ticket.ticketType.id;
      const ticketForm = this.ticketsForms[ticketId];
      if (ticketForm) {
        if (ticket.canBuy) {
          if (ticketForm.perTicket === true) {
            await this.changeTicketsFormsQuantity(ticketId, ticket.quantity);
          } else {
            if (isEmpty(ticketForm.additionalForms)) {
              ticket.quantity > 0 && (await this.addTicketsAdditionalForm(ticketId, 1));
            } else if (ticket.quantity === 0) {
              // remove addtional form when quantity === 0
              await this.changeTicketsFormsQuantity(ticketId, 0);
            }
          }
        }
      }
    });
  }

  async addTicketsForms(ticketId: string, quantity: number) {
    if (this.ticketsForms && this.ticketsForms[ticketId]) {
      const { perTicket, additionalForms } = this.ticketsForms[ticketId];
      if (perTicket) {
        await this.addTicketsAdditionalForm(ticketId, quantity);
      } else if (Object.keys(additionalForms).length === 0) {
        // just added 1 form for case perTicket = false
        await this.addTicketsAdditionalForm(ticketId, 1);
      }
    }
  }

  /**
   * remove ticket's additonal forms, priority by removing blank form first, then the lastest added info form.
   * @param ticketId
   * @param totalQuantity
   * @returns
   */

  async removeTicketForms(ticketId: string, totalQuantity: number) {
    if (this.ticketsForms && this.ticketsForms[ticketId]) {
      const { additionalForms } = this.ticketsForms[ticketId];
      const additionalFormKeys = Object.keys(additionalForms);

      const blankFormIds = additionalFormKeys.filter(key => TicketsAdditionalInfo.isBlankForm(additionalForms[key]));
      const hasDataFormIds = additionalFormKeys.filter(key => TicketsAdditionalInfo.isFilledForm(additionalForms[key]));
      const deleteFormIds = hasDataFormIds.concat(blankFormIds).slice(-totalQuantity);
      const deletedFormIds = await this.removeTicketAdditionalForms(ticketId, deleteFormIds);
      return deletedFormIds;
    }
    return [];
  }
  /**
   * Based on quantity and ticket's forms quantity, we decide add/remove forms
   * when change ticket's quantity
   * @param ticketId
   * @param quantity
   * @returns
   */
  async changeTicketsFormsQuantity(ticketId: string, totalQuantity: number) {
    if (this.ticketsForms && this.ticketsForms[ticketId]) {
      const { additionalForms } = this.ticketsForms[ticketId];
      const formsQuantity = Object.keys(additionalForms).length;
      if (totalQuantity > formsQuantity) {
        await this.addTicketsForms(ticketId, totalQuantity - formsQuantity);
      } else if (totalQuantity < formsQuantity) {
        await this.removeTicketForms(ticketId, formsQuantity - totalQuantity);
      }
    }
  }

  /**
   * update ticket's forms field data
   * @param ticketId
   * @param additionalFormId
   * @param field
   */
  async updateTicketsAdditionalFormField(ticketId: string, additionalFormId: string, field: AdditionalFormField) {
    if (this.ticketsForms[ticketId]) {
      const currentTicket = assign({}, this.ticketsForms[ticketId]);
      const updatingField = currentTicket.additionalForms[additionalFormId]?.fields[field.id];
      if (updatingField) {
        currentTicket.additionalForms[additionalFormId].fields[field.id] = {
          ...updatingField,
          ...field
        };
        await this.setTicketsForms({
          ...this.ticketsForms,
          [ticketId]: currentTicket
        });
      }
    }
  }

  /**
   * update ticket's forms fields data
   * @param ticketId
   * @param additionalFormId
   * @param fields
   */
  async updateTicketsAdditionalFormFields(ticketId: string, additionalFormId: string, fields: AdditionalFormFields) {
    if (this.ticketsForms[ticketId]) {
      const currentTicket = assign({}, this.ticketsForms[ticketId]);
      const updatingFields = currentTicket.additionalForms[additionalFormId]?.fields;
      if (updatingFields) {
        currentTicket.additionalForms[additionalFormId].fields = {
          ...updatingFields,
          ...fields
        };
        await this.setTicketsForms({
          ...this.ticketsForms,
          [ticketId]: currentTicket
        });
      }
    }
  }

  async updateTicketsAdditionalFormFieldsFromFormData(
    ticketId: string,
    additionalFormId: string,
    formData: { [filedId: string]: number | string },
    appliedToAll: boolean
  ) {
    const currentField = this.ticketsForms[ticketId]?.additionalForms[additionalFormId];

    if (currentField && !isEmpty(formData)) {
      const currentTicket = this.ticketsForms[ticketId];
      const { additionalForms } = currentTicket;

      const fields: AdditionalFormFields = {};
      Object.keys(formData).forEach(key => {
        let value = formData[key];
        if (typeof formData[key] === 'string') {
          value = `${formData[key]}`;
        } else if (typeof formData[key] === 'boolean') {
          value = formData[key] ? 'true' : 'false';
        }
        assign(fields, {
          [key]: {
            ...currentField.fields[key],
            value
          }
        });
      });

      if (appliedToAll === true && getProductTypeMeta(currentTicket.productType, 'isTicketLike')) {
        const additionalFormKeys = Object.keys(additionalForms);

        const firstAdditionalFormId = additionalFormKeys[0];
        if (currentTicket.appliedToAll === false) {
          currentTicket.appliedToAll = true;
          // set ticket's additional forms value to ""
          additionalFormKeys.forEach(key => {
            Object.keys(additionalForms[key].fields).forEach(fieldKey => {
              additionalForms[key].fields[fieldKey].value = '';
            });
          });
        }
        // applied for all option: update the first element for all type of tickets levels: 'TicketForm' | 'EventForm' | 'SeasonForm'

        await this.updateTicketsAdditionalFormFields(ticketId, firstAdditionalFormId, fields);
      } else {
        if (currentTicket.appliedToAll === true) {
          currentTicket.appliedToAll = false;
        }
        await this.updateTicketsAdditionalFormFields(ticketId, additionalFormId, fields);
      }
    }
  }

  getTicketsAdditionalFormFieldsAsFormData(
    ticketId: string,
    additionalFormId: string
  ): { [filedId: string]: number | string } {
    const fields = this?.ticketsForms[ticketId]?.additionalForms[additionalFormId]?.fields;
    if (fields) {
      const fieldsData = {};
      Object.keys(fields).forEach(key => {
        assign(fieldsData, {
          [key]: fields[key].value
        });
      });
      return fieldsData;
    }
    return {};
  }
  /**
   * get additional forms of ticket's form
   * @param ticketId
   * @returns
   */
  getAdditionalFormsOfTicket(ticketId: string) {
    if (!isEmpty(this.ticketsForms)) {
      return assign({}, this.ticketsForms[ticketId]?.additionalForms || {});
    }
    return {};
  }

  /**
   * get index of addtional form in ticket's additional forms
   * @param ticketId
   * @param additionalFormId
   * @returns
   */
  getAdditionalFormIndex(ticketId: string, additionalFormId: string) {
    if (this.ticketsForms && this.ticketsForms[ticketId]) {
      return Object.keys(this.ticketsForms[ticketId].additionalForms).findIndex(id => id == additionalFormId);
    }
    return -1;
  }

  async saveTicketsForms() {
    await TheStore.setItem('tickets-forms', JSON.stringify(this.ticketsForms));
  }

  async saveForms() {
    await TheStore.setItem('forms', JSON.stringify(this.forms));
  }

  async getLocalStorageTicketsForms() {
    const item = await TheStore.getItem('tickets-forms');
    if (item) {
      try {
        return JSON.parse(item);
      } catch (err: any) {
        recordError(err, {
          originatingFunction: 'TicketsAdditionalInfo-getLocalStorageTicketsForms',
          customMessage: 'Error parsing ticket(s) forms from local storage',
          errorGroup: NEW_RELIC_ERROR_GROUPS.DataIssues,
          data: { item }
        });
      }
    }
    return {};
  }

  getFormToDetectEmptyForm = (forms: AdditionalForms) =>
    Object.values(forms).map(({ fields, id }) => {
      const value = Object.values(fields).map(({ value }) => value);
      return {
        value,
        id,
        isEmptyForm: value.every(txt => isEmpty(txt))
      };
    });

  async getLocalStorageForms() {
    const item = await TheStore.getItem('forms');
    if (item) {
      try {
        return JSON.parse(item);
      } catch (err: any) {
        recordError(err, {
          originatingFunction: 'TicketsAdditionalInfo-getLocalStorageForms',
          customMessage: 'Error parsing form(s) from local storage',
          errorGroup: NEW_RELIC_ERROR_GROUPS.DataIssues,
          data: { item }
        });
      }
    }
    return {};
  }

  /**
   * get ticket forms for create order.
   * @param productId
   * @returns
   */
  getOrderTicketForms = (productId: string): IOrderTicketForm[] => {
    const { additionalForms, appliedToAll } = this.ticketsForms[productId] || {};
    if (!isEmpty(additionalForms)) {
      const mapFields = (fields: AdditionalFormFields, formId: string) => ({
        fields: Object.values(fields).map(({ id: formFieldId, name, value, displayOnTicket }) => {
          return {
            formFieldId,
            name,
            value,
            displayOnTicket
          };
        }),
        formId
      });
      const formId = Object.keys(this.ticketsForms[productId].forms)[0];
      const additionalFormKeys = Object.keys(additionalForms);
      if (appliedToAll) {
        const firstAdditionalFormId = additionalFormKeys[0];
        const firstAdditionalFormFields = additionalForms[firstAdditionalFormId].fields;
        return additionalFormKeys.map(_ =>
          mapFields(firstAdditionalFormFields, formId)
        ) as unknown as IOrderTicketForm[];
      }
      return Object.values(additionalForms).map(({ fields }) =>
        mapFields(fields, formId)
      ) as unknown as IOrderTicketForm[];
    }
    return [];
  };

  getFormsEncryptedStrings = async () => {
    let unencryptedStrings: string[] = [];
    Object.keys(this.ticketsForms).forEach(productId => {
      const ticketForms = this.getOrderTicketForms(productId);
      ticketForms.forEach(item => {
        item.fields.forEach(field => {
          isEncryptedField(field.displayOnTicket, field.name) && unencryptedStrings.push(field.value);
        });
      });
    });
    if (!isEmpty(unencryptedStrings)) {
      const { data: encryptedStrings } = await getEncryptedStrings(uniq(unencryptedStrings));
      return encryptedStrings;
    }
    return {};
  };

  removeTicketFormsOfTicket = async (ticketId = '', qty: number) => {
    const mappedObject = this.getFormToDetectEmptyForm(this.getAdditionalFormsOfTicket(ticketId));
    const emptyAdditionalFormIds = mappedObject
      .filter((form, index) => index + 1 > qty && form.isEmptyForm)
      .map(({ id }) => id);
    await this.removeTicketAdditionalForms(ticketId, emptyAdditionalFormIds);
  };

  // TicketsAdditionalInfo's utils
  static isBlankForm(form: AdditionalForm) {
    const { fields } = form;
    return Object.keys(fields).every(key => isEmpty(fields[key].value));
  }

  static isFormNeedFilled({ fields }: AdditionalForm | { fields: AdditionalFormFields }) {
    if (isEmpty(fields)) return false;
    return Object.keys(fields).some(key => (fields[key].required ? isEmpty(fields[key].value) : false));
  }

  /**
   * Is Optional Aditional Form: every fields are not required
   * @param param0 e
   * @returns
   */
  static isOptionalAditionalForm({ fields }: AdditionalForm | { fields: AdditionalFormFields }) {
    return Object.keys(fields).every(key => fields[key].required === false);
  }

  /**
   * Is RequiredAditional Forms: ome fields are required
   * @param
   * @returns
   */
  static isRequiredAditionalForm({ fields }: AdditionalForm | { fields: AdditionalFormFields }) {
    return Object.keys(fields).some(key => fields[key].required);
  }

  static isDirtyForm(fields: AdditionalFormFields) {
    // form has added data for some fields
    return Object.keys(fields).some(key => !isEmpty(fields[key].value));
  }

  static isFilledForm(form: AdditionalForm) {
    const { fields } = form;
    return Object.keys(fields).every(key => {
      if (fields[key].required) return !isEmpty(fields[key].value);
      return true;
    });
  }

  static shouldAddFormsInfo(additionalForms: AdditionalForms, appliedToAll: boolean) {
    if (isEmpty(additionalForms)) return false;
    const addtionalFormKeys = Object.keys(additionalForms);
    if (appliedToAll) {
      const firstAFId = addtionalFormKeys[0];
      return TicketsAdditionalInfo.isFormNeedFilled(additionalForms[firstAFId]);
    }
    return addtionalFormKeys.some(key => TicketsAdditionalInfo.isFormNeedFilled(additionalForms[key]));
  }

  static mustCompleteTicketsForms(ticketsForms: TicketsForms) {
    if (isEmpty(ticketsForms)) return false;
    return Object.keys(ticketsForms).some(key => {
      const ticketsForm = ticketsForms[key];
      const { additionalForms, appliedToAll } = ticketsForm;

      return TicketsAdditionalInfo.shouldAddFormsInfo(additionalForms, appliedToAll);
    });
  }

  static getUncompletedTicketsForms(_ticketsForms: TicketsForms) {}

  static getOrderedFields(fields: AdditionalFormFields) {
    if (isEmpty(fields)) return [];
    const list = Object.keys(fields)
      .map(key => fields[key])
      .sort((a, b) => {
        if (a.level === b.level) return 0;
        if (a.level === TicketLevelType.EVENT_FORM || a.level === TicketLevelType.SEASON_FORM) return -1;
        return 1;
      });
    return list;
  }

  static getEventFormFields(event: Event, withTickets = true): Map<number, InformationFormField> {
    const formFields = new Map<number, InformationFormField>();
    if (event) {
      const { form: eventForm, ticketTypes } = event;
      if (eventForm && !isEmpty(eventForm.formFields)) {
        eventForm.formFields.forEach(field => {
          if (formFields.has(Number(field.id))) return;
          formFields.set(Number(field.id), field);
        });
      }
      if (withTickets && !isEmpty(ticketTypes)) {
        for (const ticket of ticketTypes) {
          const { form: ticketForm } = ticket;
          if (ticketForm && !isEmpty(ticketForm.formFields)) {
            ticketForm.formFields.forEach(field => {
              if (formFields.has(Number(field.id))) return;
              formFields.set(Number(field.id), field);
            });
          }
        }
      }
    }

    return formFields;
  }
}
