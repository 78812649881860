import React, { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import styled from '@gf/cross-platform-lib/styled-components';
import cloneDeep from 'lodash/cloneDeep';

import { Carbonicons, KeyboardAvoidingScrollView } from '@gf/cross-platform-lib/components';
import { MotiView } from 'moti';
import { AddInformationTab } from './AddInformationTab';
import { ApplyToAllTab } from './ApplyToAllTab';
import { Dimensions, Platform } from 'react-native';

import { AdditionalFormFields, getProductTypeMeta, ProductType } from '@gf/cross-platform-lib/interfaces';
import { styles } from './TicketsAdditionalModal.styles';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { reviewAndBuyPage, testProperties } from '@gf/cross-platform-lib/utils';

const Container = styled.View`
  ${styles.container}
`;

const Dialog = styled.View`
  ${styles.dialog}
`;

const TabsBar = styled.View`
  ${styles.tabsBar}
`;

const LelfItem = styled.TouchableOpacity`
  ${styles.leftItem}
`;

const RightItem = styled.TouchableOpacity`
  ${styles.rightItem}
`;

const Tabs = styled(MotiView)`
  ${styles.tabs}
`;

const SCREEN_VERTICAL_PADDING = 100;
interface TicketsAdditionalModalProps {
  fields: AdditionalFormFields;
  totalForm: number;
  totalCompletedForm: number;
  additionalFormId: string;
  mode: 'Edit' | 'Add';
  productType: ProductType;
  ticketQuantity: number;
  perTicket?: boolean;
  closeOnClickOutside?: boolean;
  formIndex: number;
  appliedToAll?: boolean;
  onCloseDialog: () => void;
  onSave: (data: any, appliedToAll?: boolean) => void;
}

export const TicketsAdditionalModal = ({
  fields,
  totalForm,
  totalCompletedForm,
  additionalFormId,
  mode,
  productType,
  ticketQuantity,
  perTicket,
  formIndex,
  appliedToAll,
  onCloseDialog,
  onSave
}: TicketsAdditionalModalProps) => {
  const { isNativeApp } = useMediaQuery();
  const [tabNumber, setTabNumber] = useState(0);
  const [fieldsData, setFieldsData] = useState<AdditionalFormFields>({});

  const canBack = tabNumber > 0;
  const hasApplyToAll =
    appliedToAll ||
    (perTicket === true && ticketQuantity > 1 && (getProductTypeMeta(productType, 'isTicketLike') as boolean));
  const onAddInfoTabSave = (data: FieldValues, isAppliedToAll: boolean) => {
    if (isAppliedToAll) {
      const formCompletedCount = mode === 'Edit' ? totalCompletedForm - 1 : totalCompletedForm;
      if (formIndex === 0 && formCompletedCount === 0) {
        // apply to all
        onSave(data, true);
      } else {
        // show apply to all tab
        setTabNumber(1);
        if (data) {
          const formData = cloneDeep(fieldsData);
          Object.keys(data).forEach(id => {
            formData[id].value = data[id];
          });
          setFieldsData(formData);
        }
      }
    } else {
      onSave(data);
    }
  };

  const onApplyToAll = (data: FieldValues) => {
    onSave(data, true);
  };

  useEffect(() => {
    setFieldsData(cloneDeep(fields));
  }, [additionalFormId]);

  const window = Dimensions.get('window');
  const screenHeight = window.height;

  const dialogHeight = Platform.OS === 'ios' ? screenHeight - SCREEN_VERTICAL_PADDING : 80;
  const dialogUnits = Platform.OS === 'ios' ? 'px' : '%';
  const bottomMarginOffset = SCREEN_VERTICAL_PADDING / 2;
  return true ? (
    <Dialog
      height={dialogHeight}
      isNativeApp={isNativeApp}
      units={dialogUnits}
      {...testProperties(reviewAndBuyPage.addInfoDialog.section)}
    >
      <TabsBar>
        {canBack && (
          <LelfItem onPress={() => setTabNumber(0)}>
            <Carbonicons name='chevronLeft' size={22} />
          </LelfItem>
        )}

        <RightItem onPress={onCloseDialog}>
          <Carbonicons name='close' size={22} color='#161616' />
        </RightItem>
      </TabsBar>
      <Tabs
        isNativeApp={isNativeApp}
        tabNumber={tabNumber}
        animate={{ translateX: -tabNumber * 320 }}
        transition={{
          type: 'timing',
          duration: 200
        }}
      >
        <KeyboardAvoidingScrollView style={{ width: 320 }} bottomMarginOffset={bottomMarginOffset}>
          <AddInformationTab
            fields={fieldsData}
            mode={mode}
            hasApplyAllCheckbox={hasApplyToAll}
            totalForm={totalForm}
            formIndex={formIndex}
            perTicket={perTicket}
            onConfirm={onAddInfoTabSave}
            onCancel={onCloseDialog}
            appliedToAll={appliedToAll}
          />
        </KeyboardAvoidingScrollView>

        {canBack && (
          <Container>
            <ApplyToAllTab
              fields={fieldsData}
              onConfirm={onApplyToAll}
              onCancel={onCloseDialog}
              isActive={tabNumber === 1}
            />
          </Container>
        )}
      </Tabs>
    </Dialog>
  ) : null;
};
