import { css } from 'styled-components';

export const styles = {
  container: css`
    margin-top: 32px;
  `,
  subIconContainer: css`
    position: relative;
    justify-content: flex-end;
    margin-bottom: 11px;
    align-self: center;
  `,
  subIconWrapper: css`
    align-self: flex-end;
    position: absolute;
    right: 4px;
    top: 44px;
  `,
  warningWrapper: css`
    border-radius: 40px;
    background-color: rgb(50, 50, 50);
    height: 64px;
    width: 64px;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    position: absolute;
    right: 10px;
    top: 48px;
  `,
  textWrapper: css`
    margin-top: 16px;
    margin-bottom: 16px;
    max-width: 320px;
  `,
  dateWrapper: css<{ renewed: boolean }>`
    border-radius: 12px;
    height: 24px;
    margin-bottom: 32px;
    background-color: ${props => (props.renewed ? 'rgb(167, 240, 186)' : 'rgb(255, 215, 217)')};
    align-items: center;
    justify-content: center;
    padding-horizontal: 8px;
    align-self: center;
  `,
  fetchingSeats: css`
    margin-bottom: 32px;
  `
};
