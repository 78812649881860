// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const NoTicket = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='currentColor' {...props}>
    <Path d='M28 6h-.586L30 3.414 28.586 2 2 28.586 3.414 30l4-4H28a2.003 2.003 0 0 0 2-2v-5a1 1 0 0 0-1-1 2 2 0 0 1 0-4 1 1 0 0 0 1-1V8a2.002 2.002 0 0 0-2-2Zm0 6.127a4 4 0 0 0 0 7.746V24h-7v-3h-2v3H9.414L19 14.414V19h2v-6.586L25.414 8H28ZM4 12.127V8h15V6H4a2.002 2.002 0 0 0-2 2v5a1 1 0 0 0 1 1 2 2 0 0 1 0 4 1 1 0 0 0-1 1v5h2v-4.127a4 4 0 0 0 0-7.747Z' />
    <Path
      data-name='&lt;Transparent Rectangle&gt;'
      style={{
        fill: 'none'
      }}
      d='M0 0h32v32H0z'
    />
  </Svg>
);
