import styled from '@gf/cross-platform-lib/styled-components';
import { Text } from 'react-native';
import { textColor, textStyle } from '@gf/cross-platform-lib/styled-system';
import type { TextColorTokenType, TextTokenType } from '@gf/cross-platform-lib/styled-system';

export interface StyledTextProps {
  typeToken: TextTokenType;
  color?: TextColorTokenType;
  weight?: number;
  center?: boolean;
  textAlign?: 'center' | 'left' | 'right' | 'default';
  textDecoration?: 'underline' | 'none';
  maxHeight?: number | string;
  textDecorationColor?: string;
  testID?: string;
}

export const StyledText = styled(Text).attrs<StyledTextProps>((props: StyledTextProps) => ({
  testID: props.testID
}))<StyledTextProps>(
  ({
    color,
    typeToken,
    weight,
    textAlign,
    textDecoration,
    textDecorationColor,
    maxHeight = '100%'
  }: StyledTextProps) => ({
    color: color ? textColor(color) : '#161616',
    ...textStyle(typeToken),
    ...() => (weight ? { fontWeight: String(weight) } : {}),
    textAlign,
    textDecoration,
    maxHeight,
    textDecorationColor
  })
);
