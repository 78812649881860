// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const Accessibility = (props: SvgProps) => (
  <Svg
    id='gf_icon_accessibility'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='32px'
    height='32px'
    viewBox='0 0 32 32'
    xmlSpace='preserve'
    enableBackground='new 0 0 32 32'
    aria-label='Accessibility icon'
    {...props}
  >
    <Path
      d='M29.55,26.11,26.5,27.63,23.66,21H15a2,2,0,0,1-2-2V13a2,2,0,0,1,4,0v4h7V15H19V13a4,4,0,0,0-8,0v1a9,9,0,1,0,8.77,11H17.71A7,7,0,1,1,11,16v3a4,4,0,0,0,4,4h7.34l3.16,7.37,4.95-2.48Z'
      fill='black'
    />
    <Path
      d='M15.5,8A3.5,3.5,0,1,1,19,4.5,3.5,3.5,0,0,1,15.5,8Zm0-5A1.5,1.5,0,1,0,17,4.5,1.5,1.5,0,0,0,15.5,3Z'
      fill='black'
    />
    <Path id='Accessibility_Transparent_Rectangle' fill='none' />
  </Svg>
);
