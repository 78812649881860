// The color tokens based on design:
// https://app.abstract.com/projects/a919c830-500d-4582-968b-42dc825669fa/branches/51ccd0f5-2f2b-4ffb-8280-fef560130211/commits/527dbbedf9be3d3790481c3818a56e4c1501618e/files/a4fd13c5-6fa9-4eb1-a4dc-9efed77c71e0/layers/2A1ED310-EA4E-4519-A15C-DBA259244C88?mode=design

export const inherit = 'inherit';
export const transparent = '#ffffffff';
export const black = '#000000';
export const modernBlack0 = '#323232';
export const modernBlack1 = '#5b5b5b';
export const modernBlack2 = '#d3d3d3'; // Need confirm from Designer
export const modernBlackNeg1 = '#282828'; // Modern Black Negative

export const black100 = black;
export const blackHover = '#212121';

export const white = '#ffffff';
export const white0 = white;
export const whiteHover = '#e8e8e8';

export const red10 = '#fff1f1';
export const red20 = '#ffd7d9';
export const red30 = '#ffb3b8';
export const red40 = '#ff8389';
export const red50 = '#fa4d56';
export const red60 = '#da1e28';
export const red70 = '#a2191f';
export const red80 = '#750e13';
export const red90 = '#520408';
export const red100 = '#2d0709';
export const red = {
  10: red10,
  20: red20,
  30: red30,
  40: red40,
  50: red50,
  60: red60,
  70: red70,
  80: red80,
  90: red90,
  100: red100
};

export const red100Hover = '#540d11';
export const red90Hover = '#66050a';
export const red80Hover = '#921118';
export const red70Hover = '#c21e25';
export const red60Hover = '#b81922';
export const red50Hover = '#ee0713';
export const red40Hover = '#ff6168';
export const red30Hover = '#ff99a0';
export const red20Hover = '#ffc2c5';
export const red10Hover = '#ffe0e0';
export const redHover = {
  100: red100Hover,
  90: red90Hover,
  80: red80Hover,
  70: red70Hover,
  60: red60Hover,
  50: red50Hover,
  40: red40Hover,
  30: red30Hover,
  20: red20Hover,
  10: red10Hover
};

export const iosBlue = '#0E7AFE';
export const blue10 = '#edf5ff';
export const blue20 = '#d0e2ff';
export const blue30 = '#a6c8ff';
export const blue40 = '#78a9ff';
export const blue50 = '#4589ff';
export const blue60 = '#0f62fe';
export const blue70 = '#0043ce';
export const blue80 = '#002d9c';
export const blue90 = '#001d6c';
export const blue100 = '#001141';
export const blue = {
  10: blue10,
  20: blue20,
  30: blue30,
  40: blue40,
  50: blue50,
  60: blue60,
  70: blue70,
  80: blue80,
  90: blue90,
  100: blue100
};

export const blue100Hover = '#001f75';
export const blue90Hover = '#00258a';
export const blue80Hover = '#0039c7';
export const blue70Hover = '#0053ff';
export const blue60Hover = '#0050e6';
export const blue50Hover = '#1f70ff';
export const blue40Hover = '#5c97ff';
export const blue30Hover = '#8ab6ff';
export const blue20Hover = '#b8d3ff';
export const blue10Hover = '#dbebff';
export const blueHover = {
  100: blue100Hover,
  90: blue90Hover,
  80: blue80Hover,
  70: blue70Hover,
  60: blue60Hover,
  50: blue50Hover,
  40: blue40Hover,
  30: blue30Hover,
  20: blue20Hover,
  10: blue10Hover
};

export const gray10 = '#f4f4f4';
export const gray20 = '#e0e0e0';
export const gray30 = '#c6c6c6';
export const gray40 = '#a8a8a8';
export const gray50 = '#8d8d8d';
export const gray60 = '#6f6f6f';
export const gray70 = '#525252';
// Changed from #4a4a4a to #767676 for contrast ratio due to accessibility scanning requirements
export const gray75 = '#767676';
export const gray80 = '#393939';
export const gray90 = '#262626';
export const gray100 = '#161616';
export const gray = {
  10: gray10,
  20: gray20,
  30: gray30,
  40: gray40,
  50: gray50,
  60: gray60,
  70: gray70,
  75: gray75,
  80: gray80,
  90: gray90,
  100: gray100
};

export const gray10Hover = '#e8e8e8';
export const gray20Hover = '#d1d1d1';
export const gray30Hover = '#b5b5b5';
export const gray40Hover = '#999999';
export const gray50Hover = '#7a7a7a';
export const gray60Hover = '#5e5e5e';
export const gray70Hover = '#636363';
export const gray80Hover = '#474747';
export const gray90Hover = '#333333';
export const gray100Hover = '#292929';
export const grayHover = {
  10: gray10Hover,
  20: gray20Hover,
  30: gray30Hover,
  40: gray40Hover,
  50: gray50Hover,
  60: gray60Hover,
  70: gray70Hover,
  80: gray80Hover,
  90: gray90Hover,
  100: gray100Hover
};

// Background
export type BackgroundColorToken =
  | 'background'
  | 'backgroundActive'
  | 'backgroundHover'
  | 'backgroundSelected'
  | 'backgroundSelectedHover'
  | 'backgroundBranch'
  | 'backgroundInverse'
  | 'backgroundInverseHover'
  | 'backgroundInfo'; // Private token

export const backgroundColors = {
  background: white,
  backgroundActive: gray30,
  backgroundHover: gray10Hover,
  backgroundSelected: gray20,
  backgroundSelectedHover: gray20Hover,
  backgroundBranch: blue60,
  backgroundInverse: modernBlack0,
  backgroundInverseHover: modernBlack1,
  backgroundInfo: modernBlack2
};

export const backgroundColor = (name: BackgroundColorToken): string => {
  if (!backgroundColors[name]) {
    throw new Error(
      `Unable to find background color: \`${name}\`. Expected one of: [${Object.keys(backgroundColors).join(', ')}]`
    );
  }
  return backgroundColors[name];
};
// Layer
export type LayerColorToken =
  | 'layer01'
  | 'layerActive01'
  | 'layerHover01'
  | 'layerSelected01'
  | 'layerSelectedHover01'
  | 'layerDisabled01'
  | 'layer02'
  | 'layerActive02'
  | 'layerHover02'
  | 'layerSelected02'
  | 'layerSelectedHover02'
  | 'layerDisabled02'
  | 'layer03'
  | 'layerActive03'
  | 'layerHover03'
  | 'layerSelected03'
  | 'layerSelectedHover03'
  | 'layerDisabled03'
  | 'layerSelectedInverse'
  | 'layerSelectedDisabled';

// layers - (Design is not updated)
export const layerColors = {
  layer01: gray10,
  layerActive01: gray30,
  layerHover01: gray10Hover,
  layerSelected01: gray20,
  layerSelectedHover01: gray20Hover,
  layerDisabled01: gray10,
  layer02: white,
  layerActive02: gray30,
  layerHover02: gray10Hover,
  layerSelected02: gray20,
  layerSelectedHover02: gray20Hover,
  layerDisabled02: white,
  layer03: gray10,
  layerActive03: gray30,
  layerHover03: gray10Hover,
  layerSelected03: gray20,
  layerSelectedHover03: gray20Hover,
  layerDisabled03: gray10,
  layerSelectedInverse: gray100,
  layerSelectedDisabled: gray50
};

// LayerAccent
export type LayerAccentColorToken =
  | 'layerAccent01'
  | 'layerAccentActive01'
  | 'layerAccentHover01'
  | 'layerAccent02'
  | 'layerAccentActive02'
  | 'layerAccentHover02'
  | 'layerAccent03'
  | 'layerAccentActive03'
  | 'layerAccentHover03';

// layerAccents - (Design is not updated)
export const layerAccentColors = {
  layerAccent01: gray20,
  layerAccentActive01: gray40,
  layerAccentHover01: gray20Hover,
  layerAccent02: gray20,
  layerAccentActive02: gray40,
  layerAccentHover02: gray20Hover,
  layerAccent03: gray20,
  layerAccentActive03: gray40,
  layerAccentHover03: gray20Hover
};

// Field
export type FieldColorToken =
  | 'field01'
  | 'fieldHover01'
  | 'fieldDisabled01'
  | 'field02'
  | 'fieldHover02'
  | 'fieldDisabled02'
  | 'field03'
  | 'fieldHover03'
  | 'fieldDisabled03';

// fields
export const fieldColors = {
  field01: gray10,
  fieldHover01: gray10Hover,
  fieldDisabled01: gray10,
  field02: white,
  fieldHover02: whiteHover,
  fieldDisabled02: white,
  field03: gray10,
  fieldHover03: gray10Hover,
  fieldDisabled03: gray10
};

// Borders
export type BorderColorToken =
  | 'borderSubtle00'
  | 'borderSubtle01'
  | 'borderSubtleSelected01'
  | 'borderSubtle02'
  | 'borderSubtleSelected02'
  | 'borderSubtle03'
  | 'borderSubtleSelected03'
  | 'borderStrong01'
  | 'borderStrong02'
  | 'borderStrong03'
  | 'borderInverse'
  | 'borderInteractive'
  | 'borderDisabled';
// fields
export const borderColors = {
  borderSubtle00: gray20,
  borderSubtle01: gray20,
  borderSubtleSelected01: gray30,
  borderSubtle02: gray20,
  borderSubtleSelected02: gray30,
  borderSubtle03: gray20,
  borderSubtleSelected03: gray30,
  borderStrong01: gray50,
  borderStrong02: gray50,
  borderStrong03: gray50,
  borderInverse: blue100,
  borderInteractive: blue60,
  borderDisabled: gray10
};

export type TextColorTokenType =
  | 'textPrimary'
  | 'textSecondary'
  | 'textPlaceholder'
  | 'textOnColor'
  | 'textOnColorDisabled'
  | 'textHelper'
  | 'textError'
  | 'textInverse'
  | 'textDisabled'
  | 'textDisclaimer'
  | 'linkPrimary'
  | 'linkPrimaryHover'
  | 'linkSecondary'
  | 'linkInverse'
  | 'linkVisited'
  | 'inherit';

export const textColors = {
  textPrimary: gray100,
  textSecondary: gray70,
  textPlaceholder: gray40,
  textOnColor: white,
  textOnColorDisabled: gray50,
  textHelper: gray60,
  textError: red60,
  textInverse: white,
  textDisabled: gray100 + '40', // 25% Opacity
  textDisclaimer: gray75,
  linkPrimary: blue60,
  linkPrimaryHover: blue70,
  linkSecondary: blue70,
  linkInverse: blue40,
  linkVisited: blue60,
  iosBlue: iosBlue,
  inherit: inherit
};
/**
 * Get text color based on token
 * @param { token } name - family name
 * @returns { string }
 */
export function textColor(token: TextColorTokenType | string): string {
  const key = Object.keys(textColors).find(k => k === token) as TextColorTokenType;
  if (key) return textColors[key];
  return token;
}

export const linkColors = {
  linkPrimary: blue60,
  linkPrimaryHover: blue70,
  linkSecondary: blue70,
  linkInverse: blue40,
  linkVisited: blue60
};

export const iconColors = {
  iconPrimary: gray100,
  iconSecondary: gray70,
  iconOnColor: white,
  iconOnColorDisabled: gray50,
  iconInverse: white,
  iconDisabled: gray100 + '40' // 25% Opacity
};

export const buttonColors = {
  buttonPrimary: modernBlack0,
  buttonPrimaryHover: modernBlack1,
  buttonPrimaryActive: modernBlackNeg1,
  buttonSecondary: gray80,
  buttonSecondaryHover: gray80Hover,
  buttonSecondaryActive: gray60,
  buttonTertiary: blue60,
  buttonTertiaryHover: blue60Hover,
  buttonTertiaryActive: blue80,
  buttonDangerPrimary: red60,
  buttonDangerSecondary: red60,
  buttonDangerHover: red60Hover,
  buttonDangerActive: red80,
  buttonSeparator: gray20,
  buttonDisabled: gray30
};

export const colors = {
  transparent,
  black: {
    100: black
  },
  modernBlack: {
    0: modernBlack0,
    1: modernBlack1,
    '-1': modernBlackNeg1
  },
  blue,
  gray,
  red,
  white: {
    0: white
  },
  backgroundColors,
  layerColors,
  layerAccentColors,
  fieldColors,
  textColors,
  buttonColors,
  linkColors,
  iconColors
};

export const hoverColors = {
  whiteHover,
  blackHover,
  blueHover,
  grayHover,
  redHover
};
