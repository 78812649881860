// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path, G } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const Facebook = (props: SvgProps) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 1000 1000'
    enableBackground='new 0 0 1000 1000'
    xmlSpace='preserve'
    {...props}
  >
    <G>
      <Path d='M826.7,10H173.3C83.1,10,10,83.1,10,173.4v653.3C10,916.9,83.1,990,173.3,990H500V561.3H377.5V438.8H500v-91.9c0-84.6,68.6-153.1,153.1-153.1h153.1v122.5H653.1c-16.9,0-30.6,13.7-30.6,30.6v91.9h168.4l-30.6,122.5H622.5V990h204.2c90.2,0,163.3-73.1,163.3-163.4V173.4C990,83.1,916.9,10,826.7,10z' />
    </G>
  </Svg>
);
