// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const ChevronLeft = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill={'black'} {...props}>
    <Path d='M10 16L20 6 21.4 7.4 12.8 16 21.4 24.6 20 26z' />
    <Path id='_x3C_Transparent_Rectangle_x3E_' d='M0 0H32V32H0z' fill='none' />
  </Svg>
);
