// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Rect, G, Path, Defs, ClipPath } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const LogoTwitter = (props: SvgProps) => (
  <Svg
    aria-label='Twitter icon'
    xmlns='http://www.w3.org/2000/svg'
    width={25}
    height={24}
    fill='none'
    viewBox='0 0 25 24'
    {...props}
  >
    <Rect width={24} height={24} x={0.975} fill='#282828' rx={4} />
    <G clipPath='url(#a)'>
      <Path
        fill='#fff'
        d='m14.37 10.874 5.46-6.207h-1.295l-4.74 5.39-3.787-5.39H5.641l5.726 8.15-5.726 6.509h1.294l5.006-5.692 3.999 5.692h4.367l-5.938-8.452Zm-1.773 2.014-.58-.811L7.4 5.619h1.987l3.725 5.212.58.811 4.843 6.774h-1.987l-3.952-5.527Z'
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M4.975 4h16v16h-16z' />
      </ClipPath>
    </Defs>
  </Svg>
);
