import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './EventInfoDescriptionModal.styles';
import { useModal } from '../../ModalProvider';
import { Carbonicons, StyledText, StyledButton } from '@gf/cross-platform-lib/components';
import { ScrollView } from 'react-native';
const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const Title = styled.Text`
  ${styles.title};
`;

const Description = styled.Text`
  ${styles.description};
`;

const IconWrapper = styled.TouchableOpacity`
  ${styles.iconWrapper};
`;
const ButtonWrapper = styled.View`
  ${styles.buttonWrapper};
`;
export const EventInfoDescriptionModal = () => {
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const {
    eventTitle,
    eventDescription,
    onConfirm = () => {
      hideModal();
    }
  } = modalProps || {};

  return (
    <ModalWrapper>
      <IconWrapper onPress={onConfirm}>
        <Carbonicons name='close' color='black' size={18}></Carbonicons>
      </IconWrapper>
      <Title numberOfLines={2}>{eventTitle} description</Title>
      <ScrollView style={{ maxHeight: '56%' }}>
        <Description>{eventDescription}</Description>
      </ScrollView>
      <ButtonWrapper>
        <StyledButton height='48px' onPress={onConfirm} accessibilityLabel='Got it'>
          <StyledText color='white' textAlign='center'>
            Got it
          </StyledText>
        </StyledButton>
      </ButtonWrapper>
    </ModalWrapper>
  );
};
