import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  spacer: css`
    margin-left: 68px; // exitButtons width + margin, this centers the school logo
  `,
  headerContainer: css<{ headerHeight: number }>`
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    height: ${({ headerHeight }) => `${headerHeight}px`};
    width: 100%;
  `,
  logoContainer: css<{ schoolColor: string }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ schoolColor = '#000000' }) => `#${schoolColor}`};
    height: 64px;
    width: 64px;
    border-radius: 32px;
  `,
  exitButton: css<{ isLight: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f6f6fa;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    margin-right: 28px;
    box-shadow: ${({ isLight }) => (isLight ? '0px 4px 5px rgba(0, 0, 0, 0.12)' : 'none')};
  `
};
