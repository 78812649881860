export type DiscountType = 'amount' | 'percent';
export const DISCOUNT_TYPES = {
  PERCENT: 'percent',
  AMOUNT: 'amount'
};
export interface TicketPromotion {
  accessLevel: string;
  code: string;
  deleted: boolean;
  discountType: DiscountType;
  endDateTime: Date;
  id: number;
  label: number;
  limit: number;
  name: string;
  productAssociations: [
    {
      productId: number;
      productSpecificLimit: number;
    }
  ];
  required: boolean;
  startDateTime: Date;
  value: number;
}
