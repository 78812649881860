// @ts-nocheck
import * as React from 'react';
import { SvgExtProps } from '../types';
import Svg, { Path } from 'react-native-svg';

export const Mobile = (props: SvgExtProps) => (
  <Svg
    id='gf_icon_mobile'
    aria-label='Mobile icon'
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    {...props}
  >
    <Path d='M22 4H10a2.002 2.002 0 00-2 2v22a2.002 2.002 0 002 2h12a2.003 2.003 0 002-2V6a2.002 2.002 0 00-2-2zm0 2v2H10V6zM10 28V10h12v18z' />
    <Path id='Mobile_Transparent_Rectangle_' data-name='&lt;Transparent Rectangle&gt;' d='M0 0H32V32H0z' fill='none' />
  </Svg>
);
