import { PurchasedTicket } from '@gf/cross-platform-lib/interfaces';
import { TheStore } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import isEqual from 'lodash/isEqual';
import { Fan } from './Fan';

const getMobilePassKey = (email: string) => `mobilePassKey-${email}`;
export class MobilePass {
  private static instance: MobilePass;

  acceptPasses: PurchasedTicket[];

  public static getInstance(): MobilePass {
    if (!MobilePass.instance) {
      MobilePass.instance = new MobilePass();
    }
    return MobilePass.instance;
  }

  private constructor() {
    this.acceptPasses = [];
  }

  get acceptMobilePasses() {
    return this.acceptPasses;
  }

  async addAcceptMobilePass(data: PurchasedTicket) {
    this.acceptPasses.push(data);
    const store = JSON.parse((await TheStore.getItem(getMobilePassKey(Fan.getInstance().email))) || '[]');
    if (store && isEqual(store, this)) {
      return;
    }
    await TheStore.setItem(getMobilePassKey(Fan.getInstance().email), JSON.stringify(this));
  }

  async init() {
    return TheStore?.getItem(getMobilePassKey(Fan.getInstance().email)).then((data: string) => {
      if (data !== null) {
        const store = JSON.parse(data) as {
          acceptPasses: PurchasedTicket[];
        };
        this.acceptPasses = store.acceptPasses;
      } else {
        this.acceptPasses = [];
      }
      return this;
    });
  }

  async emptyPasses(): Promise<void> {
    this.acceptPasses = [];
    await TheStore.removeItem(getMobilePassKey(Fan.getInstance().email));
  }
}
