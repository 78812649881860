import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalWrapper: css`
    border-radius: 4px;
    margin: auto;
    padding: 16px;
    width: 320px;
    background: #f4f4f4;
    overflow-y: auto;
    max-height: 60%;
  `,
  mobileWrapper: css`
    background: #f4f4f4;
    width: 270px;
    max-height: 60%;
    border-radius: 14px;
    margin: auto;
    padding-top: 19px;
    overflow-y: auto;
  `,
  mobileTitle: css`
    color: #000000;
    padding-horizontal: 16px;
    font-family: SFPro-SemiBold;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    text-align: center;
    margin-bottom: 2px;
    letter-spacing: 0.64px;
  `,
  title: css`
    width: 288px;
    color: rgb(50, 50, 50);
    font-size: 20px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
    margin-bottom: 4px;
  `,
  description: css`
    width: 288px;
    color: rgb(22, 22, 22);
    font-size: 14px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    max-height: 100%;
  `,
  mobileDescription: css`
    color: #000000;
    font-family: SFPro-Regular;
    font-size: 13px;
    font-weight: normal;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.64px;
    padding-horizontal: 16px;
  `,
  closedIconWrapper: css`
    margin-left: 269px;
    margin-bottom: 24px;
  `,
  okButtonWrapper: css`
    margin-top: 16px;
    height: 44px;
    border-top-width: 0.5px;
    border-top-color: rgba(60, 60, 67, 0.36);
    justify-content: center;
  `,
  overlay: css`
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  `,
  buttonText: css`
    color: #007aff;
    font-family: SFPro-SemiBold;
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.64px;
    text-align: center;
  `
};
