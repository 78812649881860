/**
 * Wrapper for carbon icons
 * Lib: https://carbondesignsystem.com/guidelines/icons/library/
 * please add more icon from link above that is still not existed.
 */
import React from 'react';
import { ColorValue } from 'react-native';

import {
  ArrowRight,
  CalendarHeatMap,
  CheckmarkFilled,
  CheckmarkOutline,
  CheckmarkOutlineWarning,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  Close,
  CloseFilled,
  DirectionBearRightFilled,
  EventSchedule,
  Export,
  Facebook,
  Favorite,
  FavoriteFilled,
  Flow,
  Information,
  InvolvedSchool,
  Location,
  LocationFilled,
  NoTicket,
  Refresh,
  Share,
  Star,
  StarFilled,
  TextLinkAnalysis,
  Ticket,
  Time,
  TrashCan,
  TurnRight,
  Twitter,
  LogoTwitter,
  LogoTwitterWithoutBorder,
  User,
  View,
  ViewFilled,
  ViewOff,
  Warning,
  WarningFilled,
  WarningAltFilled,
  StopValidation,
  CopyLink,
  Email,
  NewTab,
  Send,
  SendAlt,
  SendFilled,
  SendAltFilled,
  DesktopWarning,
  InformationFilled,
  Checkmark,
  Accessibility,
  Mobile,
  Google,
  Apple,
  Outlook,
  Print,
  Edit,
  LinkPayment,
  CashApp,
  LetsChat,
  Launch,
  Next,
  Previous
} from './collections';
import { PlayoffIcon } from '../PlayoffIcon';
import { ThemeIcon } from '../ThemeIcon';
import { FeaturedStarFilled } from './collections/FeaturedStarFilled';
import { FeaturedStar } from './collections/FeaturedStar';

const IconMap = {
  view: View,
  'view-off': ViewOff,
  'view-filled': ViewFilled,
  'warning-filled': WarningFilled,
  'warning-alt-filled': WarningAltFilled,
  warning: Warning,
  favorite: Favorite,
  'favorite-filled': FavoriteFilled,
  'arrow-right': ArrowRight,
  'chevron-down': ChevronDown,
  'chevron-up': ChevronUp,
  location: Location,
  locationFilled: LocationFilled,
  time: Time,
  flow: Flow,
  star: Star,
  starFilled: StarFilled,
  featuredStarFilled: FeaturedStarFilled,
  featuredStar: FeaturedStar,
  textLinkAnalysis: TextLinkAnalysis,
  involvedSchool: InvolvedSchool,
  refresh: Refresh,
  facebook: Facebook,
  twitter: Twitter,
  logoTwitter: LogoTwitter,
  LogoTwitterWithoutBorder: LogoTwitterWithoutBorder,
  trashCan: TrashCan,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  checkmarkFilled: CheckmarkFilled,
  checkmarkOutline: CheckmarkOutline,
  checkmarkOutlineWarning: CheckmarkOutlineWarning,
  close: Close,
  closeFilled: CloseFilled,
  information: Information,
  playoffIcon: PlayoffIcon,
  themeIcon: ThemeIcon,
  directionBearRightFilled: DirectionBearRightFilled,
  eventSchedule: EventSchedule,
  calendarHeatMap: CalendarHeatMap,
  export: Export,
  share: Share,
  user: User,
  ticket: Ticket,
  turnRight: TurnRight,
  noTicket: NoTicket,
  stopValidation: StopValidation,
  copyLink: CopyLink,
  email: Email,
  newTab: NewTab,
  send: Send,
  sendAlt: SendAlt,
  sendFilled: SendFilled,
  sendAltFilled: SendAltFilled,
  desktopWarning: DesktopWarning,
  informationFilled: InformationFilled,
  checkmark: Checkmark,
  accessibility: Accessibility,
  mobile: Mobile,
  google: Google,
  apple: Apple,
  outlook: Outlook,
  print: Print,
  edit: Edit,
  linkPayment: LinkPayment,
  cashApp: CashApp,
  letsChat: LetsChat,
  launch: Launch,
  next: Next,
  previous: Previous
};

export type IconName = keyof typeof IconMap;

export type IconProps = {
  name: IconName;
  size?: number;
  color?: ColorValue;
  figurecolor?: ColorValue;
  testId?: string;
};

/**
 * @param { name, size, color } name - name of icon, size - size in px, color of icon type is string, format as "#HEX" or "rgba(,,)".
 * @returns Icon
 */

export const Carbonicons = ({ name, size, color, testId, figurecolor }: IconProps) => {
  const AppIcon = IconMap[name];

  return <AppIcon width={size || 14} height={size || 14} fill={color} data-testid={testId} figurecolor={figurecolor} />;
};
