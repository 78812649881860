//import { CSSObject } from 'styled-components';
import { buttonBaseStyles } from './StyledButton.styles.base';
import { buttonSizes } from './StyledButton.styles.base';

export { buttonSizes };

export const buttonStyles = {
  base: {
    ...buttonBaseStyles.base,
    border: 'none',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(91, 91, 91)'
    }
  },
  primary: {
    ...buttonBaseStyles.primary,
    color: 'rgb(255, 255, 255)',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(91, 91, 91)'
    },
    ':active': {
      backgroundColor: 'rgb(40, 40, 40)'
    },
    ':disabled': {
      backgroundColor: 'rgb(198, 198, 198)',
      color: 'rgba(22, 22, 22, 0.25)',
      cursor: 'not-allowed'
    }
  },
  secondary: {
    ...buttonBaseStyles.secondary,
    color: 'rgb(255, 255, 255)',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(91, 91, 91)'
    },
    ':active': {
      backgroundColor: 'rgb(40, 40, 40)'
    },
    ':disabled': {
      backgroundColor: 'rgb(198, 198, 198)',
      color: 'rgba(22, 22, 22, 0.25)',
      cursor: 'not-allowed'
    }
  },
  tertiary: {
    ...buttonBaseStyles.tertiary,
    border: 'solid',
    color: 'rgb(50, 50, 50)',
    ':hover': {
      cursor: 'pointer',
      color: 'rgb(255, 255, 255)',
      backgroundColor: 'rgb(91, 91, 91)',
      borderColor: 'transparent'
    },
    ':active': {
      backgroundColor: 'rgb(40, 40, 40)'
    },
    ':disabled': {
      backgroundColor: 'rgb(255, 255, 255)',
      color: 'rgba(22, 22, 22, 0.25)',
      borderColor: 'rgba(22, 22, 22, 0.25)',
      cursor: 'not-allowed'
    }
  },
  ghost: {
    ...buttonBaseStyles.ghost,
    color: 'rgb(50, 50, 50)',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(232, 232, 232)'
    },
    ':active': {
      backgroundColor: 'rgb(198, 198, 198)'
    },
    ':disabled': {
      backgroundColor: 'transparent',
      color: 'rgba(22, 22, 22, 0.25)',
      borderColor: 'transparent',
      cursor: 'not-allowed'
    }
  },
  'ghost--border': {
    ...buttonBaseStyles['ghost--border'],
    color: 'rgb(50, 50, 50)',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(232, 232, 232)'
    },
    ':active': {
      backgroundColor: 'rgb(198, 198, 198)'
    },
    ':disabled': {
      backgroundColor: 'transparent',
      color: 'rgba(22, 22, 22, 0.25)',
      borderColor: 'rgba(22, 22, 22, 0.25)',
      cursor: 'not-allowed'
    }
  },
  danger: {
    ...buttonBaseStyles.danger,
    color: 'rgb(255, 255, 255)',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(186, 27, 35)'
    },
    ':active': {
      backgroundColor: 'rgb(117, 14, 19)'
    },
    ':disabled': {
      backgroundColor: 'rgb(198, 198, 198)',
      color: 'rgba(22, 22, 22, 0.25)',
      cursor: 'not-allowed'
    }
  },
  'danger--tertiary': {
    ...buttonBaseStyles['danger--tertiary'],
    color: 'rgb(218, 30, 40)',
    ':hover': {
      cursor: 'pointer',
      color: 'rgb(255, 255, 255)',
      backgroundColor: 'rgb(186, 27, 35)',
      borderColor: 'transparent'
    },
    ':active': {
      backgroundColor: 'rgb(117, 14, 19)'
    },
    ':disabled': {
      backgroundColor: 'rgb(255, 255, 255)',
      color: 'rgba(22, 22, 22, 0.25)',
      borderColor: 'rgba(22, 22, 22, 0.25)',
      cursor: 'not-allowed'
    }
  },
  'danger--ghost': {
    ...buttonBaseStyles['danger--ghost'],
    color: 'rgb(218, 30, 40)',
    ':hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(186, 27, 35)',
      color: 'rgb(255, 255, 255)'
    },
    ':active': {
      backgroundColor: 'rgb(117, 14, 19)'
    },
    ':disabled': {
      backgroundColor: 'transparent',
      color: 'rgba(22, 22, 22, 0.25)',
      borderColor: 'transparent',
      cursor: 'not-allowed'
    }
  }
};
