// @flow
import { useEffect, useState } from 'react';

import { getLevelGenderTitle, isEmpty, mapToEventSeason } from '@gf/cross-platform-lib/utils';
import { Season, Event, EventType } from '@gf/cross-platform-lib/interfaces';
import { usePluralTitleText } from './usePluralTitleText';
import { getDateRange } from './useDate';
import { useEventSchedule } from './useEventSchedule';

export type EventInfoResult = {
  eventId: string;
  eventName: string;
  eventType: EventType;
  eventTypeDescription: string;
  alert?: string;
  ticketsRemaining: number;
  totalSeasonEventsText: string;
  ticketsRemainingText: string;
  isSoldOut: boolean;
  eventSchedule: string;
  ticketLimitPerOrder?: number;
  isSameDay: boolean;
};

export const useEventInfo = (event: Event, season?: Season): EventInfoResult => {
  const [ticketsRemaining, setTicketsRemaining] = useState(10e7);
  const [totalSeasonEvents, setTotalSeasonEvents] = useState(0);
  const [isSoldOut, setIsSoldOut] = useState(false);

  const { pluralTitleText: ticketsRemainingText } = usePluralTitleText(ticketsRemaining, 'Ticket');
  const { pluralTitleText: totalSeasonEventsText } = usePluralTitleText(totalSeasonEvents, 'Season Event Scheduled');
  const eventSeason = mapToEventSeason(event, season);
  const { id: eventId, name: eventName = '', type: eventType } = eventSeason || {};
  const { isSameDay } = getDateRange(eventSeason.startDateTime, eventSeason.endDateTime, eventSeason.timeZone);
  // optionalTypeDescription -> Levels -> Genders -> Activity
  const genders: string[] = [];
  if (event.genders && !isEmpty(event.genders)) {
    event.genders.forEach(gender => genders.push(gender));
  } else if (!isEmpty(event.levels)) {
    event.levels
      ?.map(level => level.genders)
      .forEach(levelGenders =>
        Array.isArray(levelGenders)
          ? levelGenders.forEach((gender: string) => genders.push(gender))
          : genders.push(levelGenders)
      );
  } else if (event.activity?.gender) {
    genders.push(event.activity.gender);
  }
  const customTypeDescription = getLevelGenderTitle(
    eventType,
    event.levels as { levelName: string; genders: string[] }[],
    event.genders,
    eventName
  );
  const conditionalHyphen = isEmpty(eventType) ? '' : ' - ';
  const eventTypeDescription = `${customTypeDescription ? conditionalHyphen + customTypeDescription : ''}`;

  useEffect(() => {
    const tickets: number = !isEmpty(event.salesInfo) ? event.salesInfo!.eventTotalRemainingQuantity : ticketsRemaining;
    tickets !== null && setTicketsRemaining(tickets);
  }, [event.salesInfo]);

  useEffect(() => {
    const currentTotalSeasonEvents = season ? season.eventIds.length : 0;
    season && setTotalSeasonEvents(currentTotalSeasonEvents);
  }, [event.salesInfo]);

  useEffect(() => {
    const soldOut = ticketsRemaining <= 0;
    setIsSoldOut(soldOut);
  }, [ticketsRemaining]);

  let eventSchedule: string = useEventSchedule(event).eventSchedule;

  return {
    eventId,
    eventName,
    eventType,
    eventTypeDescription,
    ticketsRemaining,
    totalSeasonEventsText,
    ticketsRemainingText,
    isSoldOut,
    eventSchedule,
    ticketLimitPerOrder: event.ticketLimitPerOrder,
    isSameDay
  };
};
