// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const InformationFilled = (props: SvgProps) => (
  <Svg
    id='gf_icon_information_filled'
    aria-label='Information icon'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    {...props}
  >
    <Path
      d='M16 8a1.5 1.5 0 1 1-1.5 1.5A1.5 1.5 0 0 1 16 8zm4 13.875h-2.875v-8H13v2.25h1.875v5.75H12v2.25h8z'
      fill='none'
    />
    <Path d='M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2zm0 6a1.5 1.5 0 1 1-1.5 1.5A1.5 1.5 0 0 1 16 8zm4 16.125h-8v-2.25h2.875v-5.75H13v-2.25h4.125v8H20z' />
    <Path d='M0 0h32v32H0z' fill='none' />
  </Svg>
);
