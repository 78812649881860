import { useEffect, useState } from 'react';

import Pluralize from 'pluralize';

const usePluralTitleText = (total: number, text: string) => {
  const [pluralTitleText, setPluralTitleText] = useState<string>('');

  useEffect(() => {
    const currentTitleText = `${total} ${Pluralize(text, total)}`;
    total > 0 && setPluralTitleText(currentTitleText);
  });

  return { pluralTitleText, setPluralTitleText };
};

export { usePluralTitleText };
