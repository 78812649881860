import { Carbonicons } from '../Icons';
import styled from '@gf/cross-platform-lib/styled-components';
import React from 'react';
import { styles } from './DeclineIcon.styles';
import { GFImage } from '../Image';
import { activityIcons } from '@gf/cross-platform-lib/constants/';

const Wrapper = styled.View`
  ${styles.wrapper}
`;
const WarningWrapper = styled.View`
  ${styles.warningWrapper}
`;

export function DeclineIcon() {
  return (
    <Wrapper>
      <GFImage width={88} height={62} name={activityIcons.digitalseasonpass} />
      <WarningWrapper>
        <Carbonicons name='desktopWarning' size={40} />
      </WarningWrapper>
    </Wrapper>
  );
}
