export const archery = 'activity-icons/archery.png';
export const academics = 'activity-icons/academics.png';
export const band = 'activity-icons/band.png';
export const banquet = 'activity-icons/banquet.png';
export const badminton = 'activity-icons/badminton.png';
export const baseball = 'activity-icons/baseball.png';
export const basketball = 'activity-icons/basketball.png';
export const bowling = 'activity-icons/bowling.png';
export const camps = 'activity-icons/camps.png';
export const chess = 'activity-icons/chess.png';
export const chorus = 'activity-icons/chorus.png';
export const club = 'activity-icons/school-event.png';
export const eventnonschool = 'activity-icons/school-event.png';
export const eventschool = 'activity-icons/school-event.png';

export const trackandfield = 'activity-icons/cross-country.png';
export const crosscountry = 'activity-icons/cross-country.png';

export const danceteam = 'activity-icons/cheerleading.png';
export const cheer = 'activity-icons/cheerleading.png';
export const cheerleading = 'activity-icons/cheerleading.png';

export const dance = 'activity-icons/school-dance.png';
export const prom = 'activity-icons/school-dance.png';
export const schooldance = 'activity-icons/school-dance.png';

export const drillteam = 'activity-icons/drill-team.png';
export const esports = 'activity-icons/esports.png';
export const digitalseasonpass = 'activity-icons/digital-season-pass.png';
export const fencing = 'activity-icons/fencing.png';
export const fieldhockey = 'activity-icons/field-hockey.png';
export const fieldday = 'activity-icons/field-day.png';
export const fieldtrip = 'activity-icons/field-trip.png';
export const powderpuff = 'activity-icons/flag-football.png';
export const flagfootball = 'activity-icons/flag-football.png';

export const fundraiser = 'activity-icons/fundraiser.png';
export const football = 'activity-icons/football.png';
export const golf = 'activity-icons/golf.png';
export const gymnastics = 'activity-icons/gymnastics.png';
export const graduation = 'activity-icons/graduation.png';
export const hockey = 'activity-icons/hockey.png';
export const martialarts = 'activity-icons/martial-arts.png';
export const merchandise = 'activity-icons/merchandise.png';
export const lacrosse = 'activity-icons/lacrosse.png';
export const orchestra = 'activity-icons/orchestra.png';
export const parking = 'activity-icons/parking.png';
export const passcard = 'activity-icons/pass-card.png';
export const performingarts = 'activity-icons/performing-arts.png';
export const physicals = 'activity-icons/physicals.png';
export const playoffs = 'activity-icons/playoffs.png';
export const race5k = 'activity-icons/race-5k.png';
export const registration = 'activity-icons/registration.png';
export const rugby = 'activity-icons/rugby.png';
export const sheetticket = 'activity-icons/ticket-sheets.png';
export const soccer = 'activity-icons/soccer.png';
export const softball = 'activity-icons/softball.png';
export const swimminganddiving = 'activity-icons/swimming.png';
export const sponsorship = 'activity-icons/sponsorship.png';
export const summerschool = 'activity-icons/summer-school.png';
export const tailgate = 'activity-icons/tailgate.png';
export const tennis = 'activity-icons/tennis.png';
export const test = 'activity-icons/test.png';
export const unlabeled = 'activity-icons/unlabeled.png';
export const volleyball = 'activity-icons/volleyball.png';
export const waterpolo = 'activity-icons/water-polo.png';
export const weightlifting = 'activity-icons/weightlifting.png';
export const wrestling = 'activity-icons/wrestling.png';
export const yearbook = 'activity-icons/yearbook.png';

export const activityIcons = {
  archery,
  academics,
  band,
  banquet,
  badminton,
  baseball,
  basketball,
  bowling,
  camps,
  chess,
  chorus,
  club,
  eventnonschool,
  eventschool,
  trackandfield,
  crosscountry,
  danceteam,
  cheer,
  cheerleading,
  dance,
  prom,
  schooldance,
  drillteam,
  esports,
  digitalseasonpass,
  fencing,
  fieldhockey,
  fieldday,
  fieldtrip,
  powderpuff,
  flagfootball,
  fundraiser,
  football,
  golf,
  gymnastics,
  graduation,
  hockey,
  martialarts,
  merchandise,
  lacrosse,
  orchestra,
  parking,
  passcard,
  performingarts,
  physicals,
  race5k,
  registration,
  rugby,
  sheetticket,
  soccer,
  softball,
  swimminganddiving,
  sponsorship,
  summerschool,
  tailgate,
  tennis,
  test,
  unlabeled,
  volleyball,
  waterpolo,
  weightlifting,
  wrestling,
  yearbook,
  playoffs
};
