import React, { useState } from 'react';
import { Linking, Clipboard, Share, Dimensions } from 'react-native';
import { Carbonicons, IconName, StyledText } from '@gf/cross-platform-lib/components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';

import { Modal } from '../Modal';
import { useModal } from '../../ModalProvider';
import { styles } from './TicketTransferModal.styles';
import { getModalTitle, getMessage, openEmail, sendMessage } from './utils';
import styled from '@gf/cross-platform-lib/styled-components';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { getPosition, insertString, testProperties, transferTicketDialog } from '@gf/cross-platform-lib/utils';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

const ModalContent = styled.ScrollView`
  ${styles.modalContent}
`;

const SelectionWrapper = styled.TouchableOpacity<{ isActive: boolean }>`
  ${styles.selectionWrapper}
`;

const Selection = styled.View`
  ${styles.selection}
`;

interface ITransferSelection {
  title: string;
  name: string;
  icon: IconName | React.ReactNode;
  testProps: string;
  action?: () => void;
}

export const TicketTransferModal = () => {
  const { modalState, inProgress } = useModal();
  const { modalProps } = modalState;
  const {
    accessTokens = [],
    transferUrl,
    openLinkUrl,
    productType,
    eventName,
    eventDate,
    onConfirm,
    onHideModal
  } = modalProps || {};
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const { isMobile, isNativeApp, isWebApp } = useMediaQuery();
  const userSelectedOption = (name: string, action?: () => void) => {
    setSelectedOption(name);
    if (typeof action === 'function') action();
    !isOptionSelected && setIsOptionSelected(true);
  };

  const onOpenLink = (url: string) => {
    const foundIndex = getPosition(url, '/', 3);
    const openBrowserURL = insertString(url, '/browser', foundIndex);
    Linking.openURL(openBrowserURL);
  };

  const onEmail = (url: string) => {
    openEmail({ eventName, eventDate, transferUrl: url });
  };

  const onMessage = (url: string) => {
    sendMessage({ eventName, eventDate, transferUrl: url });
  };

  const onCopyLink = (url: string) => {
    Clipboard.setString(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1200);
  };

  const onShare = async (url: string) => {
    try {
      await Share.share({
        message: getMessage(eventName, eventDate, url)
      });
    } catch (err: any) {
      recordError(err, {
        originatingFunction: 'TicketTransferModal-onShare',
        customMessage: 'Failed to share ticket transfer link.',
        errorGroup: NEW_RELIC_ERROR_GROUPS.UserActions,
        data: { eventName, eventDate, url }
      });
    }
  };

  const selections: ITransferSelection[] = [
    {
      name: 'copyLink',
      title: 'Copy transfer link',
      icon: copied ? <StyledText>Copied</StyledText> : 'copyLink',
      testProps: transferTicketDialog.copyLink,
      action: () => onCopyLink(transferUrl)
    },
    {
      name: 'email',
      title: 'Email',
      icon: 'email',
      testProps: transferTicketDialog.email,
      action: () => onEmail(transferUrl)
    },
    {
      name: 'openNewTab',
      title: 'Open in browser',
      icon: 'newTab',
      testProps: transferTicketDialog.openInBrowser,
      action: () => onOpenLink(openLinkUrl)
    }
  ];

  if (isMobile) {
    selections.push({
      name: 'message',
      title: 'Message',
      icon: 'sendAlt',
      testProps: transferTicketDialog.message,
      action: () => onMessage(transferUrl)
    });
  }

  if (isNativeApp || (isWebApp && typeof navigator?.share === 'function')) {
    // Some browser has supported for share function
    selections.push({
      name: 'moreOptions',
      title: 'More options',
      icon: 'export',
      testProps: transferTicketDialog.moreOptions,
      action: () => onShare(transferUrl)
    });
  }
  const renderTransferSelection = ({ name, title, icon, testProps, action }: ITransferSelection) => (
    <SelectionWrapper
      onPress={() => {
        userSelectedOption(name, action);
      }}
      isActive={name === selectedOption}
      key={name}
      {...testProperties(testProps)}
    >
      <Selection>
        <StyledText typeToken='bodyCompact02' color='textPrimary'>
          {title}
        </StyledText>
        {typeof icon === 'string' ? <Carbonicons name={icon as IconName} size={16} color='#323232' /> : icon}
      </Selection>
    </SelectionWrapper>
  );

  const maxHeight = Dimensions.get('window').height * 0.45;
  return (
    <Modal
      onCancel={onHideModal}
      onConfirm={onConfirm}
      title={getModalTitle(accessTokens.length, productType)}
      subTitle='Transferring tickets creates a link for others to use.'
      confirmLabel='Done'
      disableConfirm={!isOptionSelected}
      inProgress={inProgress}
      inProgressLabel={'Transferring ...'}
      wrapperTestID={transferTicketDialog.name}
    >
      <ModalContent maxHeight={maxHeight}>
        {selections.map(selection => renderTransferSelection(selection))}
      </ModalContent>
    </Modal>
  );
};
