import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

export const ThemeIcon = ({ width, height, ...props }: SvgProps) => (
  <Svg width={width} height={height} viewBox='0 0 22 24' {...props}>
    <G fill='none' fillRule='evenodd'>
      <Path d='M1 2h20v20H1z' />
      <Path
        d='M11 2v20M2 7l18 10M2 17 20 7'
        fill='#323232'
        fillRule='nonzero'
        stroke='#323232'
        strokeLinecap='round'
        strokeWidth={2.5}
      />
    </G>
  </Svg>
);
