// Adds the keyboard's height to the ScrollViews bottom margin when the keyboard is open on iOS

import { useKeyboard } from '@gf/cross-platform-lib/hooks';
import React from 'react';
import { Platform, ScrollView, View } from 'react-native';

interface Props {
  children: React.ReactNode;
  style?: object;
  overScrollMode?: 'auto' | 'never' | 'always' | undefined;
  bottomMarginOffset?: number;
  contentContainerStyle?: object | undefined;
}

export const KeyboardAvoidingScrollView = ({
  children,
  style = {},
  overScrollMode = 'auto',
  bottomMarginOffset = 0,
  contentContainerStyle
}: Props) => {
  const platform = Platform.OS;
  const keyboard = useKeyboard(bottomMarginOffset);
  const { keyboardHeight } = keyboard;

  switch (platform) {
    case 'web':
      return <View style={contentContainerStyle ? contentContainerStyle : style}>{children}</View>;
    case 'ios':
      return (
        <ScrollView
          style={{ ...style, marginBottom: keyboardHeight }}
          overScrollMode={overScrollMode}
          contentContainerStyle={contentContainerStyle}
        >
          {children}
        </ScrollView>
      );
    default:
      return (
        <ScrollView style={style} overScrollMode={overScrollMode} contentContainerStyle={contentContainerStyle}>
          {children}
        </ScrollView>
      );
  }
};
