export const isStringNotEmpty = (str: string) => {
  return typeof str !== 'undefined' && str?.length > 0;
};

export const getPosition = (str: string, subString: string, index: number) => {
  return str.split(subString, index).join(subString).length;
};

export const insertString = (str: string, value: string, index: number) => {
  return str.substring(0, index) + value + str.substring(index);
};

export const abbreviateWords = (input: string, toUpperCase: boolean = false): string => {
  const abbreviation = input?.split(' ').map(word => word[0]);

  return toUpperCase ? abbreviation?.join('').toUpperCase() : abbreviation?.join('');
};
