import { css } from 'styled-components';

export const baseStyles = {
  inputWrapper: css`
    flex-direction: row;
    align-items: center;
    border: 2px solid;
    border-color: #dbdbdb;
    border-radius: 4px;
  `,
  input: css<{ disabled?: boolean }>`
    color: #323232;
    background: #fff;
    font-family: Rubik-Regular;
    font-size: 14px;
    line-height: 18px;
    height: 18px;
    border-radius: 4px;
    width: 100%;
    height: 40px;
    padding: 0 16px;
  `,
  checkboxWrapper: css`
    flex-direction: column;
  `,
  label: css`
    color: #525252;
    font-family: Rubik-Regular;
    font-size: 12px;
    line-height: 16px;
  `
};
