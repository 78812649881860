// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path, Circle } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const ViewFilled = (props: SvgProps) => (
  <Svg
    id='gf_icon_view_filled'
    aria-label='View icon'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    {...props}
  >
    <Circle cx={16} cy={16} r={4} />
    <Path d='M30.94 15.66A16.69 16.69 0 0016 5 16.69 16.69 0 001.06 15.66a1 1 0 000 .68A16.69 16.69 0 0016 27a16.69 16.69 0 0014.94-10.66 1 1 0 000-.68zM16 22.5a6.5 6.5 0 116.5-6.5 6.51 6.51 0 01-6.5 6.5z' />
    <Path
      id='ViewFilled_Transparent_Rectangle_'
      data-name='&lt;Transparent Rectangle&gt;'
      d='M0 0H32V32H0z'
      fill='none'
    />
  </Svg>
);
