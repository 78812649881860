import { QueryParams } from '@gf/cross-platform-lib/interfaces';

const formatNestedQueryParams = (key: string, value: any): string => {
  if (Array.isArray(value)) {
    return value.map((item, index) => formatNestedQueryParams(`${key}[${index}]`, item)).join('&');
  } else if (typeof value === 'object' && value !== null) {
    return Object.keys(value)
      .map(subKey => formatNestedQueryParams(`${key}.${subKey}`, value[subKey]))
      .join('&');
  } else if (key === 'genders') {
    return `${encodeURIComponent(`levels[0]`)}.${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  } else {
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  }
};
//TODO: https://huddleinc.atlassian.net/browse/FAN-6856
export const parseQueryParamsToStringV2 = (queryParams?: QueryParams): string => {
  if (!queryParams) {
    return '';
  }

  const keys = Object.keys(queryParams);

  if (!keys.length) {
    return '';
  }
  return '?' + keys.map((key: string) => formatNestedQueryParams(key, queryParams[key])).join('&');
};
