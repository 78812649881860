import { Platform } from 'react-native';

export const Rubik = {
  light: 'Rubik-Light',
  normal: 'Rubik',
  medium: 'Rubik-Medium',
  semiBold: 'Rubik-SemiBold',
  bold: 'Rubik-Bold'
};

export const webFont = (font: string) => {
  return Platform.select({
    web: `${font}; -apple-system; sans-serif`,
    default: font
  });
};

export const fontFamilies = {
  RubikLight: webFont('Rubik-Light'),
  RubikRegular: webFont('Rubik-Regular'),
  RubikSemiBold: webFont('Rubik-SemiBold'),
  RubikBold: webFont('Rubik-Bold'),
  RubikBlack: webFont('Rubik-Black')
};

export type FontWeightType = {
  light: string;
  regular: string;
  normal: string;
  semibold: string;
  black: string;
};

export const fontWeights: FontWeightType = {
  light: '300',
  regular: '400',
  normal: '500',
  semibold: '600',
  black: '900'
};
