import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { Control, FieldValues, FieldErrorsImpl, UseFormSetValue } from 'react-hook-form';

import { AdditionalFormFields } from '@gf/cross-platform-lib/interfaces';
import { TicketsAdditionalInfo } from '@gf/cross-platform-lib/models';

import { getFormFieldLabel } from '@gf/cross-platform-lib/utils';

import { FormField } from '../FormField';
import { styles } from './TicketsAdditionalForm.styles';
import { View } from 'react-native';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';

const FormScrollContainer = styled.ScrollView`
  ${styles.formContainer}
`;

const FormContainer = styled.View`
  ${styles.formContainer}
`;

const Form = styled.View`
  ${styles.form}
`;
const FormFieldContainer = styled.View`
  ${styles.formFieldContainer}
`;

interface TicketsAdditionalFormProps {
  fields: AdditionalFormFields;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  errors: FieldErrorsImpl<{
    [x: string]: string;
  }>;
  disabled?: boolean;
  onSubmit: React.FormEventHandler<HTMLFormElement> | undefined;
}

export const TicketsAdditionalForm = ({
  fields,
  control,
  setValue,
  errors,
  disabled,
  onSubmit
}: TicketsAdditionalFormProps) => {
  const { isNativeApp } = useMediaQuery();
  const renderFields = () => {
    const orderedFields = TicketsAdditionalInfo.getOrderedFields(fields);
    return orderedFields.map((field, index) => (
      <FormFieldContainer key={field.id} isLastItem={index === orderedFields.length - 1}>
        <FormField
          label={getFormFieldLabel(field.name, field.required)}
          field={field}
          control={control}
          setValue={setValue}
          errorMessage={errors[field.id]?.message}
          disabled={disabled}
        />
      </FormFieldContainer>
    ));
  };
  return isNativeApp ? (
    <FormContainer style={{ margin: '0 -16px' }}>
      <Form id='additional-form' onSubmit={onSubmit}>
        {renderFields()}
      </Form>
    </FormContainer>
  ) : (
    <View style={{ minHeight: 80, maxHeight: 256 }}>
      <FormScrollContainer bounces>
        <Form id='additional-form' onSubmit={onSubmit}>
          {renderFields()}
        </Form>
      </FormScrollContainer>
    </View>
  );
};
