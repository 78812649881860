/**
 * TODO: import { useSyncExternalStore } from 'react'; for react version 18
 */
import { TaxUpdateTypes } from '@gf/cross-platform-lib/constants';
import { useSyncExternalStore } from './useSyncExternalStore';
import { SchoolCart } from '@gf/cross-platform-lib/models';

export default function createGlobalStateManager<T>(initialState: T) {
  let currentState = initialState;
  let isInitialized = false;
  const listeners = new Set<(state: T) => void>();
  const subscribe = (listener: (state: T) => void) => {
    listeners.add(listener);
    return () => listeners.delete(listener);
  };
  return {
    getState: () => currentState,
    setState: (newState: T) => {
      currentState = newState;
      listeners.forEach(listener => listener(currentState));
    },
    subscribe,
    serverInitialize: (initialState: T) => {
      if (!isInitialized) {
        currentState = initialState;
        isInitialized = true;
      }
    },
    useGlobalState: <SelectorOutput>(selector: (state: T) => SelectorOutput): SelectorOutput => {
      return useSyncExternalStore(subscribe, () => selector(currentState));
    }
  };
}

export interface CartGlobalState {
  cartSchools: SchoolCart[];
  isExpired?: boolean;
  isEmpty?: boolean;
  lastTicketAddedDate?: Date;
  totalTax: number;
  taxUpdateType?: TaxUpdateTypes;
}

export const InitCartState: CartGlobalState = {
  cartSchools: [],
  isExpired: false,
  isEmpty: true,
  totalTax: 0,
  taxUpdateType: TaxUpdateTypes.NONE
};

export interface GoFanGlobalState {
  cart: CartGlobalState;
  payment: any;
  eventDetails: any;
}

export const InitGlobalState = {
  cart: InitCartState,
  payment: null,
  eventDetails: null
};

export const {
  /**
   * @function getGlobalState
   * @return any
   * @description Getting latest global state without render
   * @example const state = getGlobalState()
   */
  getState: getGlobalState,
  /**
   * @function setGlobalState
   * @param updateState: any
   * @return void
   * @description Updating state with trigger re-render at the place is registering to get global state
   * @example setGlobalState({...(globalState || {}), count: globalState?.count + 1})
   */
  setState: setGlobalState,
  /**
   * @function useGlobalState
   * @param selector: Function
   * @return stateData
   * @description Registering for getting latest state with every re-render
   * @example cart = useGlobalState((state) => state.cart)
   */
  useGlobalState
} = createGlobalStateManager<GoFanGlobalState>(InitGlobalState);
