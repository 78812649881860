// @ts-nocheck
import * as React from 'react';
import { ColorValue } from 'react-native';
import Svg, { SvgProps, G, Path, Rect } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const TurnRight = (props: SvgProps & { figurecolor?: ColorValue }) => (
  <Svg width='13px' height='12px' viewBox='0 0 13 12' xmlns='http://www.w3.org/2000/svg' {...props}>
    <G
      transform='translate(-1226 -97) translate(1204 78) translate(16 10.5) translate(6.74 8.727)'
      stroke='none'
      strokeWidth={1}
      fill='none'
      fillRule='evenodd'
    >
      <Rect fill={props.color || '#323232'} x={0} y={0} width={11.27} height={11.27} rx={5.635} />
      <Path
        d='M4.735 8.651h-.829c-.864 0-1.296-.62-1.296-1.861s.432-1.862 1.296-1.862H8.61'
        stroke={props.figurecolor || '#FFF'}
      />
      <Path
        fill={props.figurecolor || '#FFF'}
        transform='rotate(45 7.532 3.865)'
        d='M5.59680501 3.36507829H9.46680501V4.36507829H5.59680501z'
      />
      <Path
        fill={props.figurecolor || '#FFF'}
        transform='rotate(-45 7.517 5.935)'
        d='M5.59680501 5.43507829H9.43680501V6.43507829H5.59680501z'
      />
    </G>
  </Svg>
);
