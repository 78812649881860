import React, { useMemo, useState } from 'react';
import { Dimensions } from 'react-native';
import { google, outlook, ics, CalendarEvent } from './utils';
import { Carbonicons, IconName, StyledText, Link } from '@gf/cross-platform-lib/components';
import styled from '@gf/cross-platform-lib/styled-components';
import { useModal } from '../../ModalProvider';
import { Modal } from '../Modal';
import { styles } from './CalendarAdditionModal.styles';
import isEmpty from 'lodash/isEmpty';

const ModalContent = styled.ScrollView`
  ${styles.modalContent}
`;

const SelectionWrapper = styled.TouchableOpacity<{ isActive: boolean }>`
  ${styles.selectionWrapper}
`;

const Selection = styled.View`
  ${styles.selection}
`;

interface ICalendarAdditionSelection {
  title: string;
  name: string;
  icon: IconName | React.ReactNode;
  url: string;
  action?: () => void;
}

export interface ICalendarInfo {
  name: string;
  startDateTime: string;
  endDateTime: string;
  details: string;
  venue: string;
}

export interface CalendarAdditionModalProps {
  calendarInfo: ICalendarInfo;
}

export const CalendarAdditionModal = () => {
  const { modalState, hideModal, inProgress } = useModal();
  const { modalProps } = modalState;
  const { calendarInfo } = (modalProps as CalendarAdditionModalProps) || {};
  const [selectedOption, setSelectedOption] = useState<string>('');
  const userSelectedOption = (name: string, action?: () => void) => {
    setSelectedOption(name);
    if (typeof action === 'function') action();
  };

  const selections: ICalendarAdditionSelection[] = useMemo(() => {
    const event: CalendarEvent = {
      title: calendarInfo.name,
      description: calendarInfo.details,
      start: calendarInfo.startDateTime,
      end: calendarInfo.endDateTime,
      location: calendarInfo.venue
    };

    return [
      {
        name: 'addToGoogleCalendar',
        title: 'Add to Google Calendar',
        icon: 'google',
        url: google(event)
      },
      {
        name: 'addToOutlookCalendar',
        title: 'Add to Outlook Calendar',
        icon: 'outlook',
        url: outlook(event)
      },
      {
        name: 'addToICalCalendar',
        title: 'Add to iCal Calendar',
        icon: 'apple',
        url: '',
        action: async () => {
          const element = document.createElement('a');
          element.href = ics(event);
          element.download = 'event.ics';
          document.body.appendChild(element);
          element.click();
        }
      }
    ];
  }, [calendarInfo]);

  const renderTransferSelection = ({ name, title, icon, url, action }: ICalendarAdditionSelection) => {
    const renderTitle = () => (
      <Selection>
        <StyledText typeToken='bodyCompact02' color='textPrimary'>
          {title}
        </StyledText>
        {typeof icon === 'string' ? <Carbonicons name={icon as IconName} size={16} color='#323232' /> : icon}
      </Selection>
    );

    return (
      <SelectionWrapper
        onPress={() => {
          userSelectedOption(name, action);
        }}
        isActive={name === selectedOption}
        key={name}
      >
        {isEmpty(url) ? (
          <>{renderTitle()}</>
        ) : (
          <Link href={url} shouldOpenExternal>
            {renderTitle()}
          </Link>
        )}
      </SelectionWrapper>
    );
  };

  const maxHeight = Dimensions.get('window').height * 0.45;

  return (
    <Modal
      onCancel={hideModal}
      onConfirm={hideModal}
      title='Add to your calendar'
      confirmLabel='Done'
      inProgress={inProgress}
      inProgressLabel={'Transferring ...'}
      hasClose={false}
    >
      <ModalContent
        maxHeight={maxHeight}
        contentContainerStyle={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16
        }}
      >
        {selections.map(selection => renderTransferSelection(selection))}
      </ModalContent>
    </Modal>
  );
};
