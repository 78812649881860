// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

export const Outlook = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1024' fill='currentColor' {...props} xmlSpace='preserve'>
    <G fillRule='evenodd' clipRule='evenodd'>
      <Path d='M644.877 764.422v-271.72c19.329 13.255 37.371 25.627 55.413 37.996 16.194 11.101 18.919 11.045 35.633-.064 92.48-61.468 184.979-122.915 277.479-184.355 2.803-1.861 5.698-3.585 9.774-6.141.322 4.208.777 7.36.777 10.514.035 118.218.089 236.438-.007 354.656-.034 39.297-19.884 59.078-58.972 59.097-102.802.047-205.604.018-308.406.018l-11.691-.001zM645.616 216.848h10.489c108.559 0 217.119-.009 325.679.01 19.41.004 34.427 11.871 40.529 30.528 2.68 8.197-.047 12.205-6.669 16.641-84.095 56.307-168.013 112.878-251.978 169.38-11.994 8.072-24.149 15.92-35.93 24.292-6.448 4.579-12.154 4.828-18.844.521-19.187-12.357-38.738-24.161-57.786-36.721-3.179-2.095-6.486-6.876-6.505-10.434-.337-63.505-.176-127.011-.094-190.516.005-.712.412-1.423 1.109-3.701zM596.717 1024C397.632 982.333 199.327 940.823.273 899.16v-13.094C.272 633.553.349 381.04 0 128.527c-.016-10.461 2.793-14.09 13.038-16.049C172.19 82.075 331.259 51.237 490.35 20.507 525.431 13.73 560.517 6.979 596.717 0v1024zm-158.68-512.975c-.402-41.613-6.094-80.035-26.656-114.852-16.6-28.107-39.024-49.44-71.278-58.626-62.755-17.872-122.839 11.521-152.41 75.036-18.947 40.696-22.994 83.874-19.337 128.078 3.169 38.303 14.261 73.688 38.958 103.96 49.093 60.169 138.435 60.569 188.185.776 32.939-39.589 41.886-86.711 42.538-134.372z' />
      <Path d='M232.92 511.935c-.085-29.653 3.91-58.359 20.699-83.795 15.494-23.472 39.016-33.061 64.938-26.578 20.146 5.038 32.316 19.265 40.427 37.222 13.847 30.658 16.247 63.273 12.056 95.936-2.325 18.11-7.45 36.464-14.808 53.175-9.507 21.596-27.783 33.954-52.195 34.493-24.572.542-41.658-12.202-53.632-32.584-14.129-24.051-17.552-50.585-17.485-77.869z' />
    </G>
  </Svg>
);
