import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  descriptionHTML: css`
    color: rgb(50, 50, 50);
    font-family: Rubik-Regular;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    word-wrap: break-word;
    overflow: scroll;
    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }
    height: 116px;
    > p {
      line-height: 1.5;
      margin-bottom: 1rem;
      font-size: 16px;
    }

    > h1 {
      line-height: 1.5;
      margin-bottom: 0.5rem;
    }
  `
};
