// @ts-nocheck
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

export const CheckmarkOutline = props => (
  <Svg xmlns='http://www.w3.org/2000/svg' fill={props.color || 'currentColor'} viewBox='0 0 32 32' {...props}>
    <Path d='m14 21.414-5-5.001L10.413 15 14 18.586 21.585 11 23 12.415l-9 8.999z' />
    <Path d='M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2Zm0 26a12 12 0 1 1 12-12 12 12 0 0 1-12 12Z' />
    <Path
      data-name='&lt;Transparent Rectangle&gt;'
      style={{
        fill: 'none'
      }}
      d='M0 0h32v32H0z'
    />
  </Svg>
);
