import { FULL_DAY_IN_WEEK_FORMAT, GF_PROD_BOXOFFICE, BOXOFFICE } from '@gf/cross-platform-lib/constants';
import { useDate, isSaleTimeTicket } from '@gf/cross-platform-lib/hooks/useDate';
import { useEventInfo } from '@gf/cross-platform-lib/hooks/useEventInfo';
import { useTicketType } from '@gf/cross-platform-lib/hooks/useTicketType';
import { EventSeason, ProductSalesMap, ProductSeating, ReservedSeat } from '@gf/cross-platform-lib/interfaces';
import { Event } from '@gf/cross-platform-lib/interfaces';
import { TicketTypeSelection } from '@gf/cross-platform-lib/models';
import {
  DATE_WITH_TIME_ZONE_FORMAT,
  doesExist,
  getUnixTime,
  isEmpty,
  isFutureDate,
  isPastDate
} from '@gf/cross-platform-lib/utils';
import isEqual from 'lodash/isEqual';
import { AccessCodeInformation } from '@gf/cross-platform-lib/interfaces';
import dayjs from 'dayjs';

export enum EventAvailabilityMessage {
  AVAILABLE = 'Tickets available.',
  FUTURE_SALE_DATE = 'Tickets will be available for purchase on',
  NO_TICKETS = 'There are no available tickets for this event at this time. Please check back later.',
  NOT_FOR_INDIVIDUAL_SALE = `We’re sorry, tickets for this event are not available for individual sale.`,
  PAST_SALE_DATE = 'GoFan tickets for this event are no longer available for purchase.',
  PAST_DATE = 'This event has passed and tickets are no longer available for purchase.',
  SOLD_OUT = `We're sorry, tickets for this event are sold out. Sold out`
}

export const isFutureDateValid = (date = '') => dayjs(date).isValid() && isFutureDate(date);

export const useEventAvailabilityMessage = (event: Event, schoolId?: string): string => {
  let message: string = EventAvailabilityMessage.AVAILABLE;

  const { isSoldOut } = useEventInfo(event);

  const schoolSalesMap = event.salesInfo?.schoolSalesMap;
  if (isSoldOut) {
    message = EventAvailabilityMessage.SOLD_OUT;
  } else if (schoolId && schoolSalesMap) {
    const schoolSalesTotal = !schoolSalesMap[schoolId] || schoolSalesMap[schoolId] <= 0 ? 0 : schoolSalesMap[schoolId];
    const schoolLimit = event.schoolsTicket?.find(item => item.schoolId === schoolId)?.schoolLimit;
    const schoolSalesRemaining: number = (schoolLimit || 0) - schoolSalesTotal;

    const schoolSoldOut = schoolLimit !== null && schoolLimit !== undefined && schoolSalesRemaining <= 0;
    schoolSoldOut && (message = EventAvailabilityMessage.SOLD_OUT);
  }

  const { nowIsPastDate: isPastSaleDate } = useDate(event.salesEndDateTime || '', event.timeZone);
  const { nowIsPastDate } = useDate(event.endDateTime, event.timeZone);
  if (event.salesEndDateTime && isPastSaleDate !== null && isPastSaleDate) {
    message = EventAvailabilityMessage.PAST_SALE_DATE;
  }

  if (event.endDateTime && nowIsPastDate) {
    message = EventAvailabilityMessage.PAST_DATE;
  }

  if (event.salesStartDateTime) {
    const isFutureSaleDate = isFutureDate(event?.salesStartDateTime);
    if (isFutureSaleDate) {
      const salesStartDate = getUnixTime(
        event.salesStartDateTime,
        event.timeZone ?? '',
        DATE_WITH_TIME_ZONE_FORMAT
      ).format(FULL_DAY_IN_WEEK_FORMAT);
      message = `${EventAvailabilityMessage.FUTURE_SALE_DATE} ${salesStartDate}.`;
    }
  }

  if (event.disabledForIndividualSale === true) message = EventAvailabilityMessage.NOT_FOR_INDIVIDUAL_SALE;

  if (isEmpty(event.ticketTypes)) message = EventAvailabilityMessage.NO_TICKETS;

  if (!isEmpty(event.ticketTypes)) {
    const hasValidEvent = event.ticketTypes.some(ticket =>
      isSaleTimeTicket(ticket.salesStartDateTime, ticket.salesEndDateTime, ticket.timezone)
    );
    if (hasValidEvent) {
      return message;
    }
    const hasFutureSale = event.ticketTypes.some(tk => isFutureDate(tk.salesStartDateTime));
    const hasPastSale = event.ticketTypes.some(tk => isPastDate(tk.salesEndDateTime));
    const isFutureSaleDate = hasFutureSale && !hasPastSale;
    const isPastSaleDate = !hasFutureSale && hasPastSale;
    const isBetweenSalesDate = hasFutureSale && hasPastSale;

    if (isFutureSaleDate || isBetweenSalesDate) {
      const earliestDay = event.ticketTypes.reduce((acc, event) => {
        if (!isFutureDateValid(event.salesStartDateTime)) {
          return acc;
        }
        if (!isFutureDateValid(acc.salesStartDateTime)) {
          return event;
        }
        return dayjs(event.salesStartDateTime).isAfter(dayjs(acc.salesStartDateTime)) ? acc : event;
      }).salesStartDateTime;

      const salesStartDate = getUnixTime(
        event.salesStartDateTime
          ? dayjs(earliestDay).isAfter(dayjs(event.salesStartDateTime))
            ? event.salesStartDateTime || ''
            : earliestDay
          : earliestDay,
        event.timeZone ?? '',
        DATE_WITH_TIME_ZONE_FORMAT
      ).format(FULL_DAY_IN_WEEK_FORMAT);
      message = `${EventAvailabilityMessage.FUTURE_SALE_DATE} ${salesStartDate}.`;
    }

    if (isPastSaleDate) {
      message = EventAvailabilityMessage.PAST_DATE;
    }
  }

  return message;
};

export const getEventMinimumTickets = (event: Event) => {
  return event.ticketLimitPerOrder && event.salesInfo?.eventTotalRemainingQuantity
    ? Math.min(event.ticketLimitPerOrder, event.salesInfo?.eventTotalRemainingQuantity)
    : event.ticketLimitPerOrder && !event.salesInfo?.eventTotalRemainingQuantity
    ? event.ticketLimitPerOrder
    : !event.ticketLimitPerOrder && event.salesInfo?.eventTotalRemainingQuantity
    ? event.salesInfo.eventTotalRemainingQuantity
    : undefined;
};

export const filterTickets = (
  tickets: ProductSeating[],
  productSalesMap: ProductSalesMap = {},
  schoolId?: string,
  uniqueProductId?: string
): { tickets: ProductSeating[]; accessCodeTickets: ProductSeating[] } => {
  return tickets.reduce(
    (acc, ticket) => {
      ticket.limit = Math.min(
        productSalesMap[ticket.id]?.remainingQuantity || 0,
        ticket.limit !== null ? ticket.limit! : 9999999
      );
      let included = true;
      if (ticket.schoolHuddleId && schoolId) {
        included = isEqual(ticket.schoolHuddleId, schoolId);
      } else if (ticket.id && uniqueProductId) {
        included = isEqual(ticket.id.toString(), uniqueProductId);
      }
      included = included && (ticket.isEnabled || ticket.encodedString !== '');
      if (ticket.distributionChannel) {
        included =
          included &&
          !isEqual(
            ticket.distributionChannel.replace(' ', '').toUpperCase(),
            GF_PROD_BOXOFFICE.replace(' ', '').toUpperCase()
          );
      }

      if (included) {
        if (ticket.isPromotionRequired || ticket.promotionRequired) {
          acc.accessCodeTickets.push(ticket);
        } else {
          acc.tickets.push(ticket);
        }
      }

      return acc;
    },
    { tickets: [] as ProductSeating[], accessCodeTickets: [] as ProductSeating[] }
  );
};

export const toTicketTypeSelections = (
  ticketsToQuantity: Map<ProductSeating, number>,
  prodIdsToAccessCodes: Map<number, AccessCodeInformation>,
  uniqueUrl?: string,
  ticketsByUpdateAt?: { [k: string]: number },
  upsellSelectedIndex?: number
) => {
  const tickets: TicketTypeSelection[] = [];
  ticketsToQuantity.forEach((quantity, ticketType) => {
    tickets.push({
      isReservedSeating: ticketType.isReservedSeating,
      quantity,
      canBuy: useTicketType(ticketType, quantity).canSell,
      ticketType,
      createAt:
        prodIdsToAccessCodes.has(parseInt(ticketType.id)) && prodIdsToAccessCodes.get(parseInt(ticketType.id))?.id
          ? prodIdsToAccessCodes.get(parseInt(ticketType.id))?.createAt
          : Date.now(),
      updateAt: ticketsByUpdateAt && ticketsByUpdateAt[ticketType.id] ? ticketsByUpdateAt[ticketType.id] : Date.now(),
      promos:
        prodIdsToAccessCodes.has(parseInt(ticketType.id)) && prodIdsToAccessCodes.get(parseInt(ticketType.id))?.id
          ? [prodIdsToAccessCodes.get(parseInt(ticketType.id))?.id!]
          : undefined,
      accessCode:
        prodIdsToAccessCodes.has(parseInt(ticketType.id)) && prodIdsToAccessCodes.get(parseInt(ticketType.id))?.id
          ? prodIdsToAccessCodes.get(parseInt(ticketType.id))?.code!
          : undefined,
      uniqueUrl,
      upsellSelectedIndex: upsellSelectedIndex
    });
  });
  return tickets;
};

export const getAvailableTickets = (
  ticketTypes: ProductSeating[],
  schoolId: string | undefined,
  encodedStrings: string[] | undefined = undefined
) =>
  ticketTypes.filter(ticketType => {
    let generateLinkValue = false;
    const shouldMatchEncodedStrings =
      doesExist(encodedStrings) &&
      encodedStrings!.length > 0 &&
      (!ticketType.isDistributionSchool || ticketType.generateLink);

    let doesMatchSomeEncodedStrings = true;
    if (shouldMatchEncodedStrings) {
      generateLinkValue = true;
      doesMatchSomeEncodedStrings =
        encodedStrings!.some(es => ticketType.encodedString === es) &&
        (ticketType.schoolHuddleId
          ? schoolId === ticketType.schoolHuddleId && (ticketType.generateLink ?? true)
          : true) &&
        ticketType.isEnabled;
    }
    if (!!schoolId && !!ticketType.schoolHuddleId && schoolId !== ticketType.schoolHuddleId) {
      return false;
    }

    return (
      (shouldMatchEncodedStrings ? doesMatchSomeEncodedStrings : ticketType.isEnabled) &&
      !isEqual(ticketType.distributionChannel.replace(' ', '').toUpperCase(), BOXOFFICE) &&
      ticketType.generateLink === generateLinkValue
    );
  });

// This function matches seats.io seats to ticket types based on differing conditions for web and native, multi and single-tier pricing.
export function ticketTypeMatchesSelectedSeat(ticketType: ProductSeating, selectedSeat: ReservedSeat) {
  const { category: selectedSeatCategory, pricing, category } = selectedSeat.selectedSeat;

  const renewalInfo = selectedSeat.renewalInfo;

  // handle matching based on renewalInfo
  if (renewalInfo && renewalInfo.seats) {
    const renewalSeat = renewalInfo.seats.find(
      renewalSeat => renewalSeat.seat.ticketId === ticketType.id && renewalSeat.seat.seatsIoLabel === selectedSeat.id
    );

    if (renewalSeat != null) {
      return true;
    }
  }

  if (ticketType.seatsIoCategory !== selectedSeatCategory.label) {
    return false;
  }

  // if there's a pricing property on the selected seat, it's coming from web
  if (pricing) {
    // if there's a ticketTypes property on pricing, it is multi-tier pricing, otherwise single-tier
    // Note: need to match both price and ticketType name in case two tiers have the same name, which is allowed by HQ.
    return pricing.ticketTypes
      ? selectedSeat.ticketType === ticketType.name && selectedSeat.price === ticketType.price
      : selectedSeat.ticketType === ticketType.seatsIoCategory && selectedSeat.price === ticketType.price;
  } else {
    // if there's no pricing property on the selected seat, it's coming from native
    // on native, the pricing property is nested within the category object, but from that point has the same structure as web.
    return category.pricing.ticketTypes
      ? selectedSeat.ticketType === ticketType.name && selectedSeat.price === ticketType.price
      : selectedSeat.ticketType === ticketType.seatsIoCategory && selectedSeat.price === ticketType.price;
  }
}

export const isSaleTimeEvent = (event: EventSeason) =>
  isSaleTimeTicket(event.salesStartDateTime || '', event.salesEndDateTime || '', event.timeZone) &&
  event.ticketTypes.some(tk => isSaleTimeTicket(tk.salesStartDateTime, tk.salesEndDateTime, tk.timezone));
