import { css } from '@gf/cross-platform-lib/styled-components';
import {
  RIGHT_DRAWER_Z_INDEX,
  DEFAULT_RIGHT_DRAWER_SIZE,
  APP_HEADER_SIZE,
  APP_FOOTER_SIZE,
  LEFT_DRAWER_Z_INDEX,
  BOTTOM_DRAWER_Z_INDEX,
  DEFAULT_LEFT_DRAWER_SIZE,
  DEFAULT_BOTTOM_DRAWER_SIZE
} from './drawerConstants';
import { Dimensions, Platform, StatusBar } from 'react-native';

const windowHeight = Dimensions.get('window').height;
const statusBarHeight = Platform.OS === 'android' ? StatusBar.currentHeight : Platform.OS === 'ios' ? 20 : 0;

export const styles = {
  appWrapper: css<{ lowerOpacity: boolean }>`
    display: flex;
    height: 100%;
  `,
  rightDrawerWrapper: css<{ isMobile: string; isNative: string }>`
    cursor: default;
    top: ${({ isMobile, isNative }) =>
      (isNative === 'true'
        ? APP_HEADER_SIZE + statusBarHeight!
        : isMobile === 'true'
        ? APP_HEADER_SIZE
        : APP_HEADER_SIZE) + 'px'};
    height: ${({ isNative, isMobile }) =>
      isNative === 'true'
        ? windowHeight - APP_HEADER_SIZE - APP_FOOTER_SIZE - statusBarHeight! + 'px'
        : isMobile === 'true'
        ? windowHeight - APP_HEADER_SIZE - APP_FOOTER_SIZE + 'px'
        : windowHeight - APP_HEADER_SIZE + 'px'};
    width: 100%;
    position: ${({ isNative }) => (isNative ? 'absolute' : 'fixed')};
    background-color: rgba(0, 0, 0, 0.5);
  `,
  rightDrawerWrapperFullScreen: css<{ isNative: string }>`
    cursor: default;
    height: 100%;
    width: 100%;
    position: ${({ isNative }) => (isNative ? 'absolute' : 'fixed')};
    background-color: rgba(0, 0, 0, 0.5);
  `,
  closeButtonContainer: css`
    align-items: center;
  `,
  doneBackgroundContainer: css`
    height: 32px;
    background-color: black;
    padding: 0 16px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  `,
  doneText: css`
    color: white;
    font-family: Roboto-Regular;
  `,
  rightDrawerContainer: css<{ drawerSize?: string; isMobile: string; isNative?: string; maxHeight: string }>`
    background-color: white;
    height: 100%;
    max-height: ${({ maxHeight }) => maxHeight};
    width: ${({ drawerSize }) => drawerSize + 'px'};
    max-width: ${({ drawerSize }) => (drawerSize !== undefined ? drawerSize : DEFAULT_RIGHT_DRAWER_SIZE)}px;
    position: ${({ isNative }) => (isNative ? 'absolute' : 'fixed')};
    z-index: ${RIGHT_DRAWER_Z_INDEX};
  `,
  rightDrawerContainerFullScreen: css<{ isNative?: string }>`
    background-color: white;
    height: 100%;
    width: 100%;
    position: ${({ isNative }) => (isNative ? 'absolute' : 'fixed')};
    z-index: ${RIGHT_DRAWER_Z_INDEX};
  `,
  leftDrawerWrapper: css<{ isMobile: string; isNative: string }>`
    cursor: default;
    top: 0px;
    height: 100%;
    width: 100%;
    position: ${({ isNative }) => (isNative ? 'absolute' : 'fixed')};
    background-color: rgba(0, 0, 0, 0.5);
  `,
  leftDrawerContainer: css<{ drawerSize?: string; isMobile: string; isNative?: string; maxHeight: string }>`
    background-color: white;
    height: 100%;
    width: ${({ drawerSize }) => drawerSize + 'px'};
    max-width: ${({ drawerSize }) => (drawerSize !== undefined ? drawerSize : DEFAULT_LEFT_DRAWER_SIZE)}px;
    position: ${({ isNative }) => (isNative ? 'absolute' : 'fixed')};
    z-index: ${LEFT_DRAWER_Z_INDEX};
    overflow: scroll;
  `,
  bottomDrawerWrapper: css<{ isMobile: string; isNative: string }>`
    cursor: default;
    bottom: 0px;
    height: 100%;
    width: 100%;
    position: ${({ isNative }) => (isNative === 'true' ? 'absolute' : 'fixed')};
    background-color: rgba(0, 0, 0, 0.5);
  `,
  bottomDrawerContainer: css<{ drawerSize?: string; isMobile: string; isNative?: string; maxHeight: string }>`
    background-color: transparent;
    height: ${({ drawerSize }) => (drawerSize !== undefined ? drawerSize : DEFAULT_BOTTOM_DRAWER_SIZE)}px;
    width: 100%;
    position: ${({ isNative }) => (isNative === 'true' ? 'absolute' : 'fixed')};
    z-index: ${BOTTOM_DRAWER_Z_INDEX};
    overflow: scroll;
    ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background: transparent;
    }
  `,
  propagationCatcher: css`
    height: 100%;
    cursor: default;
  `
};
