import React from 'react';
import { Dimensions } from 'react-native';

import styled from '@gf/cross-platform-lib/styled-components';
import { Modal } from '../Modal';

import { styles } from './ShareEventModal.styles';

import { SharingOption } from './SharingOption';
import { useEventSharing } from './useEventSharing';

const ModalContent = styled.ScrollView`
  ${styles.modalContent}
`;
const OptionWrapper = styled.View`
  ${styles.optionWrapper}
`;
const Divider = styled.View`
  ${styles.divider}
`;

export const ShareEventModal = () => {
  const { socialOptions, options, selectedOption, isOptionSelected, userSelectedOption, onHideModal, onConfirm } =
    useEventSharing();

  const renderSharingOnSocialMedia = () => (
    <OptionWrapper>
      {socialOptions.map(option => (
        <SharingOption
          isActive={option.name === selectedOption}
          key={option.name}
          option={option}
          onSelectOption={option => userSelectedOption(option.name, option.action)}
          centered
        />
      ))}
    </OptionWrapper>
  );

  const renderSharingOptions = () =>
    options.map(option => (
      <OptionWrapper key={option.name}>
        <SharingOption
          isActive={option.name === selectedOption}
          key={option.name}
          option={option}
          onSelectOption={option => userSelectedOption(option.name, option.action)}
        />
      </OptionWrapper>
    ));

  const maxHeight = Dimensions.get('window').height * 0.45;

  return (
    <Modal
      onCancel={onHideModal}
      onConfirm={onConfirm}
      title='Share this event'
      confirmLabel='Done'
      disableConfirm={!isOptionSelected}
    >
      <ModalContent maxHeight={maxHeight} contentContainerStyle={{ gap: 16 }}>
        {renderSharingOnSocialMedia()}
        <Divider />
        {renderSharingOptions()}
      </ModalContent>
    </Modal>
  );
};
