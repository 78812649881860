// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path, Polygon } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const Information = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' {...props} viewBox='1 1 30 30' fill={'black'}>
    <Polygon points='17 22 17 14 13 14 13 16 15 16 15 22 12 22 12 24 20 24 20 22 17 22' />
    <Path d='M16 8a1.5 1.5 0 1 0 1.5 1.5A1.5 1.5 0 0 0 16 8Z' />
    <Path d='M16 30A14 14 0 1 1 30 16 14 14 0 0 1 16 30ZM16 4A12 12 0 1 0 28 16 12 12 0 0 0 16 4Z' />
  </Svg>
);
