import { GetCurrentApplicationConfiguration } from './config';
import { getUserInfo } from './userInfo';

const config = GetCurrentApplicationConfiguration();
const { enabled } = config.newrelic;
const isNewRelicDisabled = !enabled;

type CustomAttributes = {
  [key: string]: any;
  errorGroup?: string;
};

type RecordErrorOptions = {
  stackTrace?: string;
  attributes?: CustomAttributes;
  errorGroup?: string;
  errorContext?: string;
  originatingFunction?: string;
  data?: Record<string, unknown>;
  customMessage?: string;
  expected?: boolean;
};

export const recordError = (error: Error | String, options?: RecordErrorOptions) => {
  const { attributes, errorGroup, errorContext, originatingFunction, customMessage, data, expected } = options || {};

  if (!isNewRelicDisabled) {
    try {
      if (typeof newrelic !== undefined) {
        let errorMessage: string;

        if (error instanceof Error) {
          errorMessage = `${customMessage} -- Got error message: ${error.message ? error.message : ' Unknown'}`;
          newrelic.setCustomAttribute('stackTrace', error.stack || '');
        } else if (typeof error === 'string') {
          errorMessage = `${customMessage} -- Got error message: ${error}`;
        } else {
          errorMessage = `${customMessage} -- Got error message: Unknown`;
        }

        newrelic.setCustomAttribute('originatingFunction', originatingFunction || 'Unknown');
        newrelic.setCustomAttribute('customMessage', customMessage || '');
        newrelic.setCustomAttribute('data', JSON.stringify(data || {}));
        newrelic.setCustomAttribute('userInfo', JSON.stringify(getUserInfo()));
        newrelic.setCustomAttribute('errorContext', errorContext || '');
        newrelic.setCustomAttribute('expected', String(expected || false));

        if (errorGroup) {
          newrelic.setCustomAttribute('errorGroup', errorGroup || 'Unknown Group');
        }

        for (const key in attributes) {
          if (attributes.hasOwnProperty(key)) {
            newrelic.setCustomAttribute(key, attributes[key]);
          }
        }

        newrelic.noticeError(errorMessage, attributes);
      }
    } catch (e) {
      // We don't want error reporting to crash the app
    }
  }
  console.error(error);
};

export const recordPageAction = (name: string, data: any) => {
  console.log({
    track: {
      name,
      data
    }
  });
  if (!isNewRelicDisabled) {
    try {
      if (typeof newrelic !== undefined && name) {
        newrelic.setCustomAttribute('pageActionLogData', JSON.stringify(data || {}));
        newrelic.setCustomAttribute('userInfo', JSON.stringify(getUserInfo()));
        newrelic.addPageAction(name);
      }
    } catch (e) {}
  }
};

export const setUserId = (userId: string | number) => {
  if (!isNewRelicDisabled) {
    try {
      if (typeof newrelic !== undefined) {
        // @ts-ignore
        newrelic.setUserId(String(userId));
      }
    } catch (e) {
      // We don't want new relic to crash the app
    }
  }
};

type Props = {
  children: JSX.Element;
};

// ErrorBoundary is needed to report errors to New Relic via the React Native agent. It is not required for web browser errors,
// Which New Relic will catch automatically. So this library will simply export noop functions to keep its api consistent with
// native.
export const ErrorBoundary: React.FC<Props> = ({ children }) => {
  return children;
};
