import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css<{ height: number; width: number }>`
    background: #f4f4f4;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: ${({ height = 0 }) => `${height}px`};
    margin: auto;
    padding-bottom: 16px;
    width: 320px;
    z-index: 3;
  `,
  overlay: css`
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  `,
  container: css`
    display: flex;
    flex-direction: row;
  `,
  button: css<{
    bgColor: string;
    removeBorder?: boolean;
    color: string;
    disabledTextColor: string;
    disabled: boolean;
  }>`
    border-radius: 4px;
    border: 1px solid #323232;
    border-width: ${({ removeBorder }) => (removeBorder ? 0 : '1px')};
    height: 48px;
    width: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ bgColor, disabled }) => (!disabled ? bgColor : '#c6c6c6')};
    color: ${({ color, disabledTextColor, disabled }) => (!disabled ? color : disabledTextColor)};
    cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'not-allowed')};
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 18px;
    text-align: center;
  `,
  titleSection: css`
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  title: css`
    color: rgb(50, 50, 50);
    font-family: Rubik-Regular;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
    flex: 1;
    padding: 0 16px;
    margin-right: 40px;
  `,
  subTitle: css`
    color: rgb(22, 22, 22);
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    margin-bottom: 2px;
    margin-top: 4px;
    padding: 0 16px;
    max-width: 224px;
  `,
  additionalForm: css`
    overflow: scroll;
    flex: 3;
    padding: 0 16px;
  `,
  additionalFormTitle: css`
    color: rgb(50, 50, 50);
    font-size: 12px;
    font-family: Rubik-SemiBold;
    font-weight: 600;
    letter-spacing: 0.32px;
    line-height: 16px;
  `,
  checkboxContainer: css`
    display: inline-block;
    vertical-align: middle;
  `,
  icon: css`
    fill: none;
    stroke: white;
    stroke-width: 2px;
  `,
  hiddenCheckbox: css`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  `,
  styledCheckbox: css<{ checked: boolean }>`
    display: inline-block;
    width: 16px;
    height: 16px;
    background: ${props => (props.checked ? 'black' : '#f4f4f4')};
    border-radius: 0px;
    border: 1px solid black;
    transition: all 150ms;
    font-style: normal;
    font-weight: 200;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
  `,
  fieldContainer: css`
    display: flex;
    justify-content: space-between;
    width: 245px;
    margin-top: 8px;
    flex-direction: row;
  `,
  fieldName: css`
    color: rgb(50, 50, 50);
    font-size: 12px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0.32px;
    line-height: 16px;
  `,
  fieldValue: css`
    width: 132px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    color: rgb(50, 50, 50);
    font-size: 12px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0.32px;
    line-height: 16px;
  `,
  buttonContainer: css`
    justify-content: space-between;
    padding: 0;
    margin-top: 32px;
    padding: 0 16px;
  `,
  closeIconWrapper: css`
    position: absolute;
    right: 12px;
    z-index: 3;
  `
};
