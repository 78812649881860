import React from 'react';
import { View, Text } from 'react-native';

import { styles } from './FundraiserProgressBar.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { StyledText } from '@gf/cross-platform-lib/components';
import { LightenDarkenColor, testProperties } from '@gf/cross-platform-lib/utils';
import Svg, { Stop, LinearGradient, Defs, Rect } from 'react-native-svg';
import { DEFAULT_FUNDRAISER_PROGRESS_BAR_COLOR } from '@gf/cross-platform-lib/constants';

const FundraiserProgressBarContainer = styled(View)`
  ${styles.fundraiserProgressBarContainer}
`;
const ProgressBar = styled(View)`
  ${styles.progressBar}
`;
const ProgressBarText = styled(Text)`
  ${styles.progressBarText};
`;
const Line1 = styled(View)`
  ${styles.line1};
`;
const Line2 = styled(View)`
  ${styles.line2};
`;
const Line3 = styled(View)`
  ${styles.line3};
`;
const TextWrapper = styled(View)`
  ${styles.textWrapper};
`;
const PercentTextWrapper = styled(View)`
  ${styles.percentTextWrapper};
`;
export interface FundraiserProgressBarProps {
  donationGoal?: number;
  donationsRaised?: number;
  barColor?: string;
  isMobile?: boolean;
  isEventDetails?: boolean;
  disableGradient?: boolean;
}

export const FundraiserProgressBar = ({
  donationGoal,
  donationsRaised,
  barColor,
  isMobile,
  isEventDetails,
  disableGradient
}: FundraiserProgressBarProps) => {
  const donationPercent = Math.ceil((donationsRaised! / donationGoal!) * 100);
  const darkBarColor = LightenDarkenColor(barColor ? barColor : '323232', -60);

  return (
    <FundraiserProgressBarContainer {...testProperties('progress-bar')}>
      <ProgressBar isMobile={isMobile}>
        <Svg height='100%' width={donationPercent >= 100 ? '100%' : donationPercent + '%'} aria-hidden>
          <Defs>
            <LinearGradient id={barColor || DEFAULT_FUNDRAISER_PROGRESS_BAR_COLOR} x1='0%' y1='0%' x2='100%' y2='0%'>
              <Stop
                offset='0%'
                stopColor={barColor !== '000000' ? `#${disableGradient ? barColor : darkBarColor}` : '#323232'}
                stopOpacity={1}
              />
              <Stop offset='100%' stopColor={barColor !== '000000' ? `#${barColor}` : '#323232'} stopOpacity={1} />
            </LinearGradient>
          </Defs>
          <Rect width='100%' height='100%' fill={`url(#${barColor})`} />
        </Svg>
        <Line1 />
        <Line2 />
        <Line3 />
      </ProgressBar>
      <PercentTextWrapper isEventDetails={isEventDetails}>
        {donationsRaised! <= 0 ? (
          <StyledText typeToken='helperText01' color='rgb(82, 82, 82)'>
            Be the first to donate!
          </StyledText>
        ) : (
          <TextWrapper
            donationQuarterRaised={Math.abs(donationPercent - 100) >= 75}
            donationPercentString={donationPercent >= 100 ? '0%' : Math.abs(donationPercent - 100) + '%'}
          >
            <StyledText typeToken='helperText01' color='rgb(82, 82, 82)' {...testProperties('funded-percentage')}>
              <ProgressBarText>{donationPercent.toLocaleString() + '% '}</ProgressBarText>
              funded!
            </StyledText>
          </TextWrapper>
        )}
      </PercentTextWrapper>
    </FundraiserProgressBarContainer>
  );
};

export default FundraiserProgressBar;
