import React, { useEffect } from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { Carbonicons, GFImage } from '@gf/cross-platform-lib/components';
import { StyledText } from '@gf/cross-platform-lib/components/StyledText';
import { activityIcons } from '@gf/cross-platform-lib/constants';

export interface SuccessMessageProps {
  eventTypeName: string;
  transferredTo: string;
  timeoutSeconds?: number;
  onCloseModal: () => void;
}

export const SuccessMessage = ({
  eventTypeName,
  transferredTo,
  timeoutSeconds = 3,
  onCloseModal
}: SuccessMessageProps) => {
  const [timeout, setTimeOut] = React.useState<number>(timeoutSeconds);
  useEffect(() => {
    const interval = setInterval(() => {
      setTimeOut(timeout => timeout - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    if (timeout !== -1) return;
    onCloseModal();
  }, [timeout]);

  return (
    <Wrapper>
      <SuccessIcon>
        <GFImage name={activityIcons.digitalseasonpass} width={86} height={62} />
        <CheckmarkContainer>
          <Carbonicons name='checkmarkFilled' size={68} color='#24a148' figurecolor='#FFF' />
        </CheckmarkContainer>
      </SuccessIcon>
      <Title typeToken='heading03'>Transfer request sent!</Title>
      <PassName typeToken='body01'>{eventTypeName}</PassName>
      <Message typeToken='body01' textAlign='center'>
        A request was sent to <StyledText typeToken='body01SemiBold'>{transferredTo}</StyledText> to accept and complete
        the transfer.
      </Message>
      <GoingAwayMessage>{`Going away in ${timeout} seconds`}</GoingAwayMessage>
    </Wrapper>
  );
};

const Wrapper = styled.View`
  justify-content: center;
  align-items: center;
`;

const SuccessIcon = styled.View`
  position: relative;
  height: 100px;
`;

const CheckmarkContainer = styled.View`
  position: absolute;
  top: 32px;
  right: -8px;
`;

const Title = styled(StyledText)`
  font-family: 'Rubik-SemiBold';
`;

const PassName = styled(StyledText)`
  margin-top: 8px;
`;

const Message = styled(StyledText)`
  margin-top: 16px;
`;

const GoingAwayMessage = styled(StyledText)`
  margin-top: 16px;
`;
