import dayjs from 'dayjs';
import isNumber from 'lodash/isNumber';
import { Event, PatchField, getProductTypeMeta, PurchasedTicket, ProductType } from '@gf/cross-platform-lib/interfaces';
import { Fan } from '@gf/cross-platform-lib/models/Fan';
import {
  GetCurrentApplicationConfiguration,
  Method,
  DATE_WITH_TIME_ZONE_FORMAT,
  getUnixTime,
  isEmpty
} from '@gf/cross-platform-lib/utils';
import { privateSafeFetch, safeFetch } from '../utils';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

const appConfig = GetCurrentApplicationConfiguration();

export interface TransferResponse {
  status: number;
  accessToken: string;
  ticket?: PurchasedTicket;
}

export const cancelMobilePassTransfer = async (mobilePass: PurchasedTicket) => {
  const url = appConfig.api.orderservice.getUrlForMethodAndId!(Method.PATCH, mobilePass.accessToken);
  const now = dayjs().utc().format();
  const lastColonIndex = new Date().toISOString().lastIndexOf(':');
  const nowFormatted = now.slice(0, lastColonIndex);
  try {
    return await privateSafeFetch(url!, {
      method: 'PATCH',
      body: JSON.stringify({
        accessToken: mobilePass.accessToken,
        mobilePassTransferCancelledAt: nowFormatted
      })
    }).then(res => res.status);
  } catch (err: any) {
    recordError(err, {
      originatingFunction: 'transferUseTicketApis-cancelMobilePassTransfer',
      customMessage: 'Unable to cancel mobile pass transfer during PATCH request',
      errorGroup: NEW_RELIC_ERROR_GROUPS.Transfers,
      data: { mobilePass, url, now, lastColonIndex, nowFormatted }
    });
  }
};

export const confirmMobilePassTransfer = async (mobilePass: PurchasedTicket, formFields?: PatchField[]) => {
  const url = appConfig.api.orderservice.getUrlForMethodAndId!(Method.PATCH, mobilePass.accessToken);
  const fan = Fan.getInstance();

  try {
    return await safeFetch(url!, {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({
        accessToken: mobilePass.accessToken,
        mobilePassTransfereeEmailAccepted: fan.email,
        formFields
      })
    }).then(res => res.status);
  } catch (err: any) {
    recordError(err, {
      originatingFunction: 'transferUseTicketApis-confirmMobilePassTransfer',
      customMessage: 'Unable to confirm mobile pass transfer during PATCH request',
      errorGroup: NEW_RELIC_ERROR_GROUPS.Transfers,
      data: { mobilePass, url, fan, formFields }
    });
  }
};

export const transferMobilePass = async (accessToken: string, email: string) => {
  const url = appConfig.api.orderservice.getUrlForMethodAndId!(Method.PATCH, accessToken);

  try {
    return await privateSafeFetch(url!, {
      method: 'PATCH',
      body: JSON.stringify({
        accessToken: accessToken,
        mobilePassTransfereeEmailInitiated: email,
        transferUrl: `${appConfig.origin.url}/transfer/mobile-pass/${accessToken}`
      })
    }).then(res => {
      return { status: res.status, data: res.data || res.error?.data };
    });
  } catch (err: any) {
    recordError(err, {
      originatingFunction: 'transferUseTicketApis-transferMobilePass',
      customMessage: 'Unable to transfer mobile pass during PATCH request',
      errorGroup: NEW_RELIC_ERROR_GROUPS.Transfers,
      data: { accessToken, email, url }
    });
  }
};

export const transferTickets = async (accessTokens: string[]): Promise<TransferResponse[]> => {
  const promises: Promise<TransferResponse>[] = [];
  accessTokens.forEach(accessToken => {
    const url = appConfig.api.orderservice.getUrlForMethodAndId!(Method.PATCH, accessToken);
    try {
      promises.push(
        safeFetch<PurchasedTicket>(url!, {
          method: 'PATCH',
          headers: new Headers({
            'Content-Type': 'application/json'
          }),
          body: JSON.stringify({
            accessToken: accessToken,
            transferUrl: `${appConfig.origin.url}/transfer/tickets/${accessToken}`
          })
        })
          .then(async res => {
            const result = { status: res.status, accessToken };
            if (res.data) {
              const ticket: PurchasedTicket = res.data;
              return {
                ...result,
                ticket
              };
            }
            return result;
          })
          .catch(_err => ({ status: 400, accessToken }))
      );
    } catch (err: any) {
      recordError(err, {
        originatingFunction: 'transferUseTicketApis-transferTickets',
        customMessage: 'Unable to transfer tickets during PATCH request',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Transfers,
        data: { accessTokens, url }
      });
    }
  });

  return await Promise.all<TransferResponse>(promises).then(responses => responses);
};

export enum TicketValidationType {
  VALID = 'VALID',
  UNVALID = 'UNVALID',
  USED = 'USED'
}

const canUsePass = ({ pass, event }: { pass: PurchasedTicket; event: Event }) => {
  const isUseAllPass = isNumber(pass?.redemptionLimit) && pass?.redemptionCount >= pass?.redemptionLimit;
  const timeZone = pass.eventTimeZone || event.timeZone || '';

  const redeemedAtTime = isEmpty(pass?.redeemedAt)
    ? undefined
    : getUnixTime(pass?.redeemedAt, timeZone, DATE_WITH_TIME_ZONE_FORMAT);
  let availableTime = redeemedAtTime?.add(event.redemptionWindow || 0, 'm');

  return !pass.redeemedAt || (dayjs().isAfter(availableTime) && !isUseAllPass);
};

export const validateTicketsRedemption = async (tickets: PurchasedTicket[], event: Event) => {
  const promises = tickets.map(async ticket => {
    const [purchasedTicket, eventRes] = await Promise.all([
      getTicketByAccessToken(ticket.accessToken),
      getMobilePassEventInfoById(event.id)
    ]);

    if (
      (getProductTypeMeta(purchasedTicket?.productType, 'isTicketLike') && purchasedTicket?.redeemedAt) ||
      (purchasedTicket?.productType === ProductType.MOBILEPASS &&
        !canUsePass({
          pass: purchasedTicket,
          event
        }))
    ) {
      return Promise.resolve(TicketValidationType.USED);
    } else if (purchasedTicket?.refundedAt || eventRes?.canceled) {
      return Promise.resolve(TicketValidationType.UNVALID);
    }
    return Promise.resolve(TicketValidationType.VALID);
  });
  return await Promise.all(promises).then(res => {
    if (res.includes(TicketValidationType.USED)) {
      return TicketValidationType.USED;
    } else if (res.includes(TicketValidationType.UNVALID)) {
      return TicketValidationType.UNVALID;
    } else {
      return TicketValidationType.VALID;
    }
  });
};

export const handleUseTickets = async (tickets: PurchasedTicket[], signal: any) => {
  const promises: Promise<boolean>[] = [];
  tickets.forEach(ticket => {
    const url = appConfig.api.orderservice.getUrlForMethodAndId!(Method.PATCH, ticket.accessToken);
    const now = dayjs().utc().format();
    const lastColonIndex = now.lastIndexOf(':');
    const nowFormatted = now.slice(0, lastColonIndex) + now.slice(lastColonIndex + 1);
    try {
      promises.push(
        privateSafeFetch(url!, {
          signal,
          method: 'PATCH',
          body: JSON.stringify({
            accessToken: ticket.accessToken,
            redeemedAt: nowFormatted
          })
        }).then(async res => res.status === 200)
      );
    } catch (err: any) {
      recordError(err, {
        originatingFunction: 'transferUseTicketApis-handleUseTickets',
        customMessage: 'Unable to use tickets via PATCH request',
        errorGroup: NEW_RELIC_ERROR_GROUPS.UserActions,
        data: { tickets, url, now, lastColonIndex, nowFormatted, signal }
      });
      return Promise.resolve(false);
    }
  });
  return await Promise.all(promises).then(res => res.every(status => status));
};

export const getMobilePassEventInfoById = async (id: string): Promise<Event | null> =>
  await safeFetch<Event>(appConfig.api.events.getUrlForMethodAndId!(Method.GET, id) || '').then(res => res.data);

export const getTicketByAccessToken = async (accessToken: string): Promise<PurchasedTicket | null> => {
  const url = `${appConfig.api.orders.baseUrl}/orders/tickets?access-token=${accessToken}&expand=form-responses-order`;
  return await safeFetch<PurchasedTicket>(url).then(res => res.data);
};

export const handleCancelTransferPassError = async ({
  id,
  token,
  currentTicket,
  handleInvalidRefundedPass,
  handleCancelSuccess,
  handleInvalidAcceptedPass
}: {
  id: string;
  token: string;
  currentTicket: PurchasedTicket;
  handleInvalidRefundedPass: Function;
  handleCancelSuccess: Function;
  handleInvalidAcceptedPass: Function;
}) => {
  const [ticket, event] = await Promise.all([getTicketByAccessToken(token), getMobilePassEventInfoById(id)]);
  const trimmedTicket = {
    ...ticket,
    eventStartDate: event?.startDateTime,
    eventEndDate: event?.endDateTime,
    eventTimezone: event?.timeZone,
    mobilePassTransferAcceptedAt: ticket?.mobilePassTransferAcceptedAt,
    _embedded: currentTicket?._embedded
  };
  if (event?.canceled || ticket?.refundedAt) {
    return handleInvalidRefundedPass(trimmedTicket, event?.canceled);
  }
  if (trimmedTicket?.mobilePassTransferAcceptedAt) {
    return handleInvalidAcceptedPass(trimmedTicket);
  }
  return handleCancelSuccess(trimmedTicket);
};
