import { TheStore } from '@gf/cross-platform-lib/modules/AcquisitionV2/store/Store';
import { Platform } from 'react-native';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { hasAction, requestReview } from 'expo-store-review';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';
import { useCustomTracking } from '@gf/cross-platform-lib/hooks/tracking/useCustomTracking';

interface AppReviewType {
  isInitialLaunch: boolean | null;
  isDevicePromptReady: (promptHistory: PromptHistory, context: string) => boolean;
  promptAppReview: (context: string, delayMS?: number) => void;
}

export interface PromptHistory {
  [context: string]: number;
}

export const AppReviewContext = createContext<AppReviewType>({
  isInitialLaunch: null,
  isDevicePromptReady: () => false,
  promptAppReview: () => {}
});

export const NativeAppReviewProvider = ({ children }: { children: React.ReactNode }) => {
  const { trackStoreReviewPrompt } = useCustomTracking();
  const isNative: boolean = Platform.OS === 'ios' || Platform.OS === 'android';
  const [isInitialLaunch, setIsInitialLaunch] = useState<boolean | null>(null);

  const isDevicePromptReady = (promptHistory: PromptHistory, context: string): boolean => {
    const isPreviouslyPrompted = !!promptHistory[context];
    return !isInitialLaunch && !isPreviouslyPrompted && isNative;
  };

  const promptAppReview = async (context: string, delayMS: number = 0) => {
    const appReviewPrompts = await TheStore.getItem('app_review_prompts');
    const doesHaveAction = await hasAction();
    const promptHistory = appReviewPrompts ? (appReviewPrompts as PromptHistory) : ({} as PromptHistory);
    if (!isDevicePromptReady(promptHistory, context)) return;
    if (doesHaveAction) {
      setTimeout(() => {
        requestReview();
        const count = promptHistory[context] ? promptHistory[context] : 0;
        const updatedPromptHistory = {
          ...promptHistory,
          [context]: count + 1
        };
        TheStore.setItem('app_review_prompts', updatedPromptHistory);
        trackStoreReviewPrompt({
          context,
          promptCount: count + 1
        });
      }, delayMS);
    }
  };

  useEffect(() => {
    TheStore.getItem('previously_launched')
      .then(value => {
        if (value === null) {
          TheStore.setItem('previously_launched', 'true');
          setIsInitialLaunch(true);
        } else {
          setIsInitialLaunch(false);
        }
      })
      .catch(error => {
        const errorContext = 'TheStore.previously_launched';
        recordError(error, {
          originatingFunction: 'NativeAppReviewProvider-TheStore.getItem',
          customMessage: 'Failed to fetch previously_launched value from TheStore',
          errorGroup: NEW_RELIC_ERROR_GROUPS.AppInitialization,
          data: {
            platform: Platform.OS
          },
          errorContext: errorContext
        });
      });
  }, []);

  const appReviewValue = { isInitialLaunch, isDevicePromptReady, promptAppReview };

  return <AppReviewContext.Provider value={appReviewValue}>{children}</AppReviewContext.Provider>;
};

export const useAppReviewContext = () => useContext(AppReviewContext);
