export interface TransferResponse {
  status: number;
  data: TransferInvalidResponseData;
}

export interface TransferInvalidResponseData {
  message: string;
  transferUrl: string;
  statusCode: string;
  body: {
    errorCode?: string;
  };
}

export const processInvalidResponse = (response?: TransferResponse) => {
  if (response?.status === 409) {
    const { body } = response.data;

    if (body?.errorCode === 'ALREADY_TRANSFERRED') {
      return {
        title: 'Pass already transferred',
        subTitle: 'We’re sorry, this pass has already been transferred',
        confirmLabel: 'Close'
      };
    }
    if (body?.errorCode === 'ALREADY_REFUNDED') {
      return {
        title: 'Pass refunded',
        subTitle: 'We’re sorry, this pass has been refunded',
        confirmLabel: 'Close'
      };
    }
  }
  return {
    title: 'An error occurred!',
    subTitle: 'Please try again later',
    confirmLabel: 'Close'
  };
};
