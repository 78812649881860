import { IPagePropsSeo, IPagePropsSeoMetaItem } from '@gf/cross-platform-lib';
import { DEFAULT_TITLE } from '@gf/cross-platform-lib/constants';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import { GetCurrentApplicationConfiguration } from '@gf/cross-platform-lib/utils';

interface IHeadSEOProps {
  seoData?: IPagePropsSeo | undefined;
}

const appConfig = GetCurrentApplicationConfiguration();
export const HeadSEO = ({ seoData = {} }: IHeadSEOProps) => {
  const router = useRouter();
  const { eventStructuredData, metaTags = [], title = '', canonicalLink } = seoData;
  const DEFAULT_META_TAGS: IPagePropsSeoMetaItem[] = [
    {
      name: 'viewport',
      content: 'initial-scale=1.0, width=device-width, maximum-scale=1.0'
    },
    {
      property: 'og:title',
      content: DEFAULT_TITLE
    },
    {
      property: 'og:url',
      content: `${appConfig.assets.host}${router.asPath}`
    },
    {
      property: 'og:type',
      content: 'website'
    },
    {
      property: 'og:image',
      content: 'https://production-gofan-assets.s3.amazonaws.com/gofan-link-preview.png'
    },
    {
      property: 'og:image:type',
      content: 'image/png'
    },
    {
      name: 'twitter:image',
      content: 'https://production-gofan-assets.s3.amazonaws.com/gofan-link-preview.png'
    },
    {
      name: 'twitter:card',
      content: 'summary_large_image'
    }
  ];
  const updateMetaTags = [
    ...metaTags,
    ...DEFAULT_META_TAGS.filter(dMeta => !metaTags.some(meta => meta.property === dMeta.property))
  ];
  return (
    <Head>
      <title>{title || DEFAULT_TITLE}</title>
      {canonicalLink && <link rel='canonical' href={`${appConfig.assets.host}${canonicalLink}`} />}
      {updateMetaTags.map((meta, index) => {
        return (
          <meta
            key={`${meta.property}-${index}`}
            property={meta.property}
            content={meta.content}
            name={meta.name || ''}
          />
        );
      })}
      {eventStructuredData && (
        <script
          type='application/ld+json'
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(eventStructuredData)
          }}
        ></script>
      )}
    </Head>
  );
};
