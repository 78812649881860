import React from 'react';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';

const styles = `
#cookie-banner {
    padding: 20px 0;
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 0;
    width: 100%;
    background-color: #242424;
    z-index: 4;
}
#cookie-banner .content-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 0 5%;
    flex-direction: row;
}

#cookie-banner .content-wrapper .content {
    color: white;
    padding: 12px;
    padding-right: 32px;
    font-size: 14px;
    font-family: Adjusted Rubik-Regular;
}

#cookie-banner .content-wrapper .content .link {
    color: white;
    font-size: 14px;
    font-family: Adjusted Rubik-Regular;
    cursor: pointer !important;
}

#cookie-banner .label {
    font-weight: bold;
    text-decoration-color: white;
    color: white;
}

#cookie-banner .cookie-banner-accept-button-wrapper {
    padding: 16px;
    width: auto;
}

#cookie-banner .cookie-banner-accept-button-wrapper #cookie-banner-accept-button {
    background: white;
    border-radius: 4px;
    height: 40px;
    padding: 0px 32px;
    border: 1px solid #323232;
    color: #323232;
    width: auto;
    cursor: pointer;

    line-height: 20px;
    letter-spacing: 0.16px;
    font-weight: 600;
    font-size: 14px;
    font-family: Adjusted Rubik-SemiBold;
    text-align: center;
}

#cookie-banner .cookie-banner-accept-button-wrapper #cookie-banner-accept-button:hover {
    background-color: rgb(91, 91, 91);
    border-color: transparent;
}

@media (max-width: 768px) {
    #cookie-banner {
        padding: 0;
    }

    #cookie-banner .content-wrapper {
        margin: 0;
        flex-direction: column;
    }

    #cookie-banner .content-wrapper .content {
        padding: 16px;
        font-size: 12px;
    }

    #cookie-banner .content-wrapper .content .link {
        font-size: 12px;
    }

    #cookie-banner .cookie-banner-accept-button-wrapper {
        padding: 16px;
        padding-top: 0;
        width: 100%;
    }

    #cookie-banner .cookie-banner-accept-button-wrapper #cookie-banner-accept-button {
        width: 100%;
    }
}`;
export const CookieBannerView = ({ handleCookieBannerAgree }: { handleCookieBannerAgree: () => void }) => {
  return (
    <>
      <style>{styles}</style>
      <div id='cookie-banner' style={{ minHeight: 56, minWidth: '100%' }} aria-label='alert' aria-live='polite'>
        <div className='content-wrapper'>
          <div className='content'>
            This website collects personal information using first and third-party cookies, persistent trackers, and
            similar technologies (e.g., MetaPixel) to help us operate this website, provide you with a more personalized
            user experience, and provide advertisements. The information we collect is shared with our advertising,
            social media, and analytics partners. Further use of our website or by clicking “Agree” will indicate your
            agreement to our use of technologies, the&nbsp;
            <a
              target='_blank'
              href={GOFAN_APP_PAGES['privacy-policy'].path}
              title='Link to Privacy Policy'
              rel='noreferrer'
              className='link'
            >
              Privacy Policy
            </a>
            , and the&nbsp;
            <a
              target='_blank'
              href={GOFAN_APP_PAGES['terms-of-use'].path}
              title='Link to Terms of Use'
              rel='noreferrer'
              className='link'
            >
              Terms of Use
            </a>
            , which includes a binding arbitration clause and jury waiver
          </div>
          <div className='cookie-banner-accept-button-wrapper'>
            <button id='cookie-banner-accept-button' aria-label='Agree to cookies' onClick={handleCookieBannerAgree}>
              Agree
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
