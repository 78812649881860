import { GetCurrentApplicationConfiguration } from '@gf/cross-platform-lib/utils';
import { loadStripe } from '@stripe/stripe-js';
import { useRouter } from '@gf/cross-platform-lib/hooks';
import React, { useEffect } from 'react';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';

interface StripeNativeProviderProps {
  children: React.ReactNode;
}

const appConfig = GetCurrentApplicationConfiguration();

export const StripeNativeProvider = ({ children }: StripeNativeProviderProps) => {
  const { currentRoute } = useRouter();

  useEffect(() => {
    if (currentRoute !== GOFAN_APP_PAGES.reviewAndBuy.path) {
      loadStripe(appConfig.settings.payment.stripe.publicKey, {
        apiVersion: '2024-04-10'
      });
    }
  }, []);
  return <>{children}</>;
};
