import React from 'react';
import { Carbonicons, DeclineIcon, StyledButton, StyledText } from '@gf/cross-platform-lib/components';
import { declinePassDialog, testProperties } from '@gf/cross-platform-lib/utils';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { useNavigate } from '@gf/cross-platform-lib/hooks';
import { PurchasedTicket } from '@gf/cross-platform-lib/interfaces';
import { cancelMobilePassTransfer } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import styled from '@gf/cross-platform-lib/styled-components';
import { MODAL_TYPES, useGlobalLoadingContext, useModal } from '@gf/cross-platform-lib/providers';
import { styles } from './MobilePassDeclineModal.styles';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const Title = styled.Text`
  ${styles.title};
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

const Description = styled.Text`
  ${styles.description};
`;

const ClosedIconWrapper = styled.TouchableOpacity`
  ${styles.closedIconWrapper};
`;

const IconWrapper = styled.TouchableOpacity`
  ${styles.iconWrapper};
`;

const ButtonWrapper = styled.View`
  ${styles.buttonWrapper};
`;
export interface MobilePassDeclineModalProps {
  mobilePass: PurchasedTicket;
}

export const MobilePassDeclineModal = ({}: MobilePassDeclineModalProps) => {
  const { showGlobalLoading } = useGlobalLoadingContext();
  const { modalState, hideModal, showModal } = useModal();
  const { modalProps } = modalState;
  const { mobilePass } = (modalProps as MobilePassDeclineModalProps) || {};
  const navigate = useNavigate();

  const onDecline = async () => {
    showGlobalLoading(true);
    const res = await cancelMobilePassTransfer(mobilePass);
    showGlobalLoading(false);
    hideModal();
    if (res === 409) {
      navigate(`${GOFAN_APP_PAGES.passUnavailable.path}?${`redirectHomePage=${true}`}`);
    } else if (res !== 200) {
      showModal(MODAL_TYPES.ERROR_TRANSFER_MODAL, {});
    } else {
      navigate(`${GOFAN_APP_PAGES.myTickets.path}?${`redirectHomePage=${true}`}`);
    }
  };

  return (
    <ModalWrapper {...testProperties(declinePassDialog.name)}>
      <ClosedIconWrapper onPress={hideModal}>
        <Carbonicons name='close' color='black' size={20}></Carbonicons>
      </ClosedIconWrapper>
      <IconWrapper>
        <DeclineIcon />
      </IconWrapper>
      <Title numberOfLines={2}>Are you sure you want to decline this pass?</Title>
      <Description {...testProperties(declinePassDialog.productName)}>{mobilePass?.name}</Description>
      <SubTitle>You won’t be able to accept this pass later.</SubTitle>
      <ButtonWrapper>
        <StyledButton
          backgroundColor='#da1e28'
          height='40px'
          width='132px'
          padding='0 22px'
          onPress={onDecline}
          accessibilityLabel='Decline pass'
          {...testProperties(declinePassDialog.declineBtn)}
        >
          <StyledText color='#ffffff' textAlign='center'>
            Decline pass
          </StyledText>
        </StyledButton>
        <StyledButton height='40px' width='132px' onPress={hideModal} accessibilityLabel='Cancel'>
          <StyledText color='white' textAlign='center' {...testProperties(declinePassDialog.cancelBtn)}>
            Cancel
          </StyledText>
        </StyledButton>
      </ButtonWrapper>
    </ModalWrapper>
  );
};
