import { useSegmentTracking } from '../../providers/Tracking';
import { useFirebase } from '../../providers/Firebase';
import { TRACKING_PAGE_VIEW_NAMES } from '../../constants';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '../../constants';

interface HomePageViewProps {
  version: 'nextgen' | 'app v2';
}

export interface EventListPageViewFilterProps {
  name: string;
  value: string;
}

export interface EventListPageViewProps {
  home_org: string;
  filters: EventListPageViewFilterProps[];
}

interface MySchoolsPageViewProps {
  numSchools: number;
}

export interface MyTicketsPageViewProps {
  numEvents: number;
}

interface CartPageViewProps {
  numTickets: number;
}

interface OrderDetailsPageViewProps {
  orderId: string | number;
  numTickets: number;
}

interface SeatingChartPageViewProps {
  activity: string;
  eventID: string;
}

export type TrackEventTicketsPage = {
  activity: string;
  eventID?: string;
  homeOrg: string;
  numTickets: string | number;
  numUnusedTickets: string | number;
  numUsedTickets: string | number;
  orderID?: string;
  seasonID?: string;
  tab: string;
  ticketID: number[];
  user: string;
};

export interface EventDetailsPageViewProps {
  package: string;
  home_org: string;
  financial_org: string;
  activity: string;
  eventID: string;
}

export interface RedemptionPageViewProps {
  numTickets: number;
  eventID: string;
  activity: string;
  homeOrg: string;
  user: string;
}

export type FanScanPageViewProps = {
  eventID?: string;
};

export type AllSchoolsPageViewProps = {
  accessor: string;
};

export const usePageViewTracking = () => {
  const { pageView: segmentPageView } = useSegmentTracking();
  const {
    ga4: { pageView: ga4PageView }
  } = useFirebase();
  const pageView = async (pageName: string, props?: any) => {
    segmentPageView(pageName, props);
    await ga4PageView(pageName, props);
  };

  const trackHomePageView = (props?: HomePageViewProps) => {
    pageView(TRACKING_PAGE_VIEW_NAMES.HOME, props || { version: 'nextgen' });
  };

  const trackEventListPageView = (props: EventListPageViewProps) => {
    try {
      pageView(TRACKING_PAGE_VIEW_NAMES.EVENT_LIST, props);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'usePageViewTracking-trackEventListPageView',
        customMessage: `Unable to track user view of event list page`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { props }
      });
    }
  };

  const trackSignInPageView = () => {
    pageView(TRACKING_PAGE_VIEW_NAMES.SIGN_IN);
  };

  const trackSignUpPageView = () => {
    pageView(TRACKING_PAGE_VIEW_NAMES.SIGN_UP);
  };

  const trackForgotPasswordPageView = () => {
    pageView(TRACKING_PAGE_VIEW_NAMES.FORGOT_PASSWORD);
  };

  const trackMySchoolsPageView = (props: MySchoolsPageViewProps) => {
    pageView(TRACKING_PAGE_VIEW_NAMES.MY_SCHOOLS, props);
  };

  const trackMyTicketsPageView = (props: MyTicketsPageViewProps) => {
    try {
      pageView(TRACKING_PAGE_VIEW_NAMES.MY_TICKETS, props);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'usePageViewTracking-trackMyTicketsPageView',
        customMessage: `Unable to track user view of my tickets page`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { props }
      });
    }
  };

  const trackCartPageView = (props: CartPageViewProps) => {
    pageView(TRACKING_PAGE_VIEW_NAMES.CART, props);
  };

  const trackOrderDetailsPageView = (props: OrderDetailsPageViewProps) => {
    pageView(TRACKING_PAGE_VIEW_NAMES.ORDER_DETAILS, props);
  };

  const trackSeatingChartPageView = (props: SeatingChartPageViewProps) => {
    pageView(TRACKING_PAGE_VIEW_NAMES.SEATING_CHART, props);
  };

  const trackEventDetailsPageView = (props: EventDetailsPageViewProps) => {
    try {
      pageView(TRACKING_PAGE_VIEW_NAMES.EVENT_DETAILS, props);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'usePageViewTracking-trackEventDetailsPageView',
        customMessage: `Unable to track user view of event details page`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { props }
      });
    }
  };

  const trackEventTicketsPage = (props: TrackEventTicketsPage) => {
    try {
      pageView(TRACKING_PAGE_VIEW_NAMES.EVENT_TICKETS, props);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'usePageViewTracking-trackEventTicketsPage',
        customMessage: `Unable to track user view of event tickets page`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { props }
      });
    }
  };

  const trackRedemptionPageView = (props: RedemptionPageViewProps) => {
    try {
      pageView(TRACKING_PAGE_VIEW_NAMES.REDEMPTION, props);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'usePageViewTracking-trackRedemptionPageView',
        customMessage: `Unable to track user view of redemption page`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { props }
      });
    }
  };

  const trackChangePassWordPageView = () => {
    pageView(TRACKING_PAGE_VIEW_NAMES.CHANGE_PASSWORD);
  };

  const trackFanScanPageView = (props: FanScanPageViewProps) => {
    try {
      pageView(TRACKING_PAGE_VIEW_NAMES.FAN_SCAN, props);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'usePageViewTracking-trackFanScanPageView',
        customMessage: `Unable to track user view of the ticket scan page`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { props }
      });
    }
  };

  const trackAllSchoolsPageView = (props: AllSchoolsPageViewProps) => {
    try {
      pageView(TRACKING_PAGE_VIEW_NAMES.ALL_SCHOOLS, props);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'usePageViewTracking-trackAllSchoolsPageView',
        customMessage: `Unable to track user view of the all schools page`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { props }
      });
    }
  };

  return {
    trackHomePageView,
    trackEventListPageView,
    trackEventDetailsPageView,
    trackSignInPageView,
    trackSignUpPageView,
    trackForgotPasswordPageView,
    trackMySchoolsPageView,
    trackMyTicketsPageView,
    trackCartPageView,
    trackChangePassWordPageView,
    trackOrderDetailsPageView,
    trackSeatingChartPageView,
    trackRedemptionPageView,
    trackEventTicketsPage,
    trackFanScanPageView,
    trackAllSchoolsPageView
  };
};
