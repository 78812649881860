import React from 'react';
import { Carbonicons } from '@gf/cross-platform-lib/components';
import styled from '@gf/cross-platform-lib/styled-components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { useModal } from '@gf/cross-platform-lib/providers';
import { styles } from './TicketPrintLimitModal.styles';
import { DEFAULT_SHOW_PRINT_TICKET_MODAL } from '@gf/cross-platform-lib/constants';

const ModalWrapper = styled.View`
  ${styles.modalWrapper}
`;

const BodyContainer = styled.View`
  ${styles.bodyContainer}
`;

const Container = styled.View`
  ${styles.container}
`;

const Title = styled.Text`
  ${styles.title}
`;

const ContentWrapper = styled.View`
  ${styles.contentWrapper}
`;

const ContentContainer = styled.View`
  ${styles.contentContainer}
`;

const CloseBtnContainer = styled.TouchableOpacity`
  ${styles.closeBtnContainer}
`;

const BtnContainer = styled.View`
  ${styles.btnContainer}
`;

const Button = styled.TouchableOpacity`
  ${styles.button}
`;

const SubTitle = styled.Text`
  ${styles.subTitle}
`;

const BoldText = styled.Text`
  ${styles.boldText}
`;

const ButtonTitle = styled.Text`
  ${styles.buttonTitle}
`;

const IconWrapper = styled.TouchableOpacity`
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

const PRINT_LIMIT_MODAL_TITLE = `Due to the number of tickets you purchased for this event, your tickets must be printed in batches of ${DEFAULT_SHOW_PRINT_TICKET_MODAL}.`;

export const TicketPrintLimitModal = () => {
  const { isMobile } = useMediaQuery();
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const { onConfirm = () => {} } = modalProps || {};

  return (
    <ModalWrapper isMobile={isMobile}>
      <CloseBtnContainer
        onPress={() => {
          hideModal();
        }}
      >
        <Carbonicons name='close' size={22} color='#161616' />
      </CloseBtnContainer>
      <BodyContainer>
        <ContentWrapper>
          <ContentContainer isMobile={isMobile}>
            <IconWrapper>
              <Carbonicons name='desktopWarning' size={60} />
            </IconWrapper>
            <Container>
              <Title>{PRINT_LIMIT_MODAL_TITLE}</Title>
            </Container>
            <SubTitle>
              Click <BoldText>OK</BoldText> below to proceed to the ticket details page to print your tickets or
              <BoldText> Cancel</BoldText> to stay here.
            </SubTitle>
          </ContentContainer>
        </ContentWrapper>
        <BtnContainer>
          <Button
            bgColor='#f4f4f4'
            color='#323232'
            onPress={() => {
              onConfirm();
              hideModal();
            }}
            disabled={false}
          >
            <ButtonTitle color='#323232'>OK</ButtonTitle>
          </Button>
          <Button bgColor='#da1e28' onPress={hideModal} removeBorder>
            <ButtonTitle color='#ffffff'>Cancel</ButtonTitle>
          </Button>
        </BtnContainer>
      </BodyContainer>
    </ModalWrapper>
  );
};
