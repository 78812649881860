// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const TrashCan = (props: SvgProps) => (
  <Svg
    id='gf_icon_trash_can'
    aria-label='Trash Can icon'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    {...props}
  >
    <Path d='M12 12H14V24H12z' fill='black' />
    <Path d='M18 12H20V24H18z' fill='black' />
    <Path d='M4 6v2h2v20a2 2 0 002 2h16a2 2 0 002-2V8h2V6zm4 22V8h16v20z' fill='black' />
    <Path d='M12 2H20V4H12z' fill='black' />
    <Path
      id='TrashCan_Transparent_Rectangle_'
      data-name='&lt;Transparent Rectangle&gt;'
      d='M0 0H32V32H0z'
      fill='none'
    />
  </Svg>
);
