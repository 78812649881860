import { pathsToPageConfig } from '@gf/cross-platform-lib/constants';

export const isHideCookieBanner = (pathname: string, cookieValue: boolean | string, userAgent: string): boolean => {
  const isHideCookieBannerByPagePath = Object.values(pathsToPageConfig).some(
    route => pathname.includes(route.path) && route.hideCookiesBanner
  );
  const isCookiesAcknowledged = cookieValue?.toString() == 'true';
  const isBoxOfficeUser = userAgent?.includes('boxoffice');
  return !!(isHideCookieBannerByPagePath || isCookiesAcknowledged || isBoxOfficeUser);
};
