import React, { createContext, useReducer, Dispatch, useEffect } from 'react';
import { ReservedSeat, ChartInstance, HoldToken } from '@gf/cross-platform-lib/interfaces';
import { Platform } from 'react-native';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

interface ReservedSeatState {
  [eventId: string]: {
    chartInstance: ChartInstance | null;
    selectedSeats: ReservedSeat[];
    holdToken: HoldToken | null;
  };
}

type EventAction =
  | { type: 'init_chart'; eventId: string; payload: ChartInstance }
  | { type: 'add_seat'; eventId: string; payload: ReservedSeat }
  | { type: 'remove_seat'; eventId: string; payload: { id: string } }
  | { type: 'add_hold_token'; eventId: string; payload: { token: string; expiresAt: string } }
  | { type: 'clear_seats'; eventId: string };

type GeneralAction = { type: 'reset_state' };

type Action = EventAction | GeneralAction;

export const ReservedSeatingContext = createContext<[ReservedSeatState, Dispatch<Action>] | null>(null);
const reservedSeatingReducer = (state: ReservedSeatState, action: Action): ReservedSeatState => {
  if ('eventId' in action) {
    const eventState = state[action.eventId] || { selectedSeats: [], chartInstance: null, holdToken: null };
    switch (action.type) {
      case 'init_chart':
        return { ...state, [action.eventId]: { ...eventState, chartInstance: action.payload } };
      case 'add_seat':
        const seatAlreadySelected = eventState.selectedSeats.some(
          seat => seat.selectedSeat.id === action.payload.selectedSeat.id
        );
        if (seatAlreadySelected) {
          return state;
        } else {
          return {
            ...state,
            [action.eventId]: { ...eventState, selectedSeats: [...eventState.selectedSeats, action.payload] }
          };
        }
      case 'add_hold_token':
        return { ...state, [action.eventId]: { ...eventState, holdToken: action.payload } };
      case 'remove_seat':
        return {
          ...state,
          [action.eventId]: {
            ...eventState,
            selectedSeats: eventState.selectedSeats.filter(seat => seat.selectedSeat.id !== action.payload.id)
          }
        };
      case 'clear_seats':
        return { ...state, [action.eventId]: { ...eventState, selectedSeats: [] } };
      default:
        return state;
    }
  } else {
    switch (action.type) {
      case 'reset_state':
        return initialState;
      default:
        return state;
    }
  }
};

type ReservedSeatingProviderProps = { children: React.ReactNode };

interface EventAccumulator {
  [key: string]: {
    holdToken: HoldToken | null;
    selectedSeats: ReservedSeat[];
  };
}

const initialState: ReservedSeatState = {};

const RESERVED_SEATING_STATE_KEY = 'reservedSeatingState';

const serializeStateForStorage = (state: ReservedSeatState) => {
  const stateCopy = Object.entries(state).reduce((acc: EventAccumulator, [eventId, eventState]) => {
    const { selectedSeats, chartInstance: _chartInstance, ...serializableProps } = eventState;

    const sanitizedSelectedSeats = selectedSeats.map(seat => {
      const { selectedSeat, ...restOfSeat } = seat;
      const { chart: _chart, ...restOfSelectedSeat } = selectedSeat;
      return {
        ...restOfSeat,
        selectedSeat: restOfSelectedSeat
      };
    });

    acc[eventId] = {
      ...serializableProps,
      selectedSeats: sanitizedSelectedSeats
    };

    return acc;
  }, {});

  return JSON.stringify(stateCopy);
};

const saveStateToLocalStorage = (state: ReservedSeatState) => {
  if (Platform.OS === 'web') {
    try {
      const serializedState = serializeStateForStorage(state);
      localStorage.setItem(RESERVED_SEATING_STATE_KEY, serializedState);
    } catch (err) {
      console.error('Could not save state:', err);
    }
  }
};

const loadState = (initialState: ReservedSeatState) => {
  if (Platform.OS === 'web') {
    try {
      const serializedState = localStorage.getItem(RESERVED_SEATING_STATE_KEY);
      if (serializedState === null) {
        return initialState;
      }
      return JSON.parse(serializedState);
    } catch (err: any) {
      recordError(err, {
        errorGroup: NEW_RELIC_ERROR_GROUPS.SeatsIo,
        customMessage: 'Error while loading state from local storage',
        originatingFunction: 'ReservedSeatingProvider-loadState'
      });
      return initialState;
    }
  } else {
    return initialState;
  }
};

export const ReservedSeatingProvider = ({ children }: ReservedSeatingProviderProps) => {
  const [state, dispatch] = useReducer(reservedSeatingReducer, initialState, () => loadState(initialState));

  useEffect(() => {
    saveStateToLocalStorage(state);
  }, [state]);

  return <ReservedSeatingContext.Provider value={[state, dispatch]}>{children}</ReservedSeatingContext.Provider>;
};
