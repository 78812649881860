import React, { createContext, useContext } from 'react';
import { FirebaseAuthTypes } from '@react-native-firebase/auth';
import * as FirebaseAuth from 'firebase/auth';

type Auth = FirebaseAuth.Auth;

export interface FirebaseProviderProps {
  children: React.ReactNode;
}

export interface IGA4 {
  track: (name: string, payload?: any) => Promise<void>;
  pageView: (name: string, payload?: any) => Promise<void>;
}

export type GfUserCredential = FirebaseAuthTypes.UserCredential | FirebaseAuth.UserCredential;
export type GfUser = FirebaseAuthTypes.User | FirebaseAuth.User;

export interface GfAppAuth {
  signInWithEmailAndPassword: (email: string, password: string) => Promise<GfUserCredential | undefined>;
  createUserWithEmailAndPassword: (email: string, password: string) => Promise<GfUserCredential | undefined>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
  updatePassword: (oldPassword: string) => Promise<void>;
  reauthenticateWithCredential: (oldPassword: string) => Promise<any>;
  fetchSignInMethodsForEmail: (email: string) => Promise<string[]>;
  signOut: () => Promise<void>;
  currentUser: () => GfUser | null;
  onAuthStateChanged: (listener: () => any) => Function;
}

export interface IFirebaseContextValue {
  appAuth: GfAppAuth;
  ga4: IGA4;
  isReady: boolean;
  getAuthToken: () => Promise<unknown>;
}

export const FirebaseAuth2GfAuth = (_auth: Auth): GfAppAuth => {
  return getAppAuthInitValues();
};

interface IFirebaseRef {
  current: IFirebaseContextValue;
}

export const getG4InitValues = () => ({
  track: (_eventName: string, _payload: any) => Promise.resolve(),
  pageView: (_name: string, _payload?: any) => Promise.resolve()
});

export const getAppAuthInitValues = (): GfAppAuth => ({
  signInWithEmailAndPassword: (_email: string, _password: string) => Promise.resolve(undefined),
  createUserWithEmailAndPassword: (_email: string, _password: string) => Promise.resolve(undefined),
  sendPasswordResetEmail: (_email: string) => Promise.resolve(),
  updatePassword: (_newPassword: string) => Promise.resolve(),
  reauthenticateWithCredential: (_newPassword: string) => Promise.resolve(),
  fetchSignInMethodsForEmail: (_email: string) => Promise.resolve([]),
  signOut: () => Promise.resolve(),
  currentUser: () => null,
  onAuthStateChanged: () => () => {}
});

const defaultFirebaseContextValue = {
  appAuth: getAppAuthInitValues(),
  ga4: getG4InitValues(),
  isReady: false,
  getAuthToken: () => Promise.resolve()
};

export const FirebaseContext = createContext<IFirebaseContextValue>(defaultFirebaseContextValue);

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  return context;
};

export const FirebaseRef: IFirebaseRef = {
  current: defaultFirebaseContextValue
};
