import React, { useEffect } from 'react';
import { useCartState, useNavigate } from '@gf/cross-platform-lib/hooks';
import dayjs from 'dayjs';
import { MODAL_TYPES, useModal } from '@gf/cross-platform-lib/providers';
import { Cart } from '@gf/cross-platform-lib/models';

interface TestProviderProps {
  children: React.ReactNode;
}

export const CartExpirationProvider = ({ children }: TestProviderProps) => {
  const cartstate = useCartState();
  const { showModal, hideModal } = useModal();
  const navigate = useNavigate();

  const timer = React.useRef<any>(null);
  const showCartExpiredModal = (time: number, firstFoundId: string, isFirstFoundSeason: boolean) => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      Cart.getInstance().emptyCart();
      showModal(MODAL_TYPES.CART_EXPIRED_MODAL, {
        onCloseModal: () => {
          navigate(isFirstFoundSeason ? `/season/${firstFoundId}` : `/event/${firstFoundId}`);
          hideModal();
        }
      });
    }, time);
  };

  useEffect(() => {
    if (!cartstate.isEmpty && cartstate.lastTicketAddedDate && Cart.getInstance().cartSchools.length > 0) {
      const cartLastTicketAddedDate = dayjs(cartstate.lastTicketAddedDate);
      const timeCartShouldExpire = cartLastTicketAddedDate.add(15, 'minutes');
      const millisecondsUntilExpiration = timeCartShouldExpire.diff(dayjs(), 'millisecond');
      const firstFoundId = Array.from(Cart.getInstance().cartSchools.values())[0].events[0].id;
      const isFirstFoundSeason = Array.from(Cart.getInstance().cartSchools.values())[0].events[0].isSeason;

      showCartExpiredModal(millisecondsUntilExpiration, firstFoundId, isFirstFoundSeason);
    } else {
      clearTimeout(timer.current);
    }
  }, [cartstate.cartSchools]);

  return <>{children}</>;
};

export default CartExpirationProvider;
