import { useEffect, useState } from 'react';
import { EventCombined } from '@gf/cross-platform-lib/interfaces';
import { useDate, getDateRange } from './useDate';
import {
  MONTH_DAY_YEAR_FORMAT,
  MONTH_DAY_FORMAT,
  FULL_DATE_TIME_FORMAT,
  FULL_DATE_FORMAT,
  DEFAULT_FORMAT
} from '@gf/cross-platform-lib/constants';
import { isEmpty } from '@gf/cross-platform-lib/utils';

export const useEventSchedule = (event: EventCombined): { eventSchedule: string } => {
  const [startDateFormat, setStartDateFormat] = useState<string | undefined>(FULL_DATE_TIME_FORMAT);
  const [endDateFormat, setEndDateFormat] = useState<string | undefined>(FULL_DATE_TIME_FORMAT);
  const [eventSchedule, setEventSchedule] = useState<string>('');
  const { isSameYear, isSameDay } = getDateRange(event.startDateTime, event.endDateTime, event.timeZone);
  const { dateString: startDateTimeStr } = useDate(
    event.startDateTime,
    event.timeZone,
    DEFAULT_FORMAT,
    startDateFormat
  );
  const { dateString: endDateTimeStr } = useDate(event.endDateTime, event.timeZone, DEFAULT_FORMAT, endDateFormat);
  const isSameYearOnly = isSameYear && (event.isSeason || (!event.isSeason && !isSameDay));
  useEffect(() => {
    if (isEmpty(event)) {
      setEventSchedule('');
    } else {
      const startEndFormat = ((): string[] => {
        if (event.activity?.name === 'Fundraiser') {
          return [MONTH_DAY_FORMAT, MONTH_DAY_YEAR_FORMAT];
        } else if (isSameYearOnly) {
          return [MONTH_DAY_FORMAT, MONTH_DAY_YEAR_FORMAT];
        } else if (event.isSeason) {
          return [MONTH_DAY_YEAR_FORMAT, MONTH_DAY_YEAR_FORMAT];
        } else if (event.isAllDayEvent) {
          return isSameDay ? [FULL_DATE_FORMAT, ''] : [MONTH_DAY_YEAR_FORMAT, MONTH_DAY_YEAR_FORMAT];
        } else {
          return isSameDay ? [FULL_DATE_TIME_FORMAT, ''] : [FULL_DATE_TIME_FORMAT, FULL_DATE_TIME_FORMAT];
        }
      })();

      setStartDateFormat(startEndFormat[0]);
      setEndDateFormat(startEndFormat[1]);

      let eventSchedule = `${startDateTimeStr}`;
      if (startEndFormat[1]) {
        eventSchedule += ` - ${endDateTimeStr}`;
      }
      setEventSchedule(eventSchedule);
    }
  }, [startDateTimeStr, endDateTimeStr, event]);

  return { eventSchedule };
};
