import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalWrapper: css`
    background-color: #f4f4f4;
    border-radius: 4px;
    margin: auto;
    padding: 16px;
    width: 320px;
    align-items: center;
  `,
  iconWrapper: css`
    align-self: flex-end;
  `,
  iconButtom: css`
    height: 46px;
    width: 46px;
    align-items: flex-end;
  `,
  maxWidthSmallPadding: css`
    width: 100%;
    margin-bottom: 16px;
  `,
  centeredLargePadding: css`
    margin-bottom: 32px;
    align-items: center;
  `
};
