import { GOFAN_SCHOOL_TYPES, INDUSTRIAL_CODES } from '@gf/cross-platform-lib/constants';
import { EventSeason } from '@gf/cross-platform-lib/interfaces';
import { isEmpty } from './isEmpty';

const getFinancialOrgType = (industryCode: string) => {
  if (industryCode === INDUSTRIAL_CODES.SCHOOL_BOARD) {
    return 'District';
  } else if (industryCode === INDUSTRIAL_CODES.STATE_ASSOCIATION) {
    return 'State Association';
  } else if (Object.values(INDUSTRIAL_CODES).includes(industryCode)) {
    return 'School';
  }
  return 'Other';
};

const isStateSchool = (industryCode: string | undefined) =>
  !isEmpty(industryCode) && industryCode === INDUSTRIAL_CODES.STATE_ASSOCIATION;

const isDistrictSchool = (industryCode: string | undefined) =>
  !isEmpty(industryCode) && industryCode === INDUSTRIAL_CODES.SCHOOL_BOARD;

const isStateDistrictSchool = (industryCode: string | undefined) =>
  isStateSchool(industryCode) || isDistrictSchool(industryCode);

const isAggregatorSchool = (schoolType: string) =>
  !isEmpty(schoolType) && Object.values(GOFAN_SCHOOL_TYPES.AGGREGATOR).includes(schoolType);

const isSingleSchool = (schoolType: string) =>
  !isEmpty(schoolType) && Object.values(GOFAN_SCHOOL_TYPES.SINGLE).includes(schoolType);

const shouldShowLocationFilter = (schoolType: string, huddleId: string, events: EventSeason[]) =>
  !isAggregatorSchool(schoolType) &&
  events.some(item => item.schoolHuddleId === huddleId) &&
  events.some(item => item.schoolHuddleId !== huddleId);

export {
  isStateDistrictSchool,
  isDistrictSchool,
  isStateSchool,
  isAggregatorSchool,
  isSingleSchool,
  getFinancialOrgType,
  shouldShowLocationFilter
};
