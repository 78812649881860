// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const EventSchedule = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='currentColor' {...props}>
    <Path
      style={{
        fill: props.color
      }}
      d='M21 30a8 8 0 1 1 8-8 8 8 0 0 1-8 8Zm0-14a6 6 0 1 0 6 6 6 6 0 0 0-6-6Z'
    />
    <Path
      style={{
        fill: props.color
      }}
      d='M22.59 25 20 22.41V18h2v3.59l2 2L22.59 25z'
    />
    <Path
      style={{
        fill: props.color
      }}
      d='M28 6a2 2 0 0 0-2-2h-4V2h-2v2h-8V2h-2v2H6a2 2 0 0 0-2 2v20a2 2 0 0 0 2 2h4v-2H6V6h4v2h2V6h8v2h2V6h4v6h2Z'
    />
    <Path
      style={{
        fill: 'none'
      }}
      d='M0 0h32v32H0z'
    />
  </Svg>
);
