// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const StopValidation = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 57 57' {...props}>
    <Path
      d='M55.565 15.967 41.268 1.67a3.593 3.593 0 0 0-2.54-1.052h-20.22c-.953 0-1.867.378-2.541 1.052L1.67 15.967a3.594 3.594 0 0 0-1.052 2.541v20.22c0 .952.378 1.866 1.052 2.54l14.297 14.297a3.594 3.594 0 0 0 2.541 1.053h20.22c.952 0 1.866-.379 2.54-1.053l14.297-14.297a3.594 3.594 0 0 0 1.052-2.54v-20.22c0-.953-.378-1.867-1.052-2.541ZM44.567 26.19c-.392 1.539-2.51 8.772-3.048 10.774-.997 3.705-4.61 7.664-11.764 7.664-1.512-.12-12.841 1.06-12.841-8.578V20.144c0-2.13 3.223-2.122 3.223.002l-.005 8.716c.54-.192 1.206-.38 1.968-.554l.008-13.504c0-2.398 3.679-2.484 3.676.002l-.007 12.874c.251-.03 1.575-.167 1.968-.198l.008-13.648c.002-2.408 3.679-2.466 3.677.001l-.008 13.539c.769.023 1.436.08 1.968.17l.006-11.738c.001-2.43 3.678-2.428 3.678.002l-.003 15.57c.22 1.737 1.407 1.756 1.987.201.277-.74 1.037-3.296 1.037-3.296 1.178-3.944 4.826-3.483 4.472-2.093Z'
      fill='#DA1E28'
      fillRule='nonzero'
    />
  </Svg>
);
