// @ts-nocheck
/* eslint-disable unused-imports/no-unused-vars */
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

interface WarningFilled extends SvgProps {
  figurecolor?: string;
}
export const WarningFilled = (props: WarningFilled) => (
  <Svg
    id='gf_icon_warning_filled'
    aria-label='Warning'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='32px'
    height='32px'
    viewBox='0 0 32 32'
    xmlSpace='preserve'
    enableBackground='new 0 0 32 32'
    {...props}
  >
    <Path id='WarningFilled_Transparent_Rectangle' d='M0 0H32V32H0z' fill='none' />
    <Path
      id='Compound_Path'
      fillOpacity={props.fillOpacity}
      fill={props.color}
      d='M16 2C8.3 2 2 8.3 2 16s6.3 14 14 14 14-6.3 14-14S23.7 2 16 2zm-1.1 6h2.2v11h-2.2V8zM16 25c-.8 0-1.5-.7-1.5-1.5S15.2 22 16 22s1.5.7 1.5 1.5S16.8 25 16 25z'
    />
    <Path
      id='inner-path'
      d='M17.5 23.5c0 .8-.7 1.5-1.5 1.5s-1.5-.7-1.5-1.5.7-1.5 1.5-1.5 1.5.7 1.5 1.5zM17.1 8h-2.2v11h2.2V8z'
      opacity={props.opacity}
      fill={props.figurecolor || '#fff'}
    />
  </Svg>
);
