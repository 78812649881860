import { GetCurrentApplicationConfiguration } from '@gf/cross-platform-lib/utils';
import { recordError } from './newrelic.web';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

const appConfig = GetCurrentApplicationConfiguration();

declare global {
  const grecaptcha: {
    enterprise: {
      ready: Function;
      execute: Function;
    };
  };
}

export const getRecaptchaToken = (action: string) =>
  new Promise((resolve, reject) => {
    if (typeof grecaptcha === 'undefined' || !grecaptcha.enterprise || !grecaptcha.enterprise.execute) {
      reject(
        recordError(new Error('grecaptcha is not defined'), {
          customMessage: 'failed to get recaptcha token',
          originatingFunction: 'recaptcha-getRecaptchaToken',
          errorGroup: NEW_RELIC_ERROR_GROUPS.Recaptcha
        })
      );
      return;
    }

    grecaptcha.enterprise.ready(function () {
      grecaptcha.enterprise
        .execute(appConfig.recaptcha.web_key, { action })
        .then(function (token: number) {
          resolve(token);
        })
        .catch(reject);
    });
  });
