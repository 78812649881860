import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  container: css`
    height: 114px;
    background-color: #ececf0;
    border-radius: 4px;
    border: 1px solid #c6c6ca;
    display: flex;
    flex-direction: row;
    padding: 16px;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    flex: 1;
  `,
  iconContainer: css`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
  `,
  fundraiserDate: css`
    color: #191919;
    font-size: 14px;
    margin-bottom: 2px;
  `,
  fundraiserTitle: css<{ isNativeApp: boolean }>`
    font-family: Rubik-SemiBold;
    color: #191919;
    font-size: 18px;
    padding-bottom: ${({ isNativeApp }) => (isNativeApp ? '10px' : '20px')};
  `
};
