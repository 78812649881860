import { css } from 'styled-components';
import { baseStyles } from './RHFSelect.base.styles';

export const styles = {
  ...baseStyles,
  select: css<{ disabled?: boolean }>`
    ${baseStyles.select};
    padding: 12px 16px;
    ${({ disabled }) => disabled && 'cursor: not-allowed'};
  `
};
