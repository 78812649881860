import { createContext, useContext } from 'react';
import { SegmentContextType } from './types';

const defaultContextValue: SegmentContextType = {
  track: async (_eventName, _payload) => false,
  identify: async _traits => false,
  pageView: async (_name, _options) => false,
  reset: async () => false
};

export const SegmentContext = createContext<SegmentContextType>(defaultContextValue);

export const useSegmentTracking = () => {
  const context = useContext(SegmentContext);
  return context;
};
