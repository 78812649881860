import { css } from 'styled-components';
export const styles = {
  wrapper: css<{ height: number; width: number }>`
    display: flex;
    background: #f4f4f4;
    border-radius: 4px;
    height: ${({ height = 0 }) => `${height}px`};
    width: 320px;
    padding: 0 16px 16px 16px;
    flex-direction: column;
    z-index: 3;
  `,
  container: css`
    width: 320px;
  `,
  dialog: css<{ height: number; isNativeApp: boolean; units: string }>`
    position: relative;
    margin: auto;
    width: 320px;
    min-height: 200px;
    display: flex;
    height: ${({ height, isNativeApp, units }) => (isNativeApp ? `${height}${units}` : 'auto')};
    flex-direction: column;
    background: #f4f4f4;
    border-radius: 4px;
    //top: 50%;
    overflow: hidden;
  `,
  tabsBar: css`
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  `,
  leftItem: css`
    padding: 14px 15px 12px;
    &:hover {
      cursor: pointer;
      background-color: #e8e8e8;
    }
  `,
  rightItem: css`
    padding: 14px 15px 12px;
    margin-left: auto;
    &:hover {
      cursor: pointer;
      background-color: #e8e8e8;
    }
  `,
  tabs: css<{ tabNumber: number; isNativeApp: boolean }>`
    flex-direction: row;
    flex: ${({ isNativeApp }) => (isNativeApp ? 1 : 'none')};
    width: 320px;
  `
};
