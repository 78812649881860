import React from 'react';
import styled from 'styled-components';
import { buttonSizes, buttonStyles } from './StyledButton.styles';

import type { StyledButtonProps } from './StyledButton.interfaces';

export const StyledButton = ({
  title,
  onPress,
  buttonType,
  disabled,
  size,
  width,
  height,
  padding,
  backgroundColor,
  testID,
  children
}: StyledButtonProps) => {
  return (
    <ButtonWrapper
      onClick={onPress}
      disabled={disabled}
      //@ts-ignore
      buttonType={buttonType}
      size={size}
      width={width}
      height={height}
      padding={padding}
      backgroundColor={backgroundColor}
      data-testid={testID}
    >
      {title ? title : children}
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.button.attrs({ tabIndex: 0 })(
  ({ buttonType, size, width, height, backgroundColor, padding }: StyledButtonProps) => {
    const styles = {
      ...buttonStyles.base,
      ...buttonStyles[buttonType || 'primary'],
      ...buttonSizes[size || 'md'],
      ...(width ? { width } : {}),
      ...(height ? { height } : {}),
      ...(padding ? { padding } : {})
    };
    if (backgroundColor) {
      styles.backgroundColor = backgroundColor;
    }
    return styles;
  }
);
