import React, { useEffect } from 'react';
import { GFImage, StyledButton, StyledText } from '@gf/cross-platform-lib/components';
import styled from '@gf/cross-platform-lib/styled-components';
import { useModal } from '@gf/cross-platform-lib/providers';
import { displayNameForProduct, getProductTypeMeta, ProductType } from '@gf/cross-platform-lib/interfaces';
import { styles } from './RedemptionModal.styles';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { useAppReviewContext } from '../../../NativeAppReviewProvider';
import { testProperties } from '@gf/cross-platform-lib/utils';

const ModalWrapper = styled.TouchableOpacity`
  ${styles.modalWrapper}
`;

const Title = styled.Text`
  ${styles.title}
`;

const Description = styled.Text`
  ${styles.description}
`;

const SuccessMessage = styled.Text`
  ${styles.successMessage}
`;

const ButtonWrapper = styled.View`
  ${styles.buttonWrapper}
`;

const IconWrapper = styled.View`
  ${styles.iconWrapper}
`;

interface RedemptionModalProps {
  scan?: boolean;
  numberOfTickets: number;
  productType: ProductType;
  eventName: string;
  didSucceed: boolean;
  onCancel: Function;
  onClick: Function;
}

export const RedemptionModal = () => {
  const { promptAppReview } = useAppReviewContext();
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const {
    scan,
    numberOfTickets,
    productType,
    eventName,
    didSucceed,
    onCancel,
    onClick = () => {}
  } = (modalProps as RedemptionModalProps) || {};
  const { isMobile } = useMediaQuery();

  let title = `${numberOfTickets} ${displayNameForProduct(productType, numberOfTickets)} used!`;
  if (!didSucceed) {
    title = `Using ${numberOfTickets} ${displayNameForProduct(productType, numberOfTickets)}`;
  }

  const desc = didSucceed
    ? `${eventName}`
    : 'It seems like it is taking longer than normal to use your tickets, but should finish up.';

  const onClosed = () => {
    onCancel();
    hideModal();
  };

  useEffect(() => {
    if (didSucceed) promptAppReview('redemption-modal', 2000);
  }, []);

  return (
    <ModalWrapper
      activeOpacity={1}
      onPress={() => {
        if (didSucceed) {
          hideModal();
          onClick();
        }
      }}
    >
      <IconWrapper>
        {!didSucceed ? (
          getProductTypeMeta(productType, 'isTicketLike') ? (
            <GFImage name={'ticket-validation--waiting.png'} width={112} height={112} />
          ) : (
            <GFImage name={'pass-validation--waiting.png'} width={112} height={112} />
          )
        ) : getProductTypeMeta(productType, 'isTicketLike') ? (
          <GFImage name={'ticket-validation--success.png'} width={112} height={112} />
        ) : (
          <GFImage name={'pass-validation--success.png'} width={112} height={112} />
        )}
      </IconWrapper>
      <Title {...testProperties('ticket-status-notification')} numberOfLines={1}>
        {title}
      </Title>
      <Description numberOfLines={2}>{desc}</Description>
      {!didSucceed ? (
        <ButtonWrapper isMobile={isMobile}>
          <StyledButton
            buttonType='ghost--border'
            height='40px'
            width='320px'
            onPress={onClosed}
            accessibilityLabel='Cancel'
          >
            <StyledText color='black' textAlign='center'>
              Cancel
            </StyledText>
          </StyledButton>
        </ButtonWrapper>
      ) : (
        <>
          <SuccessMessage>Have fun and enjoy the event!</SuccessMessage>
          {scan && <SuccessMessage>Taking you back to scan ....</SuccessMessage>}
        </>
      )}
    </ModalWrapper>
  );
};
