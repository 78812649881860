import { GetCurrentApplicationConfiguration } from './config';

type CurrencyFormatterConfiguration = {
  name?: string;
};

export const useCurrencyFormatter = (config: CurrencyFormatterConfiguration = {}) => {
  const appConfig = GetCurrentApplicationConfiguration();

  const formatSettings = {
    ...appConfig.settings.currencyFormatting[config.name ?? ''],
    locale: 'en-US',
    currency: 'USD',
    style: 'currency'
  };

  const formatter = new Intl.NumberFormat(formatSettings.locale, {
    style: formatSettings.style as 'currency',
    currency: formatSettings.currency
  });

  const formatAndDropTrailingZeros = (value: number) => {
    let formattedValue = formatter.format(value);
    if (Number.isInteger(value)) {
      formattedValue = formattedValue.replace(/\.00$/, '');
    }

    return formattedValue;
  };

  return {
    format: formatter.format,
    formatAndDropTrailingZeros: formatAndDropTrailingZeros
  };
};
