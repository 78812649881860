import { isWebClientSideRenderer } from './isWebClientSideRenderer';

export function setCookie(name: string, value: any, days: number) {
  try {
    if (isWebClientSideRenderer()) {
      let expires = '';
      if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toUTCString();
      }
      document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }
  } catch (error: any) {
    return;
  }
}
export function getCookie(name: string) {
  try {
    if (isWebClientSideRenderer()) {
      const nameEQ = name + '=';
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    }
  } catch (e: any) {
    return null;
  }
}
