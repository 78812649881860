import React, { useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { LookupTTL, StorableType } from '@gf/cross-platform-lib/utils/config/config';

interface AcquisitionProviderProps {
  children: React.ReactNode;
}

const asyncStoragePersister = createAsyncStoragePersister({ storage: AsyncStorage });

export const AcquisitionProvider = ({ children }: AcquisitionProviderProps) => {
  const [queryClient, setQueryClient] = React.useState<any>();
  React.useEffect(() => {
    import('@tanstack/react-query').then(mod => {
      const { QueryClient } = mod;
      setQueryClient(
        new QueryClient({
          defaultOptions: {
            queries: {
              cacheTime: Infinity,
              staleTime: 1000 * 60 // 1 minute
            }
          }
        })
      );
    });
  }, []);

  useEffect(() => {
    if (queryClient) {
      queryClient.setQueryDefaults([StorableType.Event_List], { cacheTime: LookupTTL[StorableType.Event] });
      queryClient.setQueryDefaults([StorableType.Season_List], { cacheTime: LookupTTL[StorableType.Event] });
      queryClient.setQueryDefaults([StorableType.Event_Category], { cacheTime: LookupTTL[StorableType.Event] });
      queryClient.setQueryDefaults([StorableType.Season_Category], { cacheTime: LookupTTL[StorableType.Event] });
    }
  }, [queryClient]);

  return (
    <>
      {queryClient && (
        <PersistQueryClientProvider persistOptions={{ persister: asyncStoragePersister }} client={queryClient}>
          {children}
        </PersistQueryClientProvider>
      )}
    </>
  );
};
