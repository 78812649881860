// The color tokens based on design:
// https://app.abstract.com/projects/a919c830-500d-4582-968b-42dc825669fa/branches/51ccd0f5-2f2b-4ffb-8280-fef560130211/commits/527dbbedf9be3d3790481c3818a56e4c1501618e/files/a4fd13c5-6fa9-4eb1-a4dc-9efed77c71e0/layers/2A1ED310-EA4E-4519-A15C-DBA259244C88?mode=design

export const transparent = '#ffffffff';
export const transparentString = 'transparent';
export const black = '#000000';
export const modernBlack0 = '#323232';
export const modernBlack1 = '#5b5b5b';
export const modernBlack2 = '#d3d3d3'; // Need confirm from Designer
export const modernBlackNeg1 = '#282828'; // Modern Black Negative

export const black100 = black;
export const blackHover = '#212121';

export const white = '#ffffff';
export const white0 = white;
export const whiteHover = '#e8e8e8';

export const red10 = '#fff1f1';
export const red20 = '#ffd7d9';
export const red30 = '#ffb3b8';
export const red40 = '#ff8389';
export const red50 = '#fa4d56';
export const red60 = '#da1e28';
export const red70 = '#a2191f';
export const red80 = '#750e13';
export const red90 = '#520408';
export const red100 = '#2d0709';
export const red = {
  10: red10,
  20: red20,
  30: red30,
  40: red40,
  50: red50,
  60: red60,
  70: red70,
  80: red80,
  90: red90,
  100: red100
};

export const red100Hover = '#540d11';
export const red90Hover = '#66050a';
export const red80Hover = '#921118';
export const red70Hover = '#c21e25';
export const red60Hover = '#b81922';
export const red50Hover = '#ee0713';
export const red40Hover = '#ff6168';
export const red30Hover = '#ff99a0';
export const red20Hover = '#ffc2c5';
export const red10Hover = '#ffe0e0';
export const redHover = {
  100: red100Hover,
  90: red90Hover,
  80: red80Hover,
  70: red70Hover,
  60: red60Hover,
  50: red50Hover,
  40: red40Hover,
  30: red30Hover,
  20: red20Hover,
  10: red10Hover
};

export const blue10 = '#edf5ff';
export const blue20 = '#d0e2ff';
export const blue30 = '#a6c8ff';
export const blue40 = '#78a9ff';
export const blue50 = '#4589ff';
export const blue60 = '#0f62fe';
export const blue70 = '#0043ce';
export const blue80 = '#002d9c';
export const blue90 = '#001d6c';
export const blue100 = '#001141';
export const blue = {
  10: blue10,
  20: blue20,
  30: blue30,
  40: blue40,
  50: blue50,
  60: blue60,
  70: blue70,
  80: blue80,
  90: blue90,
  100: blue100
};

export const blue100Hover = '#001f75';
export const blue90Hover = '#00258a';
export const blue80Hover = '#0039c7';
export const blue70Hover = '#0053ff';
export const blue60Hover = '#0050e6';
export const blue50Hover = '#1f70ff';
export const blue40Hover = '#5c97ff';
export const blue30Hover = '#8ab6ff';
export const blue20Hover = '#b8d3ff';
export const blue10Hover = '#dbebff';
export const blueHover = {
  100: blue100Hover,
  90: blue90Hover,
  80: blue80Hover,
  70: blue70Hover,
  60: blue60Hover,
  50: blue50Hover,
  40: blue40Hover,
  30: blue30Hover,
  20: blue20Hover,
  10: blue10Hover
};

export const gray10 = '#f4f4f4';
export const gray20 = '#e0e0e0';
export const gray30 = '#c6c6c6';
export const gray40 = '#a8a8a8';
export const gray50 = '#8d8d8d';
export const gray60 = '#6f6f6f';
export const gray70 = '#525252';
export const gray80 = '#393939';
export const gray90 = '#262626';
export const gray100 = '#161616';
export const gray = {
  10: gray10,
  20: gray20,
  30: gray30,
  40: gray40,
  50: gray50,
  60: gray60,
  70: gray70,
  80: gray80,
  90: gray90,
  100: gray100
};

export const gray10Hover = '#e8e8e8';
export const gray20Hover = '#d1d1d1';
export const gray30Hover = '#b5b5b5';
export const gray40Hover = '#999999';
export const gray50Hover = '#7a7a7a';
export const gray60Hover = '#5e5e5e';
export const gray70Hover = '#636363';
export const gray80Hover = '#474747';
export const gray90Hover = '#333333';
export const gray100Hover = '#292929';
export const grayHover = {
  10: gray10Hover,
  20: gray20Hover,
  30: gray30Hover,
  40: gray40Hover,
  50: gray50Hover,
  60: gray60Hover,
  70: gray70Hover,
  80: gray80Hover,
  90: gray90Hover,
  100: gray100Hover
};

export const green10 = '#198038';

// Background
export type BackgroundColorToken =
  | 'background'
  | 'background-active'
  | 'background-hover'
  | 'background-selected'
  | 'background-selected-hover'
  | 'background-branch'
  | 'background-inverse'
  | 'background-inverse-hover'
  | 'background-info'; // Private token

export const backgroundColors = {
  background: white,
  'background-active': gray30,
  'background-hover': gray10Hover,
  'background-selected': gray20,
  'background-selected-hover': gray20Hover,
  'background-branch': blue60,
  'background-inverse': modernBlack0,
  'background-inverse-hover': modernBlack1,
  'background-info': modernBlack2
};

export const backgroundColor = (name: BackgroundColorToken): string => {
  if (!backgroundColors[name]) {
    throw new Error(
      `Unable to find background color: \`${name}\`. Expected one of: [${Object.keys(backgroundColors).join(', ')}]`
    );
  }
  return backgroundColors[name];
};
// Layer
export type LayerColorToken =
  | 'layer-01'
  | 'layer-active-01'
  | 'layer-hover-01'
  | 'layer-selected-01'
  | 'layer-selected-hover-01'
  | 'layer-disabled-01'
  | 'layer-02'
  | 'layer-active-02'
  | 'layer-hover-02'
  | 'layer-selected-02'
  | 'layer-selected-hover-02'
  | 'layer-disabled-02'
  | 'layer-03'
  | 'layer-active-03'
  | 'layer-hover-03'
  | 'layer-selected-03'
  | 'layer-selected-hover-03'
  | 'layer-disabled-03'
  | 'layer-selected-inverse'
  | 'layer-selected-disabled';

// layers - (Design is not updated)
export const layerColors = {
  'layer-01': gray10,
  'layer-active-01': gray30,
  'layer-hover-01': gray10Hover,
  'layer-selected-01': gray20,
  'layer-selected-hover-01': gray20Hover,
  'layer-disabled-01': gray10,
  'layer-02': white,
  'layer-active-02': gray30,
  'layer-hover-02': gray10Hover,
  'layer-selected-02': gray20,
  'layer-selected-hover-02': gray20Hover,
  'layer-disabled-02': white,
  'layer-03': gray10,
  'layer-active-03': gray30,
  'layer-hover-03': gray10Hover,
  'layer-selected-03': gray20,
  'layer-selected-hover-03': gray20Hover,
  'layer-disabled-03': gray10,
  'layer-selected-inverse': gray100,
  'layer-selected-disabled': gray50
};

// LayerAccent
export type LayerAccentColorToken =
  | 'layer-accent-01'
  | 'layer-accent-active-01'
  | 'layer-accent-hover-01'
  | 'layer-accent-02'
  | 'layer-accent-active-02'
  | 'layer-accent-hover-02'
  | 'layer-accent-03'
  | 'layer-accent-active-03'
  | 'layer-accent-hover-03';

// layerAccents - (Design is not updated)
export const layerAccentColors = {
  'layer-accent-01': gray20,
  'layer-accent-active-01': gray40,
  'layer-accent-hover-01': gray20Hover,
  'layer-accent-02': gray20,
  'layer-accent-active-02': gray40,
  'layer-accent-hover-02': gray20Hover,
  'layer-accent-03': gray20,
  'layer-accent-active-03': gray40,
  'layer-accent-hover-03': gray20Hover
};

// Field
export type FieldColorToken =
  | 'field-01'
  | 'field-hover-01'
  | 'field-disabled-01'
  | 'field-02'
  | 'field-hover-02'
  | 'field-disabled-02'
  | 'field-03'
  | 'field-hover-03'
  | 'field-disabled-03';

// fields
export const fieldColors = {
  'field-01': gray10,
  'field-hover-01': gray10Hover,
  'field-disabled-01': gray10,
  'field-02': white,
  'field-hover-02': whiteHover,
  'field-disabled-02': white,
  'field-03': gray10,
  'field-hover-03': gray10Hover,
  'field-disabled-03': gray10
};

// Borders
export type BorderColorToken =
  | 'border-subtle-00'
  | 'border-subtle-01'
  | 'border-subtle-selected-01'
  | 'border-subtle-02'
  | 'border-subtle-selected-02'
  | 'border-subtle-03'
  | 'border-subtle-selected-03'
  | 'border-strong-01'
  | 'border-strong-02'
  | 'border-strong-03'
  | 'border-inverse'
  | 'border-interactive'
  | 'border-disabled';
// fields
export const borderColors = {
  'border-subtle-00': gray20,
  'border-subtle-01': gray20,
  'border-subtle-selected-01': gray30,
  'border-subtle-02': gray20,
  'border-subtle-selected-02': gray30,
  'border-subtle-03': gray20,
  'border-subtle-selected-03': gray30,
  'border-strong-01': gray50,
  'border-strong-02': gray50,
  'border-strong-03': gray50,
  'border-inverse': blue100,
  'border-interactive': blue60,
  'border-disabled': gray10
};

export type TextColorTokenType =
  | 'text-primary'
  | 'text-secondary'
  | 'text-placeholder'
  | 'text-on-color'
  | 'text-on-color-disabled'
  | 'text-helper'
  | 'text-error'
  | 'text-inverse'
  | 'text-disabled';

export const textColors = {
  'text-primary': gray100,
  'text-secondary': gray70,
  'text-placeholder': gray40,
  'text-on-color': white,
  'text-on-color-disabled': gray50,
  'text-helper': gray60,
  'text-error': red60,
  'text-inverse': white,
  'text-disabled': gray100 + '40' // 25% Opacity
};
/**
 * Get text color based on token
 * @param { token } name - family name
 * @returns { string }
 */
export function textColor(token: TextColorTokenType): string {
  if (!textColors[token]) {
    throw new Error(
      `Unable to find text color token: \`${token}\`. Expected one of: ` + `[${Object.keys(textColors).join(', ')}]`
    );
  }

  return textColors[token];
}

export const linkColors = {
  'link-primary': blue60,
  'link-primary-hover': blue70,
  'link-secondary': blue70,
  'link-inverse': blue40,
  'link-visited': blue60
};

export const iconColors = {
  'icon-primary': gray100,
  'icon-secondary': gray70,
  'icon-on-color': white,
  'icon-on-color-disabled': gray50,
  'icon-inverse': white,
  'icon-disabled': gray100 + '40' // 25% Opacity
};

export const buttonColors = {
  'button-primary': modernBlack0,
  'button-primary-hover': modernBlack1,
  'button-primary-active': modernBlackNeg1,
  'button-secondary': gray80,
  'button-secondary-hover': gray80Hover,
  'button-secondary-active': gray60,
  'button-tertiary': blue60,
  'button-tertiary-hover': blue60Hover,
  'button-tertiary-active': blue80,
  'button-danger-primary': red60,
  'button-danger-secondary': red60,
  'button-danger-hover': red60Hover,
  'button-danger-active': red80,
  'button-separator': gray20,
  'button-disabled': gray30
};

export const colors = {
  transparent,
  black: {
    100: black
  },
  modernBlack: {
    0: modernBlack0,
    1: modernBlack1,
    '-1': modernBlackNeg1
  },
  blue,
  gray,
  red,
  white: {
    0: white
  },
  backgroundColors,
  layerColors,
  layerAccentColors,
  fieldColors,
  textColors,
  buttonColors,
  linkColors,
  iconColors
};

export const hoverColors = {
  whiteHover,
  blackHover,
  blueHover,
  grayHover,
  redHover
};
