// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const Favorite = (props: SvgProps) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    viewBox='0 0 32 32'
    style={{
      enableBackground: 'new 0 0 32 32'
    }}
    xmlSpace='preserve'
    {...props}
  >
    <Path d='M22.45 6a5.47 5.47 0 0 1 3.91 1.64 5.7 5.7 0 0 1 0 8L16 26.13 5.64 15.64a5.7 5.7 0 0 1 0-8 5.48 5.48 0 0 1 7.82 0l2.54 2.6 2.53-2.58A5.44 5.44 0 0 1 22.45 6m0-2a7.47 7.47 0 0 0-5.34 2.24L16 7.36l-1.11-1.12a7.49 7.49 0 0 0-10.68 0 7.72 7.72 0 0 0 0 10.82L16 29l11.79-11.94a7.72 7.72 0 0 0 0-10.82A7.49 7.49 0 0 0 22.45 4Z' />
    <Path
      style={{
        fill: 'none'
      }}
      d='M0 0h32v32H0z'
    />
  </Svg>
);
