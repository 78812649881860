import React from 'react';
import { LinkProps } from './LinkProps';

export const Link = ({ href, children, style, shouldOpenExternal = false, title }: LinkProps) => {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <a
      href={href}
      aria-label={title}
      target={shouldOpenExternal ? '_blank' : undefined}
      style={style}
      rel='noreferrer'
      tabIndex={0}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

export const ExternalLink = (props: LinkProps) => {
  return <Link {...props} shouldOpenExternal={true} />;
};
