import React, { useEffect, useState } from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './RedemptionTooEarlyModal.styles';
import { useModal } from '../../ModalProvider';
import { Carbonicons, StyledText, StyledButton, RedeemOnDesktopComponent } from '@gf/cross-platform-lib/components';
import { Modal } from '../../Modal/Modal';
import { useNavigate } from '@gf/cross-platform-lib/hooks';

const MobileWrapper = styled.View`
  ${styles.mobileWrapper};
`;

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const Title = styled.Text`
  ${styles.title};
`;

const Description = styled.Text`
  ${styles.description};
`;

const MobileTitle = styled.Text`
  ${styles.mobileTitle};
`;

const MobileDescription = styled.Text`
  ${styles.mobileDescription};
`;

const ClosedIconWrapper = styled.TouchableOpacity`
  ${styles.closedIconWrapper};
`;

const OkButtonWrapper = styled.TouchableOpacity`
  ${styles.okButtonWrapper};
`;

const ButtonText = styled.Text`
  ${styles.buttonText};
`;

const Overlay = styled.TouchableOpacity`
  ${styles.overlay};
`;

export const RedemptionTooEarlyModal = () => {
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const [showEarlyModal, setShowEarlyModal] = useState(true);
  const {
    isDesktop,
    scan = false,
    description = '',
    onClosed = () => {
      hideModal();
    }
  } = modalProps || {};

  useEffect(() => {
    isDesktop && setShowEarlyModal(false);
  }, [isDesktop]);

  const onHandleContinueButton = () => {
    setShowEarlyModal(true);
  };
  const nav = useNavigate();
  const onClosedEarlyModal = () => {
    if (scan) {
      nav('/scan?scanFinished=true&redemption=early');
    }
    setShowEarlyModal(false);
    !isDesktop && hideModal();
  };

  return (
    <>
      {isDesktop ? (
        <>
          {showEarlyModal && (
            <Modal transparent visible>
              <Overlay activeOpacity={1} onPress={onClosedEarlyModal} />
              <ModalWrapper>
                <ClosedIconWrapper onPress={onClosedEarlyModal}>
                  <Carbonicons name='close' color='black' size={20}></Carbonicons>
                </ClosedIconWrapper>
                <Title>It’s not quite time yet!</Title>
                <Description>{description}</Description>
                <OkButtonWrapper>
                  <StyledButton height='48px' onPress={onClosedEarlyModal} accessibilityLabel='OK'>
                    <StyledText color='white' textAlign='center'>
                      OK
                    </StyledText>
                  </StyledButton>
                </OkButtonWrapper>
              </ModalWrapper>
            </Modal>
          )}
          <RedeemOnDesktopComponent onClosed={onClosed} onContinue={onHandleContinueButton} />
        </>
      ) : (
        <MobileWrapper>
          <MobileTitle>It’s not quite time yet!</MobileTitle>
          <MobileDescription>{description}</MobileDescription>
          <OkButtonWrapper onPress={onClosedEarlyModal}>
            <ButtonText>OK</ButtonText>
          </OkButtonWrapper>
        </MobileWrapper>
      )}
    </>
  );
};
