import React from 'react';
import { AdditionalFieldTypes } from '@gf/cross-platform-lib/interfaces';
import { Controller, Control, FieldValues, RegisterOptions, UseFormSetValue } from 'react-hook-form';
import styled from '@gf/cross-platform-lib/styled-components';
import { formatUSPhoneNumber, formatNumber } from '@gf/cross-platform-lib/utils';
import { styles } from './RHFInput.styles';
import { US_PHONE_NUMBER_PATTERN, EMAIL_FORMAT_PATTERN, ZIP_CODE_PATTERN } from '@gf/cross-platform-lib/constants';
import { Checkbox } from '@gf/cross-platform-lib/components';
import { useReturnKeyType } from '@gf/cross-platform-lib/hooks';

const InputWrapper = styled.View`
  ${styles.inputWrapper}
`;

const Input = styled.TextInput`
  ${styles.input};
`;

const CheckboxWrapper = styled.View`
  ${styles.checkboxWrapper}
`;

const Label = styled.Text`
  ${styles.label}
`;

const keyboardTypeMaps = {
  tel: 'phone-pad',
  number: 'number-pad',
  email: 'email-address'
};

const MIN_LENGTH = 2;

interface RHFInputProps extends React.HTMLProps<HTMLInputElement> {
  fieldType: AdditionalFieldTypes;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  id?: string;
  rules?: RegisterOptions;
  required?: boolean;
  label?: string;
  options?: string[];
}

export const RHFInput = ({
  type,
  control,
  name,
  id,
  rules,
  required,
  label,
  disabled,
  fieldType,
  setValue
}: RHFInputProps) => {
  const returnKeyType = useReturnKeyType();

  const updateValue = (e: any, validateValue?: Function) => {
    const v = e.target.value;
    if (typeof v === 'string') {
      if (validateValue) {
        if (validateValue(v)) {
          setValue(name, v.trim());
        }
      } else setValue(name, v.trim());
    }
  };

  const ownedRules = (): RegisterOptions => {
    const defaultOptions: RegisterOptions = {
      required: {
        value: required || false,
        message: type === 'checkbox' ? `This field is required to be checked` : 'Please enter value'
      },
      onChange: (e: any) => updateValue(e, (v: string) => v.length < MIN_LENGTH),
      onBlur: updateValue
    };
    if (type === 'text' || type === 'terms') {
      Object.assign(defaultOptions, {
        minLength: {
          value: MIN_LENGTH,
          message: `Must be at least ${MIN_LENGTH} characters`
        }
      });
    }
    if (type === 'email') {
      Object.assign(defaultOptions, {
        pattern: {
          value: EMAIL_FORMAT_PATTERN,
          message: 'Please enter a valid email'
        }
      } as RegisterOptions);
    }
    if (type === 'tel') {
      Object.assign(defaultOptions, {
        pattern: {
          value: US_PHONE_NUMBER_PATTERN,
          message: 'Enter your 10-digit phone number'
        },
        onChange: (e: any) => {
          setValue(name, formatUSPhoneNumber(e.target.value));
        }
      });
    }
    if (type === 'number') {
      const isZipCode = fieldType ? fieldType === 'zip-code' : true;
      const zipCodePattern = {
        value: ZIP_CODE_PATTERN,
        message: 'Enter your 5-digit zip code'
      };

      Object.assign(defaultOptions, {
        pattern: isZipCode ? zipCodePattern : {},
        onChange: (e: any) => {
          setValue(name, formatNumber(e.target.value, isZipCode));
        }
      });
    }
    return defaultOptions;
  };

  const getKeyboardType = (type: string | undefined) => {
    //@ts-ignore
    if (type) return keyboardTypeMaps[type] || 'default';
    return 'default';
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...ownedRules(),
        ...rules
      }}
      render={({ field: { onChange, onBlur, value, ref } }) =>
        type === 'checkbox' ? (
          <CheckboxWrapper>
            <Checkbox
              onValueChange={onChange}
              value={value}
              color='#323232'
              label={<Label style={{ marginLeft: 8 }}>{`${label}${required ? ' *' : ''}`}</Label>}
            />
            {required && value === '' && (
              <Label style={{ marginTop: 4 }}>(*) This field is required to be checked</Label>
            )}
          </CheckboxWrapper>
        ) : (
          <InputWrapper>
            <Input
              type={type}
              disabled={disabled}
              maxLength={type === 'tel' ? 14 : 255}
              id={id}
              onBlur={onBlur}
              onChangeText={onChange}
              value={value}
              ref={ref}
              editable={!disabled}
              selectTextOnFocus={!disabled}
              keyboardType={getKeyboardType(type)}
              returnKeyType={returnKeyType}
            />
          </InputWrapper>
        )
      }
    />
  );
};
