import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  wrapper: css<{ fillColor: string }>`
    position: relative;
    margin: auto;
    width: 320px;ß
    min-height: 200px;
    display: flex;
    flex-direction: column;
    background: ${({ fillColor }) => fillColor};
    border-radius: 4px;
    overflow: hidden;
  `,
  container: css`
    flex-direction: column;
    padding: 0 16px;
    margin-top: 8px;
  `,
  modalNavigationBar: css`
    height: 52px;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  `,
  leftItem: css`
    padding: 14px 15px 12px;
  `,
  rightItem: css`
    height: 52px;
    width: 52px;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    color: #323232;
  `,
  modalContent: css`
    margin: 0 -16px;
  `,
  title: css``,
  subTitle: css`
    margin-top: 4px;
  `,
  modalFooter: css`
    flex-direction: row;
    margin-top: auto;
    padding-vertical: 24px;
  `
};
