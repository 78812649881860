import pluralize from 'pluralize';

export enum ProductType {
  TICKET = 'TICKET',
  MOBILEPASS = 'MOBILEPASS',
  SEASON = 'SEASON',
  DONATION = 'DONATION',
  DONATION_ONGOING = 'DONATION_ONGOING',
  ITEM = 'ITEM',
  DEFAULT = 'DEFAULT'
}

export const productTypeFromString = (typeString: string = '') =>
  [`${ProductType.TICKET}`, `${ProductType.MOBILEPASS}`, `${ProductType.SEASON}`, `${ProductType.ITEM}`].includes(
    `${typeString}`.toUpperCase()
  )
    ? (typeString as ProductType)
    : ProductType.DEFAULT;

interface ProductTypeMeta {
  itemName: string;
  displayName: string;
  urlName: string;
  isTicketLike: boolean;
}

type ProductTypeInfo = { [n: string]: ProductTypeMeta };

// Various values for each product type. Defaults to ProductType.ITEM for any unknown product types
const PRODUCT_TYPE_INFO: ProductTypeInfo = {
  [ProductType.TICKET]: {
    itemName: ProductType.TICKET,
    displayName: ProductType.TICKET.toLocaleLowerCase(),
    urlName: 'tickets',
    isTicketLike: true
  },
  [ProductType.DONATION]: {
    itemName: ProductType.DONATION,
    displayName: ProductType.DONATION.toLocaleLowerCase(),
    urlName: 'donations',
    isTicketLike: true
  },
  [ProductType.MOBILEPASS]: {
    itemName: ProductType.MOBILEPASS,
    displayName: 'pass',
    urlName: 'mobile-pass',
    isTicketLike: false
  },
  [ProductType.SEASON]: {
    itemName: ProductType.SEASON,
    displayName: ProductType.SEASON.toLocaleLowerCase(),
    urlName: ProductType.SEASON.toLocaleLowerCase(),
    isTicketLike: true
  },
  [ProductType.ITEM]: {
    itemName: ProductType.ITEM,
    displayName: ProductType.ITEM.toLocaleLowerCase(),
    urlName: 'tickets',
    isTicketLike: true
  }
};

type ProductTypeMetaKey = keyof ProductTypeMeta;

const DEFAULT_PRODUCT_TYPE = ProductType.ITEM;
const DEFAULT_PRODUCT_TYPE_INFO = PRODUCT_TYPE_INFO[DEFAULT_PRODUCT_TYPE];
PRODUCT_TYPE_INFO[ProductType.DEFAULT] = DEFAULT_PRODUCT_TYPE_INFO;

export const getProductTypeMeta = (
  pt: ProductType = ProductType.DEFAULT,
  fieldName: ProductTypeMetaKey
): string | boolean => {
  let meta = PRODUCT_TYPE_INFO[pt];
  if (!meta) {
    meta = PRODUCT_TYPE_INFO[DEFAULT_PRODUCT_TYPE];
  }

  return meta[fieldName];
};

export const PLURAL = 0;
export const displayNameForProduct = (pt: ProductType, count: number = 1) =>
  pluralize(getProductTypeMeta(pt, 'displayName').toString(), count);

export const quantityWithProductName = (pt: ProductType, count: number) =>
  `${count !== 1 ? `${count} ` : ''}${displayNameForProduct(pt, count)}`;
