import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import styled from 'styled-components';
import { DrawerProps, getLeftDrawerSpringValues } from '../drawerUtil';
import { styles } from '../base.styles';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { Dimensions } from 'react-native';
import { APP_FOOTER_SIZE, APP_HEADER_SIZE } from '../drawerConstants';

const windowHeight = Dimensions.get('window').height;

export const LeftDrawer = ({ content, drawerOpen, drawerSize }: DrawerProps) => {
  const { isMobile, isNativeApp } = useMediaQuery();

  const springStyle = useSpring({
    ...getLeftDrawerSpringValues(drawerOpen)
  });

  return (
    <LeftDrawerContainer
      isMobile={isMobile.toString()}
      isNative={isNativeApp.toString()}
      drawerSize={drawerSize?.toString()}
      maxHeight={
        isMobile
          ? (windowHeight - APP_HEADER_SIZE - APP_FOOTER_SIZE).toString() + 'px'
          : windowHeight - APP_HEADER_SIZE + 'px'
      }
      style={{
        ...springStyle
      }}
    >
      {content}
    </LeftDrawerContainer>
  );
};

const LeftDrawerContainer = styled(animated.div)`
  ${styles.leftDrawerContainer}
`;

export default LeftDrawer;
