import React, { useState } from 'react';
import { styles } from './RemoveTicketModal.styles';
import isEmpty from 'lodash/isEmpty';
import styled from '@gf/cross-platform-lib/styled-components';
import { Checkbox, Carbonicons } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { AdditionalForms } from '@gf/cross-platform-lib/interfaces';
import { useModal } from '@gf/cross-platform-lib/providers';
import { getFieldLabel } from '@gf/cross-platform-lib/utils';

const Wrapper = styled.View`
  ${styles.wrapper};
`;

const TitleSection = styled.View`
  ${styles.titleSection};
`;
const Title = styled.Text`
  ${styles.title};
`;

const SubTitleView = styled.View`
  width: 75%;
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

const AdditionalForm = styled.ScrollView`
  ${styles.additionalForm};
`;

const AdditionalFormTitle = styled.Text`
  ${styles.additionalFormTitle};
`;

const FieldContainer = styled.View`
  ${styles.fieldContainer};
`;

const FieldName = styled.Text`
  ${styles.fieldName};
`;

const FieldValue = styled.Text`
  ${styles.fieldValue};
`;

const BtnContainer = styled.View`
  ${styles.container};
  ${styles.buttonContainer};
`;

const Button = styled.TouchableOpacity`
  ${styles.button};
`;

const ButtonTitle = styled.Text<{ disabled: boolean; color: string; disabledColor: string }>`
  color: ${({ disabled = false, color = '#ffffff', disabledColor = '#ffffff' }) => (disabled ? disabledColor : color)};
`;

const CloseIconWrapper = styled.TouchableOpacity`
  ${styles.closeIconWrapper}
`;

const mappingObjects = (forms: AdditionalForms) =>
  Object.values(forms).map(({ fields, id }) => ({
    fields: Object.values(fields).map(({ name, value }) => ({
      name,
      value
    })),
    id
  }));

export interface RemoveTicketModalProps {}

export const RemoveTicketModal = ({}: RemoveTicketModalProps) => {
  const { modalState } = useModal();
  const { modalProps } = modalState;
  const { emptyForms = [''], forms = {}, onRemove = () => {}, onCancel = () => {} } = modalProps || {};

  const { isMobile } = useMediaQuery();
  const [removeIds, setRemoveIds] = useState(new Set());

  const mappedForms = !isEmpty(forms) ? mappingObjects(forms) : [];
  const formsHaveValue = mappedForms.filter(({ id }) => !emptyForms.includes(id));

  const removeTicketFormById = (id: string) => {
    const ids = new Set(Array.from(removeIds));
    if (ids.has(id)) {
      ids.delete(id);
    } else {
      ids.add(id);
    }
    setRemoveIds(ids);
  };

  const onPressRemoveButton = () => {
    if (!isEmpty(Array.from(removeIds))) {
      onRemove([...(Array.from(removeIds) as string[]), ...emptyForms]);
    }
    setRemoveIds(new Set());
  };

  const modalHeight = isMobile ? 494 : 470;

  return (
    <Wrapper height={modalHeight}>
      <TitleSection>
        <Title>Are you sure you want to remove tickets?</Title>
        <CloseIconWrapper onPress={onCancel}>
          <Carbonicons name='close' size={22} color='#161616' />
        </CloseIconWrapper>
      </TitleSection>
      <SubTitleView>
        <SubTitle>Please select the tickets you want to remove.</SubTitle>
      </SubTitleView>
      <AdditionalForm>
        {formsHaveValue.map(({ fields, id }, index: number) => (
          <View key={`${id} - ${index}`}>
            <TitleSection>
              <AdditionalFormTitle>
                Additional information ({index + 1} of {mappedForms.length})
              </AdditionalFormTitle>
              <View>
                <Checkbox
                  onValueChange={() => removeTicketFormById(id)}
                  disabled={false}
                  value={(Array.from(removeIds) || []).some(removeId => removeId === id)}
                  color='#323232'
                />
              </View>
            </TitleSection>
            {fields.map(({ name, value }) => (
              <FieldContainer key={name}>
                <FieldName>{getFieldLabel(name)}</FieldName>
                <FieldValue>{value}</FieldValue>
              </FieldContainer>
            ))}
          </View>
        ))}
      </AdditionalForm>
      <BtnContainer>
        <Button bgColor='#f4f4f4' color='#323232' onPress={onCancel} disabled={false}>
          <ButtonTitle color='#323232'>Cancel</ButtonTitle>
        </Button>
        <Button bgColor='#da1e28' onPress={onPressRemoveButton} removeBorder disabled={removeIds.size === 0}>
          <ButtonTitle color='#ffffff'>Remove</ButtonTitle>
        </Button>
      </BtnContainer>
    </Wrapper>
  );
};
