import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  modalWrapper: css`
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  title: css`
    margin-top: 18px;
    color: #323232;
    font-size: 20px;
    font-family: Rubik-SemiBold;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0px;
  `,
  description: css`
    width: 320px;
    margin-top: 9px;
    color: #161616;
    font-size: 14px;
    font-family: Rubik-Regular;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.16px;
  `,
  successMessage: css`
    margin-top: 16px;
    color: #525252;
    font-size: 14px;
    font-family: Rubik-Regular;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.16px;
  `,
  buttonWrapper: css<{ isMobile: boolean }>`
    margin-top: 16px;
    position: absolute;
    bottom: 10%;
  `,
  iconWrapper: css`
    position: relative;
  `,
  iconPlaceholder: css`
    width: 60px;
    height: 60px;
    background-color: grey;
    position: absolute;
    right: 0;
    bottom: -20%;
  `
};
