import React from 'react';
import { InformationFormField, AdditionalFieldTypes } from '@gf/cross-platform-lib/interfaces';
import { RHFInput, RHFSelect, RHFTextArea } from '../RHFAdditionalFormInputs';
import { styles } from './FormField.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { Control, FieldValues, UseFormSetValue } from 'react-hook-form';
import { getFieldLabel, reviewAndBuyPage, testProperties } from '@gf/cross-platform-lib/utils';

type InputType = 'text' | 'textarea' | 'tel' | 'number' | 'checkbox' | 'dropdown';

const getFieldInputType = (fieldType: AdditionalFieldTypes): InputType => {
  if (fieldType === 'STRING') return 'text';
  if (fieldType === 'TEXTBOX') return 'textarea';
  if (fieldType === 'PHONE') return 'tel';
  if (fieldType === 'zip-code') return 'number';
  if (fieldType === 'TEMRS') return 'checkbox';
  return fieldType.toLocaleLowerCase() as InputType;
};

const getWidgetByType = (type: string) => {
  if (type === 'textarea') return RHFTextArea;
  if (type === 'dropdown') return RHFSelect;

  return RHFInput;
};

const FieldWrapper = styled.View`
  ${styles.fieldWrapper}
`;
const WidgetWrapper = styled.View`
  ${styles.widgetWrapper}
`;
const Label = styled.Text`
  ${styles.label}
`;
const ErrorText = styled.Text`
  ${styles.errorText}
`;

interface FormFieldProps {
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  label: string;
  errorMessage: string | undefined;
  field: InformationFormField;
  disabled?: boolean;
}

export const FormField = ({ control, setValue, label, errorMessage, field, disabled }: FormFieldProps) => {
  const type = getFieldInputType(field.fieldType) || 'text';
  const Widget = getWidgetByType(type);
  return (
    <FieldWrapper>
      {label && type !== 'checkbox' ? <Label numberOfLines={5}>{label}</Label> : null}
      <WidgetWrapper style={{ width: '100%' }} {...testProperties(reviewAndBuyPage.addInfoDialog.field(field.name))}>
        <Widget
          type={type}
          control={control}
          setValue={setValue}
          name={field.id.toString()}
          id={field.id.toString()}
          fieldType={field.fieldType}
          label={getFieldLabel(field.name)}
          required={field.required}
          options={field.options}
          disabled={disabled}
        />
      </WidgetWrapper>
      {errorMessage ? <ErrorText numberOfLines={5}>{errorMessage}</ErrorText> : null}
    </FieldWrapper>
  );
};
