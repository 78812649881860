// 'Cache' is already used elsewhere. This is our Store.

import AsyncStorage, { AsyncStorageStatic } from '@react-native-async-storage/async-storage';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

/// BEGIN: not exported
// These types are not exported - there is no need for
// any other part of the system to know how the cache
// does its work.

// We need to keep timestamps for when each item is updated
interface Timestamped<T> {
  lastUpdated: Date; // the last time this item was fetched via API
  lastUsed?: Date; // the last time this item was accessed through lookup<T>()
  ttl?: number; // seconds
  expired: boolean;
  item: T;
}

type TimestampedItem = Timestamped<any>;

const KEY_PREFIX = 'ts-';

/// END: not exported

// The Blueprint for the cache
export class Store {
  // Let's use a prefix so we can know when an item is truly ours
  makeKey = (id: string): string => {
    if (id.startsWith(KEY_PREFIX)) {
      console.error('ALREADY PREFIXED');
      return id;
    }
    return `${KEY_PREFIX}${id}`;
  };

  storage: AsyncStorageStatic = AsyncStorage;
  setStorage = (s: any) => {
    this.storage = s;
  };

  store = async (key: string, tsitem: TimestampedItem): Promise<void> => {
    const prefixedKey = this.makeKey(key);
    const jsonStr = JSON.stringify(tsitem);

    let keepTrying = true;
    let tryCount = 3;
    while (keepTrying) {
      try {
        await this.storage!.setItem(prefixedKey, jsonStr);
        keepTrying = false;
      } catch (error) {
        console.error(error);

        await this.removeOldestItem();
        tryCount -= 1;
        keepTrying = tryCount != 0;

        if (!keepTrying) {
          console.error(`Couldn't use local storage. Failing to store ${key}`);
        }
      }
    }
  };

  setItem = async (key: string, item: any, ttl?: number | undefined): Promise<void> => {
    try {
      const tsitem: TimestampedItem = { lastUpdated: new Date(), item: item, ttl, expired: false };
      await this.store(key, tsitem);
    } catch (e: any) {
      // corrupt cache should not crash app
      recordError(e, {
        originatingFunction: 'Store-setItem',
        customMessage: `Unable to setItem in the Store for key ${key}`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Cache,
        data: { key, item, ttl }
      });
    }
  };

  evaluateExpiration = (tsitem: TimestampedItem): void => {
    if (typeof tsitem.ttl !== 'undefined' && !tsitem.expired) {
      const now = new Date().getTime();
      const expires = new Date(tsitem.lastUpdated).getTime() + tsitem.ttl;

      tsitem.expired = expires < now;
    }
  };

  getItem = async (key: string): Promise<any | null> => {
    try {
      const prefixedKey = this.makeKey(key);
      const lsitem = await this.storage!.getItem(prefixedKey);
      if (!lsitem) return null;

      const tsitem: TimestampedItem = JSON.parse(lsitem);
      if (!tsitem) return null;

      tsitem.lastUpdated = new Date(tsitem.lastUpdated);
      tsitem.lastUsed = new Date();
      this.evaluateExpiration(tsitem);

      await this.store(key, tsitem); // don't use the prefixed key

      return tsitem.item ? tsitem.item : null;
    } catch (e: any) {
      // corrupt cache should not crash app
      recordError(e, {
        originatingFunction: 'Store-getItem',
        customMessage: `Unable to getItem in the Store for key ${key}`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Cache,
        data: { key }
      });
    }
    return null;
  };

  removeItem = async (key: string): Promise<any | null> => {
    try {
      const prefixedKey = this.makeKey(key);
      const lsitem = await this.storage!.getItem(prefixedKey);
      if (!lsitem) return null;

      await this.storage!.removeItem(prefixedKey);
      return JSON.parse(lsitem);
    } catch (e: any) {
      // corrupt cache should not crash app
      recordError(e, {
        originatingFunction: 'Store-removeItem',
        customMessage: `Unable to removeItem in the Store for key ${key}`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Cache,
        data: { key }
      });
    }
  };

  isExpired = async (key: string): Promise<boolean> => {
    try {
      const prefixedKey = this.makeKey(key);
      const lsitem = await this.storage!.getItem(prefixedKey);
      if (!lsitem) return true;

      const tsitem: TimestampedItem = JSON.parse(lsitem);
      if (!tsitem) return true;

      this.evaluateExpiration(tsitem);

      return tsitem.expired;
    } catch (e: any) {
      // corrupt cache should not crash app
      recordError(e, {
        originatingFunction: 'Store-isExpired',
        customMessage: `Error encountered while evaluating expiration status for key ${key}`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Cache,
        data: { key }
      });
    }
    return true;
  };

  removeOldestItem = async () => {
    let oldestId: string | null = null;
    let oldestDate: Date | null = null;
    const allKeys = await this.storage.getAllKeys();
    for (let i = allKeys.length; i >= 0; i--) {
      const lskey = allKeys[i];
      if (!lskey || !lskey.startsWith(KEY_PREFIX)) continue;
      const lsitem = await this.storage!.getItem(lskey);
      if (!lsitem) continue;

      const tsitem: TimestampedItem = JSON.parse(lsitem);

      if (!oldestDate && tsitem.lastUsed) {
        oldestDate = tsitem.lastUsed!;
        oldestId = lskey;
      }
      if (!oldestDate) {
        oldestDate = tsitem.lastUpdated;
        oldestId = lskey;
      }
    }

    if (oldestId) {
      await this.storage!.removeItem(oldestId);
    }
  };
}

// The Actual Store of All Things Data
export const TheStore = new Store();
