import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './FundraiserUpsellModal.styles';
import { UpsellInfoHeader } from '@gf/cross-platform-lib/modules/review-and-buy/components/CartReview/CartFundraiserUpsell/FundraiserUpsellInfo/UpsellInfoHeader';
import { useModal } from '../../ModalProvider';
import { FundraiserUpsellContent } from '@gf/cross-platform-lib/modules/review-and-buy/components/CartReview/CartFundraiserUpsell/FundraiserUpsellInfo/FundraiserUpsellContent/FundraiserUpsellContent';
import { View } from 'react-native';
import { FundraiserDetail } from '@gf/cross-platform-lib/modules/review-and-buy/components/CartReview/CartFundraiserUpsell/FundraiserUpsellInfo/FundraiserDetail';
import { DEFAULT_BOTTOM_DRAWER_HEADER_HEIGHT } from '@gf/cross-platform-lib/providers/DrawerProvider/drawerConstants';

const Container = styled.View`
  ${styles.container}
`;
const ModalBody = styled.View`
  ${styles.modalBody}
`;

export const FundraiserUpsellModal = () => {
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;

  return (
    <Container>
      <UpsellInfoHeader
        handleCloseButtonPress={hideModal}
        schoolLogo={modalProps.schoolLogo}
        schoolColor={modalProps.schoolColor}
        headerHeight={DEFAULT_BOTTOM_DRAWER_HEADER_HEIGHT}
      />
      <ModalBody>
        <FundraiserUpsellContent
          schoolName={modalProps.schoolName}
          eventTitle={modalProps.eventTitle}
          eventDescription={modalProps.eventDescription}
        />
        <View style={{ height: 32 }}></View>
        <FundraiserDetail
          eventSchedule={modalProps.eventSchedule}
          eventTitle={modalProps.eventTitle}
          eventGoal={modalProps.eventGoal}
          eventRaised={modalProps.eventRaised}
          barColor={modalProps.schoolColor}
        />
      </ModalBody>
    </Container>
  );
};
