import { Linking, Share, Clipboard } from 'react-native';
import { GetCurrentApplicationConfiguration, getFormattedStartDate } from '@gf/cross-platform-lib/utils';
import { EventSeason } from '@gf/cross-platform-lib/interfaces';
import { GOFAN_APP_PAGES } from '@gf/cross-platform-lib/constants';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

const appConfig = GetCurrentApplicationConfiguration();

export const patchedEncodeURIComponent = (url: string) =>
  encodeURIComponent(url).replace(/[!'()*]/g, c => `%${c.charCodeAt(0).toString(16)}`);

export const getEventUrl = (eventId: string, isSeason: boolean) => {
  return encodeURI(`${appConfig.origin.url}${GOFAN_APP_PAGES[isSeason ? 'season' : 'event'].getPath(eventId)}`);
};

export const getShareEventMessage = (event: EventSeason) => {
  const eventTitle = getFormattedStartDate({
    startDateTime: event.startDateTime,
    endDateTime: event.endDateTime,
    timeZone: event.timeZone,
    isAllDayEvent: event.isAllDayEvent,
    isSeason: event.isSeason
  });
  return `${event.title}\n${eventTitle}`;
};

interface ShareEventArgs extends Record<string, string> {
  url: string;
  title: string;
  description: string;
  socialDesc: string;
  emailAddress: string;
  phoneNumber: string;
}

const getShareEventMesssageArgs = (event: EventSeason): ShareEventArgs => {
  const description = getShareEventMessage(event);
  const eventUrl = getEventUrl(event.id, event.isSeason);
  const socialDesc = `${description}\nWho's with me?\nGet your tickets at\n`;
  return {
    url: eventUrl,
    title: event.title || '',
    description,
    emailAddress: '',
    phoneNumber: '',
    socialDesc
  };
};

export const SocialMedia = {
  SMS: 'sms',
  EMAIL: 'email',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  MORE: 'more'
};

export const getMediaLink = {
  sms: (body: string, phoneNumber?: string) => `sms:${phoneNumber}?&body=${body}`,
  facebook: (url: string, socialDesc: string) => `http://www.facebook.com/sharer.php?u=${url}&quote=${socialDesc}`,
  twitter: (url: string, socialDesc: string) => `https://twitter.com/intent/tweet?url=${url}&text=${socialDesc}`,
  email: (subject: string, body: string, emailAddress?: string) =>
    `mailto:${''}?subject=${subject}&cc=${emailAddress}&body=${body}`,
  more: (message: string) => message
};

export type SocialMediaType = 'sms' | 'email' | 'facebook' | 'twitter' | 'more';

const getShareEventSiteLinks = (args: ShareEventArgs, socialType: SocialMediaType) => {
  const { url, title, description, phoneNumber, emailAddress, socialDesc } = args;

  const titleEncoded = patchedEncodeURIComponent(title);
  const urlEncoded = patchedEncodeURIComponent(url);
  const socialDescEncoded = patchedEncodeURIComponent(socialDesc);

  switch (socialType) {
    case SocialMedia.SMS: {
      return getMediaLink.sms(`${socialDescEncoded}${urlEncoded}`, phoneNumber);
    }
    case SocialMedia.FACEBOOK: {
      return getMediaLink.facebook(urlEncoded, socialDescEncoded);
    }
    case SocialMedia.TWITTER: {
      return getMediaLink.twitter(urlEncoded, socialDescEncoded);
    }
    case SocialMedia.EMAIL: {
      return getMediaLink.email(titleEncoded, `${socialDescEncoded}${urlEncoded}`, emailAddress);
    }
    default:
      return getMediaLink.more(`${description}\n${url}`);
  }
};

export const getShareEventUrl = (event: EventSeason, socialType: SocialMediaType) => {
  const args = getShareEventMesssageArgs(event);
  const urlOrDescription = getShareEventSiteLinks(args, socialType);
  const sharingContent = `${args.socialDesc}${args.url}`;
  return {
    urlOrDescription,
    sharingContent
  };
};

export const shareEvent = async (event: EventSeason, socialType: SocialMediaType) => {
  const { urlOrDescription, sharingContent } = getShareEventUrl(event, socialType);
  if (socialType === SocialMedia.MORE) {
    try {
      await Share.share({
        message: urlOrDescription
      });
    } catch (err: any) {
      recordError(err, {
        originatingFunction: 'utils/shareEvent',
        customMessage: `Failed to share event via ${socialType}.`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.UserActions,
        data: { event, socialType, urlOrDescription, sharingContent }
      });
    }
  } else {
    if (socialType === SocialMedia.FACEBOOK || socialType === SocialMedia.TWITTER) {
      Clipboard.setString(sharingContent);
    }
    Linking.openURL(urlOrDescription);
  }
};
