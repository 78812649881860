import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalWrapper: css<{ height: number; width: number }>`
    background: #f4f4f4;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: ${({ height = 0 }) => `${height}px`};
    margin: auto;
    padding: 16px;
    width: ${({ width = 0 }) => `${width}px`};
    z-index: 3;
  `,
  container: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  button: css<{ bgColor: string; removeBorder?: boolean; color: string }>`
    border-radius: 4px;
    border: 1px solid #323232;
    border-width: ${({ removeBorder }) => (removeBorder ? 0 : '1px')};
    height: 48px;
    width: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ bgColor }) => bgColor};
    color: ${({ color }) => color};
    cursor: pointer;
  `,
  title: css`
    color: #323232;
    font-size: 20px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
  `,
  subTitle: css<{ isMobile: boolean }>`
    margin-top: ${({ isMobile }) => (isMobile ? '4px' : '14px')};
    width: 80%;
    color: #161616;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    height: 60px;
    letter-spacing: 0.16px;
    line-height: 20px;
  `,
  closeButton: css`
    height: 10px;
    width: 10px;
    cursor: pointer;
  `
};
