// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const Warning = (props: SvgProps) => (
  <Svg id='gf_icon_warning' aria-label='Warning icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <Path d='M16 2a14 14 0 1014 14A14 14 0 0016 2zm0 26a12 12 0 1112-12 12 12 0 01-12 12z' />
    <Path d='M15 8H17V19H15z' />
    <Path d='M16 22a1.5 1.5 0 101.5 1.5A1.5 1.5 0 0016 22z' />
    <Path id='Warning_Transparent_Rectangle_' data-name='&lt;Transparent Rectangle&gt;' d='M0 0H32V32H0z' fill='none' />
  </Svg>
);
