// Returns a promise for every invocation.
// Delays invocation of func until interval has passed.
// After invocation of func, resolves each returned promise with the single value.
export const debouncedPromise = (func: Function, interval: number) => {
  let timer: any = null;

  return (...args: any) => {
    clearTimeout(timer);
    return new Promise(resolve => {
      timer = setTimeout(() => resolve(func(...args)), interval);
    });
  };
};
