// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Path, Defs, ClipPath } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const LogoTwitterWithoutBorder = (props: SvgProps) => (
  <Svg
    aria-label='Twitter icon'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    width={32}
    height={32}
    viewBox='0 0 20 20'
    {...props}
  >
    <G clipPath='url(#a)'>
      <Path
        fill='#000'
        d='M11.743 8.592 18.568.833h-1.617L11.025 7.57 6.292.833H.832L7.99 11.021.833 19.157H2.45l6.258-7.114 4.999 7.114h5.459L11.743 8.592Zm-2.215 2.519-.725-1.015-5.77-8.072h2.484l4.657 6.515.725 1.014 6.053 8.468h-2.484l-4.94-6.91Z'
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M0 0h20v20H0z' />
      </ClipPath>
    </Defs>
  </Svg>
);
