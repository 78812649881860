import { GetCurrentApplicationConfiguration } from '@gf/cross-platform-lib/utils';
import isArray from 'lodash/isArray';
import { safeFetch } from '../utils';
const appConfig = GetCurrentApplicationConfiguration();

export const fetchValidTlds = async () => {
  const url = `${appConfig.assets.host}/static/topLevelDomains.json`;
  const res = await safeFetch<string[]>(url!);
  return isArray(res.data) ? res.data : undefined;
};
