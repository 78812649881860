import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

export const PlayoffIcon = ({ width, height, ...props }: SvgProps) => (
  <Svg width={width} height={height} viewBox='1.5 1.5 22 25' {...props}>
    <G stroke='#323232' fill='none' fillRule='evenodd'>
      <Path stroke='none' d='M1 1h20v20H1z' />
      <Path strokeWidth={1.5} strokeLinecap='round' d='M1.683 2.44h6.862v6.184H1.683M1.683 14.258h6.862v6.183H1.683' />
      <Path strokeLinecap='round' d='M8.545 5.532h6.652V17.35H8.545' />
      <Path strokeWidth={1.5} strokeLinecap='round' d='M8.545 5.532h6.652V17.35H8.545M15.197 11.441h4.726' />
    </G>
  </Svg>
);
