import React, { createContext, useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { getUserInfo } from '@gf/cross-platform-lib/utils/userInfo/getUserInfo.web';
import { getCookie, isHideCookieBanner, setCookie } from '@gf/cross-platform-lib/utils';
import { COOKIE_ACKNOWLEDGED_KEY, NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';
import { TheStore } from '../../AcquisitionV2';
import { recordError } from '../../../utils/newrelic';

const CookieBannerContext = createContext<{
  hideCookieBanner: boolean;
  handleCookieBannerAgree: () => void;
}>({
  hideCookieBanner: true,
  handleCookieBannerAgree: () => {}
});

export const useCookieBannerContext = () => useContext(CookieBannerContext);

export const CookieBannerProvider = ({ children }: { children: React.ReactNode }) => {
  const { pathname } = useRouter();
  const userInfo = getUserInfo();
  const [hideCookieBanner, setHideCookieBanner] = useState<boolean>(
    isHideCookieBanner(pathname, getCookie(COOKIE_ACKNOWLEDGED_KEY), userInfo?.userAgent || '')
  );
  const handleSetCookie = (value: boolean) => {
    setCookie(COOKIE_ACKNOWLEDGED_KEY, value, 365);
  };
  const handleCookieBannerAgree = () => {
    setHideCookieBanner(true);
    handleSetCookie(true);
  };

  useEffect(() => {
    try {
      TheStore.getItem(COOKIE_ACKNOWLEDGED_KEY).then(value => {
        if (value !== 'undefined' && value !== null && !getCookie(COOKIE_ACKNOWLEDGED_KEY)) {
          setHideCookieBanner(value == 'true');
          // migrate to cookie storage from current old user local storage
          handleSetCookie(value == 'true');
        }
      });
    } catch (error) {
      const errorContext = 'TheStore.cookies_acknowledged';
      recordError(error, {
        originatingFunction: 'CookieBanner-TheStore.getItem',
        customMessage: 'Failed to fetch cookies_acknowledged value from TheStore',
        errorGroup: NEW_RELIC_ERROR_GROUPS.DataIssues,
        data: {
          platform: 'web'
        },
        errorContext: errorContext
      });
    }
  }, []);

  return (
    <CookieBannerContext.Provider value={{ hideCookieBanner, handleCookieBannerAgree }}>
      {children}
    </CookieBannerContext.Provider>
  );
};
