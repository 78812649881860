import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { isEmpty } from './isEmpty';
import { DEFAULT_FORMAT, FULL_DATE_TIME_FORMAT } from '@gf/cross-platform-lib/constants';

export const SIMPLE_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_WITH_TIME_ZONE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZZ';
export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm';
export const SHORT_DATE_FORMAT = 'MM DD, YYYY';
export const AMERICA_NEW_YORK = 'America/New_York';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

export const getUnixTime = (strInputTime: string, timeZone: string, format: string): Dayjs => {
  let inputTime = strInputTime;
  if (!isEmpty(timeZone)) {
    return dayjs(strInputTime, format).tz(timeZone);
  }

  return dayjs(inputTime, format);
};

export const isUpcomingEvent = (
  startDateTime: string,
  endDateTime: string,
  timezone: string = 'America/New_York',
  nowDateTime?: string
) => {
  const now = nowDateTime ? dayjs(nowDateTime, DEFAULT_FORMAT).tz(timezone) : getNow(timezone);
  const startDateMoment = dayjs(startDateTime, DEFAULT_FORMAT).tz(timezone);
  const endDateMoment = dayjs(endDateTime, DEFAULT_FORMAT).tz(timezone);

  return (
    endDateMoment.isSameOrAfter(startDateMoment) &&
    now.isSameOrBefore(endDateMoment) &&
    !(
      now.isSame(startDateMoment, 'date') &&
      now.isSame(startDateMoment, 'month') &&
      now.isSame(startDateMoment, 'year')
    )
  );
};

export const getNow = (timezone: string = 'America/New_York') => {
  return dayjs().tz(timezone);
};

export const isTodayEvent = (
  startDateTime: string,
  endDateTime: string,
  timezone: string = 'America/New_York',
  nowDateTime?: string
) => {
  const now = nowDateTime ? dayjs(nowDateTime, DEFAULT_FORMAT).tz(timezone) : getNow(timezone);
  const startDateMoment = dayjs(startDateTime, DEFAULT_FORMAT).tz(timezone);
  const endDateMoment = dayjs(endDateTime, DEFAULT_FORMAT).tz(timezone);

  return (
    startDateMoment.isSameOrBefore(endDateMoment) &&
    now.isSame(startDateMoment, 'date') &&
    now.isSame(startDateMoment, 'month') &&
    now.isSame(startDateMoment, 'year')
  );
};

export const isFutureDate = (dateTime: string | Dayjs) => {
  const now = dayjs();
  const dateMoment = dayjs(dateTime);

  return now.isBefore(dateMoment);
};

export const isPastDate = (dateTime: string | Dayjs) => {
  const now = dayjs();
  const dateMoment = dayjs(dateTime);

  return now.isAfter(dateMoment);
};

export const isRecentDate = (dateTime: string | Dayjs, inputFormat: string = DEFAULT_FORMAT) => {
  const recent = dayjs().subtract(7, 'days');
  const dateMoment = dayjs.utc(dateTime, inputFormat).local();
  return dateMoment.isAfter(recent);
};

export const getUTCTime = (
  strInputTime: string,
  inputFormat: string = DEFAULT_FORMAT,
  outputFormat: string = FULL_DATE_TIME_FORMAT
): string => {
  const time = dayjs.utc(strInputTime, inputFormat).local();
  return time.format(outputFormat);
};
