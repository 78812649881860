import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalWrapper: css`
    background-color: #f4f4f4;
    justify-content: center;
    margin: auto;
    z-index: 3;
  `,
  container: css`
    display: flex;
    flex-direction: row;
  `,
  contentWrapper: css`
    display: flex;
  `,
  contentContainer: css<{ isMobile: boolean }>`
    width: ${({ isMobile }) => (isMobile ? '280px' : '565px')};
    height: ${({ isMobile }) => (isMobile ? '320px' : '240px')};
  `,
  closeBtnContainer: css`
    position: absolute;
    top: 0;
    right: 0;
    padding: 16px;
    z-index: 5;
  `,
  title: css`
    color: rgb(50, 50, 50);
    font-family: Rubik-SemiBold;
    font-size: 18px;
    letter-spacing: 0px;
    line-height: 28px;
  `,
  subTitle: css`
    padding-top: 8px;
    color: rgb(22, 22, 22);
    font-family: Rubik-Regular;
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
  `,
  bodyContainer: css`
    padding: 16px 32px 16px 16px;
  `,
  btnContainer: css`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0;
  `,
  button: css<{ bgColor: string; removeBorder?: boolean; color: string }>`
    border-radius: 4px;
    border: 1px solid #323232;
    border-width: ${({ removeBorder }) => (removeBorder ? 0 : '1px')};
    height: 48px;
    width: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ bgColor }) => bgColor};
    color: ${({ color }) => color};
  `,
  buttonTitle: css<{ disabled: boolean; color: string; disabledColor: string }>`
    color: ${({ disabled = false, color = '#ffffff', disabledColor = '#ffffff' }) =>
      disabled ? disabledColor : color};
  `,
  boldText: css`
    font-family: Rubik-SemiBold;
  `
};
