import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { Carbonicons, StyledButton } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';
import { StyledText } from '@gf/cross-platform-lib/components/StyledText';
import { styles } from './Modal.styles';
import { testProperties } from '@gf/cross-platform-lib/utils';

const Wrapper = styled.View`
  ${styles.wrapper}
`;

const Container = styled.View`
  ${styles.container}
`;

const ModalNavigationBar = styled.View`
  ${styles.modalNavigationBar}
`;

const LeftItem = styled.View`
  ${styles.leftItem}
`;

const RightItem = styled.TouchableOpacity`
  ${styles.rightItem}
`;

const ModalHeader = styled.View``;

const ModalContent = styled.View`
  ${styles.modalContent}
`;

const ModalFooter = styled.View`
  ${styles.modalFooter}
`;

const Title = styled(StyledText)`
  ${styles.title}
`;

const SubTitle = styled(StyledText)`
  ${styles.subTitle}
`;

export interface ModalProps {
  title: string;
  subTitle?: string;
  boldTitle?: boolean;
  hasClose?: boolean;
  hasConfirm?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  disableClose?: boolean;
  disableConfirm?: boolean;
  buttonSize?: 'sm' | 'md' | 'lg';
  fillColor?: string;
  ignoreTabSelection?: boolean;
  leftNavItem?: React.ReactNode;
  inProgress?: boolean;
  inProgressLabel?: string;
  wrapperTestID?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  children?: React.ReactNode;
}

export const Modal = ({
  title,
  subTitle,
  boldTitle = false,
  hasClose = true,
  hasConfirm = true,
  disableClose = false,
  disableConfirm = false,
  buttonSize = 'lg',
  fillColor = '#f4f4f4',
  confirmLabel = 'Done',
  cancelLabel = 'Cancel',
  ignoreTabSelection = false,
  leftNavItem,
  inProgress,
  inProgressLabel,
  wrapperTestID = 'modal-wrapper',
  onCancel,
  onConfirm,
  children
}: ModalProps) => {
  return (
    <Wrapper fillColor={fillColor} {...testProperties(wrapperTestID)}>
      <ModalNavigationBar>
        {leftNavItem ? <LeftItem>{leftNavItem}</LeftItem> : null}
        <RightItem onPress={onCancel}>
          <Carbonicons name='close' size={21} color='#323232' />
        </RightItem>
      </ModalNavigationBar>
      <Container>
        <ModalHeader>
          {title && <Title typeToken={boldTitle ? 'heading03SemiBold' : 'heading03'}>{title}</Title>}
          {subTitle && <SubTitle typeToken='body01'>{subTitle}</SubTitle>}
        </ModalHeader>

        {children ? <ModalContent>{children}</ModalContent> : null}
        <ModalFooter>
          {hasClose && (
            <View style={{ flex: 1, marginRight: hasConfirm ? 8 : 0 }}>
              <StyledButton
                size={buttonSize}
                padding={'0'}
                buttonType='tertiary'
                disabled={disableClose}
                onPress={onCancel}
                tabIndex={ignoreTabSelection ? -1 : undefined}
                title={cancelLabel}
                accessibilityLabel={cancelLabel}
                {...testProperties('cancel-btn')}
              />
            </View>
          )}
          {hasConfirm && (
            <View style={{ flex: 1, marginLeft: hasClose ? 8 : 0 }}>
              <StyledButton
                size={buttonSize}
                padding={'0'}
                disabled={disableConfirm || inProgress}
                onPress={onConfirm}
                tabIndex={ignoreTabSelection ? -1 : undefined}
                title={inProgress ? inProgressLabel : confirmLabel}
                accessibilityLabel={inProgress ? inProgressLabel : confirmLabel}
                {...testProperties('confirm-btn')}
              ></StyledButton>
            </View>
          )}
        </ModalFooter>
      </Container>
    </Wrapper>
  );
};
