import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalWrapper: css<{ isMobile: boolean; height: number; width: number }>`
    background-color: rgb(50, 50, 50);
    border-radius: 4px;
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: ${({ isMobile }) => (isMobile ? '14px' : '0px')};
    padding-top: ${({ isMobile }) => (isMobile ? '14px' : '0px')};
    width: ${({ width }) => `${width}px`};
  `,
  title: css`
    color: rgb(50, 50, 50);
    font-family: Rubik-Regular;
    font-size: 20px;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
  `,
  subTitle: css<{ isMobile: boolean; marginBottom: number }>`
    margin-top: ${({ isMobile }) => (isMobile ? '4px' : '14px')};
    margin-bottom: ${({ isMobile }) => (isMobile ? '4px' : '14px')};
    color: rgb(255, 255, 255);
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
  `
};
