import { css } from '@gf/cross-platform-lib/styled-components';
export const baseStyles = {
  inputWrapper: css`
    flex-direction: column;
    align-items: center;
    border: 2px solid;
    border-color: #dbdbdb;
    border-radius: 4px;
  `,
  textArea: css<{ disabled?: boolean }>`
    width: 100%;
    background: #fff;
    font-family: Rubik-Regular;
    color: #323232;
    font-size: 14px;
    line-height: 18px;
    border-radius: 2px;
    padding: 8px 16px;
    min-height: 72px;
  `,
  dismissButton: css`
    width: 100%;
  `
};
