import React, { ReactNode } from 'react';
import { GFImage } from '@gf/cross-platform-lib/components';
import { View, StyleSheet } from 'react-native';
import { styles } from './UpsellInfoHeader.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import Svg, { ClipPath, Defs, LinearGradient, Rect, Stop } from 'react-native-svg';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import BackgroundPatternSvg from './UpsellBackgroundIconsSVG';
import XIconSvg from './XIconSvg';
import { LightenDarkenColor } from '@gf/cross-platform-lib/utils/lightenDarkenColor';
import { isLight } from '@gf/cross-platform-lib/utils/colors';

const Spacer = styled.View`
  ${styles.spacer}
`;
const HeaderContainer = styled.View`
  ${styles.headerContainer}
`;
const LogoContainer = styled.View`
  ${styles.logoContainer}
`;
const ExitButton = styled.TouchableOpacity`
  ${styles.exitButton}
`;

export type UpsellInfoHeaderProps = {
  handleCloseButtonPress: () => void;
  headerHeight: number;
  schoolLogo?: string;
  schoolColor: string;
};

export const UpsellInfoHeader = ({
  handleCloseButtonPress,
  schoolLogo,
  schoolColor,
  headerHeight
}: UpsellInfoHeaderProps) => {
  const schoolColorGradientTo = LightenDarkenColor('#' + schoolColor, -60);

  return (
    <Background schoolColor={schoolColor} schoolColorGradientTo={schoolColorGradientTo} headerHeight={headerHeight}>
      <HeaderContainer headerHeight={headerHeight}>
        <Spacer />
        <LogoContainer schoolColor={schoolColor}>
          <GFImage src={schoolLogo} height={40} width={40} />
        </LogoContainer>
        <ExitButton onPress={() => handleCloseButtonPress()} isLight={isLight(schoolColor)}>
          <XIconSvg />
        </ExitButton>
      </HeaderContainer>
    </Background>
  );
};

export type BackgroundProps = {
  children: ReactNode;
  schoolColor: string;
  schoolColorGradientTo: string;
  headerHeight: number;
};

const Background = ({ children, schoolColor, schoolColorGradientTo, headerHeight }: BackgroundProps) => {
  const { isDesktop } = useMediaQuery();
  const borderRadius = isDesktop ? 4 : 30;

  return (
    <View style={{ display: 'flex', borderTopRightRadius: borderRadius, borderTopLeftRadius: borderRadius }}>
      <Svg height={`${headerHeight}px`} width='100%' style={StyleSheet.absoluteFillObject}>
        <Defs>
          <ClipPath id='round-corner'>
            <Rect x='0' y='0' width='100%' height={`${headerHeight + borderRadius}px`} rx={borderRadius} />
          </ClipPath>
          <LinearGradient id='grad' x1='0%' y1='0%' x2='0%' y2='100%'>
            <Stop offset='0' stopColor={`#${schoolColor}`} />
            <Stop offset='1' stopColor={`#${schoolColorGradientTo}`} />
          </LinearGradient>
        </Defs>
        <Rect width='100%' height='100%' fill='url(#grad)' clipPath='url(#round-corner)' />
        <BackgroundPatternSvg style={StyleSheet.absoluteFillObject} isLight={isLight(schoolColor)} />
      </Svg>
      {children}
    </View>
  );
};
