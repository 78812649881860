import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './RedeemOnDesktopComponent.styles';
import { Carbonicons } from '../Icons';
import { StyledText } from '../StyledText';
import { StyledButton } from '../StyledButton';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const Title = styled.Text`
  ${styles.title};
`;

const Description = styled.Text`
  ${styles.description};
`;

const ClosedIconWrapper = styled.TouchableOpacity`
  ${styles.closedIconWrapper};
`;

const IconWrapper = styled.TouchableOpacity`
  ${styles.iconWrapper};
`;

const ButtonWrapper = styled.View`
  ${styles.buttonWrapper};
`;

interface RedeemOnDesktopComponentProps {
  onContinue: () => void;
  onClosed: () => void;
}

export const RedeemOnDesktopComponent = ({ onContinue, onClosed }: RedeemOnDesktopComponentProps) => {
  return (
    <ModalWrapper>
      <ClosedIconWrapper onPress={onClosed}>
        <Carbonicons name='close' color='black' size={20}></Carbonicons>
      </ClosedIconWrapper>
      <IconWrapper>
        <Carbonicons name='desktopWarning' size={60} />
      </IconWrapper>
      <Title>We noticed that you might be on a desktop</Title>
      <Description>
        Tickets/passes should be used onsite at the event. Are you sure you want to use your tickets now?
      </Description>
      <ButtonWrapper>
        <StyledButton
          buttonType='tertiary'
          height='48px'
          width='132px'
          padding='0 30px'
          onPress={onContinue}
          accessibilityLabel='Continue'
        >
          <StyledText color='#323232' textAlign='center'>
            Continue
          </StyledText>
        </StyledButton>
        <StyledButton height='48px' width='132px' onPress={onClosed} accessibilityLabel='Cancel'>
          <StyledText color='white' textAlign='center'>
            Cancel
          </StyledText>
        </StyledButton>
      </ButtonWrapper>
    </ModalWrapper>
  );
};
