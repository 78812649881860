import { css } from 'styled-components';

export const baseStyles = {
  inputWrapper: css`
    width: 100%;
    position: relative;
    border-bottom-width: 2px;
    border-bottom-color: #dbdbdb;
    border-bottom: 2px solid #dbdbdb;
    border-radius: 4px;
    background: #fff;
    box-sizing: border-box;
  `,
  select: css<{ disabled?: boolean }>`
    width: 100%;
    height: 40px;
    border: none;
    border-radius: 4px;
    background: #fff;
    outline: 2px solid transparent;
    font-family: Rubik-Regular;
    color: #323232;
    font-size: 14px;
    line-height: 18px;
    appearance: none;
  `,
  iconWrapper: css`
    position: absolute;
    top: 12px;
    right: 12px;
  `
};
