import { Linking } from 'react-native';
import pluralize from 'pluralize';
import { ProductType } from '@gf/cross-platform-lib/interfaces/ProductType';

export const getModalTitle = (numOfTickets: number, productType: ProductType) => {
  let product: string = '';
  switch (productType) {
    case ProductType.TICKET:
      product = 'ticket';
      break;
    case ProductType.DONATION:
      product = 'donation';
      break;
    default:
      product = 'ticket';
  }
  return `Transfer ${numOfTickets} ${pluralize(product, numOfTickets)}`;
};

export const getEmailSubject = (eventName: string, eventDate: string) => {
  return `Transfer Your Tickets for ${eventName} on ${eventDate}`;
};

export const getEmailBody = (eventName: string, eventDate: string, transferUrl: string) => {
  return `Hello,\n\n You have been sent tickets for ${eventName} on ${eventDate}.\n\nPlease follow the link below to view and manage your tickets:\n${transferUrl}\n\nLearn more about accessing and sharing GoFan tickets:\nhttps://gofancustomerservice.zendesk.com/hc/en-us/articles/7585358266644\n\nGoFan Support\nservice@gofan.co`;
};

export const getMessage = (eventName: string, eventDate: string, transferUrl: string) => {
  return `Your ticket(s) for ${eventName} on ${eventDate}: ${transferUrl}`;
};

export const openEmail = ({
  eventName,
  eventDate,
  transferUrl
}: {
  eventName: string;
  eventDate: string;
  transferUrl: string;
}) => {
  const mailto = '';
  const subject = getEmailSubject(eventName, eventDate);
  const body = getEmailBody(eventName, eventDate, transferUrl);
  Linking.openURL(
    `mailto:${mailto}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`
  );
};

export const sendMessage = ({
  eventName,
  eventDate,
  transferUrl
}: {
  eventName: string;
  eventDate: string;
  transferUrl: string;
}) => {
  const message = getMessage(eventName, eventDate, transferUrl);
  Linking.openURL(`sms:?&body=${encodeURIComponent(message) || ''}`);
};
