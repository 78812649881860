import React from 'react';
import { ErrorBoundary, recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';
import { GlobalLoadingProvider } from './GlobalLoadingProvider';
import { NavigationProvider } from './navigation';
import { BusinessProvider } from './BusinessProvider';
import { LocationProvider } from './LocationProvider';
import { ModalProvider } from './ModalProvider';
import { TrackingProvider } from './Tracking';
import { FirebaseProvider } from './Firebase';
import { ReservedSeatingProvider } from './ReservedSeatingProvider/ReservedSeatingProvider';
import { AcquisitionProvider } from './AcquisitionProvider';
import { LaunchDarklyProvider } from './LaunchDarkly';
import { NativeAppReviewProvider } from './NativeAppReviewProvider';
import { CartExpirationProvider } from './CartExpirationProvider';
import { DrawerProvider } from './DrawerProvider/DrawerProvider';
import { StripeNativeProvider } from './StripeNativeProvider';

interface AppProviderProps {
  children: React.ReactNode;
}

const errorHandler = (error: Error) => {
  recordError(error, {
    originatingFunction: 'AppProvider',
    customMessage:
      'An unexpected React rendering error occurred at the root level of the application. This serves as a catch-all for unhandled rendering errors within child components.',
    errorGroup: NEW_RELIC_ERROR_GROUPS.AppInitialization,
    data: {}
  });
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <ErrorBoundary onError={errorHandler}>
      <LaunchDarklyProvider>
        <NativeAppReviewProvider>
          <AcquisitionProvider>
            <FirebaseProvider>
              <ReservedSeatingProvider>
                <TrackingProvider>
                  <LocationProvider>
                    <GlobalLoadingProvider>
                      <NavigationProvider>
                        <ModalProvider>
                          <DrawerProvider>
                            <StripeNativeProvider>
                              <CartExpirationProvider>
                                <BusinessProvider>{children}</BusinessProvider>
                              </CartExpirationProvider>
                            </StripeNativeProvider>
                          </DrawerProvider>
                        </ModalProvider>
                      </NavigationProvider>
                    </GlobalLoadingProvider>
                  </LocationProvider>
                </TrackingProvider>
              </ReservedSeatingProvider>
            </FirebaseProvider>
          </AcquisitionProvider>
        </NativeAppReviewProvider>
      </LaunchDarklyProvider>
    </ErrorBoundary>
  );
};
