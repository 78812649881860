import React from 'react';
import { AdditionalFieldTypes } from '@gf/cross-platform-lib/interfaces';

import { Controller, Control, FieldValues, RegisterOptions, UseFormSetValue } from 'react-hook-form';
import styled from '@gf/cross-platform-lib/styled-components';

import { styles } from './RHFTextArea.styles';
import { Text, Platform } from 'react-native';
import { iosBlue } from '@gf/cross-platform-lib/styled-system/theme/colors';
import { useKeyboard } from '@gf/cross-platform-lib/hooks/useKeyboard';

const InputWrapper = styled.View`
  ${styles.inputWrapper}
`;

const TextArea = styled.TextInput`
  ${styles.textArea}
`;

const DismissButton = styled.TouchableOpacity`
  ${styles.dismissButton}
`;

interface RHFTextAreaProps extends React.HTMLProps<HTMLTextAreaElement> {
  fieldType: AdditionalFieldTypes;
  setValue: UseFormSetValue<FieldValues>;
  control: Control<FieldValues, any>;
  name: string;
  rules?: RegisterOptions;
  required?: boolean;
  options?: string[];
}

export const RHFTextArea = ({ control, name, rules, required, disabled, rows = 3 }: RHFTextAreaProps) => {
  const keyboard = useKeyboard();

  const ownedRules = (): RegisterOptions => {
    const defaultOptions: RegisterOptions = {
      minLength: {
        value: 2,
        message: 'Please enter valid value'
      },
      required: {
        value: required || false,
        message: 'Please enter valid value'
      },
      onBlur: (_e: any) => {}
    };
    return defaultOptions;
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...ownedRules(),
        ...rules
      }}
      render={({ field: { onChange, onBlur, value, ref } }) => (
        <InputWrapper>
          <TextArea
            multiline
            numberOfLines={rows}
            disabled={disabled}
            onBlur={onBlur}
            onChangeText={onChange}
            value={value}
            ref={ref}
            editable={!disabled}
            selectTextOnFocus={!disabled}
            textAlignVertical='top'
            maxLength={255}
          />
          {keyboard.keyboardShown && Platform.OS === 'ios' ? (
            <DismissButton
              onPress={() => {
                keyboard.dismiss();
              }}
              testID='dismiss-button'
            >
              <Text style={{ alignSelf: 'flex-end', color: iosBlue, textAlign: 'right', padding: 6 }}>Done</Text>
            </DismissButton>
          ) : null}
        </InputWrapper>
      )}
    />
  );
};
