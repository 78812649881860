// @ts-nocheck
import * as React from 'react';
import Svg, { G, Path, Defs, ClipPath } from 'react-native-svg';

export const LinkPayment = (props: SvgProps) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 32 32'
    fill='none'
    className='SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--32-svg'
    {...props}
  >
    <G clipPath='url(#a)'>
      <Path fill='#33DDB3' d='M0 0h32v32H0z' />
      <Path
        fill='#1D3944'
        d='M7.978 10.172a1.023 1.023 0 1 1 1.136 1.7 1.023 1.023 0 0 1-1.136-1.7ZM4 10.109h1.829v11.063H4V10.109Zm24.725 3.165a26.691 26.691 0 0 1-2.234 3.895L29 21.166h-2.16L25.298 18.7c-1.55 1.766-3.084 2.63-4.563 2.63-1.802 0-2.539-1.288-2.539-2.754 0-.349.005-.778.005-1.047 0-1.932-.204-2.476-.856-2.392-1.253.168-3.156 3.022-4.395 6.03h-1.72v-7.893h1.828v3.949c1.043-1.76 1.997-3.268 3.535-3.85.895-.34 1.647-.191 2.04-.018 1.417.625 1.415 2.151 1.396 4.197-.005.27-.005.56-.005.869 0 .741.205 1.067.71 1.117.3.026.602-.041.864-.191v-9.238h1.828v7.917s1.585-1.45 3.261-4.752h2.038Zm-19.265.004H7.633v7.895h1.829v-7.895H9.46Z'
      />
    </G>
    <Defs>
      <ClipPath id='a'>
        <Path fill='#fff' d='M0 0h32v32H0z' />
      </ClipPath>
    </Defs>
  </Svg>
);
