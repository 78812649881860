// @ts-nocheck
import * as React from 'react';
import { SvgExtProps } from '../types';
import Svg, { Path } from 'react-native-svg';

export const CopyLink = (props: SvgExtProps) => (
  <Svg
    id='gf_icon_copy_link'
    aria-label='Copy link icon'
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    {...props}
  >
    <Path d='M11.947 19a4.948 4.948 0 01-3.499-8.447l5.106-5.104a4.948 4.948 0 016.998 6.998l-.553.552-1.415-1.413.557-.557a2.95 2.95 0 00-.004-4.166 3.02 3.02 0 00-4.17 0l-5.104 5.104a2.947 2.947 0 000 4.17 3.02 3.02 0 004.17 0l1.414 1.414a4.918 4.918 0 01-3.5 1.449z' />
    <Path d='M19.947 17a4.948 4.948 0 01-3.499-8.447l.553-.552 1.414 1.415-.552.552a2.948 2.948 0 000 4.169 3.02 3.02 0 004.17 0l5.105-5.105a2.951 2.951 0 000-4.168 3.02 3.02 0 00-4.17 0l-1.414-1.415a4.948 4.948 0 016.998 6.998l-5.104 5.103a4.92 4.92 0 01-3.5 1.45z' />
    <Path d='M24 30H4a2.002 2.002 0 01-2-2V8a2.002 2.002 0 012-2h4v2H4v20h20V18h2v10a2.002 2.002 0 01-2 2z' />
    <Path
      id='CopyLink_Transparent_Rectangle_'
      data-name='&lt;Transparent Rectangle&gt;'
      d='M0 0H32V32H0z'
      fill='none'
    />
  </Svg>
);
