import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalWrapper: css`
    border-radius: 4px;
    margin: auto;
    padding: 16px;
    width: 320px;
    background: #f4f4f4;
    overflow-y: auto;
    max-height: 60%;
  `,
  title: css`
    width: 288px;
    color: rgb(50, 50, 50);
    font-size: 20px;
    font-family: Rubik-SemiBold;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 28px;
    margin-bottom: 2px;
    text-align: center;
  `,
  description: css`
    width: 288px;
    color: rgb(22, 22, 22);
    font-size: 14px;
    font-family: Rubik-Regular;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    text-align: center;
  `,
  subTitle: css`
    width: 288px;
    color: rgb(82, 82, 82);
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.16px;
    line-height: 20px;
    text-align: center;
    padding-top: 16px;
  `,
  iconWrapper: css`
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
  `,
  closedIconWrapper: css`
    margin-left: 269px;
    margin-bottom: 24px;
  `,
  buttonWrapper: css`
    margin-top: 32px;
    flex-direction: row;
    justify-content: space-between;
  `
};
