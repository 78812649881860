import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalContent: css<{ maxHeight: number }>`
    padding-horizontal: 16px;
    margin-top: 16px;
    ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px` : '')}
  `,
  optionWrapper: css`
    flex-direction: row;
    gap: 16px;
  `,
  iconButton: css<{ isActive: boolean }>`
    background-color: ${({ isActive }) => (isActive ? '#dbdbdb' : '#eaeaea')};
    align-items: center;
    justify-content: center;
    height: 38px;
    flex: 1;
    border-radius: 4px;
  `,
  divider: css`
    width: 100%;
    height: 1px;
    background: #e0e0e0;
  `,
  selectionWrapper: css<{ isActive: boolean }>`
    background-color: ${({ isActive }) => (isActive ? '#dbdbdb' : '#eaeaea')};
    border-radius: 4px;
    padding: 8px 16px;
    flex: 1;
    justify-content: center;
  `,
  selection: css<{ centered: boolean }>`
    flex-direction: row;
    justify-content: ${({ centered }) => (centered ? 'center' : ' space-between;')}
    align-items: center;
  `
};
