// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const StarFilled = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='currentColor' {...props}>
    <Path d='m16 2-4.55 9.22-10.17 1.47 7.36 7.18L6.9 30l9.1-4.78L25.1 30l-1.74-10.13 7.36-7.17-10.17-1.48Z' />
    <Path
      data-name='&lt;Transparent Rectangle&gt;'
      style={{
        fill: 'none'
      }}
      d='M0 0h32v32H0z'
    />
  </Svg>
);
