// @ts-nocheck
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgProps } from '../types';

export const Checkmark = (props: SvgProps) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    data-name='Layer 1'
    fill={props.color || 'currentColor'}
    viewBox='0 0 32 32'
    {...props}
  >
    <Path d='m13 24-9-9 1.414-1.414L13 21.171 26.586 7.586 28 9 13 24z' />
    <Path
      d='M0 0h32v32H0z'
      data-name='&lt;Transparent Rectangle&gt;'
      style={{
        fill: 'none'
      }}
    />
  </Svg>
);
