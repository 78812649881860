import {
  AdditionalFieldTypes,
  InformationFormField,
  OrderFormProps,
  IOrderTicketFormField
} from '@gf/cross-platform-lib/interfaces';

import capitalize from 'lodash/capitalize';
import { capitalizeFirstChar } from './capitalizeFirstChar';

const mustBeUpperCasedFields = ['id'];
export const getFieldLabel = (fieldName: string) => {
  if (fieldName) {
    return capitalizeFirstChar(fieldName)
      .split('-')
      .map(token => {
        if (typeof token === 'string' && mustBeUpperCasedFields.includes(token.toLocaleLowerCase()))
          return token.toUpperCase();
        return token;
      })
      .join(' ');
  }
  return '';
};

export const getFieldValue = (value: string, type: AdditionalFieldTypes) => {
  if (type === 'CHECKBOX') return value ? 'Yes' : 'No';
  return value;
};

export const getFormFieldLabel = (fieldName: string, required?: boolean) => {
  if (fieldName)
    return `${capitalizeFirstChar(fieldName)
      .split('-')
      .map(token => {
        if (typeof token === 'string' && mustBeUpperCasedFields.includes(token.toLocaleLowerCase()))
          return token.toUpperCase();
        return token;
      })
      .join(' ')} ${required ? '*' : ''}`;
  return '';
};

export const fieldShouldBeFullWith = (field: InformationFormField | null, isLastItem?: boolean) => {
  if (field === null || field === undefined) return false;
  return (
    field.fieldType === 'CHECKBOX' ||
    field.fieldType === 'DROPDOWN' ||
    field.fieldType === 'TEXTBOX' ||
    field.name.length > 30 ||
    isLastItem ||
    false
  );
};

export const getFieldWidths = (formFields: InformationFormField[]): number[] => {
  const fieldWidths: number[] = [];
  const rowMap = new Map<number, number[]>();
  let rowIndex = 0;

  const isLastItem = (index: number) => index + 1 === formFields.length;
  const addFullWidthField = () => {
    rowMap.set(rowIndex, [1]);
    fieldWidths.push(1);
    rowIndex += 1;
  };
  const addLastHalfWidthField = () => {
    rowMap.set(rowIndex, [0.5, 0.5]);
    fieldWidths.push(...[0.5, 0.5]);
    rowIndex += 1;
  };
  const addFirstHalfWidthField = () => rowMap.set(rowIndex, [0.5]);

  formFields.forEach((currentField, currentIndex) => {
    const nextField = currentIndex < formFields.length ? formFields[currentIndex + 1] : null;
    const currentFieldShouldBeFullWidth = fieldShouldBeFullWith(currentField, isLastItem(currentIndex));
    const nextFieldShouldBeFullWidth = fieldShouldBeFullWith(nextField, isLastItem(currentIndex + 1));
    if (currentFieldShouldBeFullWidth) {
      addFullWidthField();
    } else {
      const cell = rowMap.get(rowIndex);
      if (cell) {
        addLastHalfWidthField();
      } else {
        if (nextFieldShouldBeFullWidth) {
          addFullWidthField();
        } else addFirstHalfWidthField();
      }
    }
  });

  return fieldWidths;
};

export const topFieldNames = ['first-name', 'last-name', 'email', 'phone-number'];
export function sortFieldsOrder(formFields: InformationFormField[]): InformationFormField[] {
  const topFields: InformationFormField[] = [];
  topFieldNames.forEach(topFieldName => {
    const topField = formFields.find(field => field.name === topFieldName);
    if (topField) {
      topFields.push(topField);
    }
  });
  const remainingFields = formFields.filter(field => field.name && !topFieldNames.includes(field.name));

  return [...topFields, ...remainingFields];
}

export const getOrderFormFields = (fields: OrderFormProps['fields']): Map<string, string> => {
  const initial = new Map<string, string>();
  initial.set(`Name`, '');

  const orderFormFields = fields.reduce((formFields, field) => {
    if (field.displayOnTicket) {
      formFields.set(getFieldLabel(field.name), getFieldValue(field.value, field.fieldType));
    }
    return formFields;
  }, initial);

  // Replace First name, Last name by Name if they are both existed.
  const firstName = orderFormFields.get('First name')?.trim();
  const lastName = orderFormFields.get('Last name')?.trim();
  if (firstName && lastName) {
    orderFormFields.set('Name', `${capitalize(firstName)} ${capitalize(lastName)}`);
    orderFormFields.delete('First name');
    orderFormFields.delete('Last name');
  } else {
    orderFormFields.delete('Name');
  }

  return orderFormFields;
};

export const getFormInfo = (ticketForms: IOrderTicketFormField[]) => {
  let fullName = '';
  const formInfo: {
    [key: string]: string;
  } = ticketForms.reduce((nameToValue, item) => {
    const { name, displayOnTicket, value, fieldType } = item;
    if (displayOnTicket === false) return nameToValue;
    if (name === 'first-name' || name === 'last-name') {
      const _value = capitalize(value);
      if (fullName) {
        fullName = name === 'first-name' ? [_value, fullName].join(' ') : [fullName, _value].join(' ');
      } else {
        fullName = _value;
      }
      return { ...nameToValue, [getFieldLabel('name')]: getFieldValue(fullName, fieldType) };
    }

    return { ...nameToValue, [getFieldLabel(item.name)]: getFieldValue(value, fieldType) };
  }, {});

  return formInfo;
};
