// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const FeaturedStarFilled = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 14' fill='currentColor' {...props}>
    <Path d='M6.30894 3.67361L6.99984 1.68712L7.69074 3.67361C7.86761 4.18215 8.46672 4.40021 8.92909 4.12433L10.7352 3.04669L9.98761 5.01254C9.79621 5.51579 10.115 6.06793 10.6465 6.15381L12.7228 6.48926L10.8865 7.51461C10.4164 7.7771 10.3057 8.40498 10.6576 8.81242L12.0325 10.404L9.96673 10.0091C9.43789 9.908 8.94949 10.3178 8.95722 10.8562L8.9874 12.9592L7.65875 11.3288C7.31862 10.9114 6.68106 10.9114 6.34093 11.3288L5.01227 12.9592L5.04246 10.8562C5.05019 10.3178 4.56179 9.908 4.03295 10.0091L1.96714 10.404L3.34205 8.81242L2.96368 8.48556L3.34205 8.81242C3.69402 8.40497 3.58331 7.7771 3.11321 7.51461L1.27687 6.48926L3.35316 6.15381C3.88468 6.06793 4.20346 5.51579 4.01207 5.01254L3.26444 3.04669L5.07059 4.12433C5.53296 4.40021 6.13207 4.18215 6.30894 3.67361Z' />
    <Path
      style={{
        fill: 'none'
      }}
      d='M0 0h14v14H0z'
    />
  </Svg>
);
