import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalContent: css<{ maxHeight: number }>`
    padding-horizontal: 16px;
    margin-top: 16px;
    ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px` : '')}
  `,
  emailControl: css`
    margin-top: 8px;
  `,
  message: css`
    margin-top: 16px;
  `,
  divider: css`
    height: 1px;
    background-color: #e0e0e0;
    width: 100%;
    margin-vertical: 16px;
  `
};
