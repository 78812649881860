import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './ErrorTransferModal.styles';
import { useModal } from '../../ModalProvider';
import { Carbonicons, StyledText, StyledButton } from '@gf/cross-platform-lib/components';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const Title = styled.Text`
  ${styles.title};
`;

const Description = styled.Text`
  ${styles.description};
`;

const ClosedIconWrapper = styled.TouchableOpacity`
  ${styles.closedIconWrapper};
`;

const CloseButtonWrapper = styled.TouchableOpacity`
  ${styles.closeButtonWrapper};
`;

export const ErrorTransferModal = () => {
  const { hideModal } = useModal();

  return (
    <ModalWrapper>
      <ClosedIconWrapper onPress={hideModal}>
        <Carbonicons name='close' color='black' size={20}></Carbonicons>
      </ClosedIconWrapper>
      <Title>An error occurred!</Title>
      <Description>Please try again later.</Description>
      <CloseButtonWrapper>
        <StyledButton height='48px' onPress={hideModal} accessibilityLabel='Close'>
          <StyledText color='white' textAlign='center'>
            Close
          </StyledText>
        </StyledButton>
      </CloseButtonWrapper>
    </ModalWrapper>
  );
};
