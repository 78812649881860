import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from '@gf/cross-platform-lib/styled-components';
import { InputEmailType, RHFEmailInput } from '@gf/cross-platform-lib/components';
import { StyledText } from '@gf/cross-platform-lib/components/StyledText';
import { useGlobalLoadingContext } from '@gf/cross-platform-lib/providers/GlobalLoadingProvider';

import { Modal } from '../Modal';
import { useModal } from '../../ModalProvider';

import { BriefPassCard, ImportantInformationSection, SuccessMessage } from './components';
import { styles } from './PassTransferModal.styles';
import { PurchasedTicket } from '@gf/cross-platform-lib/interfaces';
import { processInvalidResponse, TransferResponse, TransferInvalidResponseData } from './utils';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

export enum TransferState {
  UNKNOWN,
  TRANSFERRING,
  SUCCESS,
  ERROR
}
export const PassTransferModal = () => {
  const { showGlobalLoading } = useGlobalLoadingContext();
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const {
    eventName,
    eventTypeName,
    userFullName,
    accessToken,
    transferMobilePass,
    onCompletedSuccess,
    onPassTransferred,
    onCompletedError
  } = modalProps || {};
  const [email, setEmail] = useState<string>('');
  const [transferState, setTransferState] = useState<TransferState>(TransferState.UNKNOWN);
  const [invalidResponse, setInvalidResponse] = useState<TransferResponse>();
  const [needRefetch, setNeedRefetch] = useState(true);
  const {
    handleSubmit,
    control,
    formState: { isValid, errors }
  } = useForm({
    defaultValues: {
      email: email
    },
    mode: 'onChange'
  });

  const onConfirm = handleSubmit(data => {
    if (transferState === TransferState.UNKNOWN) {
      setEmail(data.email);
      setTransferState(TransferState.TRANSFERRING);
    } else if (transferState === TransferState.SUCCESS) {
      onCompletedSuccess(accessToken);
    } else {
      onCompletedError(needRefetch);
    }
  });

  const onCancel = () => {
    switch (transferState) {
      case TransferState.UNKNOWN:
        return hideModal();
      case TransferState.ERROR:
        return onCompletedError(needRefetch);
      default:
        return hideModal();
    }
  };

  const renderTransferContent = () => (
    <>
      <BriefPassCard eventName={eventName} eventTypeName={eventTypeName} userFullName={userFullName} />
      <Message typeToken='body01'>Transfer your pass to a friend! Simply let us know where to send it.</Message>
      <EmailControl>
        <RHFEmailInput
          title='Recipient’s email'
          inputType={InputEmailType.GUEST}
          control={control}
          error={errors?.email?.message}
          autoFocus={true}
          required
          requiredMessage={`Please enter the recipient's email to continue`}
          placeholder='Enter email address'
          inputSize='md'
        />
      </EmailControl>
      <Divider />
      <ImportantInformationSection />
    </>
  );

  const renderContent = () => {
    switch (transferState) {
      case TransferState.ERROR:
        return null;
      case TransferState.SUCCESS:
        return (
          <SuccessMessage
            timeoutSeconds={5}
            eventTypeName={eventTypeName}
            transferredTo={email}
            onCloseModal={onCompletedSuccess}
          />
        );
      case TransferState.UNKNOWN | TransferState.TRANSFERRING:
        return renderTransferContent();
      default:
        return renderTransferContent();
    }
  };

  const getModalTitle = () => {
    switch (transferState) {
      case TransferState.ERROR:
        return processInvalidResponse(invalidResponse);
      case TransferState.SUCCESS:
        return {
          title: '',
          subTitle: '',
          confirmLabel: 'Done'
        };
      case TransferState.UNKNOWN | TransferState.TRANSFERRING:
        return {
          title: 'Transfer pass',
          subTitle: '',
          confirmLabel: 'Transfer'
        };
      default:
        return {
          title: 'Transfer pass',
          subTitle: '',
          confirmLabel: 'Transfer'
        };
    }
  };

  useEffect(() => {
    if (!email || transferState !== TransferState.TRANSFERRING) return;
    showGlobalLoading(true, { loadingText: 'Transferring...' });
    transferMobilePass(accessToken, email)
      .then((res: { status: number; data: PurchasedTicket | TransferInvalidResponseData }) => {
        if (res.status === 200 || res.status === 204) {
          onPassTransferred(res.data as PurchasedTicket);
          setTransferState(TransferState.SUCCESS);
        } else {
          setNeedRefetch(true);
          setTransferState(TransferState.ERROR);
          setInvalidResponse({ status: res.status, data: res.data as TransferInvalidResponseData });
        }
      })
      .catch((e: any) => {
        setNeedRefetch(false);
        setTransferState(TransferState.ERROR);
        recordError(e, {
          originatingFunction: 'PassTransferModal-transferMobilePass',
          customMessage: `Failed to transfer mobile pass to email ${email} using access token ${accessToken}.`,
          errorGroup: NEW_RELIC_ERROR_GROUPS.UserActions,
          data: { email, accessToken, transferState }
        });
      })
      .finally(() => {
        showGlobalLoading(false);
      });
  }, [email, transferState]);

  const { title, subTitle, confirmLabel } = getModalTitle();

  return (
    <Modal
      onCancel={onCancel}
      onConfirm={onConfirm}
      title={title}
      subTitle={subTitle}
      boldTitle
      confirmLabel={confirmLabel}
      disableConfirm={!isValid}
      inProgress={transferState === TransferState.TRANSFERRING}
      inProgressLabel={'Transferring ...'}
      hasClose={false}
      buttonSize='md'
      fillColor='#FFF'
    >
      <ModalContent>{renderContent()}</ModalContent>
    </Modal>
  );
};

const ModalContent = styled.ScrollView`
  ${styles.modalContent}
`;

const EmailControl = styled.View`
  ${styles.emailControl}
`;

const Message = styled(StyledText)`
  ${styles.message}
`;

const Divider = styled.View`
  ${styles.divider}
`;
