// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path, Rect } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const Previous = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='none' {...props}>
    <Rect width={32} height={32} fill='#ECECF0' rx={16} />
    <Path
      fill={props.fill}
      d='M22 16a.75.75 0 0 1-.75.75h-8.787l3.287 2.941a.75.75 0 1 1-1 1.118L10 16.559a.75.75 0 0 1 0-1.118l4.75-4.25a.75.75 0 1 1 1 1.118l-3.287 2.941h8.787A.75.75 0 0 1 22 16Z'
    />
  </Svg>
);
