import React from 'react';

export const NavigationContext = React.createContext({});

export const useNavigationContext = () => {
  return React.useContext(NavigationContext);
};

interface NavigationProviderProps {
  children: React.ReactNode;
}

export function NavigationProvider({ children }: NavigationProviderProps) {
  return <NavigationContext.Provider value={{}}>{children}</NavigationContext.Provider>;
}
