// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const Flow = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 32 32' {...props}>
    <Path d='M27 22.14V17a2 2 0 0 0-2-2h-8V9.86a4 4 0 1 0-2 0V15H7a2 2 0 0 0-2 2v5.14a4 4 0 1 0 2 0V17h18v5.14a4 4 0 1 0 2 0ZM8 26a2 2 0 1 1-2-2 2 2 0 0 1 2 2Zm6-20a2 2 0 1 1 2 2 2 2 0 0 1-2-2Zm12 22a2 2 0 1 1 2-2 2 2 0 0 1-2 2Z' />
    <Path
      data-name='&lt;Transparent Rectangle&gt;'
      style={{
        fill: 'none'
      }}
      d='M0 0h32v32H0z'
    />
  </Svg>
);
