import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  formContainer: css<{ maxHeight: number }>`
    overflow-y: auto;
    margin: 0 -16px;
    ::-webkit-scrollbar {
      width: 12px;
    }
    ::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 9999px;
      background-color: #aaaaaa;
    }
  `,

  form: css`
    padding: 0 16px;
  `,

  formFieldContainer: css<{ isLastItem?: boolean }>`
    margin-bottom: ${({ isLastItem }) => (isLastItem ? 0 : '16px')};
  `
};
