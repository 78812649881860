// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

interface WarningAltFilledProps extends SvgProps {
  exclamationColor?: string;
  shouldHaveBorder?: string;
}
export const WarningAltFilled = (props: WarningAltFilledProps) => (
  <Svg
    id='gf_icon_warning_alt_filled'
    aria-label='Warning alt icon'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='32px'
    height='32px'
    viewBox='0 0 32 32'
    xmlSpace='preserve'
    enableBackground='new 0 0 32 32'
    {...props}
  >
    <Path
      id='inner-path'
      d='M16,26a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,16,26Zm-1.125-5h2.25V12h-2.25Z'
      opacity={props.opacity}
      fill={props.exclamationColor || '#fff'}
    />
    <Path
      fill={props.color || 'black'}
      d='M16.002,6.1714h-.004L4.6487,27.9966,4.6506,28H27.3494l.0019-.0034ZM14.875,12h2.25v9h-2.25ZM16,26a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,16,26Z'
    />
    {props.shouldHaveBorder === 'false' ? null : (
      <Path d='M29,30H3a1,1,0,0,1-.8872-1.4614l13-25a1,1,0,0,1,1.7744,0l13,25A1,1,0,0,1,29,30ZM4.6507,28H27.3493l.002-.0033L16.002,6.1714h-.004L4.6487,27.9967Z' />
    )}
    <Path id='WarningAltFilled_Transparent_Rectangle' fill='none' />
  </Svg>
);
