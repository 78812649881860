import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import styled from 'styled-components';
import { DrawerProps, getBottomDrawerSpringValues } from '../drawerUtil';
import { styles } from '../base.styles';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { Dimensions, TouchableOpacity } from 'react-native';
import { APP_FOOTER_SIZE, APP_HEADER_SIZE } from '../drawerConstants';

const windowHeight = Dimensions.get('window').height;

export const BottomDrawer = ({ content, drawerOpen, drawerSize }: DrawerProps) => {
  const { isMobile, isNativeApp } = useMediaQuery();

  const springStyle = useSpring({
    ...getBottomDrawerSpringValues(drawerOpen)
  });

  return (
    <BottomDrawerContainer
      isMobile={isMobile.toString()}
      isNative={isNativeApp.toString()}
      drawerSize={drawerSize?.toString()}
      maxHeight={
        isMobile
          ? (windowHeight - APP_HEADER_SIZE - APP_FOOTER_SIZE).toString() + 'px'
          : windowHeight - APP_HEADER_SIZE + 'px'
      }
      style={{
        ...springStyle
      }}
    >
      <TouchableOpacity activeOpacity={1} style={{ height: '100%' }}>
        {content}
      </TouchableOpacity>
    </BottomDrawerContainer>
  );
};

const BottomDrawerContainer = styled(animated.div)`
  ${styles.bottomDrawerContainer}
`;

export default BottomDrawer;
