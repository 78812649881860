import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './LimitedTicketModal.styles';
import { ORDER_LIMIT, ORDER_LIMIT_PER_TICKET, TICKET_LIMIT } from '@gf/cross-platform-lib/constants';
import { Carbonicons, StyledButton } from '@gf/cross-platform-lib/components';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { useModal } from '../../ModalProvider';
import pluralize from 'pluralize';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const Container = styled.View`
  ${styles.container};
`;

const Title = styled.Text`
  ${styles.title};
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

const BtnContainer = styled.View<{ isCenter: boolean }>`
  ${styles.container};
  justify-content: ${({ isCenter = false }) => (isCenter ? 'center' : 'flex-end')};
`;

const ContentWrapper = styled.View`
  ${styles.contentWrapper}
`;

const ContentContainer = styled.View<{ paddingRight: number }>`
  ${styles.contentContainer}
`;

const CloseBtnContainer = styled.TouchableOpacity`
  ${styles.closeBtnContainer}
`;

export interface LimitedTicketModalProps {}

export const LimitedTicketModal = ({}: LimitedTicketModalProps) => {
  const { isMobile } = useMediaQuery();
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const {
    type = TICKET_LIMIT,
    ticketsLeft = 0,
    isPromotionRequired,
    packCount,
    onConfirm = () => {
      hideModal();
    },
    isSeason
  } = modalProps || {};
  const modalWidth = type === TICKET_LIMIT ? 400 : 640;
  const modalHeight = type === TICKET_LIMIT ? 206 : 210;
  const isOrAre = ticketsLeft > 1 ? 'are' : 'is';
  const ticketOrTickets = ticketsLeft > 1 ? 'tickets' : 'ticket';
  return (
    <ModalWrapper isMobile={isMobile} height={modalHeight} width={isMobile ? 320 : modalWidth}>
      {type === ORDER_LIMIT && (
        <CloseBtnContainer onPress={onConfirm}>
          <Carbonicons name='close' size={22} color='#161616' />
        </CloseBtnContainer>
      )}
      <ContentWrapper>
        <ContentContainer paddingRight={type === ORDER_LIMIT ? (isMobile ? 40 : 84) : 0}>
          <Container>
            <Title>{`Only ${ticketsLeft} ${ticketOrTickets} ${type === TICKET_LIMIT ? 'left' : 'allowed'}!`}</Title>
          </Container>
          <SubTitle isMobile={isMobile} marginBottom={isMobile || type === TICKET_LIMIT ? 24 : 48}>
            {isSeason
              ? `We’re sorry, only ${ticketsLeft} ${pluralize(
                  'ticket',
                  ticketsLeft
                )} can be purchased at a time for this season. Please adjust the number of tickets.`
              : type === TICKET_LIMIT
              ? isPromotionRequired
                ? packCount && packCount > 0
                  ? `We're sorry, the access promo code is only available for ${ticketsLeft} ${ticketOrTickets}. The total number of tickets within the pack exceeds such limit. Please adjust the number of ticket packs to continue.`
                  : `We're sorry, the access promo code is only available for ${ticketsLeft} ${ticketOrTickets}. Please adjust the number of tickets to continue.`
                : `We’re sorry, there ${isOrAre} only ${ticketsLeft} ${ticketOrTickets} remaining for this ticket type. If your selection exceeds the limit, we’ll automatically adjust the ticket count.`
              : type === ORDER_LIMIT
              ? `We’re sorry, only ${ticketsLeft} ${pluralize(
                  'ticket',
                  ticketsLeft
                )} can be purchased at a time for this event. Please adjust the number of tickets.`
              : type === ORDER_LIMIT_PER_TICKET
              ? `We’re sorry, only ${ticketsLeft} ${pluralize(
                  'ticket',
                  ticketsLeft
                )} can be purchased at a time for this ticket. Please adjust the number of tickets.`
              : `We’re sorry, there ${isOrAre} only ${ticketsLeft} ${ticketOrTickets} remaining for this event. If your selection exceeds the limit, we’ll automatically adjust the ticket count.`}
          </SubTitle>
        </ContentContainer>
      </ContentWrapper>
      <BtnContainer isCenter={type === ORDER_LIMIT}>
        <StyledButton buttonType='primary' title='OK' accessibilityLabel='OK' onPress={onConfirm} width='128px' />
      </BtnContainer>
    </ModalWrapper>
  );
};
