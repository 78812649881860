import { Platform } from 'react-native';

const regex = /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/;
export const getLocation = function (href: string) {
  const match = href.match(regex);
  const protocol = match ? match[1] : null;
  const host = match ? match[2] : null;
  const origin = protocol && host ? `${protocol}//${host}` : null;
  return {
    href: href,
    protocol,
    host,
    origin,
    hostname: match ? match[3] : null,
    port: match ? match[4] : null,
    pathname: match ? match[5] : null,
    search: match ? match[6] : null,
    hash: match ? match[7] : null
  };
};
export const sameOrigin = function (urlA: string, urlB: string) {
  const locationA = getLocation(urlA);
  const locationB = getLocation(urlB);

  return locationA.origin && locationB.origin && locationA.origin === locationB.origin;
};

// urlOrPathString: the URL object or URL string to normalize; string variant may contain query/search string
export const normalize = function (urlOrPathString: URL | string): URL {
  let url: URL;

  if (typeof urlOrPathString !== 'string') {
    // it's not a string, so it must be a URL
    url = urlOrPathString;
  } else {
    try {
      // if urlOrPathString is actually a complete URL...
      url = new URL(urlOrPathString);
    } catch (error) {
      // if not, this is a path and we need to build the URL
      url = new URL(`${window.location.protocol}${window.location.host}${urlOrPathString}`);
    }
  }

  return url;
};

// Compares URLs (as strings) for equality
// includeSearch: whether to include the search string in the normalized URL
export const urlsAreEqual = function (
  url1: URL,
  url2: URL,
  includeSearch: boolean = true,
  includeHash: boolean = false
): boolean {
  let u1 = url1;
  let u2 = url2;

  u1 = includeHash ? u1 : new URL(u1.toString().replace(u1.hash, ''));
  u2 = includeHash ? u2 : new URL(u2.toString().replace(u2.hash, ''));

  u1 = includeSearch ? u1 : new URL(u1.toString().replace(u1.search, ''));
  u2 = includeSearch ? u2 : new URL(u2.toString().replace(u2.search, ''));

  return u1.toString() === u2.toString();
};

export const relocate = function (
  urlOrPath: URL | string,
  as?: string,
  options?: object,
  reloadOnQueryChange?: boolean
) {
  // should only be called on the client
  if (typeof window !== 'undefined' && typeof history !== 'undefined') {
    const newLocation: URL = typeof urlOrPath === 'string' ? normalize(urlOrPath) : urlOrPath;
    const shouldNavigate = !urlsAreEqual(new URL(window.location.href), newLocation, !!reloadOnQueryChange);

    // prevent eternal reloading
    if (shouldNavigate) {
      window.location.assign(newLocation);
    } else {
      // but keep track of where we've been
      history.pushState(null, '', newLocation);
    }
  }
};

export const serialize = function (obj: any) {
  let str =
    '?' +
    Object.keys(obj)
      .reduce(function (a: string[], k) {
        a.push(`${k}=${encodeURIComponent(obj[k])}`);
        return a;
      }, [])
      .join('&');
  return str;
};

export const removeURLParameter = function (url: string, parameter: string) {
  const urlparts = url.split('?');
  if (urlparts.length >= 2) {
    const prefix = encodeURIComponent(parameter) + '=';
    const parts = urlparts[1].split(/[&;]/g);

    for (let i = parts.length - 1; i >= 0; i -= 1) {
      if (parts[i].lastIndexOf(prefix, 0) !== -1) {
        parts.splice(i, 1);
      }
    }

    return urlparts[0] + (parts.length > 0 ? '?' + parts.join('&') : '');
  }
  return url;
};

export const isProductionDomain = () => {
  if (Platform.OS !== 'web') {
    return false;
  }
  return window.location.hostname === 'gofan.co';
};

const allowedDomains = [
  'development.iron.gofan.co',
  'development.gofan.co',
  'dev1.gofan.co',
  'dev2.gofan.co',
  'dev3.gofan.co',
  'dev4.gofan.co',
  'dev5.gofan.co',
  'qa1.gofan.co',
  'qa2.gofan.co',
  'qa3.gofan.co',
  'qa4.gofan.co',
  'qa5.gofan.co',
  'staging.iron.gofan.co',
  'staging.gofan.co',
  'iron.gofan.co',
  'gofan.co',
  'www.development.iron.gofan.co',
  'www.development.gofan.co',
  'www.dev1.gofan.co',
  'www.dev2.gofan.co',
  'www.dev3.gofan.co',
  'www.dev4.gofan.co',
  'www.dev5.gofan.co',
  'www.qa1.gofan.co',
  'www.qa2.gofan.co',
  'www.qa3.gofan.co',
  'www.qa4.gofan.co',
  'www.qa5.gofan.co',
  'www.staging.iron.gofan.co',
  'www.staging.gofan.co',
  'www.iron.gofan.co',
  'www.gofan.co'
];

export const transformToHomeWebUrl = (originalURL: string) => {
  //Regex valid URL
  const urlRegex =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;
  const replacedURL = originalURL.replace(urlRegex, (match, protocol, subdomainAndDomain) => {
    const matchedDomain = allowedDomains.find(allowedUrl => allowedUrl === subdomainAndDomain);
    if (matchedDomain) {
      const hasWWW = subdomainAndDomain.slice(0, 3) === 'www';
      return hasWWW ? match.replace('www.', 'www.web.') : match.replace('://', '://web.');
    }
    return originalURL;
  });

  return replacedURL;
};
