import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { GFImage, StyledText } from '@gf/cross-platform-lib/components';
import { activityIcons } from '@gf/cross-platform-lib/constants';

export interface BriefPassCardProps {
  eventName: string;
  eventTypeName: string;
  userFullName: string;
}

export const BriefPassCard = ({ eventName, eventTypeName, userFullName }: BriefPassCardProps) => {
  return (
    <Wrapper>
      <Columns>
        <LeftColumn>
          <GFImage name={activityIcons.digitalseasonpass} width={20} height={14} />
        </LeftColumn>
        <RightColumn>
          <StyledText typeToken='heading02' color='#323232' style={{ width: 220 }}>
            {eventName}
          </StyledText>
          <StyledText typeToken='body01'>{eventTypeName}</StyledText>
          <StyledText typeToekn='body01'>{userFullName}</StyledText>
        </RightColumn>
      </Columns>
    </Wrapper>
  );
};

const Wrapper = styled.View`
  background-color: #f9f9f9;
  padding: 16px 14px;
`;

const Columns = styled.View`
  flex-direction: row;
`;

const LeftColumn = styled.View`
  margin-top: 5px;
`;
const RightColumn = styled.View`
  margin-left: 8px;
`;
