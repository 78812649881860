import { GetCurrentApplicationConfiguration, Method } from '@gf/cross-platform-lib/utils';
import { PromotionLockResponse, Promotion, PromotionLockResponseError } from '@gf/cross-platform-lib/interfaces';
import { safeFetch } from '../utils';

const FALLBACK_EROR: PromotionLockResponseError = {
  body: null,
  message: 'An unknown error occurred.',
  statusCode: 500
};

const appConfig = GetCurrentApplicationConfiguration();
export const createPromotionLock = async (
  eventId: number,
  promoCode: string,
  quantity: number = 0,
  isSeason?: boolean
) => {
  const url = appConfig.api.promotions.getUrlForMethodAndId!(Method.POST, '');
  const { data, error } = await safeFetch<PromotionLockResponse>(
    url!,
    {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify(isSeason ? { seasonId: eventId, quantity, promoCode } : { eventId, promoCode, quantity })
    },
    { redirect404: false }
  );

  return data || (error?.data as PromotionLockResponseError) || FALLBACK_EROR;
};

export const deletePromoLock = async (promoId: string) => {
  const url = appConfig.api.promotions.getUrlForMethodAndId!(Method.DELETE, promoId);
  return await safeFetch(
    url!,
    {
      method: 'DELETE',
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    },
    { redirect404: false }
  );
};

export const updatePromoLock = async (promoId: string, quantity: number) => {
  const url = appConfig.api.promotions.getUrlForMethodAndId!(Method.PATCH, promoId);
  const { data, error } = await safeFetch<PromotionLockResponse>(
    url!,
    {
      method: 'PATCH',
      headers: new Headers({
        'Content-Type': 'application/json'
      }),
      body: JSON.stringify({ id: promoId, quantity })
    },
    { redirect404: false }
  );
  return data || (error?.data as PromotionLockResponseError) || FALLBACK_EROR;
};

export const getPromoLock = async (promoId: string) => {
  const url = appConfig.api.promotions.getUrlForMethodAndId!(Method.GET, promoId);
  const { data, error } = await safeFetch<Promotion>(
    url!,
    {
      method: 'GET',
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    },
    { redirect404: false }
  );
  return data || (error?.data as PromotionLockResponseError) || FALLBACK_EROR;
};
