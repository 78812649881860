import { useSegmentTracking } from '../../providers/Tracking';
import { useFirebase } from '../../providers/Firebase';
import { Fan } from '@gf/cross-platform-lib/models';
import { getDefaultIdentifyPayload, getSignUpSource, setSignUpSourceCache } from './utils';
import { useMediaQuery } from '../useMediaQuery';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

interface IIdentifyPhoneNumberChangedPayload {
  phoneNumber: string;
  receiptEmail: string;
}

export const useIdentifyTracking = () => {
  const { identify } = useSegmentTracking();
  const { ga4 } = useFirebase();
  const { isMobile } = useMediaQuery();
  const identifyFavoriteSchool = async (fan: Fan) => {
    try {
      if (fan?.firebaseUser) {
        identify({
          ...(await getDefaultIdentifyPayload(fan)),
          user_type: 'registered user'
        });
      }
    } catch (err: any) {
      recordError(err, {
        customMessage: `Failed to identify fan for analytics after toggling a favorite school`,
        originatingFunction: 'useIdentifyTracking-identifyFavoriteSchool',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { fan }
      });
    }
  };

  const identifySignUp = async (fan: Fan) => {
    try {
      const payload = await getDefaultIdentifyPayload(fan);
      payload.signup_source = getSignUpSource(isMobile);
      await identify(payload);
      setSignUpSourceCache(fan.email, payload.signup_source || '');
      await ga4.track('account_created', {
        account_name: fan.email
      });
    } catch (err: any) {
      recordError(err, {
        customMessage: `Error identifying fan after sign-up`,
        originatingFunction: 'useIdentifyTracking-identifySignUp',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { fan }
      });
    }
  };
  const identifySignIn = async (fan: Fan) => {
    try {
      await identify({
        ...(await getDefaultIdentifyPayload(fan))
      });
      await ga4.track('signed_in', {
        username: fan.email
      });
    } catch (err: any) {
      recordError(err, {
        customMessage: `Error identifying fan after sign-in`,
        originatingFunction: 'useIdentifyTracking-identifySignIn',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { fan }
      });
    }
  };

  const identifySignOut = async (fan: Fan) => {
    try {
      await ga4.track('signed_out', {
        username: fan.email
      });
    } catch (err: any) {
      recordError(err, {
        customMessage: `Error identifying fan after sign-out`,
        originatingFunction: 'useIdentifyTracking-identifySignOut',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { fan }
      });
    }
  };

  const identifyForgotPassword = async (email: string) => {
    try {
      await ga4.track('forgot_password', {
        username: email
      });
    } catch (err: any) {
      recordError(err, {
        customMessage: `Error identifying fan after password reset email sent`,
        originatingFunction: 'useIdentifyTracking-identifyForgotPassword',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { email }
      });
    }
  };

  const identifyPhoneNumberChanged = async (
    fan: Fan,
    { phoneNumber, receiptEmail }: IIdentifyPhoneNumberChangedPayload
  ) => {
    try {
      if (fan?.firebaseUser) {
        await identify({
          ...(await getDefaultIdentifyPayload(fan)),
          phone_number: phoneNumber,
          email: receiptEmail,
          user_type: 'registered user'
        });
      } else {
        await identify({
          phone_number: phoneNumber,
          email: receiptEmail,
          user_type: 'guest',
          favorite_schools: undefined,
          signup_source: undefined,
          created_at: undefined
        });
      }
    } catch (err: any) {
      recordError(err, {
        customMessage: `Error identifying fan after phone number was changed`,
        originatingFunction: 'useIdentifyTracking-IIdentifyPhoneNumberChanged',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { fan, phoneNumber, receiptEmail }
      });
    }
  };
  const identifyPageView = async (fan: Fan) => {
    try {
      if (fan?.firebaseUser) {
        await identify({
          ...(await getDefaultIdentifyPayload(fan))
        });
      }
    } catch (err: any) {
      recordError(err, {
        customMessage: `Error identifying fan upon page view`,
        originatingFunction: 'useIdentifyTracking-identifyPageView',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { fan }
      });
    }
  };

  const identifyOrderCompleted = async (fan: Fan, receiptEmail: string) => {
    try {
      const payload = await getDefaultIdentifyPayload(fan);
      const isGuest = !fan?.firebaseUser;
      if (isGuest) {
        await identify({
          ...payload,
          email: receiptEmail,
          user_type: 'guest',
          created_at: undefined,
          signup_source: undefined,
          favorite_schools: undefined
        });
      } else {
        await identify({
          ...payload,
          user_type: 'registered user'
        });
      }
    } catch (err: any) {
      recordError(err, {
        originatingFunction: 'useIdentifyTracking-identifyOrderCompleted',
        customMessage: `Error identifying fan after order completion`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { fan, receiptEmail }
      });
    }
  };

  const identifyChangePassword = async (email: string) => {
    try {
      await ga4.track('change_password', {
        username: email
      });
    } catch (err: any) {
      recordError(err, {
        originatingFunction: 'useIdentifyTracking-identifyChangePassword',
        customMessage: `Error identifying fan after they changed their password`,
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { email }
      });
    }
  };

  return {
    identifyFavoriteSchool,
    identifySignUp,
    identifySignIn,
    identifyPhoneNumberChanged,
    identifyPageView,
    identifyOrderCompleted,
    identifySignOut,
    identifyForgotPassword,
    identifyChangePassword
  };
};
