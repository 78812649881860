import { css } from 'styled-components';

export const styles = {
  wrapper: css`
    position: relative;
    height: 100px;
  `,
  warningWrapper: css`
    position: absolute;
    height: 68px;
    width: 68px;
    right: 1;
    bottom: 0;
    background-color: #323232;
    border-radius: 34px;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
  `
};
