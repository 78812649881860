import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { StyledButton } from '@gf/cross-platform-lib/components';
import { View } from 'react-native';
import { StyledText } from '@gf/cross-platform-lib/components/StyledText';
import { styles } from './Tab.styles';
import { reviewAndBuyPage, testProperties } from '@gf/cross-platform-lib/utils';

const Container = styled.View`
  ${styles.container}
`;

const Content = styled.View`
  ${styles.contentContainer}
`;

const ActionButtons = styled.View`
  ${styles.actionButtons}
`;

const Title = styled(StyledText)`
  ${styles.title}
`;

const SubTitle = styled(StyledText)`
  ${styles.subTitle}
`;

export interface TabProps {
  title: string;
  subTitle: string;
  hasClose?: boolean;
  hasConfirm?: boolean;
  confirmLabel?: string;
  cancelLabel?: string;
  disableClose?: boolean;
  disableConfirm?: boolean;
  isTall?: boolean;
  ignoreTabSelection?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  children?: React.ReactNode;
}

export const Tab = ({
  title,
  subTitle,
  hasClose = true,
  hasConfirm = true,
  disableClose = false,
  disableConfirm = false,
  confirmLabel = 'Save',
  cancelLabel = 'Cancel',
  ignoreTabSelection = false,
  onCancel,
  onConfirm,
  children
}: TabProps) => {
  return (
    <Container>
      {title && <Title typeToken='heading03'>{title}</Title>}
      {subTitle && <SubTitle typeToken='body01'>{subTitle}</SubTitle>}
      <Content>{children}</Content>
      <ActionButtons>
        {hasClose && (
          <View style={{ flex: 1, marginRight: 8 }} {...testProperties(reviewAndBuyPage.addInfoDialog.cancel)}>
            <StyledButton
              size='lg'
              buttonType='tertiary'
              disabled={disableClose}
              onPress={onCancel}
              tabIndex={ignoreTabSelection ? -1 : undefined}
              title={cancelLabel}
            />
          </View>
        )}
        {hasConfirm && (
          <View style={{ flex: 1, marginLeft: 8 }} {...testProperties(reviewAndBuyPage.addInfoDialog.save)}>
            <StyledButton
              size='lg'
              disabled={disableConfirm}
              onPress={onConfirm}
              tabIndex={ignoreTabSelection ? -1 : undefined}
              title={confirmLabel}
            ></StyledButton>
          </View>
        )}
      </ActionButtons>
    </Container>
  );
};
