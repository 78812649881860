import { getLocation, navigate, safeOrigins } from '@gf/cross-platform-lib/utils';
import { FirebaseRef } from '@gf/cross-platform-lib/providers/Firebase';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { SafeFetchResponse } from '@gf/cross-platform-lib/interfaces';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

type ResponseOptions = {
  redirect404?: boolean;
  showLayoutOnError?: boolean;
};

export interface PrivateSafeFetchOptions extends RequestInit {
  requiredAuthHeader?: boolean;
  headers?: HeadersInit;
}

export const safeFetch = <T>(
  requestedUrl: string,
  requestOptions?: RequestInit,
  responseOptions?: ResponseOptions
): Promise<SafeFetchResponse<T>> => {
  const { origin } = getLocation(requestedUrl);
  if (!safeOrigins.includes(origin)) {
    throw new Error('Unexpected url origin.');
  }
  const redirect404 =
    responseOptions && typeof responseOptions.redirect404 !== 'undefined' ? responseOptions.redirect404 : true;
  const promise = fetch(requestedUrl, requestOptions).then(async (res: Response) => {
    if (res.status === 503) {
      navigate('maintenance');
    }
    if (res.status === 404 && redirect404) {
      navigate('error', { code: 404, showLayoutOnError: responseOptions?.showLayoutOnError ?? true });
    }
    if (res.status === 401 && redirect404) {
      navigate('error', { code: 401 });
    }
    if (res.status === 410) {
      navigate('updateApp');
    }

    const data = await res
      .json()
      .then(data => data as T)
      .catch(() => null);

    //success res
    if (res.status == 200 || res.status === 201) {
      return {
        status: res.status,
        data,
        error: null
      } as SafeFetchResponse<T>;
    }
    return {
      status: res.status,
      data: null,
      error: {
        code: res.status,
        message: res.statusText,
        data
      }
    } as SafeFetchResponse<T>;
  });

  return promise;
};

export const privateSafeFetch = async <T>(
  requestedUrl: string,
  requestOptions?: PrivateSafeFetchOptions,
  responseOptions?: ResponseOptions
): Promise<SafeFetchResponse<T>> => {
  const authToken = await FirebaseRef.current.getAuthToken();
  const requiredHeaderOptions: any = {
    'Content-Type': 'application/json',
    Authorization: ''
  };
  if (requestOptions && requestOptions.requiredAuthHeader && !authToken) {
    recordError('Missing auth token', {
      originatingFunction: 'httpClient-privateSafeFetch',
      customMessage: 'Missing authentication token while making a private fetch request',
      errorGroup: NEW_RELIC_ERROR_GROUPS.Firebase,
      data: {
        requestedUrl,
        requestOptions
      }
    });
    navigate('error', { code: 401 });
  } else if (authToken) {
    requiredHeaderOptions.Authorization = `Bearer ${authToken}`;
  }
  const updatedRequestOptions: any = typeof requestOptions === 'object' ? { ...requestOptions } : {};
  (updatedRequestOptions.headers = new Headers(updatedRequestOptions.headers)),
    Object.entries(requiredHeaderOptions).forEach(([key, value]) => {
      updatedRequestOptions.headers.set(key, value);
    });

  return safeFetch<T>(requestedUrl, updatedRequestOptions, responseOptions);
};
