import { safeFetch } from '../utils';
import { School, SchoolQueryParams, IPaginationResponse, SchoolDistrictInfo } from '@gf/cross-platform-lib/interfaces';
import { GetCurrentApplicationConfiguration, parseQueryParamsToString } from '@gf/cross-platform-lib/utils';
import isArray from 'lodash/isArray';
import uniqBy from 'lodash/uniqBy';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

const appConfig = GetCurrentApplicationConfiguration();
const schoolIdRegex = /^[a-zA-Z 0-9-\\._]+$/;

export async function fetchSchool(id: string, queryParams?: SchoolQueryParams, redirect404: boolean = false) {
  if (!id || !schoolIdRegex.test(String(id))) {
    recordError(`Invalid ID used to fetch school: ${id} with params ${queryParams}`, {
      originatingFunction: 'schoolApis-fetchSchool',
      customMessage: 'Attempted to fetch school with invalid or blank ID',
      errorGroup: NEW_RELIC_ERROR_GROUPS.DataIssues,
      data: { id, queryParams }
    });
    throw 'Error fetching school information.';
  }
  const url = `${appConfig.api.schools.baseUrl}/schools/${id}${parseQueryParamsToString(queryParams)}`;
  return safeFetch<School>(url, undefined, {
    redirect404
  });
}

export const getAllSchools = async () => {
  const data = await getPaginatedSchools(0, 2000);
  const totalPages = data.data?.totalPages;
  let schools = data.data?.content || [];

  if (totalPages && totalPages > 1) {
    const nextSchools = await Promise.all(
      Array.from(Array(totalPages - 1).keys()).map(item => getPaginatedSchools(item + 1, 2000))
    );
    nextSchools.forEach(item => {
      schools = schools.concat(item.data?.content || []);
    });
  }

  return uniqBy(schools, 'huddleId');
};

export const getPaginatedSchools = async (page: number = 0, size: number = 2000) => {
  const url = `${appConfig.api.schools.baseUrl}/schools/?page=${page}&size=${size}`;
  const data = await safeFetch<IPaginationResponse<School>>(url);
  return {
    data: {
      ...data.data,
      content: uniqBy(data.data?.content || [], 'huddleId')
    }
  };
};

export const fetchAllStates = async () => {
  const url = `${appConfig.assets.host}/static/listVenueState.json`;
  const res = await safeFetch<{ abbreviation: string; stateName: string }[]>(url!);
  return isArray(res.data) ? res.data : undefined;
};

export const getAllSchoolsForSchoolDistrict = async (schoolDistrictHuddleId: string) => {
  const url = `${appConfig.api.schools.baseUrl}/schools/school-district/${schoolDistrictHuddleId}`;
  return await safeFetch<SchoolDistrictInfo[]>(url!);
};
