import { Carbonicons } from '@gf/cross-platform-lib/components';
import { AdditionalFieldTypes } from '@gf/cross-platform-lib/interfaces';
import React from 'react';
import { FieldValues, RegisterOptions, Control, Controller, UseFormSetValue } from 'react-hook-form';
import styled from 'styled-components';

import { styles } from './RHFSelect.styles.web';

interface RHFSelectProps {
  options?: string[];
  fieldType: AdditionalFieldTypes;
  control: Control<FieldValues, any>;
  setValue: UseFormSetValue<FieldValues>;
  name: string;
  rules?: RegisterOptions;
  required?: boolean;
  disabled?: boolean;
}

const Select = styled.select`
  ${styles.select}
`;

const InputWrapper = styled.div`
  ${styles.inputWrapper}
`;

const IconWrapper = styled.div`
  ${styles.iconWrapper}
`;

export const RHFSelect = ({ name, rules, required, control, options, disabled }: RHFSelectProps) => {
  const ownedRules = (): RegisterOptions => {
    const defaultOptions: RegisterOptions = {
      required: {
        value: required || false,
        message: 'Please make a selection'
      }
    };
    return defaultOptions;
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        ...ownedRules(),
        ...rules
      }}
      render={({ field: { onChange, onBlur, value } }) => (
        <InputWrapper>
          <Select disabled={disabled} onChange={onChange} onBlur={onBlur} value={value} defaultValue={options?.at(0)}>
            <option hidden>{required ? 'Please select an option' : ''}</option>
            {options &&
              options.map(value => {
                const newValue = value || '';

                return (
                  <option key={newValue} value={newValue}>
                    {newValue}
                  </option>
                );
              })}
          </Select>
          <IconWrapper>
            <Carbonicons name='chevron-down' size={14} color={disabled ? '#dbdbdb' : '#161616'} />
          </IconWrapper>
        </InputWrapper>
      )}
    />
  );
};
