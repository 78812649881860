import styled from '@gf/cross-platform-lib/styled-components';
import { AnimatedSpinner } from './AnimatedSpinner';
import { View, ActivityIndicator, ColorValue } from 'react-native';
import React from 'react';
import { loadingIndicator, testProperties } from '@gf/cross-platform-lib/utils';

interface LoadingSpinnerProps {
  transparency?: string;
  loadingText?: string;
  usingLoadingIndicator?: boolean;
  asSpinner?: boolean;
  spinnerSize?: number;
  spinnerColor?: ColorValue;
}

export const LoadingSpinner = ({
  transparency,
  loadingText = 'Loading',
  usingLoadingIndicator,
  asSpinner,
  spinnerSize,
  spinnerColor
}: LoadingSpinnerProps) => {
  if (asSpinner) {
    return usingLoadingIndicator ? (
      <LoadingIndicator size={spinnerSize || 12} color={spinnerColor || '#323232'} />
    ) : (
      <AnimatedSpinner size={spinnerSize || 12} color={spinnerColor || '#323232'} />
    );
  }
  return (
    <>
      <LoadingOverlay {...testProperties(loadingIndicator)} transparency={transparency || '0.7'}>
        {usingLoadingIndicator ? (
          <LoadingIndicator size={spinnerSize || 40} color={spinnerColor || '#323232'} />
        ) : (
          <Box>
            <AnimatedSpinner size={40} color={spinnerColor || '#323232'} />
            {loadingText ? <LoadingText>{loadingText}</LoadingText> : null}
          </Box>
        )}
      </LoadingOverlay>
    </>
  );
};

const LoadingOverlay = styled(View)<LoadingSpinnerProps>`
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.66);
`;

const LoadingIndicator = styled(ActivityIndicator)``;

const Box = styled.View`
  width: 160px;
  height: 160px;
  background-color: #f4f4f4;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  z-index: 99999;
`;

const LoadingText = styled.Text`
  height: 20px;
  color: #323232;
  font-size: 20px;
  font-family: Rubik-SemiBold;
  font-weight: 600;
  text-align: center;
  letter-spacing: 0px;
  line-height: 20px;
  margin-top: 16px;
`;
