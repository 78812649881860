import React from 'react';
import { ModalProps } from './types';
import { View } from 'react-native';

export const Modal = (props: ModalProps) => {
  return (
    <View style={{ margin: 'auto', width: '100%', height: '100%', position: 'absolute', zIndex: 99999 }}>
      {props.children}
    </View>
  );
};
