import { EventOrderHistory, OrderHistory, ProductType } from '@gf/cross-platform-lib/interfaces';
import { ActivityAchievement, ProfileData, ProfileOrder } from './profileInterfaces';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

// TO DO: Change/remove when new profile-lite endpoints are available
// Temporary method to use the current OrderService's endpoints until new ones are up
export const mapToProfileData = (orders: ProfileOrder[]): ProfileData => {
  return orders.reduce(
    (profileData, order) => {
      order.tickets.forEach(ticket => {
        profileData.uniqueEvents.add(Number(ticket._embedded.event.id));
        const activityLabel = ticket._embedded.event._embedded.activity.label;
        if (profileData.activityAchievements.has(activityLabel)) {
          const activityAchievement = profileData.activityAchievements.get(activityLabel);
          profileData.activityAchievements.set(activityLabel, {
            ...activityAchievement,
            timesAttended: activityAchievement?.timesAttended! + 1,
            playoffEvents: ticket._embedded.event.postSeason
              ? activityAchievement?.playoffEvents! + 1
              : activityAchievement?.playoffEvents,
            seasonTicketHolder: activityAchievement?.seasonTicketHolder || ticket.seasonId !== null,
            passHolder: activityAchievement?.passHolder || ticket.productType === ProductType.MOBILEPASS
          } as ActivityAchievement);
        } else {
          profileData.activityAchievements.set(activityLabel, {
            timesAttended: 1,
            playoffEvents: ticket._embedded.event.postSeason ? 1 : 0,
            seasonTicketHolder: ticket.seasonId !== null,
            passHolder: ticket.productType === ProductType.MOBILEPASS
          } as ActivityAchievement);
        }
      });
      return profileData;
    },
    {
      uniqueEvents: new Set<number>(),
      favoriteSchools: 0,
      activityAchievements: new Map<string, ActivityAchievement>()
    } as ProfileData
  );
};

// Method header due to change when profile API endpoints are finished.
// Return top 4 activities with priority ->
//  1. Season holder
//  2. Pass holder
//  3. Playoff event
//  4. Number of event per activity type
export const sortActivityAchievements = (activityAchievements: Map<string, ActivityAchievement>) => {
  return Array.from(activityAchievements.entries())
    .sort((a, b) => {
      const firstEvent = a[1];
      const secondEvent = b[1];
      if (firstEvent.seasonTicketHolder !== secondEvent.seasonTicketHolder) {
        return firstEvent.seasonTicketHolder ? -1 : 1;
      } else if (firstEvent.passHolder !== secondEvent.passHolder) {
        return firstEvent.passHolder ? -1 : 1;
      } else if (firstEvent.playoffEvents !== secondEvent.playoffEvents) {
        return secondEvent.playoffEvents - firstEvent.playoffEvents;
      } else {
        return secondEvent.timesAttended - firstEvent.timesAttended;
      }
    })
    .slice(0, 4);
};

export const mapToOrderHistoryData = (orders: EventOrderHistory[] | undefined): OrderHistory[] => {
  if (isEmpty(orders)) {
    return [];
  }

  const groupByOrders: { [key: string]: OrderHistory } = {};
  orders?.forEach(item => {
    if (isEmpty(groupByOrders[item.order_uuid])) {
      groupByOrders[item.order_uuid] = {
        schoolIds: [item.school_id],
        totalTicketsBought: item.total_tickets_bought,
        totalOrder: item.total_order_amount,
        createAt: item.order_created_at,
        orderUuid: item.order_uuid,
        orderId: item.order_id
      };
    } else {
      const order = groupByOrders[item.order_uuid];
      groupByOrders[item.order_uuid] = {
        ...order,
        schoolIds: [...order.schoolIds, item.school_id],
        totalTicketsBought: order.totalTicketsBought + item.total_tickets_bought
      };
    }
  });
  return Object.keys(groupByOrders)
    .map(key => groupByOrders[key])
    .sort((a, b) => dayjs(b.createAt).unix() - dayjs(a.createAt).unix());
};
