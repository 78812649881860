export const APP_HEADER_SIZE = 44;
export const APP_FOOTER_SIZE = 62;
export const CLOSE_BUTTON_Z_INDEX = 14;
export const DEFAULT_LEFT_DRAWER_SIZE = 350;
export const DEFAULT_RIGHT_DRAWER_SIZE = 350;
export const DEFAULT_BOTTOM_DRAWER_SIZE = 410;
export const LEFT_DRAWER_Z_INDEX = 10;
export const RIGHT_DRAWER_Z_INDEX = 10;
export const BOTTOM_DRAWER_Z_INDEX = 10;
export const DEFAULT_BOTTOM_DRAWER_HEADER_HEIGHT = 72;
export const SCHOOL_DISTRICT_LIST = 'school-district-list';
export const ATHLETIC_TEAMS_LIST = 'athletic-teams-list';
export const PROFILE = 'profile-lite';
export const UPSELL_BOTTOM_DRAWER = 'upsell-bottom-drawer';
export const MULTISELECT_BOTTOM_DRAWER = 'multiselect-bottom-drawer';
export const RESEND_EMAIL_RIGHT_DRAWER = 'resend-email-right-drawer';
export const RESEND_EMAIL_BOTTOM_DRAWER = 'resend-email-bottom-drawer';
