import { isEmpty } from './isEmpty';

const titleCase = (originalStr: string) => {
  if (isEmpty(originalStr)) {
    return '';
  }
  const str = originalStr.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
};

export { titleCase };
