// @ts-nocheck
import * as React from 'react';
import { SvgExtProps } from '../types';
import Svg, { Path } from 'react-native-svg';

export const CloseFilled = (props: SvgExtProps) => (
  <Svg
    id='gf_icon_close_filled'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='32px'
    height='32px'
    viewBox='0 0 32 32'
    xmlSpace='preserve'
    enableBackground='new 0 0 32 32'
    aria-label='Close icon'
    {...props}
  >
    <Path
      fill={props.fill ? props.fill : 'none'}
      d='M16 2C8.2 2 2 8.2 2 16s6.2 14 14 14 14-6.2 14-14S23.8 2 16 2zm5.4 21L16 17.6 10.6 23 9 21.4l5.4-5.4L9 10.6 10.6 9l5.4 5.4L21.4 9l1.6 1.6-5.4 5.4 5.4 5.4-1.6 1.6z'
    />
    <Path
      fill={props.figurecolor ? props.figurecolor : 'none'}
      id='inner-path'
      d='M14.4 16L9 10.6 10.6 9 16 14.4 21.4 9 23 10.6 17.6 16 23 21.4 21.4 23 16 17.6 10.6 23 9 21.4 14.4 16'
      opacity={props.figureOpacity ? props.figureOpacity : 1}
    />
    <Path id='CloseFilled_Transparent_Rectangle_' d='M0 0H32V32H0z' fill='none' />
  </Svg>
);
