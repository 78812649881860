import React from 'react';
import { Carbonicons, StyledButton } from '@gf/cross-platform-lib/components';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './PassInvalidModal.styles';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { useModal } from '@gf/cross-platform-lib/providers';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const BodyContainer = styled.View`
  ${styles.bodyContainer};
`;

const Container = styled.View`
  ${styles.container};
`;

const Title = styled.Text`
  ${styles.title};
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

const BtnContainer = styled.View<{ isCenter: boolean }>`
  ${styles.container};
  justify-content: ${({ isCenter = false }) => (isCenter ? 'center' : 'flex-end')};
`;

const ContentWrapper = styled.View`
  ${styles.contentWrapper}
`;

const ContentContainer = styled.View<{ paddingRight: number }>`
  ${styles.contentContainer}
`;

const CloseBtnContainer = styled.TouchableOpacity`
  ${styles.closeBtnContainer}
`;

export const PassInvalidModal = () => {
  const { isMobile } = useMediaQuery();
  const { modalState, hideModal } = useModal();
  const { modalProps } = modalState;
  const { onConfirm = () => {}, subTitle, title = 'An error occurred!' } = modalProps || {};

  return (
    <ModalWrapper isMobile={isMobile}>
      <CloseBtnContainer
        onPress={() => {
          onConfirm();
          hideModal();
        }}
      >
        <Carbonicons name='close' size={22} color='#161616' />
      </CloseBtnContainer>
      <BodyContainer>
        <ContentWrapper>
          <ContentContainer isMobile={isMobile}>
            <Container>
              <Title>{title}</Title>
            </Container>
            <SubTitle isMobile={isMobile} marginBottom={isMobile ? 24 : 48}>
              {subTitle}
            </SubTitle>
          </ContentContainer>
        </ContentWrapper>
        <BtnContainer isCenter>
          <StyledButton
            buttonType='primary'
            title='OK'
            accessibilityLabel='OK'
            onPress={() => {
              onConfirm();
              hideModal();
            }}
            width='100%'
          />
        </BtnContainer>
      </BodyContainer>
    </ModalWrapper>
  );
};
