import React from 'react';
import { useModal } from '../../ModalProvider';
import { Modal } from '../Modal';

export const InvalidMessageModal = () => {
  const { modalState } = useModal();
  const { onCloseModal, title, message, confirmLabel } = modalState.modalProps || {};

  return (
    <Modal
      onCancel={onCloseModal}
      onConfirm={onCloseModal}
      title={title ? title : 'An error occurred!'}
      subTitle={message ? message : 'Please try again later'}
      boldTitle
      confirmLabel={confirmLabel ? confirmLabel : 'Close'}
      hasClose={false}
      buttonSize='md'
      fillColor='#FFF'
    />
  );
};
