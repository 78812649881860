import React from 'react';
import { useModal } from '../../ModalProvider';
import { Modal } from '../Modal';

export const CartExpiredModal = () => {
  const { modalState } = useModal();
  const { onCloseModal } = modalState.modalProps || {};

  return (
    <Modal
      onCancel={onCloseModal}
      onConfirm={onCloseModal}
      title={'Cart expired'}
      subTitle={
        "Sorry, your cart expired after 15 minutes of inactivity. We'll redirect you back to the event to view more items."
      }
      boldTitle
      confirmLabel={'OK'}
      hasClose={false}
      buttonSize='md'
      fillColor='#FFF'
    />
  );
};
