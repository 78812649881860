// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const FavoriteFilled = (props: SvgProps) => (
  <Svg
    id='gf_icon_favorite_filled'
    aria-label='Favorite icon'
    xmlns='http://www.w3.org/2000/svg'
    x='0px'
    y='0px'
    width='32px'
    height='32px'
    viewBox='0 0 32 32'
    xmlSpace='preserve'
    enableBackground='new 0 0 32 32'
    {...props}
  >
    <Path d='M22.5 4c-2 0-3.9.8-5.3 2.2L16 7.4l-1.1-1.1c-2.9-3-7.7-3-10.6-.1l-.1.1c-3 3-3 7.8 0 10.8L16 29l11.8-11.9c3-3 3-7.8 0-10.8C26.4 4.8 24.5 4 22.5 4z' />
    <Path id='FavoriteFilled_Transparent_Rectangle_' d='M0 0H32V32H0z' fill='none' />
  </Svg>
);
