// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const InvolvedSchool = (props: SvgProps) => (
  <Svg viewBox='0 0 25 23' xmlns='http://www.w3.org/2000/svg' {...props}>
    <Path
      d='M24.656 11.133a34.949 34.949 0 0 0-2.99-3.6V.91a.827.827 0 0 0-.833-.82h-2.491a.827.827 0 0 0-.834.82v2.696C15.188 1.642 13.353.314 13.205.215a1.265 1.265 0 0 0-1.41 0C11.498.414 4.49 5.252.225 11.277a1.198 1.198 0 0 0-.083 1.265c.215.406.642.66 1.108.66h2.065l1.047 7.267c.074.513.468.926.984 1.032a35.876 35.876 0 0 0 14.307 0c.517-.105.91-.518.984-1.032l1.048-7.267h2.065c.69 0 1.25-.543 1.25-1.222 0-.328-.13-.626-.344-.847Zm-8.924 7.047a.826.826 0 0 1-.74.765c-.825.09-1.663.12-2.492.12-.83 0-1.668-.03-2.492-.12a.826.826 0 0 1-.74-.765l-.2-3.289a.812.812 0 0 1 .225-.611.84.84 0 0 1 .607-.258h5.2c.23 0 .45.094.607.258a.81.81 0 0 1 .225.611l-.2 3.289Z'
      fill='#323232'
      fillRule='nonzero'
    />
  </Svg>
);
