import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './ConfirmationModal.styles';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { useModal } from '@gf/cross-platform-lib/providers';
import { CloseIcon } from '@gf/cross-platform-lib/components';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const Container = styled.View`
  ${styles.container};
`;
const Title = styled.Text`
  ${styles.title};
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

const BtnContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
`;

const Button = styled.TouchableOpacity`
  ${styles.button};
`;

const ButtonTitle = styled.Text<{ disabled: boolean; color: string; disabledColor: string }>`
  color: ${({ disabled = false, color = '#ffffff', disabledColor = '#ffffff' }) => (disabled ? disabledColor : color)};
`;

const CloseIconWrapper = styled.TouchableOpacity``;

export interface ConfirmationModalProps {}

export const ConfirmationModal = ({}: ConfirmationModalProps) => {
  const { isMobile } = useMediaQuery();
  const { modalState } = useModal();
  const { modalProps } = modalState;
  const { onRemove = () => {}, onCancel = () => {} } = modalProps || {};

  const modalHeight = isMobile ? 212 : 190;
  const modalWidth = isMobile ? 320 : 640;

  return (
    <ModalWrapper height={modalHeight} width={modalWidth}>
      <Container>
        <Title>Are you sure you want to remove this ticket?</Title>
        <CloseIconWrapper onPress={onCancel}>
          <CloseIcon width={21} height={21} color='#525252' />
        </CloseIconWrapper>
      </Container>
      <SubTitle isMobile={isMobile}>This action will remove the selected ticket from your cart.</SubTitle>
      <BtnContainer>
        <Button bgColor='#f4f4f4' color='#323232' onPress={onCancel} disabled={false}>
          <ButtonTitle color='#323232'>Cancel</ButtonTitle>
        </Button>
        <Button bgColor='#da1e28' onPress={onRemove} removeBorder>
          <ButtonTitle color='#ffffff'>Remove</ButtonTitle>
        </Button>
      </BtnContainer>
    </ModalWrapper>
  );
};
