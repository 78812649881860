// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: title */

export const Refresh = (props: SvgProps) => (
  <Svg fill='currentColor' viewBox='0 0 16 14' xmlns='http://www.w3.org/2000/svg' {...props}>
    <Path
      d='M0 6.904a6.911 6.911 0 0 0 6.904 6.903 1.212 1.212 0 1 0 0-2.424c-2.47 0-4.48-2.01-4.48-4.48 0-2.469 2.01-4.479 4.48-4.479a4.485 4.485 0 0 1 4.434 3.847h-1.64c-.494 0-.655.323-.36.717l2.72 3.626c.295.394.78.394 1.075 0l2.719-3.626c.296-.394.135-.717-.359-.717h-1.716C13.457 2.76 10.497 0 6.904 0A6.911 6.911 0 0 0 0 6.904Z'
      fill='#323232'
      fillRule='nonzero'
    />
  </Svg>
);
