import { DEFAULT_BOTTOM_DRAWER_SIZE, DEFAULT_LEFT_DRAWER_SIZE, DEFAULT_RIGHT_DRAWER_SIZE } from './drawerConstants';

export interface DrawerProps {
  content: React.ReactNode;
  drawerOpen: boolean;
  drawerSize?: number;
  isFullScreen?: boolean;
}

export type DrawerPosition = 'left' | 'right' | 'top' | 'bottom';

export interface DrawerRegistry {
  content: React.JSX.Element;
  position: DrawerPosition;
  isOpen: boolean;
  drawerSize?: number;
  closeFunction?: () => void;
  isFullScreen?: boolean;
}

export const getRightDrawerSpringValues = (rightDrawerOpen: boolean, drawerSize?: number) => {
  return {
    from: {
      right: (drawerSize ? drawerSize : DEFAULT_RIGHT_DRAWER_SIZE) * -1
    },
    to: {
      right: rightDrawerOpen ? 0 : (drawerSize ? drawerSize : DEFAULT_RIGHT_DRAWER_SIZE) * -1
    }
  };
};

export const getLeftDrawerSpringValues = (leftDrawerOpen: boolean, drawerSize?: number) => {
  return {
    from: {
      left: (drawerSize ? drawerSize : DEFAULT_LEFT_DRAWER_SIZE) * -1
    },
    to: {
      left: leftDrawerOpen ? 0 : (drawerSize ? drawerSize : DEFAULT_LEFT_DRAWER_SIZE) * -1
    }
  };
};

export const getBottomDrawerSpringValues = (bottomDrawerOpen: boolean, drawerSize?: number) => {
  return {
    from: {
      bottom: (drawerSize ? drawerSize : DEFAULT_BOTTOM_DRAWER_SIZE) * -1
    },
    to: {
      bottom: bottomDrawerOpen ? 0 : (drawerSize ? drawerSize : DEFAULT_BOTTOM_DRAWER_SIZE) * -1
    }
  };
};
