import { useRouter } from 'next/router';
import React from 'react';

export const useCloseModalOnRouteChange = (hideModal: () => void) => {
  const router = useRouter();
  React.useEffect(() => {
    const handleRouteChangeStart = () => {
      hideModal && hideModal();
    };
    router?.events?.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router?.events?.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [hideModal]);
};
