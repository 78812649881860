import { PurchasedTicket } from '@gf/cross-platform-lib/interfaces';
import { TheStore } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import isEqual from 'lodash/isEqual';

const redemtionKey = 'redemptionkey';

export class TicketRedemption {
  private static instance: TicketRedemption;

  tickets: PurchasedTicket[];
  selectedTickets: PurchasedTicket[];
  redirectUrl: string;

  public static getInstance(): TicketRedemption {
    if (!TicketRedemption.instance) {
      TicketRedemption.instance = new TicketRedemption();
    }
    return TicketRedemption.instance;
  }

  private constructor() {
    this.tickets = [];
    this.selectedTickets = [];
    this.redirectUrl = '';
  }

  get ticketRedemption() {
    return this.tickets;
  }

  get selectedTicketsRedemption() {
    return this.selectedTickets;
  }

  async setTicketRedemption(data: PurchasedTicket[], selectedTickets: PurchasedTicket[]) {
    this.tickets = data;
    this.selectedTickets = selectedTickets;
    const store = JSON.parse((await TheStore.getItem(redemtionKey)) || '{}');
    if (store && isEqual(store, this)) {
      return;
    }
    await TheStore.setItem(redemtionKey, JSON.stringify(this));
  }

  async init() {
    return TheStore?.getItem(redemtionKey).then((ticketsStorageData: string) => {
      if (ticketsStorageData !== null) {
        const store = JSON.parse(ticketsStorageData) as {
          tickets: PurchasedTicket[];
          selectedTickets: PurchasedTicket[];
          redirectUrl: string;
        };
        this.tickets = store.tickets;
        this.selectedTickets = store.selectedTickets;
        this.redirectUrl = store.redirectUrl;
      } else {
        this.tickets = [];
        this.selectedTickets = [];
        this.redirectUrl = '';
      }
      return this;
    });
  }

  async updateRedirectUrl(redirectUrl: string) {
    this.redirectUrl = redirectUrl;
    const store = JSON.parse((await TheStore.getItem(redemtionKey)) || '{}');
    if (store && isEqual(store, this)) {
      return;
    }
    await TheStore.setItem(redemtionKey, JSON.stringify(this));
  }

  async emptyTickets(): Promise<void> {
    const newStore = {
      tickets: [],
      selectedTickets: [],
      redirectUrl: this.redirectUrl
    };
    const store = JSON.parse((await TheStore.getItem(redemtionKey)) || '{}');
    if (store && isEqual(store, newStore)) {
      return;
    }
    await TheStore.setItem(redemtionKey, JSON.stringify(newStore));
  }

  async emptyTicketRedemption(): Promise<void> {
    this.tickets = [];
    this.selectedTickets = [];
    this.redirectUrl = '';
    await TheStore.removeItem(redemtionKey);
  }
}
