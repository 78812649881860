import React from 'react';
import { ColorValue } from 'react-native';
import { MotiTransitionProp, MotiView } from 'moti';

import { SpinnerIcon } from './SpinnerIcon';

interface AnimatedSpinnerProps {
  size?: number;
  color?: ColorValue;
}

export const AnimatedSpinner = ({ size = 20, color = '#323232' }: AnimatedSpinnerProps) => {
  return (
    <MotiView
      from={{ scale: 1, rotate: '0deg' }}
      animate={{ scale: 1, rotate: '1080deg' }}
      transition={{ loop: true, type: 'timing', duration: 1600, repeatReverse: false } as MotiTransitionProp}
    >
      <SpinnerIcon color={color} width={size} height={size} />
    </MotiView>
  );
};
