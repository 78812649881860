// @ts-nocheck
import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const CheckmarkOutlineWarning = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' fill={props.color || 'currentColor'} viewBox='0 0 32 32' {...props}>
    <Path d='M14 24a10 10 0 1 1 10-10h2a12 12 0 1 0-12 12Z' />
    <Path d='M12 15.59 9.41 13 8 14.41l4 4 7-7L17.59 10 12 15.59zM27.38 28h-6.762L24 21.236ZM24 18a1 1 0 0 0-.895.553l-5 10A1 1 0 0 0 19 30h10a1 1 0 0 0 .921-1.39l-5.026-10.057A1 1 0 0 0 24 18Z' />
    <Path
      data-name='&lt;Transparent Rectangle&gt;'
      style={{
        fill: 'none'
      }}
      d='M0 0h32v32H0z'
    />
  </Svg>
);
