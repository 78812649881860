import { css } from 'styled-components';

export const styles = {
  fieldWrapper: css`
    display: flex;
    flex-direction: column;
  `,
  widgetWrapper: css`
    width: 100%;
  `,
  label: css`
    font-family: Rubik-Regular;
    color: #525252;
    font-size: 12px;
    line-height: 16px;
    min-height: 16px;
    margin-bottom: 8px;
  `,
  errorText: css`
    width: 100%;
    color: #da1e28;
    font-family: Rubik-Regular;
    font-size: 12px;
    line-height: 16px;
    min-height: 16px;
    margin-top: 4px;
  `
};
