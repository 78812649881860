// @ts-nocheck

import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { SvgProps } from '../types';

export const CalendarHeatMap = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' {...props}>
    <Path d='M26 4h-4V2h-2v2h-8V2h-2v2H6a2.002 2.002 0 0 0-2 2v20a2.002 2.002 0 0 0 2 2h20a2.002 2.002 0 0 0 2-2V6a2.002 2.002 0 0 0-2-2ZM6 6h4v2h2V6h8v2h2V6h4v4H6Zm0 6h5v6H6Zm13 14h-6v-6h6Zm0-8h-6v-6h6Zm2 8v-6h5l.001 6Z' />
    <Path d='M0 0h32v32H0z' data-name='&lt;Transparent Rectangle&gt;' fill='none' />
  </Svg>
);
