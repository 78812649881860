import React, { useState, useMemo } from 'react';
import { Clipboard } from 'react-native';

import { EventSeason } from '@gf/cross-platform-lib/interfaces';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { Carbonicons, StyledText } from '@gf/cross-platform-lib/components';

import { useModal } from '../../ModalProvider';

import { SocialMedia, SocialMediaType, getEventUrl, shareEvent } from './utils';
import { IOption } from './SharingOption';

interface ShareEventModalProps {
  event: EventSeason;
  onConfirm: () => void;
  onHideModal: () => void;
}

const socialOptions: IOption[] = [
  {
    name: 'facebook',
    icon: <Carbonicons name='facebook' size={16} color='#323232' />,
    action: (event: EventSeason) => shareEvent(event, SocialMedia.FACEBOOK as SocialMediaType)
  },
  {
    name: 'twitter',
    icon: <Carbonicons name='LogoTwitterWithoutBorder' size={16} color='#323232' />,
    action: (event: EventSeason) => shareEvent(event, SocialMedia.TWITTER as SocialMediaType)
  }
];

export const useEventSharing = () => {
  const { modalState } = useModal();
  const { modalProps } = modalState;
  const { event, onConfirm, onHideModal } = (modalProps as ShareEventModalProps) || {};
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const { isMobile, isNativeApp, isWebApp } = useMediaQuery();

  const userSelectedOption = (name: string, action?: (param: any) => void) => {
    setSelectedOption(name);
    if (typeof action === 'function') action(event);
    !isOptionSelected && setIsOptionSelected(true);
  };

  const onCopyLink = (url: string) => {
    Clipboard.setString(url);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1200);
  };

  const options: IOption[] = useMemo(() => {
    const defaultOptions: IOption[] = [
      {
        name: 'email',
        title: 'Share via email',
        icon: 'email',
        action: (event: EventSeason) => shareEvent(event, 'email')
      },
      {
        name: 'copyLink',
        title: 'Copy event link',
        icon: copied ? <StyledText>Copied</StyledText> : 'copyLink',
        action: (event: EventSeason) => onCopyLink(getEventUrl(event.id, event.isSeason))
      }
    ];

    if (isMobile) {
      defaultOptions.unshift({
        name: 'message',
        title: 'Share via text',
        icon: 'mobile',
        action: (event: EventSeason) => shareEvent(event, SocialMedia.SMS as SocialMediaType)
      });
    }
    if (isNativeApp || (isWebApp && typeof navigator?.share === 'function')) {
      defaultOptions.push({
        name: 'moreOptions',
        title: 'More options',
        icon: 'export',
        action: (event: EventSeason) => shareEvent(event, SocialMedia.MORE as SocialMediaType)
      });
    }
    return defaultOptions;
  }, [event, copied, isMobile, isNativeApp]);

  return {
    socialOptions,
    options,
    selectedOption,
    isOptionSelected,
    onHideModal,
    onConfirm,
    userSelectedOption
  };
};
