import { css } from '@gf/cross-platform-lib/styled-components';
import { styles as baseStyles } from './Modal.styles.base';

export const styles = {
  ...baseStyles,
  leftItem: css`
    ${baseStyles.leftItem};
  `,
  rightItem: css`
    ${baseStyles.rightItem};
    &:hover {
      cursor: pointer;
      background-color: #e8e8e8;
    }
  `
};
