import {
  EVENT_TYPES,
  GA4_TRACKING_EVENTS,
  GOFAN_APP_PAGES,
  INPUT_METHOD,
  NEW_RELIC_ERROR_GROUPS,
  pathsToPageConfig,
  PAYMENT_TYPE,
  SEARCH_RESULT_CATEGORIES,
  SEGMENTS_TRACKING_EVENTS,
  TRACKING_EVENTS
} from '@gf/cross-platform-lib/constants';
import { useFirebase } from '@gf/cross-platform-lib/providers/Firebase';
import { useSegmentTracking } from '@gf/cross-platform-lib/providers/Tracking';
import isEmpty from 'lodash/isEmpty';
import {
  emptySegmentStorageProps,
  getGender,
  getOrderPaymentType,
  getPaymentTypeTracking,
  getPrintTicketTrackingProps,
  getSegmentRecentProps,
  getUseTicketTrackingProps,
  setSegmentRecentProps
} from './utils';
import {
  Activity,
  EventSeasonCombined,
  PrintTicketTrackingProps,
  ProductSeating,
  ProductType as ProductTypeEnum,
  UseTicketTrackingProps
} from '@gf/cross-platform-lib/interfaces';
import { Cart, EventCart, OrderDTO, SeasonCart, TicketCart, TicketCartPromotion } from '@gf/cross-platform-lib/models';
import { useCartState } from '../useCartState';
import dayjs from 'dayjs';
import { InputChangeType } from '../../components/NumberInput/contants';
import { Level } from '@gf/cross-platform-lib/interfaces/Level';
import { recordError } from '@gf/cross-platform-lib/utils/newrelic';
import groupBy from 'lodash/groupBy';
import { isAggregatorSchool, isSingleSchool, delay } from '@gf/cross-platform-lib/utils';

interface PromptContext {
  context: string;
  promptCount: number;
}

type SchoolDistrictListProps = {
  home_org: string;
  number_of_schools: number;
  type_of_schools: string;
};

type OrganizationType = {
  orgId: string;
  orgType: string;
};

export type SearchResultType = {
  category: string;
  orgId: string;
  orgType: string;
};

type INPUT_METHOD = {
  CLICK: 'click';
  ENTER: 'enter';
  EMPTY: 'empty';
  SYSTEM_REMOVE: 'system_remove';
};

type ProductType = {
  removeAll?: boolean;
  quantity: number;
  ticket: ProductSeating;
  coupon: string | undefined;
  inputMethod: string;
  index: number;
  event: ProductTypeEvent;
  inCart: boolean;
  renewal: boolean;
  uniqueUrl: string;
  opponentSchoolId?: string;
  formFieldNames?: string;
  eventType?: string;
};

type ProductTypeEvent = {
  activity?: Activity;
  financialSchoolID?: string;
  financialSchoolIndustryCode?: string;
  financialSchoolState?: string;
  financialSchoolType?: string;
  schoolHuddleId?: string;
  startDateTime?: string;
  endDateTime?: string;
  opponentSchoolId?: string;
  eventType?: string;
  levels?: Level[];
};

type ProductTypeInCart = {
  removeAll?: boolean;
  quantity: number;
  ticket: ProductSeating;
  coupon: TicketCartPromotion | string;
  renewal: boolean;
  uniqueUrl: string;
  inputMethod: string;
  index: number;
  event: ProductTypeEvent;
  inCart: boolean;
  opponentSchoolId?: string;
  formFieldNames?: string;
};

type PromoCodeType = {
  codeId: string;
  codeName: string;
  discount?: number;
  codeAccess?: string;
  reason?: string;
};

export type PromoLocation = 'favorites' | 'event list' | 'order details' | 'my tickets';

type TeamSitePromoClickMetadata = {
  home_org: string;
  page: PromoLocation;
  url: string;
};

export type FilterProps = {
  filters: FilterType[];
};
export type FilterType = {
  name: string;
  value: string;
};

export type SponsorshipMetadata = {
  home_org: string;
  event_id: string;
  activity: string;
  event_type: string;
  page: 'order details' | 'my tickets';
  sponsor_type: string;
  click_through_link: string;
};

interface TrackingContextType {
  trackFilterEvent: (filters: FilterProps) => void;
  trackSearchEvent: (page: string, query: string) => void;
  trackFavoriteEvent: (page: string, properties: OrganizationType) => void;
  trackSearchResultSelected: (page: string, searchResult: SearchResultType) => void;
  trackUnFavoriteEvent: (path: string, properties: OrganizationType) => void;
  trackProductRemoved: (properties?: ProductType, propertiesInCart?: ProductTypeInCart) => void;
  trackProductAdded: (properties?: ProductType, propertiesInCart?: ProductTypeInCart) => void;
  trackQuantityChange: (
    ticket: TicketCart,
    event: EventCart,
    oldQuantity: number,
    quantity: number,
    changeType?: InputChangeType,
    schoolId?: string,
    formFieldNames?: string
  ) => void;
  trackCartViewedEvent: (totalNumberOfTickets: number) => void;
  trackCheckoutStartedEvent: (isFanSignedIn: boolean) => void;
  trackPaymentInfoEnter: (paymentType: string) => void;
  trackSegmentOrderComplete: (order: OrderDTO, isFanSignedIn: boolean) => void;
  trackPromoteCodeApplied: (properties: {
    promotion?: PromoCodeType;
    promotions?: Array<PromoCodeType>;
    inCart: boolean;
  }) => void;
  trackPromoteCodeRemoved: (properties: {
    promotion?: PromoCodeType;
    promotions?: Array<PromoCodeType>;
    inCart: boolean;
  }) => void;
  trackPromoteCodeDenied: (properties: {
    promotion?: PromoCodeType;
    promotions?: Array<PromoCodeType>;
    inCart: boolean;
  }) => void;
  trackPromoteCodeEntered: (properties: {
    promotion?: PromoCodeType;
    promotions?: Array<PromoCodeType>;
    inCart: boolean;
  }) => void;
  trackViewEvent: (event: EventSeasonCombined) => void;
  trackViewDistrictSchoolList: (schoolDistrictListProps: SchoolDistrictListProps) => void;
  trackRefreshEventList: (method: string) => void;
  trackUseTickets: (props: UseTicketTrackingProps) => void;
  trackPrintTickets: (props: PrintTicketTrackingProps) => void;
  trackStoreReviewPrompt: (promptContext: PromptContext) => void;
  trackClickTeamSitePromo: (teamSitePromoMetadata: TeamSitePromoClickMetadata) => void;
  trackClickSponsorship: (sponsorshipMetadata: SponsorshipMetadata) => Promise<void>;
  trackFavoriteTeam: (teamId: string, organizationId: string) => void;
}

const MAP_TRACKING_PAGE = {
  [GOFAN_APP_PAGES.search.name]: 'home',
  [GOFAN_APP_PAGES.myTickets.name]: 'my tickets',
  [GOFAN_APP_PAGES.orderDetails.name]: 'order details',
  [GOFAN_APP_PAGES.school.name]: 'event list',
  [GOFAN_APP_PAGES.orderDetails.name]: 'order details',
  [GOFAN_APP_PAGES['my-schools'].name]: 'my schools'
};

const getFinancialOrgType = (schoolType: string) => {
  if (!schoolType) {
    return '';
  }

  if (isAggregatorSchool(schoolType)) {
    return 'Aggregator';
  }

  if (isSingleSchool(schoolType)) {
    return 'Single';
  }

  return '';
};

export const getEventType = ({ activity, isMobilePass }: { activity?: Activity; isMobilePass: boolean }) => {
  if (isEmpty(activity)) {
    return EVENT_TYPES.ATHLETIC;
  }

  let eventType = EVENT_TYPES.ATHLETIC;
  if (EVENT_TYPES.PERFORMING_ART.includes(activity.name || '')) {
    eventType = EVENT_TYPES.PERFORMING_ART;
  } else if (EVENT_TYPES.REGISTRATION.includes(activity.name || '')) {
    eventType = EVENT_TYPES.REGISTRATION;
  } else if (EVENT_TYPES.SCHOOL_DANCE.includes(activity.name || '')) {
    eventType = EVENT_TYPES.SCHOOL_DANCE;
  } else if (isMobilePass) {
    eventType = EVENT_TYPES.MOBILE_PASS;
  } else if (!activity.isAthletic) {
    eventType = EVENT_TYPES.OTHER;
  }
  return eventType;
};

export const useCustomTracking: () => TrackingContextType = () => {
  const { track } = useSegmentTracking();
  const {
    ga4: { track: ga4Track }
  } = useFirebase();
  const { cartSchools } = useCartState();
  const cart = Cart.getInstance();

  const trackFilterEvent = (filters: FilterProps) => {
    try {
      const splitNameAndMultipleValues = ({ name, value }: { name: string; value: string }) => {
        const valuesArray = value.split(',');
        const result = valuesArray.map(val => name + ' - ' + val.trim());
        return result;
      };
      track(TRACKING_EVENTS.FILTER, filters);
      ga4Track(GA4_TRACKING_EVENTS.FILTER, {
        filters: filters.filters
          .map(filter => splitNameAndMultipleValues(filter))
          .flat()
          .toString()
      });
    } catch (e: any) {
      recordError(e, {
        customMessage: `Failed to track that an event was filtered.`,
        originatingFunction: 'useCustomTracking-trackFilterEvent',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { filters }
      });
    }
  };

  const trackSearchEvent = (page: string, query: string) => {
    try {
      const routeName = pathsToPageConfig[page].name;
      const payload = {
        page: MAP_TRACKING_PAGE[routeName] || '',
        query: query
      };
      track(TRACKING_EVENTS.SEARCH, payload);
      ga4Track(GA4_TRACKING_EVENTS.SEARCH, payload);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackSearchEvent',
        customMessage: 'Failed to track a search event (possible user input timeout)',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { page, query }
      });
    }
  };

  const trackSearchResultSelected = (path: string, searchResult: SearchResultType) => {
    try {
      if (isEmpty(searchResult)) {
        return;
      }
      const MAPPING_CATEGORY = {
        [SEARCH_RESULT_CATEGORIES.MY_FAVORITES]: 'Favorites',
        [SEARCH_RESULT_CATEGORIES.RECENTLY_VIEWED]: 'Recently Viewed'
      };

      const routeName = pathsToPageConfig[path].name;
      const { category, orgId, orgType } = searchResult;
      const payload = {
        search_category: MAPPING_CATEGORY[category] || '',
        home_org: orgId,
        home_org_type: getFinancialOrgType(orgType),
        page: MAP_TRACKING_PAGE[routeName] || ''
      };
      track(TRACKING_EVENTS.SEARCH_RESULT_SELECTED, payload);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackSearchResultSelected',
        customMessage: 'Failed to track that a search result was selected',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { path, searchCategory: searchResult.category, orgId: searchResult.orgId, orgType: searchResult.orgType }
      });
    }
  };

  const trackFavoriteEvent = (path: string, properties: OrganizationType) => {
    try {
      if (isEmpty(properties)) {
        return;
      }
      const routeName = pathsToPageConfig[path].name;
      const { orgId, orgType } = properties;
      const home_org_type = getFinancialOrgType(orgType);
      const payload = {
        home_org: orgId,
        home_org_type,
        page: MAP_TRACKING_PAGE[routeName] || ''
      };
      track(TRACKING_EVENTS.FAVORITE, payload);
      ga4Track('favorite_school_added', {
        home_org: orgId,
        home_org_type
      });
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackFavoriteEvent',
        customMessage: 'Failed to track that an event was favorited.',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { path, properties: properties }
      });
    }
  };

  const trackUnFavoriteEvent = (path: string, properties: OrganizationType) => {
    try {
      if (isEmpty(properties)) {
        return;
      }
      const routeName = pathsToPageConfig[path].name;
      const { orgId, orgType } = properties;
      const home_org_type = getFinancialOrgType(orgType);
      const payload = {
        home_org: orgId,
        home_org_type,
        page: MAP_TRACKING_PAGE[routeName] || ''
      };
      track(TRACKING_EVENTS.UN_FAVORITE, payload);
      ga4Track('favorite_school_removed', {
        home_org: orgId,
        home_org_type
      });
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackUnFavoriteEvent',
        customMessage: 'Failed to track unfavoriting of a school',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { path, orgId: properties.orgId, orgType: properties.orgType }
      });
    }
  };

  const trackProductAdded = async (properties?: ProductType, propertiesInCart?: ProductTypeInCart) => {
    try {
      const propertiesTracking =
        properties !== undefined ? properties : propertiesInCart !== undefined ? propertiesInCart : undefined;
      if (propertiesTracking === undefined) {
        return;
      }
      const {
        ticket,
        event,
        quantity,
        index,
        inputMethod,
        uniqueUrl,
        coupon,
        inCart,
        renewal,
        opponentSchoolId,
        formFieldNames
      } = propertiesTracking;
      const {
        id,
        eventId,
        seasonId,
        productType,
        name,
        isReservedSeating,
        price,
        packCount = 0,
        distributionChannel
      } = ticket || {};
      const { activity, financialSchoolID, financialSchoolState, schoolHuddleId, eventType, financialSchoolType } =
        event || {};

      const data = {
        product_id: id || '',
        event_id: eventId || seasonId || '',
        activity: activity?.name || '',
        financial_org: financialSchoolID || '',
        financial_org_type: getFinancialOrgType(financialSchoolType || '') || '',
        financial_org_state: financialSchoolState || '',
        home_org: schoolHuddleId || '',
        product_type: productType || '',
        package: seasonId ? 'Season' : packCount > 1 ? 'Ticket Pack' : '',
        renewal: seasonId ? (renewal ? 'RENEWAL' : 'NEW') : '',
        name: name || '',
        reserved: isReservedSeating ? 'Reserved' : '',
        price: price || '',
        quantity: quantity || '',
        coupon: coupon || '',
        position: index + 1 || '',
        unique_url: uniqueUrl || '',
        input_method: inputMethod || INPUT_METHOD.EMPTY,
        in_cart: inCart ? 'In_Cart' : ''
      };
      const ga4Data = {
        ...data,
        away_org: opponentSchoolId,
        event_type: eventType,
        gender_level: getGender(event.levels) || '',
        start_date: event.startDateTime,
        end_date: event.endDateTime,
        distribution_channel: distributionChannel || '',
        additional_info: formFieldNames || ''
      };
      track(SEGMENTS_TRACKING_EVENTS.PRODUCT_ADDED, data);
      ga4Track(GA4_TRACKING_EVENTS.PRODUCT_ADDED, ga4Data);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackProductAdded',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        customMessage:
          'Tracking that a product was added to the cart failed. Check data destructuring or Segment track()/ga4Track functions.',
        data: { properties, propertiesInCart }
      });
    }
  };

  const trackProductRemoved = (properties?: ProductType, propertiesInCart?: ProductTypeInCart) => {
    const propertiesTracking =
      properties !== undefined ? properties : propertiesInCart !== undefined ? propertiesInCart : undefined;

    try {
      const propertiesTracking =
        properties !== undefined ? properties : propertiesInCart !== undefined ? propertiesInCart : undefined;
      if (propertiesTracking === undefined) {
        return;
      }
      const { ticket, event, quantity, index, inputMethod, uniqueUrl, coupon, inCart, renewal, removeAll } =
        propertiesTracking;
      const { id, eventId, seasonId, productType, name, isReservedSeating, price, packCount = 0 } = ticket || {};
      const { activity, financialSchoolID, financialSchoolState, schoolHuddleId, financialSchoolType } = event || {};
      const data = {
        remove_all: removeAll ? 'Remove all' : '',
        product_id: id || '',
        event_id: eventId || seasonId || '',
        activity: activity?.name || '',
        financial_org: financialSchoolID || '',
        financial_org_type: getFinancialOrgType(financialSchoolType || '') || '',
        financial_org_state: financialSchoolState || '',
        home_org: schoolHuddleId || '',
        product_type: productType || '',
        package: seasonId ? 'Season' : packCount > 1 ? 'Ticket Pack' : '',
        renewal: seasonId ? (renewal ? 'RENEWAL' : 'NEW') : '',
        name: name || '',
        reserved: isReservedSeating ? 'Reserved' : '',
        price: price || '',
        quantity: quantity.toString() || '',
        coupon: coupon || '',
        position: index + 1 || '',
        unique_url: uniqueUrl || '',
        input_method: inputMethod || INPUT_METHOD.EMPTY,
        in_cart: inCart ? 'In_Cart' : ''
      };
      const ga4Data = {
        ...data,
        gender_level: getGender(event.levels) || ''
      };
      track(SEGMENTS_TRACKING_EVENTS.PRODUCT_REMOVED, data);
      ga4Track(GA4_TRACKING_EVENTS.PRODUCT_REMOVED, ga4Data);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackProductAdded',
        customMessage: 'Failed to track product addition to cart',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: {
          product_id: propertiesTracking?.ticket?.id || '',
          event_id: propertiesTracking?.ticket?.eventId || propertiesTracking?.ticket?.seasonId || '',
          financial_org: propertiesTracking?.event?.financialSchoolID || '',
          input_method: propertiesTracking?.inputMethod || '',
          in_cart: propertiesTracking?.inCart || ''
        }
      });
    }
  };

  const trackQuantityChange = (
    ticket: TicketCart,
    event: EventCart | SeasonCart,
    oldQuantity: number,
    quantity: number,
    changeType?: InputChangeType,
    schoolId?: string,
    formFieldNames?: string
  ) => {
    const mappedTicket = event.ticketTypes.find(tk => tk.id === ticket.id);
    if (isEmpty(mappedTicket)) {
      return;
    }
    const mappedEventInCart = (event: EventCart | SeasonCart) => {
      return {
        activity: event.activity,
        financialSchoolID: event.financialSchoolID,
        financialSchoolIndustryCode: event.financialSchoolIndustryCode,
        financialSchoolState: event.financialSchoolState,
        financialSchoolType: event.financialSchoolType,
        schoolHuddleId: schoolId,
        eventType: getEventType({
          activity: event.activity,
          isMobilePass: mappedTicket.productType === ProductTypeEnum.MOBILEPASS
        }),
        startDateTime: event.startDateTime,
        endDateTime: event.endDateTime,
        levels: event.levels
      };
    };
    if (oldQuantity > quantity) {
      trackProductRemoved(undefined, {
        renewal: false,
        uniqueUrl: ticket.uniqueUrl || '',
        ticket: mappedTicket,
        event: mappedEventInCart(event),
        quantity,
        index: event.ticketTypes.findIndex(tk => tk.id === ticket.id),
        removeAll: false,
        inputMethod: changeType === InputChangeType.ON_DECREMENT ? 'Click' : 'enter',
        coupon: ticket?.promotion?.promoCode || ticket.accessCode || '',
        inCart: true,
        opponentSchoolId: ticket.opponentSchoolId
      });
    }

    if (oldQuantity < quantity) {
      trackProductAdded(undefined, {
        renewal: false,
        uniqueUrl: ticket.uniqueUrl || '',
        ticket: mappedTicket,
        event: mappedEventInCart(event),
        quantity,
        index: event.ticketTypes.findIndex(tk => tk.id === ticket.id),
        inputMethod: changeType === InputChangeType.ON_INCREMENT ? 'Click' : 'enter',
        coupon: ticket?.promotion?.promoCode || ticket.accessCode || '',
        inCart: true,
        opponentSchoolId: ticket.opponentSchoolId,
        formFieldNames
      });
    }
  };

  const trackCartViewedEvent = (totalNumberOfTickets: number) => {
    const inCartStatus = totalNumberOfTickets > 0 ? 'In_Cart' : '';
    const ticketQuantity = totalNumberOfTickets || 0;
    try {
      track(SEGMENTS_TRACKING_EVENTS.CART_VIEWED, {
        in_cart: totalNumberOfTickets > 0 ? 'In_Cart' : '',
        quantity: totalNumberOfTickets || 0
      });
      ga4Track(GA4_TRACKING_EVENTS.CART_VIEWED, {
        in_cart: totalNumberOfTickets > 0 ? 'In_Cart' : '',
        quantity: totalNumberOfTickets || 0
      });
    } catch (error: any) {
      recordError(error, {
        originatingFunction: 'useCustomTracking-trackCartViewedEvent',
        customMessage: 'Failed to track cart viewed event',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: {
          in_cart: inCartStatus,
          quantity: ticketQuantity
        }
      });
    }
  };

  const trackCheckoutStartedEvent = (isFanSignedIn: boolean) => {
    const eventsInCart = cartSchools.map(school => school.events).flat();
    const earliestDayOfEventsInCart = eventsInCart.reduce((acc, event) =>
      dayjs(event.startDateTime).isAfter(dayjs(acc.startDateTime)) ? acc : event
    ).startDateTime;
    const daysToEvent = dayjs(earliestDayOfEventsInCart).diff(dayjs(), 'day');

    try {
      const data = {
        order_id: '',
        total: cart.getCartTotal().total,
        fee: cart.getCartTotal().serviceFees,
        revenue: parseFloat(Number(cart.getCartTotal().revenue).toFixed(2)),
        guest_type: isFanSignedIn ? 'Registered User' : 'Guest',
        distribution_channel: 'GoFan',
        discount: parseFloat(Number(cart.getCartTotal().totalDiscount).toFixed(2)),
        days_to_event: daysToEvent
      };
      track(SEGMENTS_TRACKING_EVENTS.CHECKOUT_STARTED, data);
      ga4Track(GA4_TRACKING_EVENTS.CHECKOUT_STARTED, { ...data, days_to_event: -daysToEvent });
    } catch (error: any) {
      recordError(error, {
        originatingFunction: 'useCustomTracking-trackCheckoutStartedEvent',
        customMessage: 'Failed to track checkout started event',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { eventsInCart, earliestDayOfEventsInCart, daysToEvent }
      });
    }
  };

  const trackPaymentInfoEnter = async (paymentType: string) => {
    try {
      const recentEvents = cartSchools.map(school => school.events).flat() as Array<EventCart | SeasonCart>;
      await setSegmentRecentProps(recentEvents, getPaymentTypeTracking(paymentType), '');
      if (paymentType !== PAYMENT_TYPE.PAYMENT_SHEET) {
        const data = {
          payment_type: getPaymentTypeTracking(paymentType),
          checkout_id: '',
          order_id: ''
        };
        track(SEGMENTS_TRACKING_EVENTS.PAYMENT_INFO_ENTERED, data);
        ga4Track(GA4_TRACKING_EVENTS.PAYMENT_INFO_ENTERED, data);
      }
    } catch (error: any) {
      recordError(error, {
        originatingFunction: 'useCustomTracking-trackPaymentInfoEnter',
        customMessage: 'Failed to track that payment info had been entered',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { paymentType }
      });
    }
  };

  const trackSegmentOrderComplete = async (order: OrderDTO, isFanSignedIn: boolean) => {
    let data: Record<string, unknown> = {};

    try {
      const getCartTotal = (): {
        total: number;
        subTotal: number;
        serviceFees: number;
        revenue: number;
        discount: number;
      } => {
        const total = order.chargeTotalAmount;
        let subTotal = order.subTotal;
        let serviceFees = order.fee;
        let discount = order.discounts || 0;
        let hiddenFeeBase = 0;
        order.tickets.forEach(ticket => {
          hiddenFeeBase = ticket?.hiddenFees
            ? hiddenFeeBase + (ticket?.hiddenFeeBase || 0) * ticket.selectedQuantity
            : hiddenFeeBase;
        });

        const revenue = subTotal - discount - hiddenFeeBase;
        return { subTotal, serviceFees, total, revenue, discount };
      };
      const recentStorageCart = await getSegmentRecentProps('');
      if (isEmpty(recentStorageCart)) {
        return null;
      }
      const { paymentType = '', recentEvents = [] } = recentStorageCart || {};
      const { total, revenue, serviceFees, discount } = getCartTotal();
      const earliestDayOfEventsInCart = !isEmpty(recentEvents)
        ? recentEvents.reduce((acc, event) =>
            dayjs(event.startDateTime).isAfter(dayjs(acc.startDateTime)) ? acc : event
          ).startDateTime
        : dayjs();
      const daysToEvent = dayjs(earliestDayOfEventsInCart).diff(dayjs(), 'day');
      data = {
        order_id: order.accessToken || '',
        charge_id: order.paymentAgentChargeId || '',
        payment_type:
          paymentType === PAYMENT_TYPE.PAYMENT_SHEET
            ? getOrderPaymentType(order.paymentType, order.chargePaymentMethod)
            : paymentType,
        total,
        revenue: parseFloat(Number(revenue).toFixed(2)),
        fee: serviceFees || 0,
        distribution_channel: 'GoFan',
        guest_type: isFanSignedIn ? 'Registered User' : 'Guest',
        discount: parseFloat(Number(discount).toFixed(2)),
        days_to_event: daysToEvent,
        email: order.receiptEmail
      };
      track(SEGMENTS_TRACKING_EVENTS.ORDER_COMPLETED, data);
      ga4Track(GA4_TRACKING_EVENTS.ORDER_COMPLETED, { ...data, days_to_event: -daysToEvent });
      setTimeout(() => handleTrackProductPurchased(order, recentEvents), 1000);
      await emptySegmentStorageProps();
    } catch (e: any) {
      recordError(e, {
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        originatingFunction: 'useCustomTracking-trackSegmentOrderComplete',
        customMessage: 'Error in tracking Segment Order Completion',
        data
      });
    }
  };

  const handleTrackProductPurchased = async (order: OrderDTO, recentEvents: Array<EventCart | SeasonCart>) => {
    function handleTrack(event: EventCart | SeasonCart, ticket: TicketCart, index: number) {
      setTimeout(function () {
        const orderTicket = order.tickets.find(ot => `${ot.productId}` === `${ticket.id}`);
        const cartTicket = event.tickets.find(tk => `${tk.id}` === `${ticket.id}`);
        const { id, eventId, productType, name, seasonId } = orderTicket || {};
        const { price = 0, quantity = 1 } = cartTicket || {};
        const { financialSchoolID, financialSchoolState, financialSchoolType } = event || {};
        const trackingData = {
          order_id: order.accessToken || '',
          home_org: ticket.schoolHuddleId,
          opponent_org: ticket.opponentSchoolId,
          product_id: id || '',
          event_id: event.isSeason ? seasonId : eventId ? eventId : '',
          activity: event.activity?.name || '',
          gender: getGender(event.levels) || '',
          financial_org: financialSchoolID || '',
          financial_org_type: getFinancialOrgType(financialSchoolType || '') || '',
          financial_org_state: financialSchoolState || '',
          product_type: productType || '',
          package: seasonId ? 'Season' : ticket.packCount > 1 ? 'Ticket Pack' : '',
          renewal: seasonId ? (cartTicket?.seatsInfo?.some(seat => seat.renewal) ? 'RENEWAL' : 'NEW') : '',
          name,
          reserved: event?.ticketTypes?.find(tk => tk.id === ticket.id)?.isReservedSeating ? 'Reserved' : '',
          price: parseFloat(Number(price * quantity).toFixed(2)),
          quantity,
          coupon: cartTicket?.promotion?.promoCode || '',
          position: index,
          unique_url: cartTicket?.uniqueUrl || ''
        };
        const ga4TrackingData = {
          order_id: order.accessToken || '',
          product_id: id || '',
          event_id: event.isSeason ? seasonId : eventId ? eventId : '',
          activity: event.activity?.name || '',
          gender_level: getGender(event.levels) || '',
          financial_org: financialSchoolID || '',
          financial_org_type: getFinancialOrgType(financialSchoolType || '') || '',
          financial_org_state: financialSchoolState || '',
          home_org: ticket.schoolHuddleId,
          opponent_org: ticket.opponentSchoolId,
          product_type: productType || '',
          package: seasonId ? 'Season' : ticket.packCount > 1 ? 'Ticket Pack' : '',
          renewal: seasonId ? (cartTicket?.seatsInfo?.some(seat => seat.renewal) ? 'RENEWAL' : 'NEW') : '',
          name,
          reserved: event?.ticketTypes?.find(tk => tk.id === ticket.id)?.isReservedSeating ? 'Reserved' : '',
          price: parseFloat(Number(price * quantity).toFixed(2)),
          quantity,
          coupon: cartTicket?.promotion?.promoCode || '',
          position: index,
          unique_url: cartTicket?.uniqueUrl || ''
        };
        track(SEGMENTS_TRACKING_EVENTS.PRODUCT_ORDERED, trackingData);
        ga4Track(GA4_TRACKING_EVENTS.PRODUCT_ORDERED, ga4TrackingData);
      }, index * 1000);
    }

    recentEvents.forEach(event =>
      event.tickets.forEach((ticket, ticketIndex) => {
        handleTrack(event, ticket, ticketIndex + 1);
      })
    );
  };

  const trackPromoteCodeEvent = (
    TRACKING_EVENT: string,
    properties: {
      orderId: string;
      inCart?: boolean;
      promotion?: PromoCodeType;
      promotions?: Array<PromoCodeType>;
    }
  ) => {
    try {
      const { orderId, inCart, promotion, promotions } = properties;
      const isGA4Event =
        Object.values(GA4_TRACKING_EVENTS).filter(val => {
          return `${val}` === `${TRACKING_EVENT}`;
        }).length > 0;
      if (!isEmpty(promotion) && promotion !== undefined) {
        const { codeId, codeName, discount, codeAccess, reason } = promotion;
        const data = {
          order_id: orderId,
          in_cart: inCart ? 'In_Cart' : '',
          code_id: codeId || '',
          code_name: codeName,
          code_access: codeAccess,
          discount: Number.isNaN(Number(discount)) ? undefined : parseFloat(Number(discount).toFixed(2)),
          reason: reason || undefined
        };
        isGA4Event ? ga4Track(TRACKING_EVENT, data) : track(TRACKING_EVENT, data);
      } else if (!isEmpty(promotions) && promotions !== undefined) {
        Object.entries(promotions).forEach(([id, promotion]) => {
          const { codeName, codeId, discount, codeAccess, reason } = promotion;
          const data = {
            order_id: orderId,
            in_cart: inCart ? 'In_Cart' : '',
            code_id: codeId || id,
            code_name: codeName,
            code_access: codeAccess,
            discount: Number.isNaN(Number(discount)) ? undefined : parseFloat(Number(discount).toFixed(2)),
            reason: reason || undefined
          };
          isGA4Event ? ga4Track(TRACKING_EVENT, data) : track(TRACKING_EVENT, data);
        });
      }
    } catch (err: any) {
      recordError(err, {
        originatingFunction: 'useCustomTracking-trackPromoteCodeEvent',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        customMessage: 'Failed to track promotion code event.',
        data: {
          TRACKING_EVENT,
          properties
        }
      });
    }
  };

  const trackPromoteCodeEntered = ({
    promotion,
    promotions,
    inCart,
    ...rest
  }: {
    promotion?: PromoCodeType;
    promotions?: Array<PromoCodeType>;
    inCart?: boolean;
  }) => {
    trackPromoteCodeEvent(SEGMENTS_TRACKING_EVENTS.CODE_ENTERED, {
      orderId: '',
      inCart,
      promotion,
      promotions,
      ...rest
    });
    trackPromoteCodeEvent(GA4_TRACKING_EVENTS.CODE_ENTERED, {
      orderId: '',
      inCart,
      promotion,
      promotions,
      ...rest
    });
  };

  const trackPromoteCodeApplied = ({
    promotion,
    promotions,
    inCart,
    ...rest
  }: {
    promotion?: PromoCodeType;
    promotions?: Array<PromoCodeType>;
    inCart?: boolean;
  }) => {
    trackPromoteCodeEvent(SEGMENTS_TRACKING_EVENTS.CODE_APPLIED, {
      orderId: '',
      inCart,
      promotion,
      promotions,
      ...rest
    });
    trackPromoteCodeEvent(GA4_TRACKING_EVENTS.CODE_APPLIED, {
      orderId: '',
      inCart,
      promotion,
      promotions,
      ...rest
    });
  };

  const trackPromoteCodeDenied = ({
    promotion,
    promotions,
    inCart,
    ...rest
  }: {
    promotion?: PromoCodeType;
    promotions?: Array<PromoCodeType>;
    inCart: boolean;
  }) => {
    trackPromoteCodeEvent(SEGMENTS_TRACKING_EVENTS.CODE_DENIED, {
      orderId: '',
      inCart,
      promotion,
      promotions,
      ...rest
    });

    trackPromoteCodeEvent(GA4_TRACKING_EVENTS.CODE_DENIED, {
      orderId: '',
      inCart,
      promotion,
      promotions,
      ...rest
    });
  };

  const trackPromoteCodeRemoved = ({
    promotion,
    promotions,
    inCart,
    ...rest
  }: {
    promotion?: PromoCodeType;
    promotions?: Array<PromoCodeType>;
    inCart: boolean;
  }) => {
    trackPromoteCodeEvent(SEGMENTS_TRACKING_EVENTS.CODE_REMOVED, {
      orderId: '',
      inCart,
      promotion,
      promotions,
      ...rest
    });
    trackPromoteCodeEvent(GA4_TRACKING_EVENTS.CODE_REMOVED, {
      orderId: '',
      inCart,
      promotion,
      promotions,
      ...rest
    });
  };

  const trackViewEvent = (event: EventSeasonCombined) => {
    try {
      const data = {
        event_id: event.id,
        activity: event.activity?.name || '',
        financial_org: event.financialSchoolID || '',
        financial_org_type: getFinancialOrgType(event.financialSchoolType || '') || '',
        financial_org_state: event.financialSchoolState || '',
        home_org: event.schoolHuddleId || '',
        package: event.isSeason ? 'Season' : ''
      };
      track(TRACKING_EVENTS.VIEW_EVENT, data);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackViewEvent',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        customMessage:
          'Failed to track viewing an event; a null event in data preparation or a failure in the Segment tracking function might be the issue.',
        data: { event }
      });
    }
  };

  const trackRefreshEventList = (method: string) => {
    try {
      track(TRACKING_EVENTS.REFRESH_EVENT_LIST, { method });
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackViewEvent',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        customMessage: 'Failed to track refreshing the event list.',
        data: { event }
      });
    }
  };

  const trackUseTickets = (props: UseTicketTrackingProps) => {
    try {
      const { tickets, event, boxOffice, isTransferred, orderId } = props;
      Object.values(tickets).forEach(arrTickets => {
        track(
          TRACKING_EVENTS.USE_TICKETS,
          getUseTicketTrackingProps({
            tickets: arrTickets,
            event,
            boxOffice,
            isTransferred,
            orderId
          })
        );
        ga4Track(
          GA4_TRACKING_EVENTS.USE_TICKETS,
          getUseTicketTrackingProps({
            tickets: arrTickets,
            event,
            boxOffice,
            isTransferred,
            orderId
          })
        );
      });
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackUseTickets',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        customMessage: 'Track Use Tickets failed. Check Segment track()/ga4Track functions or input data.',
        data: props
      });
    }
  };

  const trackPrintTickets = ({ tickets, event, page }: PrintTicketTrackingProps) => {
    try {
      const groupByTickets = groupBy(tickets, 'productId');

      Object.values(groupByTickets).forEach(arrTickets => {
        track(
          TRACKING_EVENTS.PRINT_TICKETS,
          getPrintTicketTrackingProps(<PrintTicketTrackingProps>{
            tickets: arrTickets,
            event,
            page
          })
        );
        ga4Track(
          GA4_TRACKING_EVENTS.PRINT_TICKETS,
          getPrintTicketTrackingProps(<PrintTicketTrackingProps>{
            tickets: arrTickets,
            event,
            page
          })
        );
      });
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackPrintTickets',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        customMessage: 'Track Print Tickets failed. Check Segment track()/ga4Track functions or input data.',
        data: { tickets, event, page }
      });
    }
  };

  const trackStoreReviewPrompt = async (promptContext: PromptContext) => {
    try {
      track(TRACKING_EVENTS.STORE_REVIEW_PROMPT, promptContext);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackUseTickets',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        customMessage: 'Tracking the store review prompt failed. Check the Segment track() function.',
        data: { promptContext }
      });
    }
  };

  const trackViewDistrictSchoolList = async (schoolDistrictListProps: SchoolDistrictListProps) => {
    try {
      track(TRACKING_EVENTS.VIEW_DISTRICT_SCHOOL_LIST, schoolDistrictListProps);
      ga4Track(GA4_TRACKING_EVENTS.VIEW_DISTRICT_SCHOOL_LIST, schoolDistrictListProps);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackViewDistrictSchoolList',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        customMessage:
          'Tracking view district school list failed. Check Segment track()/ga4Track functions or input data.',
        data: { schoolDistrictListProps }
      });
    }
  };

  const trackClickTeamSitePromo = async (teamSitePromoMetadata: TeamSitePromoClickMetadata) => {
    try {
      track(TRACKING_EVENTS.TEAM_SITE_PROMOTION, teamSitePromoMetadata);
      ga4Track(GA4_TRACKING_EVENTS.TEAM_SITE_PROMOTION, teamSitePromoMetadata);
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackClickTeamSitePromo',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        customMessage: 'Tracking team site promo click failed. Check Segment track()/ga4Track functions or input data.',
        data: { teamSitePromoMetadata }
      });
    }
  };

  const trackClickSponsorship = async (sponsorshipMetadata: SponsorshipMetadata) => {
    await Promise.all([
      track(TRACKING_EVENTS.SPONSORSHIP, sponsorshipMetadata),
      ga4Track(GA4_TRACKING_EVENTS.SPONSORSHIP, sponsorshipMetadata)
    ]).catch((e: any) => {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackClickSponsorship',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        customMessage: 'Tracking sponsorship click failed. Check Segment track()/ga4Track functions or input data.',
        data: { sponsorshipMetadata }
      });
    });
    await delay(1000);
  };

  const trackFavoriteTeam = (teamId: string, organizationId: string) => {
    try {
      track(SEGMENTS_TRACKING_EVENTS.TEAM_FAVORITED, { team_id: teamId, organization_id: organizationId });
      ga4Track(GA4_TRACKING_EVENTS.TEAM_FAVORITED, {
        team_id: teamId,
        organization_id: organizationId
      });
    } catch (e: any) {
      recordError(e, {
        originatingFunction: 'useCustomTracking-trackFavoriteTeam',
        customMessage: 'Failed to track that a team was favorited.',
        errorGroup: NEW_RELIC_ERROR_GROUPS.Segment,
        data: { team_id: teamId }
      });
    }
  };

  return {
    trackFilterEvent,
    trackSearchEvent,
    trackSearchResultSelected,
    trackFavoriteEvent,
    trackUnFavoriteEvent,
    trackUseTickets,
    trackProductRemoved,
    trackProductAdded,
    trackQuantityChange,
    trackCartViewedEvent,
    trackCheckoutStartedEvent,
    trackPaymentInfoEnter,
    trackSegmentOrderComplete,
    trackPromoteCodeApplied,
    trackPromoteCodeRemoved,
    trackPromoteCodeDenied,
    trackPromoteCodeEntered,
    trackPrintTickets,
    trackViewEvent,
    trackViewDistrictSchoolList,
    trackRefreshEventList,
    trackStoreReviewPrompt,
    trackClickTeamSitePromo,
    trackClickSponsorship,
    trackFavoriteTeam
  };
};
