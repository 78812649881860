import { css } from '@gf/cross-platform-lib/styled-components';
import { InputEmailType } from './RHFEmailInput';

export interface StyledInputProps {
  inputType: InputEmailType;
  isPayment: boolean;
  inputSize?: TextInputSize;
  invalid?: boolean;
  hasEditFields?: boolean;
}

export type TextInputSize = 'none' | 'sm' | 'md' | 'lg';

const getInputHeight = (size?: TextInputSize) => {
  switch (size) {
    case 'none':
      return 'fit-content';
    case 'sm':
      return '32px';
    case 'md':
      return '40px';
    case 'lg':
      return '48px';
    default:
      return '48px';
  }
};

export const styles = {
  wrapper: css<{ hasEditFields?: boolean }>`
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    margin-top: ${({ hasEditFields }) => (hasEditFields ? '0px' : '8px')};
    height: ${({ hasEditFields }) => (hasEditFields ? '32px' : 'fit-content')};
  `,
  inputWrapper: css<{ error: string }>`
    flex-direction: row;
    align-items: center;
    width: 100%;
  `,
  input: css<StyledInputProps>`
    box-sizing: border-box;
    color: rgb(50, 50, 50);
    background-color: white;
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    height: ${({ inputSize }) => getInputHeight(inputSize)};
    letter-spacing: 0.16px;
    padding: ${({ hasEditFields }) => (hasEditFields ? '0px' : '0 48px 0 16px')};
    width: 100%;
    border-width: ${({ hasEditFields }) => (hasEditFields ? '0px' : '2px')};
    border-style: ${({ hasEditFields }) => (hasEditFields ? 'solid' : 'solid')};
    border-radius: 4px;
    border-color: ${({ invalid }) => (invalid ? '#da1e28' : 'rgb(219, 219, 219)')};
  `,
  tailingIcon: css<{ right: string }>`
    position: absolute;
    right: ${({ right }) => right || '16px'};
    top: 0;
    bottom: 0;
    justify-content: center;
  `,
  errorWrapper: css`
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 4px;
  `
};
