import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  modalContent: css<{ maxHeight: number }>`
    padding-horizontal: 16px;
    margin-top: 16px;
    ${({ maxHeight }) => (maxHeight ? `max-height: ${maxHeight}px` : '')}
  `,
  selectionWrapper: css<{ isActive: boolean }>`
    background-color: ${({ isActive }) => (isActive ? '#dbdbdb' : '#eaeaea')};
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;
  `,
  selection: css`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `
};
