// @ts-nocheck
import * as React from 'react';
import { SvgExtProps } from '../types';
import Svg, { Path } from 'react-native-svg';

export const Export = (props: SvgExtProps) => (
  <Svg
    id='gf_icon_export'
    aria-label='Export icon'
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    viewBox='0 0 32 32'
    {...props}
  >
    <Path d='M26 24v4H6v-4H4v4l.008-.005A1.998 1.998 0 006 30h20a2 2 0 002-2v-4z' />
    <Path d='M6 12L7.411 13.405 15 5.825 15 24 17 24 17 5.825 24.591 13.405 26 12 16 2 6 12z' />
    <Path id='Export_Transparent_Rectangle_' data-name='&lt;Transparent Rectangle&gt;' d='M0 0H32V32H0z' fill='none' />
  </Svg>
);
