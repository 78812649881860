import { css } from '@gf/cross-platform-lib/styled-components';
export const styles = {
  container: css`
    flex-direction: column;
    padding: 0 16px;
    background: #f4f4f4;
  `,
  contentContainer: css`
    margin: 0 -16px;
  `,
  title: css`
    margin-bottom: 4px;
  `,
  subTitle: css``,
  actionButtons: css`
    flex-direction: row;
    margin-vertical: 12px;
  `
};
