import { css } from 'styled-components';

export const styles = {
  info: css`
    margin-top: 16px;
    margin-bottom: 8px;
  `,
  content: css`
    padding: 16px 16px 20px;
  `
};
