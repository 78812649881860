// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const ChevronDown = (props: SvgProps) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    viewBox='0 0 32 32'
    style={{
      enableBackground: 'new 0 0 32 32'
    }}
    xmlSpace='preserve'
    {...props}
  >
    <Path d='M16 22 6 12l1.4-1.4 8.6 8.6 8.6-8.6L26 12z' />
    <Path
      style={{
        fill: 'none'
      }}
      d='M0 0h32v32H0z'
    />
  </Svg>
);
