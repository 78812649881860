import { css } from '@gf/cross-platform-lib/styled-components';
export const styles = {
  fundraiserProgressBarContainer: css<{}>`
    display: flex;
  `,
  progressBar: css<{ isMobile: boolean }>`
    height: 10px;
    border-radius: 4px;
    border: 1px solid rgb(50, 50, 50);
    display: flex;
    flex-flow: row nowrap;
    z-index: 0;
  `,
  progressBarText: css<{}>`
    color: rgb(50, 50, 50);
    font-size: 12px;
    font-family: Rubik-SemiBold;
  `,
  line1: css<{ isMobile: boolean }>`
    position: absolute;
    bottom: -1px;
    left: 25%;
    width: 1px;
    height: 10px;
    border: 1px solid rgb(255, 255, 255);
    z-index: 1;
  `,
  line2: css<{}>`
    position: absolute;
    bottom: -1px;
    left: 50%;
    width: 1px;
    height: 10px;
    border: 1px solid rgb(255, 255, 255);
    z-index: 1;
  `,
  line3: css<{}>`
    position: absolute;
    bottom: -1px;
    left: 75%;
    width: 1px;
    height: 10px;
    border: 1px solid rgb(255, 255, 255);
    z-index: 1;
  `,
  textWrapper: css<{
    donationQuarterRaised: boolean;
    donationPercentString: string;
  }>`
    align-items: ${({ donationQuarterRaised }) => (donationQuarterRaised ? `flex-start` : `flex-end`)};
    right: ${({ donationQuarterRaised, donationPercentString }) =>
      donationQuarterRaised ? '0%' : donationPercentString};
  `,
  percentTextWrapper: css<{ isEventDetails: boolean }>`
    padding-top: 8px;
    padding-bottom: ${({ isEventDetails }) => (isEventDetails ? `32px` : `0px`)};
  `
};
