// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

export const DesktopWarning = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 62 60' fill='currentColor' {...props}>
    <G fill='none' fillRule='evenodd'>
      <Path fill='#323232' d='M24 16h16v41H24z' />
      <Path d='M0 .5h56v56H0z' />
      <Path
        d='M60.982 53.666 35.264 6.09a4.005 4.005 0 0 0-7.035 0L2.25 54.117C.813 56.767 2.736 60 5.768 60h51.98c2.21 0 4-1.783 4-3.986 0-.875-.285-1.69-.766-2.348Zm-29.234-8.105a4.52 4.52 0 0 1 4.527 4.513 4.517 4.517 0 0 1-4.527 4.512 4.517 4.517 0 0 1-4.527-4.512 4.52 4.52 0 0 1 4.527-4.513Zm2.715-25.608c1.136.236 1.925 1.428 1.82 2.49-.492 4.684-.976 10.334-1.95 15.578-.596 3.144-4.56 3.185-5.175 0-.973-5.244-1.45-10.894-1.941-15.578-.11-1.062.765-2.254 1.906-2.49 2.07-.406 3.258-.406 5.34 0Z'
        fill='#F1C21B'
        fillRule='nonzero'
      />
    </G>
  </Svg>
);
