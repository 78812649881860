// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';

export const Edit = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' width={20} height={20} fill='none' {...props}>
    <Path
      fill={props.figurecolor ? props.figurecolor : '#323232'}
      fillRule='evenodd'
      d='m13.625 1.625 2.25 2.25c.5.5.5 1.25 0 1.75L6.5 15h-4v-4l9.375-9.375c.5-.5 1.25-.5 1.75 0ZM15 4.75 12.75 2.5l-1.875 1.875 2.25 2.25L15 4.75Zm-11.25 9V11.5L10 5.25l2.25 2.25L6 13.75H3.75Zm15 3.75v-1.25H1.25v1.25h17.5Z'
      clipRule='evenodd'
    />
  </Svg>
);
