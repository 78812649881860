// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Path } from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: style */

export const LocationFilled = (props: SvgProps) => (
  <Svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' fill='currentColor' {...props}>
    <Path d='M10.8 3.6C7.7 5.2 5 9.7 5 13.3c0 2.6 4.5 10 8.4 13.6l2.6 2.4 2.6-2.4c3.9-3.6 8.4-11 8.4-13.6C27 7.6 21.4 1.9 15.8 2c-1.3.1-3.5.7-5 1.6zm8.2 6.9c2.6 3.2-1.3 8.1-4.7 5.9-2.3-1.4-2.8-4.1-1.3-5.9 1.6-1.9 4.4-1.9 6 0z' />
  </Svg>
);
