import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './EventToolTipModal.styles';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';

const ModalWrapper = styled.View`
  ${styles.modalWrapper};
`;

const SubTitle = styled.Text`
  ${styles.subTitle};
`;

export const EventToolTipModal = () => {
  const { isMobile } = useMediaQuery();

  const modalWidth = 256;

  return (
    <ModalWrapper isMobile={isMobile} width={modalWidth && isMobile ? modalWidth : 320}>
      <SubTitle isMobile={isMobile} marginBottom={isMobile ? 0 : 48}>
        {`Tickets for this event must be presented using your mobile device, ` +
          `via GoFan app of the gofan.co mobile site. You will not be able to print tickets for this event. ` +
          `Screenshots are not accepted for entry.`}
      </SubTitle>
    </ModalWrapper>
  );
};
