// @ts-nocheck
import * as React from 'react';
import Svg, { SvgProps, Polygon } from 'react-native-svg';

export const ChevronRight = (props: SvgProps) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    fill='currentColor'
    viewBox='0 0 32 32'
    style={{
      enableBackground: 'new 0 0 32 32'
    }}
    xmlSpace='preserve'
    {...props}
  >
    <Polygon points='22,16 12,26 10.6,24.6 19.2,16 10.6,7.4 12,6 ' />
  </Svg>
);
