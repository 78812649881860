//import { CSSObject } from 'styled-components';

export const buttonSizes = {
  sm: {
    height: '32px',
    padding: '0 24px'
  },
  md: {
    height: '40px'
  },
  lg: {
    height: '48px',
    paddingHorizontal: '40px'
  }
};

export const buttonBaseStyles = {
  base: {
    justifyContent: 'center',
    background: '#323232',
    borderRadius: '4px',
    height: '48px',
    padding: '0 38px'
  },
  primary: {
    backgroundColor: 'rgb(50, 50, 50)'
  },

  secondary: {
    backgroundColor: 'rgb(50, 50, 50)'
  },

  tertiary: {
    backgroundColor: 'rgb(244, 244, 244)',
    borderColor: 'rgb(50, 50, 50)',
    borderWidth: '1px'
  },

  ghost: {
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  },

  'ghost--border': {
    backgroundColor: 'transparent',
    border: '1px solid black'
  },

  danger: {
    backgroundColor: 'rgb(218, 30, 40)',
    borderColor: 'transparent'
  },

  'danger--tertiary': {
    backgroundColor: 'rgb(255, 255, 255)',
    borderColor: 'rgb(218, 30, 40)',
    borderWidth: '1px'
  },

  'danger--ghost': {
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  }
};
